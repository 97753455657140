import React from "react";
import { Button, Col, FormGroup } from "reactstrap";

const SubmitButtons = ({ isLoading, handleBackHome, foodItem }) => {
    return (
        <Col md={12}>
            <FormGroup className="mt-2 d-flex justify-content-end">
                <Button className="btn-white mr-3 px-5" size="lg" disabled={isLoading} onClick={handleBackHome}>
                    Cancel
                </Button>
                <Button className="btn-primary --bg-primary px-5" size="lg" disabled={isLoading}>
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    {foodItem ? "Save" : "Add"}
                </Button>
            </FormGroup>
        </Col>
    );
};

export default SubmitButtons;