import React, { useState, useRef, createRef, useEffect } from 'react'
import { Icon } from '@components/common'
import Result from '@images/teachV2/stick-no-bg.svg'
// import Edit from '@images/teachV2/edit.svg'
import { Button, Col, Row } from 'reactstrap'
import GenerateItem from './GenerateItem'
import ModalEditRequirement from './ModalEditRequirement'
import axios from "axios";
import * as Config from "@constants/Config";
import stick from '@images/teachV2/stick-btn.svg'
import ModalConfirm from './ModalConfirm'
import { listGenerate } from "@utils";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { toast } from 'react-toastify';
import { apiCaller } from "@utils";
import Convert from '@images/teachV2/convert.svg'
import ModalConfirmConvert from './ModalConfirmConvert'
import { ModalFeedback } from '../../home/common'
import { BetaComponent } from '@components/common/Beta'
import Select from "react-select";
import ModalUpdateModule from './ModalUpdateModule'
import { loadCaseScript } from "@actions";
import { useDispatch } from 'react-redux'
import ScriptElement from './ScriptElement'

const ResultComponent = (props) => {
  const dispatch = useDispatch();
  const { watch, dataFileImported, dataContentFile, dataLoadCase, setDataContentFile, idScript, showNotificationCaseScript } = props
  const [dataCaseScript, setDataCaseScript] = useState(dataLoadCase ? dataLoadCase : null)
  const [loadingGenerateScript, setLoadingGenerateScript] = useState(false)
  const [listChooseGenerate, setListChooseGenerate] = useState([])
  const [dataFeedback, setDataFeedback] = useState()
  const [dataUpdate, setDataUpdate] = useState()
  const [modalOpen, setModalOpen] = useState({
    editRequired: false,
    confirmSave: false,
    editVital: false,
    confirmConvert: false,
    modalGiveFeedback: false,
    updateModule: false,
  });

  const refDefault = useRef()
  let lineRefs = useRef([]);
  lineRefs.current = dataCaseScript?.case_sections?.length > 0 && dataCaseScript?.case_sections?.map((_, i) => lineRefs.current[i] ?? createRef());
  useEffect(() => {
    if (dataCaseScript?.case_sections) {
      lineRefs.current = dataCaseScript?.case_sections?.map((_, i) => lineRefs.current[i] ?? createRef());
    }
  }, [dataCaseScript?.case_sections]) 

  // Handle Toogle Modal
  const toggleModal = (type) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
  };

  useEffect(() => {
    setDataCaseScript(dataLoadCase)
  }, [dataLoadCase])

  const handleChooseGenerate = (data) => {
    if (listChooseGenerate?.includes(data?.value)) {
      setListChooseGenerate(() => {
        return listChooseGenerate.filter(item => item !== data?.value)
      })
    } else {
      const dataAdd = listChooseGenerate.concat([data?.value])
      setListChooseGenerate(dataAdd)
    }
  }

  const handleGenerateScript = () => {
    setLoadingGenerateScript(true)
    const formData = new FormData();
    dataFileImported && formData.append("document_file", dataFileImported);
    formData.append("action_type", 'case_creation_pipeline');
    formData.append("template_id", watch('template_id'));
    formData.append("gpt_model", watch('gpt_model'));
    formData.append("step", "case_script");
    formData.append("id", dataContentFile?.id);
    dataContentFile?.augmented_document_ids && formData.append("document_id", dataContentFile?.augmented_document_ids);
    formData.append("prompt", watch('prompt'));
    formData.append("learning_objectives", dataContentFile?.learning_objectives);

    axios({
      method: "POST",
      url: `${Config.API_URL}/api/medicalcase/functional/`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      if(response.status === 200) {
        let params = {
          action_type: "load_case_script",
          id: idScript,
        }
        dispatch(loadCaseScript(params))
        showNotificationCaseScript("case_script")
      }
    }).catch(function (error) {
      console.error("Error in API call", error);
    })
    .finally(() => {
      setLoadingGenerateScript(false)
    })
  }

  const handleGenerateFinal = async (step, sections) => {
    setLoadingGenerateScript(true)
    const formData = new FormData();
    dataFileImported && formData.append("document_file", dataFileImported);
    formData.append("action_type", 'case_creation_pipeline');
    formData.append("template_id", watch('template_id'));
    formData.append("gpt_model", watch('gpt_model'));
    formData.append("id", dataContentFile?.id);
    dataContentFile?.augmented_document_ids && formData.append("document_id", dataContentFile?.augmented_document_ids);
    formData.append("prompt", watch('prompt'));
    formData.append("learning_objectives", dataContentFile?.learning_objectives);
    formData.append("step", step);
    formData.append("case_sections", JSON.stringify(sections));

    return await axios({
      method: "POST",
      url: `${Config.API_URL}/api/medicalcase/functional/`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }).then((response) => {
      if (response?.data) {
        if(response.status === 200) {
          let params = {
            action_type: "load_case_script",
            id: idScript,
          }
          dispatch(loadCaseScript(params))
          showNotificationCaseScript(step)
        }
      }
      return response?.data
    }).catch(function (error) {
      console.error("Error in API call", error);
    })
    .finally(() => {
      setLoadingGenerateScript(false)
    })
  }

  const GenerateStep = async () => {
    // eslint-disable-next-line array-callback-return
    const listFilter = listGenerate?.filter((generate) => {
      if (listChooseGenerate.includes(generate?.value)) {
        return generate?.value
      }
    })
    if (listFilter?.length) {
      const dataTest = await handleGenerateFinal(listFilter[0].value, dataCaseScript.case_sections)
      if (dataTest && listFilter?.length > 1) {
        const dataTest2 = await handleGenerateFinal(listFilter[1].value, dataTest.case_sections)
        if (dataTest2 && listFilter?.length > 2) {
          await handleGenerateFinal(listFilter[2].value, dataTest2.case_sections)
        }
      }
      setListChooseGenerate([])
    }
  }

  const handleSave = (dataRef, title) => {
    if (dataCaseScript?.case_sections) {
      const dataCloneFile = {
        ...dataContentFile,
        learning_objectives: refDefault?.current?.innerHTML
      }
      setDataContentFile(dataCloneFile)
      const dataChange = dataCaseScript?.case_sections?.map((caseSection) => {
        if (caseSection?.name === title) {
          return {
            ...caseSection,
            contents: dataRef?.current?.innerHTML
          }
        }
        return caseSection
      })
      setDataCaseScript(prev => {
        return {
          ...prev,
          case_sections: dataChange,
        }
      })
      const dataClone = {
        ...dataCaseScript,
        case_sections: dataChange,
        learning_objectives: refDefault?.current?.innerHTML
      }
      const params = {
        "action_type": "save_case_script",
        ...dataClone
      }
      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
        if (res.status === 200) {
          toast.success(`Case contents have been updated!`, {
            position: "top-right"
          });
        }
      });
    } else {
      const dataClone = {
        ...dataContentFile,
        learning_objectives: refDefault?.current?.innerHTML
      }
      setDataContentFile(dataClone)
      const params = {
        "action_type": "save_case_script",
        ...dataClone
      }
      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
        if (res.status === 200) {
          toast.success(`Case contents have been updated!`, {
            position: "top-right"
          });
        }
      });
    }
  }

  const Option = ({ innerProps, label, data, value }) => {
    return (
      <div
        {...innerProps}
        className='option-detail'
        style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
        <span className="react-select-item">Module #{data}</span>
      </div>
    );
  };

  // const checkListGenerate = () => {
  //   let list = dataCaseScript?.case_sections?.filter(section => section?.name === "Diagnosis" || section?.name === "Management" || section?.name === "Questions")
  //   if (list?.length > 0) {
  //     return false
  //   } else {
  //     return true
  //   }
  // } 

  return (
    <Row>
      <Col md={9} className='px-2'>
        <div className="resultComponent">
          <div className="resultComponent__header w-100">
            <div className="resultComponent__header-title">
              <div className="result-icon">
                <Icon src={Result}/>
              </div>
              <p>Results</p>
            </div>
            <div className="resultComponent__header-copy">
              {/* <Button
                color='primary'
                onClick={() => toggleModal("confirmSave")}
              >
                <Icon src={Save}/>
                Save to Library
              </Button> */}
              {/* <Button>
                <Icon src={Export}/>
                Export
              </Button> */}
              {dataCaseScript?.related_module_ids?.length > 0 && (
                <Select
                  className="react-select-common"
                  placeholder="Update Interactive Module"
                  // value={dataCaseScript?.related_module_ids}
                  options={dataCaseScript?.related_module_ids}
                  onChange={val => {
                    toggleModal("updateModule")
                    setDataUpdate(val)
                  }}
                  classNamePrefix="react-select-custom"
                  components={{
                    IndicatorSeparator: () => null,
                    Option: Option
                  }}
                />
              )}
              <Button
                onClick={() => toggleModal("confirmConvert")}
              >
                <Icon src={Convert} stroke="#0089C2"/>
                Convert to NEW Interactive Module
                <BetaComponent />
              </Button>
              {/* <Button>
                <Icon src={Copy} stroke="#0089C2"/>
                Copy All
              </Button> */}
            </div>
          </div>
          <div className="resultComponent__title w-100">
            <p className="resultComponent__title-label">Case Script Title:</p>
            <p className="resultComponent__title-content">{dataCaseScript?.title || dataContentFile?.title}</p>
          </div>
          <div className="resultComponent__required w-100">
            <div className="resultComponent__required-header">
              <p>Requirements</p>
              {/* <div>
                <Icon src={Edit}/>
              </div> */}
            </div>
            <p className="resultComponent__required-content">{watch('prompt')}</p>
          </div>
          <div className="resultComponent__generate w-100">
            <GenerateItem
              handleGenerateScript={handleGenerateScript}
              dataCaseScript={dataCaseScript}
              contentGenerate={dataContentFile?.learning_objectives} 
              loadingGenerateScript={loadingGenerateScript}
              handleSave={handleSave}
              dataRef={refDefault}
              index={0}
              toggleModal={toggleModal}
              setDataFeedback={setDataFeedback}
              {...props} 
            />
            {dataCaseScript?.case_sections && 
              <>
                {dataCaseScript?.case_sections?.map((section, index) => (
                  <GenerateItem
                    dataRef={lineRefs.current[index]}
                    key={index}
                    index={index+1}
                    dataCaseScript={dataCaseScript} 
                    handleGenerateScript={handleGenerateScript}
                    contentGenerate={section?.contents} 
                    titleGenerate={section?.name}
                    handleSave={handleSave}
                    toggleModal={toggleModal}
                    setDataFeedback={setDataFeedback}
                    {...props} 
                  />
                ))}
                {loadingGenerateScript && 
                  <div className="generateItem w-100">
                    <Skeleton height={20} width={`100%`} />
                    <Skeleton height={20} width={`75%`} />
                    <Skeleton height={20} width={`60%`} />
                    <Skeleton height={20} width={`80%`} />
                    <Skeleton height={20} width={`55%`} />
                  </div>
                }
                {/* {checkListGenerate() && ( */}
                  <div className="caseGenerate">
                    <p>Would you like to generate additional elements? <br /> Select the ones you need and click ‘Generate’.</p>
                    <div className="caseGenerate-list">
                      {listGenerate?.map((dataGen, index) => (
                        // dataCaseScript?.case_sections?.some(a => a.name === dataGen.name) && (
                          <span
                            className={`${listChooseGenerate?.includes(dataGen?.value) ? "active-item" : ""} caseGenerate-list__item`}
                            onClick={() => handleChooseGenerate(dataGen)}
                            key={index}
                          >
                            {dataGen.label}
                          </span>
                        // )
                      ))}
                    </div>
                    <div className={`${loadingGenerateScript ? "btn-disable" : ""} caseGenerate-btn`} onClick={GenerateStep}>
                      <Icon src={stick}/>
                      Generate 
                    </div>
                  </div>
                {/* )} */}
              </>
            }
          </div>
          <ModalEditRequirement
            isOpen={modalOpen.editRequired}
            toggle={() => toggleModal("editRequired")}
          />
          <ModalConfirm 
            isOpen={modalOpen.confirmSave}
            dataContentFile={dataContentFile}
            dataCaseScript={dataCaseScript}
            toggle={() => toggleModal("confirmSave")}
          />
          <ModalConfirmConvert 
            isOpen={modalOpen.confirmConvert}
            toggle={() => toggleModal("confirmConvert")}
            idScript={idScript || dataContentFile?.id || dataCaseScript?.id}
          />
          <ModalUpdateModule 
            isOpen={modalOpen.updateModule}
            toggle={() => toggleModal("updateModule")}
            idScript={idScript || dataContentFile?.id || dataCaseScript?.id}
            dataUpdate={dataUpdate}
          />
          <ModalFeedback
            isOpen={modalOpen.modalGiveFeedback}
            toggle={() => toggleModal("modalGiveFeedback")}
            type="case_script"
            dataFeedback={dataFeedback}
            idFeedback={dataContentFile?.id}
          />
        </div>
      </Col>
      <Col md={3} className='px-2'>
        <ScriptElement
          dataCaseScript={dataCaseScript}
          lineRefs={lineRefs}
          refDefault={refDefault}
          {...props}
        />
      </Col>
    </Row>
  )
}

export default ResultComponent
