import React from 'react';

const SortCaret = ({ order }) => {
    if (!order)
        return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
                <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
        );
    else if (order === "asc")
        return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
    else if (order === "desc")
        return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
    return null;
};

export default SortCaret;