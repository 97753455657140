import React from 'react';
import { ButtonTaskItem } from "@components/learner/StandardScreen/CaseTask";
import { useParams } from "react-router-dom";
import { getIconForObject } from "@helpers";
import { useSelector, useDispatch } from "react-redux";
import { toggleExamMobile } from "@actions";
import { useMediaQuery } from 'react-responsive';

function MessageButton({ TaskToResume, handleClickBtnNextTimePoint, handleButtonMessageChat, isViewChatFullScreen }) {
    const { id } = useParams();
    const iconPath = getIconForObject(TaskToResume?.Task);
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const CaseTask = InterfaceTemplateDetails?.CaseTask;
    const dispatch = useDispatch();

    const isMobile = useMediaQuery({ query: `(max-width: 768px)` }); // Sử dụng useMediaQuery để kiểm tra mobile

    const handleClick = () => {
        if (isMobile && TaskToResume?.Task === "exam") {
            dispatch(toggleExamMobile(true));
        } else if (TaskToResume?.Task === "advance") {
            handleClickBtnNextTimePoint(id, "advance");
        } else {
            handleButtonMessageChat(TaskToResume?.Task, isViewChatFullScreen);
        }
    };

    return (
        <div className="message-answer__button ml-auto" onClick={handleClick}>
            <div className="tasks-nav">
                {TaskToResume?.Task === "advance" ? (
                    <button className="button-yellow">{`${CaseTask?.button_next_tp?.Text || "Next Timepoint"}`}</button>
                ) : (
                    <ButtonTaskItem
                        label={TaskToResume?.Task}
                        attributeTask={TaskToResume?.Task}
                        icon={iconPath}
                        isViewMessage={true}
                    />
                )}
            </div>
        </div>
    );
}

export default MessageButton;