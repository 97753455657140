import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@components/common/Tabs";

//components
import {
    SettingsPNOrders,
    InstructionsPNOrders,
    ViewModePNOrders,
    QuestionsPNOrders,
} from "../ManageAvailableTasks/PNOrders";
import { HeadingTask } from "../ManageAvailableTasks";

// redux
import { useSelector } from "react-redux";

const PNOrdersMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    const statusOptions = [
        { key: 1, label: "Settings", content: <SettingsPNOrders {...props} /> },
        { key: 2, label: "Instructions", content: <InstructionsPNOrders {...props} /> },
        { key: 3, label: "View Mode", content: <ViewModePNOrders {...props} /> },
        { key: 4, label: "Questions", content: <QuestionsPNOrders {...props} /> }
    ];

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
    useEffect(() => {
        if (questionViewActive === "Blank_Question") {
            setActiveTab(3); // 3 corresponds to the "Questions" tab
        }
    }, [questionViewActive]);

    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`PN Orders`}
                handleClickBtn={() => console.log("handleClickBtn")}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default PNOrdersMain;