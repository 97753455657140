import React from "react";
import { TabContent, TabPane } from "reactstrap";

import { DataEmty } from "@components-common";

//components
import { OrderList } from '.';

//lodash
import _ from "lodash";

function AvailableOrders(props) {
  //props
  const { data, handleChangeItem, navListGroup, activeTab } = props && props;

  return (
    <div className="available-orders-box pd-order-box">
      <TabContent activeTab={activeTab}>
        {navListGroup?.map((value, index) => {
          const filterData = _.filter(data, { 'Category': value.Name });
          const OrdersData = filterData && filterData[0]?.Orders;
          return (
            <TabPane tabId={String(index + 1)} key={index}>
              {OrdersData?.length > 0 ? (
                <OrderList
                  {...props}
                  data={OrdersData}
                  handleChangeItem={handleChangeItem}
                  value={value}
                />
              ) : (
                <div style={{ height: 500 }}>
                  <DataEmty content="No available orders for this cateogory" />
                </div>
              )}
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
}

export default AvailableOrders;
