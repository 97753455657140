import React, { useState } from 'react';

//Boostrap
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//components
import { MainTabs } from '../schemav2/tabs';

//api
import { apiCaller } from "@utils";
import { schemaContanst } from "@constants";

function ModalAddDianostic(props) {
    const dispatch = useDispatch();
    const { isOpen, toggle, data, QuestionId } = props && props;
    let questionConceptId = data?.question_concept_id;
    let typeCurrent = data?.type === 'Feature' ? 'Clinical Feature' : 'Diagnosis';

    //State
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [stateCUID, setCUID] = useState('');
    const [isIdChecked, setIdChecked] = useState('');
    const [isSearching, setSearching] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [type, setType] = useState('');

    const hanldeSubmit = () => {
        const params = {
            "Action": "AddConcept",
            "QuestionId": QuestionId,
            "ParentQuestionConceptId": questionConceptId || null,
            "ObjectType": typeCurrent
        }

        if(type === 'general') {
            params['Name'] = stateCUID
        } else {
            params['CUID'] = stateCUID
        }

        const isLoading = true;
        setLoading(isLoading);
        //Call Api
        dispatch({ type: schemaContanst.ADD_CONCEPT_REQUEST, isLoading });
        apiCaller(`/api/teach/concept/`, "POST", params).then((res) => {
          const data = res && res.data;
          if (res && res.status === 200) {
            dispatch({type: schemaContanst.ADD_CONCEPT_SUCCESS, payload: data});
    
            //Close
            toggle();
            setLoading(false);

            //resetData
            setSearchTerm('');
            setResults([]);
            setCUID('');
          } else {
            dispatch({ type: schemaContanst.ADD_CONCEPT_FAILURE, error: 'error'});
            setLoading(false);
          }
        });
    }

    const hanldeChange = (id) => {
        setCUID(id);
        setIdChecked(id);
    }

    const hanldeSearch = () => {
        const params = {
            "Action": "Search",
            "Keyword": searchTerm
        }

        setSearching(true);
        dispatch({ type: schemaContanst.SEARCH_CONCEPT_REQUEST, isLoading: true });
        //Call Api
        apiCaller(`/api/teach/concept/`, "POST", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({ type: schemaContanst.SEARCH_CONCEPT_SUCCESS, payload: data });
                // Set results state
                setResults(data);
                setSearching(false);
            } else {
                dispatch({
                    type: schemaContanst.SEARCH_CONCEPT_FAILURE,
                    error: "error"
                });
                setSearching(false);
                return [];
            }
        });
    }

    const onChangeInputSearch = (e, type) => {
        const { value } = e.target;
        setType(type);
        setSearchTerm(value)
        if(type === 'general') {
            setCUID(value);
        }
    }

    return (
        <Modal
            toggle={toggle}
            isOpen={isOpen}
            modalClassName={`right ${isOpen ? "in" : ""}`}
            wrapClassName={`modalFixed`}
        >
            <ModalHeader
                toggle={toggle}>
                Add A {data?.type ? data?.type : ''}
            </ModalHeader>
            <ModalBody>
                <MainTabs
                    // umls
                    hanldeSearch={hanldeSearch}
                    onChangeInputSearch={onChangeInputSearch}
                    searchTerm={searchTerm}
                    results={results}
                    isSearching={isSearching}
                    hanldeChange={hanldeChange}
                    isIdChecked={isIdChecked}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    className="btn btn-brand --bg-primary d-flex align-items-center"
                    onClick={hanldeSubmit}
                    disabled={stateCUID === '' ? true : false}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>} Add
                </Button>
                {' '}
                <Button onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddDianostic;