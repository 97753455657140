import React, { useEffect, useState } from "react";

//components
import { ModelItem, ModelEditModel, ModelAddModel } from "../Models";
import { ModalDeleteCommon } from "@components/common/Modal";

//reactstrap
import { Button } from "reactstrap";

//call Api
import { apiCaller } from "@utils";

function ModelsContent(props) {
  //state
  const [stateData, setStateData] = useState([]);
  const [currentItem, setItem] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    addModel: false,
    editModel: false,
    deleteModel: false,
  });

  //Load List
  useEffect(() => {
    const fetchData = () => {
      const params = { "Action": "ListModels" }
      apiCaller(`/api/binh/model_management/nlp_model/`, "POST", params).then((res) => {
        if (res?.status === 200) {
          setStateData(res?.data);
        } else {
          console.log('error');
        }
      });
    }
    //Load Api Model
    fetchData();
  }, [])

  //Load Api Model
  const hanldeUpdateList = (newData) => {
    setStateData(newData);
  }

  // Delete NLP Model
  const handleRemoveDeleteNLPModel = (item) => {
    const params = {
      "Action": "DeleteModel",
      "ModelId": item?.model_id
    }
    setLoading(true);
    apiCaller(`/api/binh/model_management/nlp_model/`, "POST", params).then((res) => {
      const newData = res?.data;
      if (res?.status === 200) {
        hanldeUpdateList(newData);
        setLoading(false);
        setModal((prevState) => ({ ...prevState, deleteModel: false }));
      } else {
        console.log('error');
        setLoading(false);
        setModal((prevState) => ({ ...prevState, deleteModel: false }));
      }
    });
  }

  const toggle = (type, value) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    setItem(value);
  };
  
  return (
    <React.Fragment>
      <Button
        className="btn btn-sm mb-4"
        color="primary"
        onClick={() => toggle('addModel', {})}
      >
        <i className="fa fa-plus mr-2"></i>Add New Model
      </Button>

      <ModelItem
        data={stateData || []}
        handleOpenItem={toggle}
      />
      <ModelEditModel
        {...props}
        isOpen={modal.editModel}
        currentItem={currentItem}
        toggleModal={() => toggle('editModel', currentItem)}
        hanldeUpdateList={hanldeUpdateList}
      />
      <ModelAddModel
        {...props}
        isOpen={modal.addModel}
        toggleModal={() => toggle('addModel', currentItem)}
        hanldeUpdateList={hanldeUpdateList}
      />
      <ModalDeleteCommon
        title={`Are you sure you want to delete this model?`}
        sub={`This action is not reversible`}
        isOpen={modal?.deleteModel}
        toggle={() => toggle('deleteModel', currentItem)}
        handleRemove={() => handleRemoveDeleteNLPModel(currentItem)}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
}

export default ModelsContent;