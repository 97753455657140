import React from 'react';

//Icon
import iconStreak from '@images/new_interface/streak.svg';

//redux
import { useSelector } from "react-redux";

const XpBox = () => {
    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
    const XPData = AccountInfoData?.Account?.xp_data;
    const streakData = AccountInfoData?.Account?.streak;
    const TotalXP = XPData?.TotalXP;
    return (
        <div className="info-xp">
            <div className="streakBox">
                <img className="streakBox__icon" src={iconStreak} alt="icon streak" />
                <span className="streakBox__text">{streakData}</span>
            </div>
            <div className="XpBox">
                <div className="XpBox__icon">
                    XP
                </div>
                <div className="XpBox__info">
                    {TotalXP}
                </div>
            </div>
        </div>
    );
};

export default XpBox;