import React, { useState } from "react";
import ModalUploadReferenceDocument from "./ModalUploadReferenceDocument";

const WrapModalUpload = ({ children, ...props}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () =>  {
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      {children({ onOpen: handleOpen })}
      {open && 
        <ModalUploadReferenceDocument
          isOpen={open}
          toggleModal={handleClose}
          {...props}
        >
        </ModalUploadReferenceDocument>
      }
    </>
  )
}

export default WrapModalUpload
