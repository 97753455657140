import React, { useState } from 'react';

//elements
import { ButtonDelete } from '@components/common/elements';
import { ModalDeleteCommon } from '@components-common/Modal';

//components 
// import { ModalDelete } from '../Chat';

//redux
import { useDispatch } from "react-redux";

//call Action
import { deleteStandardChatTopic } from "@actions";

function ActionDelete(props) {
  const dispatch = useDispatch();

  //props
  const { item, ParentChatTopicId } = props && props;

  //state
  const [modal, setModal] = useState({
    delete: false,
  });
  const [itemCurrent, setItem] = useState((item && item) || {});

  const toggle = (type, value) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    setItem(value);
  };

  const handleRemove = () => {
    const params = {
      "Action": "DeleteStandardChatTopic",
      "ChatTopicId": item?.Id
    }
    dispatch(deleteStandardChatTopic(ParentChatTopicId, (item?.ParentTopic?.Type || item?.Type), params));
    toggle("delete", {});
  }

  return (
    <td className="text-center align-middle">
      <ButtonDelete handleDelete={() => toggle("delete", itemCurrent)} />
      <ModalDeleteCommon
        title={`Are you sure you want to remove this account from your subscription?`}
        sub={`The account will no longer have access to the contents that are limited to your subscription`}
        isOpen={modal.delete}
        toggle={() => toggle("delete", itemCurrent)}
        handleRemove={handleRemove}
      />
    </td>
  );
};

export default ActionDelete;