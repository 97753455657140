import React, { useState } from 'react';
import IconERM from '@images/learnerv2/icon-erm-sp2.svg';
import styled from 'styled-components';
import { NarativePopup } from "@components/learner/StandardScreen/LearnerMobile/Narative";

const ERMButtonContainer = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 49.116px;
  background: #FFF;
  border: none;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

const ERMButtonFixed = (props) => {
  const [activePopup, setActivePopup] = useState(false);

  const handleOpenModal = () => {
    setActivePopup(true);
  };

  const handleCloseModal = () => {
    setActivePopup(false);
  };

  return (
    <>
      <ERMButtonContainer onClick={handleOpenModal}>
        <Icon src={IconERM} alt="ERM Icon" />
      </ERMButtonContainer>
      {activePopup && <NarativePopup {...props} onClose={handleCloseModal} />}
    </>
  );
};

export default ERMButtonFixed;