import React, { useState } from 'react'
import { HomeGenerate } from '@components/teachV2/home'
import { useForm } from 'react-hook-form'
import * as Config from "@constants/Config";
import axios from "axios";
import { TourProvider } from '@reactour/tour'
import { steps } from "@utils/stepTour";
import { useDispatch } from "react-redux";
import { getCoin, getCoinMcq } from "@actions";

export default function TeachMcq() {
  const dispatch = useDispatch();
  const [dataContentFile, setDataContentFile] = useState([])
  const [loadingResult, setLoadingResult] = useState(false)
  const [dataFileImported, setDataFileImported] = useState(null)
  const [bookId, setBookId] = useState(null)
  const [mediaId, setMediaId] = useState(null)

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch
  } = useForm({
    defaultValues: {
      use_media: 'False'
    }
  })

  const handleUpload = (data, file, bookId) => {
    // Lấy tất cả document_id
    const documentIds = bookId?.map(doc => doc.document_id);
    // Tạo chuỗi ngăn cách bởi dấu phẩy
    const documentIdString = documentIds?.join(',');

    // Tạo đối tượng FormData
    const formData = new FormData();
    setDataContentFile([])
    if (file) formData.append("document_file", file);
    formData.append("template_id", data?.template_id);
    formData.append("gpt_model", data?.gpt_model);
    formData.append("number_questions", "4");
    formData.append("prompt", data?.prompt);
    formData.append("use_media", data?.use_media);
    if (documentIdString) formData.append("document_id", documentIdString);
    mediaId && formData.append("media_item_id", mediaId[0]?.id);

    // Bắt đầu tải kết quả
    setLoadingResult(true);

    // Gửi yêu cầu HTTP
    axios({
      method: "POST",
      url: `${Config.API_URL}/api/medicalcase/question-stream/`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
      onDownloadProgress: progressEvent => {
        const xhr = progressEvent.target;
        const { responseText } = xhr;
        let datasplit = responseText.split("event: question\ndata: ");
        setDataContentFile(
          datasplit?.map((data, index) => {
            return index > 0 && JSON.parse(data);
          })
        );
      }
    }).then(function (response) {
      let datasplit = response.data.split("event: question\ndata: ");
      setDataContentFile(
        datasplit?.map((data, index) => {
          return index > 0 && JSON.parse(data);
        })
      );
      if (response.status === 200) {
        dispatch(getCoin());
      }
    }).catch(function (error) {
      console.error("Error in API call", error);
    }).finally(() => {
      setLoadingResult(false);
    });
  };

  const getCoinMcqByTemplate = (value) => {
    dispatch(getCoinMcq({
      "CreateQuestionTemplateId": value
    }))
  }

  const onSubmit = (data) => {
    handleUpload(data, dataFileImported, bookId);
  }

  return (
    <div className="wrapper -teachV2HomePage">
      <TourProvider
        steps={steps}
        prevButton={() => {
          return null
        }}
        showBadge={false}
        nextButton={({ currentStep, stepsLength, setIsOpen, setCurrentStep, steps, }) => {
          const last = currentStep === stepsLength - 1
          return (
            <button
              className="tour__next"
              onClick={() => {
                if (last) {
                  setIsOpen(false)
                } else {
                  setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
                }
              }}
            >
              {last ? 'Get Started' : 'Next'}
            </button>
          )
        }}
        styles={{
          popover: (base) => ({
            ...base,
            borderRadius: 8,
            '--reactour-accent': '#fff',
          }),
          maskArea: (base) => ({ ...base, rx: 8 }),
          badge: (base) => ({ ...base, color: "#075DC6" }),
          close: (base) => ({ ...base, left: 'auto', right: 16, top: 12, width: 12, height: 12 }),
        }}
      >
        <form className="form-platform" onSubmit={handleSubmit(onSubmit)}>
          <HomeGenerate
            loadingResult={loadingResult}
            register={register}
            control={control}
            setValue={setValue}
            watch={watch}
            setDataFileImported={setDataFileImported}
            dataFileImported={dataFileImported}
            dataContentFile={dataContentFile}
            getCoinMcqByTemplate={getCoinMcqByTemplate}
            setBookId={setBookId}
            bookId={bookId}
            setMediaId={setMediaId}
            mediaId={mediaId}
            isMedia={true}
          />
        </form>
      </TourProvider>
    </div>
  )
}