import React, { useState, useEffect, useMemo, memo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getPortfolioInfo } from "@actions";
import { apiCaller, LoaderText } from "@utils";
import { TabComponentFill } from "@components/common/Tabs";
import { AssignmentDetails } from "@components/learner/Assignments/Details";
import { AssignmentList } from "@components/learner/Assignments/Home";

const statusOptions = [
  { key: "NotStarted", label: "Not Started" },
  { key: "InProgress", label: "In Progress" },
  { key: "Completed", label: "Completed" },
];

const getLaunchPlans = (portfolioReducer) => portfolioReducer?.data?.LaunchPlans || {};

const countItemsByStatus = (data, status) => data[status]?.length || 0;

const countTotalRecords = (launchPlans) => statusOptions.reduce((total, option) => total + countItemsByStatus(launchPlans, option.key), 0);

const createTabs = (launchPlans, handleItemClick) =>
  statusOptions.map((statusOption, index) => ({
    id: index + 1,
    title: statusOption.label,
    count: countItemsByStatus(launchPlans, statusOption.key),
    content: (
      <AssignmentList
        launchPlansList={launchPlans[statusOption.key] || []}
        type={statusOption.key}
        handleItemClick={(assignment) => handleItemClick(assignment, statusOption.key)}
      />
    ),
  }));

const usePortfolioData = () => {
  const dispatch = useDispatch();
  const portfolioReducer = useSelector((state) => state?.portfolio || {});

  useEffect(() => {
    dispatch(getPortfolioInfo());
  }, [dispatch]);

  const launchPlans = useMemo(() => getLaunchPlans(portfolioReducer), [portfolioReducer]);

  return {
    isLoading: portfolioReducer.isLoading,
    launchPlans,
  };
};

const AssignmentTabs = memo(({ launchPlans, handleItemClick, activeTab, setActiveTab }) => {
  const tabs = useMemo(() => createTabs(launchPlans, handleItemClick), [launchPlans, handleItemClick]);
  const totalRecords = useMemo(() => countTotalRecords(launchPlans), [launchPlans]);

  return (
    <>
      <h3 className="page-title title-4">Assignments</h3>
      <p className="page-desc">
        You have <strong>{totalRecords}</strong> assignment(s) to learn
      </p>
      <div className="mt-4">
        <TabComponentFill tabs={tabs} activeTab={activeTab} onTabChange={setActiveTab} />
      </div>
    </>
  );
});

const AssignmentsPage = (props) => {
  const { id } = useParams();
  const history = useHistory();
  const { isLoading, launchPlans } = usePortfolioData();
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [dataAssignmentDetails, setAssignmentDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (id) {
      getAssignmentsDetails(id);
    }
  }, [id]);

  const handleItemClick = (assignment) => {
    history.push(`/learn/assignments/${assignment.LaunchPlanId}`);
  };

  const getAssignmentsDetails = async (id) => {
    setLoadingDetails(true);
    try {
      const res = await apiCaller(`/api/learn/assignment/${id}/`, "GET");
      if (res?.status === 200) {
        setAssignmentDetails(res.data);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setLoadingDetails(false);
    }
  };

  const handleClickBackAssignments = () => {
    setAssignmentDetails(null);
    history.push("/learn/assignments");
  };

  const isAnyLoading = isLoading || loadingDetails;
  return (
    <div className={`${isAnyLoading ? "vh-100" : ""} position-relative`}>
      {isAnyLoading ? (
        <LoaderText
          {...props}
          textMessage="Fueling the knowledge engine... Sit tight for your dose of medical marvels!"
        />
      ) : dataAssignmentDetails ? (
        <AssignmentDetails
          {...props}
          dataAssignmentDetails={dataAssignmentDetails}
          handleClickBackAssignments={handleClickBackAssignments}
        />
      ) : (
        <AssignmentTabs
          launchPlans={launchPlans}
          handleItemClick={handleItemClick}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
};

export default AssignmentsPage;