import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, Col, UncontrolledAlert } from "reactstrap";
import { ModalAddPeriods, ModalEditPeriods } from "../Modal/";

//format time
import moment from "moment";

//redux
import { useDispatch } from "react-redux";

//LoaderText
import { LoaderText, messageData } from "@utils/";

//common
import { DataEmty, LockMessage } from "@components-common";

//actions
import { actRemovePeriod } from "@actions";

//elements
import { ButtonDelete, ButtonEdit } from "@components-common/elements";
import { ModalDeleteCommon } from "@components-common/Modal";

function Periods(props) {
  //props
  const { periodsStore, toggleTab } = props && props;
  const dataPeriods = periodsStore?.data;

  //redux
  const dispatch = useDispatch();

  //state
  const [statePeriods, setStatePeriods] = useState(
    (dataPeriods && dataPeriods) || []
  );
  const [stateItem, setItem] = useState({});

  const [modal, setModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  //onLoad
  useEffect(() => {
    if (dataPeriods) {
      setStatePeriods((dataPeriods && dataPeriods) || []);
    }
  }, [dataPeriods]);

  const toggle = (type, data) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setItem(data);
  };

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      PeriodId: stateItem?.PeriodId,
    };
    dispatch(actRemovePeriod(params, stateItem?.PeriodId));
    setModal((prevState) => ({ ...prevState, delete: false }));
  };

  const columns = [
    {
      dataField: "PeriodId",
      text: "ID",
      hidden: true,
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "Name",
      text: "Trial Period",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "StartTime",
      text: "Start Time",
      headerClasses: "--text-primary font-weight-500",
      formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
    },
    {
      dataField: "EndTime",
      text: "End Time",
      headerClasses: "--text-primary font-weight-500",
      formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
    },
    {
      dataField: "Actions",
      text: "",
      style: {
        width: "10%",
        whiteSpace: "nowrap",
      },
      headerClasses: "font-weight-400",
      formatter: (cell, row) => {
        return (
          <div className="text-right">
            <ButtonEdit handleEdit={() => toggle("edit", row)} />
            <ButtonDelete handleDelete={() => toggle("delete", row)} />
          </div>
        );
      },
    },
  ];

  return (
    <div className="position-relative">
      {periodsStore?.isLoading && <LoaderText />}
      {!periodsStore?.isLoading && (
        <React.Fragment>
          <UncontrolledAlert
            className="m-alert--default border-0 text-left"
            color="secondary"
          >
            You can define time periods to automatically launch sets of cases to
            students (via{" "}
            <span
              style={{ cursor: "pointer" }}
              onClick={toggleTab}
              className="--text-primary"
            >
              launch plan
            </span>
            ), or to compare the performance data of students between different
            trials (for example, a “pre-test” vs “post-test" comparison)
          </UncontrolledAlert>
          {typeof statePeriods === "object" &&
            statePeriods.hasOwnProperty("LockMessage") ? (
            <LockMessage {...props} data={statePeriods} />
          ) : (
            <React.Fragment>
              <BootstrapTable
                keyField="PeriodId"
                condensed
                striped
                wrapperClasses="table-responsive"
                bordered={false}
                data={(statePeriods && statePeriods) || []}
                columns={columns}
                noDataIndication={() => (
                  <div className="py-5">
                    <DataEmty content={messageData?.periodsEmty?.content} />
                  </div>
                )}
              />
              <Col sm={12} className="d-flex justify-content-center">
                <Button
                  className="--btn-primary"
                  onClick={() => toggle("add", {})}
                >
                  <i className="fa fa-plus mr-2" aria-hidden="true"></i>Add
                  Period
                </Button>
              </Col>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <ModalAddPeriods isOpen={modal.add} toggle={() => toggle("add", {})} />
      <ModalEditPeriods item={stateItem} isOpen={modal.edit} toggle={() => toggle("edit", stateItem)} />
      <ModalDeleteCommon
        title={`Are you certain that you would like to delete this trial period?`}
        sub={` Learner’s performances will not be removed.<br/>
        However, all analyzed data regarding this trial period will be removed.`}
        isOpen={modal.delete}
        toggle={() => toggle('delete', {})}
        handleRemove={handleRemove}
      />
    </div>
  );
}

export default Periods;