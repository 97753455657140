import React from 'react';
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";

//check Html
import { hasTextInside } from "@utils";

function LoadingMessage(props) {
    const { contentStream, PreceptorAvatar, preceptorNameText } = props || {};
    return (
        <div className={`messengerCt__wrapper`}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={PreceptorAvatar || imageInstructor}
                        alt="PreceptorAvatar"
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username mt-4">
                            {hasTextInside(preceptorNameText) ? (
                                <div dangerouslySetInnerHTML={{ __html: preceptorNameText }} />
                            ) : (
                                <div className='role-chat'>Instructor:</div>
                            )}
                        </div>
                        <div className="messengerCt__message-text">
                            {contentStream && (
                                <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: contentStream }} />
                            )}
                            <div className="typing-loader ml-2 mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoadingMessage;