import React from 'react';
import { Col } from 'reactstrap';

//components
import { CustomProgressBar } from '@components-common';
import { ButtonCaseItem } from "../Home";

//LinesEllipsis
import LinesEllipsis from 'react-lines-ellipsis';

//Icon
import { Iconsax } from "@components-common";

function CaseListItem(props) {
    const { Title, Thumbnail, Progress, Id, CaseSettings } = props && props;
    return (
        <Col md={4} className="case-list__item custom-padding-x mb-3" key={Id}>
            <div className="case-inner bg-box-gray h-100">
                <div className="case-list__item--thumb" style={{ backgroundImage: `url("${Thumbnail}")` }}>

                </div>
                <div className="case-list__item--info">
                    <LinesEllipsis
                        text={Title}
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                        className={`case-title`}
                    />
                    <div className="d-flex align-items-center time" style={{minHeight: "26px"}}>
                        {(CaseSettings?.duration || CaseSettings?.credit) && <Iconsax iconName="history" fill="#0089c3" />}
                        {CaseSettings?.duration && (
                            <span className="time-color ml-2">{CaseSettings?.duration} mins</span>
                        )}
                        {CaseSettings?.credit && <span className="ml-2 time-color">({CaseSettings?.credit} credit)</span>}
                    </div>
                    <CustomProgressBar value={Progress?.progress_percent || 0} colorClass={`--yellow-bg`} />
                    <ButtonCaseItem
                        {...props}
                    />
                </div>
            </div>
        </Col>
    );
}

export default CaseListItem;