import React, { useState } from 'react';

// Chat components
import {
    ChatClick,
    ChatFreeText,
    ChatVoiceBox,
    ChatQuestion,
    ChatPanel
} from "../Chat";
import { ModalEmailTemplate } from "@components/learner/StandardScreen/EmailTemplate";

//redux
import { useSelector, useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//call Action
import { atcAddRecordToChanel } from "@actions";

function ChatInterface(props) {

    //variables
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { ChatMode, data, handleOverideEffectMenu, switchChat, stateType } = props;

    //Timepoint
    const CaseProgressReducer = useSelector((state) => (state?.caseTaskCurrent?.data) || {});
    let TimePointId = CaseProgressReducer?.CaseProgress?.CurrentTimePointProgress?.TimePointId;

    //Chat - Add Record
    const apiCallerAddChatRecord = async (data) => {
        const params = {
            "Action": "AddData",
            "CaseId": parseInt(id),
            "TimePointId": parseInt(TimePointId),
            "Data": data
        };

        // Wrap the dispatch in a Promise and await it
        return new Promise(async (resolve, reject) => {
            try {
                await dispatch(atcAddRecordToChanel(params)); // Wait for dispatch to complete
                resolve(); // Resolve the promise if dispatch is successful
            } catch (error) {
                reject(error); // Reject the promise if there's an error
            }
        });
    };

    // State để lưu message từ voice để submit free text
    const [messageFromVoiceToText, setFromVoiceToText] = useState('');

    // State để điều khiển việc đóng/mở Email Template
    const [isEmailTemplate, setEmailTemplate] = useState({
        data: {},
        isToggle: false
    });

    // Handle Toggle Email Template
    const handleToggleEmailTemplate = (values) => {
        setEmailTemplate(prevState => ({
            ...prevState,
            isToggle: !prevState.isToggle,
            data: values || null
        }));
    }

    return (
        <ChatPanel {...props}>
            {/* Hiển thị Email Template nếu biến state là true */}
            {isEmailTemplate?.isToggle &&
                <ModalEmailTemplate
                    {...props}
                    isOpen={isEmailTemplate?.isToggle}
                    handleToggleEmailTemplate={handleToggleEmailTemplate}
                    isEmailTemplate={isEmailTemplate}
                />
            }

            {stateType?.chat && (
                <>
                    {ChatMode === "click" && (
                        <ChatClick
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                        />
                    )}
                    {ChatMode === "freetext" && (
                        <ChatFreeText
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                            apiCallerAddChatRecord={apiCallerAddChatRecord}
                            handleToggleEmailTemplate={handleToggleEmailTemplate}
                        />
                    )}
                    {ChatMode === "voice" && (
                        <ChatVoiceBox
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                        />
                    )}
                    {ChatMode === "switch_voice_text" && switchChat === "freetext" && (
                        <ChatFreeText
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                            messageFromVoiceToText={messageFromVoiceToText}
                            apiCallerAddChatRecord={apiCallerAddChatRecord}
                            handleToggleEmailTemplate={handleToggleEmailTemplate}
                        />
                    )}
                    {ChatMode === "switch_voice_text" && switchChat === "voice" && (
                        <ChatVoiceBox
                            {...props}
                            data={data}
                            handleOverideEffectMenu={handleOverideEffectMenu}
                            ChatMode={ChatMode}
                            handleQuestionTypePatient={(value) => {
                                setFromVoiceToText(value);
                            }}
                        />
                    )}
                </>
            )}
            {stateType?.question && (
                <ChatQuestion
                    {...props}
                    ChatMode={ChatMode}
                    apiCallerAddChatRecord={apiCallerAddChatRecord}
                    handleToggleEmailTemplate={handleToggleEmailTemplate}
                />
            )}
        </ChatPanel>
    );
}

export default ChatInterface;