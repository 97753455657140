import React, { useEffect } from 'react';

//boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";

//components
import { CongratulationsEndCase } from "../Advance";

//constants
import { feedbackConstant } from "@constants";

function ModalEndOfCase(props) {
  const { isOpen, hanldeToggleAdvance } = props && props;
  let { id } = useParams();
  const dispatch = useDispatch();

  // Interface Template
  // const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});

  useEffect(() => {
    const fetchCaseFeedback = (caseId) => {
      let feedbackParams = { "CaseId": caseId };
      dispatch({ type: feedbackConstant.CASE_FEEDBACKS_REQUEST, isLoading: true });
      apiCaller(`/api/learn/case/feedbacks/`, 'POST', feedbackParams).then(res => {
        const data = res?.data;
        if (res?.status === 200) {
          dispatch({ type: feedbackConstant.CASE_FEEDBACKS_SUCCESS, payload: data });
        }
      }).catch(err => {
        dispatch({ type: feedbackConstant.CASE_FEEDBACKS_FAILURE, error: "error" });
      });
    }

    isOpen && fetchCaseFeedback(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  // const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;
  return (
    <Modal
      className="modal-custom modal-size-lg"
      zIndex={16000}
      isOpen={isOpen}
      backdrop={`static`}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
    >
      <ModalBody>
        <CongratulationsEndCase {...props} />
      </ModalBody>
      <ModalFooter>
        <div className="mx-auto" style={{ width: "276px" }}>
          <Button
            color='primary'
            className="btn-brand-sm -btn-lg w-100 -shadown"
            onClick={
              () => {
                hanldeToggleAdvance("infoCompeleted")//close
                hanldeToggleAdvance("userFeedback")//open
              }
            }
          >
            {`Next`}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalEndOfCase;