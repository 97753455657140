import React from 'react';
import styled from 'styled-components';
import FadeLoader from 'react-spinners/FadeLoader';

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 998;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.bgNone ? 'transparent' : '#fff')};
`;

const LoaderTextStyled = styled.div`
  color: #000;
  font-size: 32px;
  font-weight: 600;
  margin-top: 60px;
`;

const LoaderDesc = styled.span`
  color: #697586;
  font-size: 18px;
  font-weight: 400;
`;

function LoaderText(props) {
  const { bgNone, colorDot, textMessage } = props;
  return (
    <LoaderWrapper bgNone={bgNone}>
      <FadeLoader loading={true} color={colorDot || '#F47814'} size={20} css="display: block; margin: 0 auto;" />
      <LoaderTextStyled>Loading...</LoaderTextStyled>
      {textMessage && <LoaderDesc dangerouslySetInnerHTML={{ __html: textMessage }} />}
    </LoaderWrapper>
  );
}

export default LoaderText;