import React from "react";

//react-responsive
import { useMediaQuery } from 'react-responsive';

//presentation
import { PointBubble, PatientBackground } from "../StandardScreen";

function PatientImageBackground(props) {
    const { getSizeWrap, caseData, handleOverideEffectMenu, isTask } = props && props;
    // ----------------------------------------------------------------------
    // data
    // ----------------------------------------------------------------------
    const dataResponse = caseData?.data;
    const dataClinicalStatus = dataResponse?.Case?.ClinicalStatus;
    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    return (
        <div className="patientPhoto" ref={getSizeWrap}>
            <PatientBackground {...props} />
            {isTask === 'exam' && !isMobile && (
                <PointBubble
                    data={dataClinicalStatus?.ExamPoints}
                    devRef={getSizeWrap}
                    handleOverideEffectMenu={handleOverideEffectMenu}
                />
            )}
        </div>
    );
}

export default PatientImageBackground;