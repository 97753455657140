import React, { useState, useEffect } from 'react';

//components
import { InputFreeText, UserMessage, PatientMessage } from '../../Chat/FreeText';
import { UserActive } from "../../Chat";
import { LoadingMessage } from "../FreeText";
import { QuestionEndChat, InstructorMessage } from "../../Chat/EndChat";

//redux
import { useSelector } from "react-redux";

function Framechat(props) {
    const {
        chatRecord,
        messagesEndRef,
        heightFooterChat,
        isStreamDataLoading,
        isShowForSwitch,
        ChatMode,
        endChatFeedbackData,
        endChatData,
        divRef
    } = props;

    //Question - Instructor
    const AnswerChoices = endChatData?.Question?.AnswerChoices;

    //Case 
    const caseReducer = useSelector((state) => state.cases || []);
    const caseResponse = caseReducer?.data?.Case;
    const ChatSettings = caseResponse.ChatSettings;
    const ChatLocked = ChatSettings?.ChatLocked;
    const InterfaceThisCase = caseResponse?.Interface;
    const PatientAvatar = InterfaceThisCase?.PatientAvatar;

    //state
    const [heightFoot, setHeightFoot] = useState(0);

    // Caculator Height
    useEffect(() => {
        setHeightFoot(heightFooterChat)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [heightFooterChat])

    //state
    const [optionsData, setOptionsData] = useState({
        SelectOne: AnswerChoices || null
    });
    const [valueOptions, setvalueOptions] = useState(null);

    //Load Data While Page Load
    useEffect(() => {
        setOptionsData(prevState => ({
            ...prevState,
            SelectOne: AnswerChoices || null
        }));
    }, [AnswerChoices])

    const showSwitchVoiceText = isShowForSwitch ? "d-block" : "d-none";
    const isDisabled = endChatFeedbackData?.Mode === "InstructorFeedback" || ChatLocked;

    //Handle Select One - Mutiple Choice
    const handleSelectOne = (data, e) => {
        const { checked, name } = e.target;
        const newDataChoices = [...optionsData?.SelectOne];
        let index = newDataChoices.findIndex((value) => value.Id === data.Id);
        if (name === "Selected") {
            newDataChoices[index]["Selected"] = checked;
        }

        // Show data for input
        displaySelectedItemsInInput(newDataChoices);

        //setData
        setOptionsData((prevState) => ({ ...prevState, SelectOne: newDataChoices || [] }));
    };

    const displaySelectedItemsInInput = (newDataChoices) => {
        const selectedItems = newDataChoices.filter(item => item.Selected).map(item => item.Content).join(", ");
        setvalueOptions(selectedItems);
    }

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const VirtualPreceptor = InterfaceTemplateDetails?.VirtualPreceptor;
    const VirtualPatient = InterfaceTemplateDetails?.VirtualPatient;
    const preceptorNameText = VirtualPreceptor?.preceptor_name?.Text;
    const patientNameText = VirtualPatient?.patient_name?.Text;
    return (
        <React.Fragment>
            <div className={`frameChat__fullBox interact_panel position-relative ${ChatMode === "switch_voice_text" ? showSwitchVoiceText : ""}`}>
                {chatRecord?.length === 0 && <UserActive userNameActive={patientNameText} RoleDefault={`Patient`} image={PatientAvatar} />}
                <div className="messengerCt scrollbarStyle" ref={messagesEndRef} style={{ height: `calc(100vh - ${heightFoot}px)` }}>
                    <div className="messengerCt__messages">
                        {chatRecord?.map((value, index) => (
                            <React.Fragment key={index}>
                                {value?.Role === "User" &&
                                    <UserMessage
                                        {...props}
                                        content={value?.Content}
                                    />
                                }
                                {value?.Role === "Patient" &&
                                    <PatientMessage
                                        {...props}
                                        index={index}
                                        values={value}
                                        patientNameText={patientNameText}
                                    />
                                }
                                {value?.Role === "Instructor" &&
                                    <InstructorMessage
                                        {...props}
                                        index={index}
                                        content={value?.Content?.Question?.Prompt}
                                        preceptorNameText={preceptorNameText}
                                    />
                                }
                                {chatRecord?.length - 1 === index && isStreamDataLoading &&
                                    <LoadingMessage
                                        {...props}
                                        patientNameText={patientNameText}
                                    />}
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div className={`questionBottom bg-white`} ref={divRef}>
                    {endChatData?.Mode === "ShowQuestion" &&
                        <QuestionEndChat
                            {...props}
                            AnswerChoices={AnswerChoices}
                            handleSelectOne={handleSelectOne}
                        />
                    }
                    {!isDisabled && <InputFreeText {...props} valueOptions={valueOptions} disabled={isDisabled} />}
                </div>
            </div>
        </React.Fragment>
    );
}

export default Framechat;