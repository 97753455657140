import React from 'react';

//Icon
import { Iconsax } from "@components-common";

//moment
import moment from 'moment';

function AssignmentTop(props) {

    //props
    const { dataAssignmentDetails } = props && props;
    const { LaunchPlanName, StartTime, EndTime, Institution, Series } = dataAssignmentDetails?.LaunchPlanContents || {};
    const Learners = dataAssignmentDetails?.Learners;
    const LearnersList = Learners?.List || [];
    const NotInListNum = Learners?.NotInListNum;
    const totalCase = Series?.Cases?.length;

    // Định dạng ngày tháng sử dụng moment
    const formattedStartTime = moment(StartTime).format("MMMM D, YYYY h:mm A");
    const formattedEndTime = moment(EndTime).format("MMMM D, YYYY h:mm A");

    // student list
    const studentList = LearnersList?.map((student, index) => {
        return (
            <React.Fragment key={index}>
                {student?.ProfilePhotoURL && (
                    <li
                        className="student-list__item"
                        style={{ backgroundImage: `url(${student.ProfilePhotoURL})` }}
                    ></li>
                )}
            </React.Fragment>
        );
    });

    return (
        <section className="assignments-details">
            <div className="card-area bg-white">
                <h2 className="headline --size-2 mb-4">{LaunchPlanName}</h2>
                <div className="d-flex align-items-center mb-4">
                    <Iconsax iconName="calendar" fill={`#697586`} />
                    <span className="ml-2" style={{ color: '#4B5565' }}>{formattedStartTime} - {formattedEndTime}</span>
                </div>
                <div className="d-flex align-items-center">
                    <span>{Institution?.InstitutionName}</span>
                    <div className="student-total">
                        <ul className="student-list">
                            {studentList}
                        </ul>
                        {NotInListNum > 1 && (
                            <span className="student-total__text ml-2">+ {NotInListNum} students</span>
                        )}
                    </div>
                </div>
                <div className="assignments-case-info">
                    <h3 className="headline --size-6 mb-3">{Series?.Name || ''}</h3>
                    <div className="d-flex align-items-center">
                        <span className="d-flex align-items-center">
                            <Iconsax iconName="notebook" fill={`#697586`} />
                            <span className="ml-2 content-gray">{`${totalCase || 0} case`}</span>
                        </span>
                        <span className="ml-4 d-flex align-items-center" style={{ marginLeft: '100px' }}>
                            <Iconsax iconName="watch" fill={`#697586`} />
                            <span className="ml-2 content-gray">{`3 hours`}</span>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AssignmentTop;