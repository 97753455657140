import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//actions
import { actSetStatusVitalSigns } from "@actions";

//common
import { SwitchItem } from "@components-common/Switches";

function SwitchShowVitalSign({ timepointData }) {
    
    let { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [stateSwitch, setSwitch] = useState({
        ShowVitalSigns: timepointData?.ShowVitalSigns
    });

    useEffect(() => {
        setSwitch({
            ShowVitalSigns: timepointData?.ShowVitalSigns
        });
    }, [timepointData?.ShowVitalSigns])

    // Handle Change Switch
    const handleChangeSwitch = (e) => {
        const { name, checked } = e.target;
        setSwitch((prevState) => ({
            ...prevState,
            [name]: checked
        }));

        //Call api
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timepointData.Id,
            ShowVitalSigns: checked,
        };
        dispatch(actSetStatusVitalSigns(id, params));
    }

    return (
        <SwitchItem
            label={`Show vital signs by default`}
            name={`ShowVitalSigns`}
            handleChangeSwitch={handleChangeSwitch}
            value={stateSwitch?.ShowVitalSigns}
        />
    );
}

export default SwitchShowVitalSign;