import React, { useState } from "react";
import { FormModal } from "@components-common/Modal";
import { useForm, Controller } from 'react-hook-form'
import { Button, Input, Label } from 'reactstrap';
import Select from 'react-select'
import { listTypeUploadMedia } from '@utils/dataDefault'

import { TitleNoteCommon } from "../../home/generate";
import { UploadComponent } from "./UploadCustom";
import { useDispatch } from 'react-redux';
import { createNewMedia } from "@actions";

const ModalUploadReferenceMedia = ({ isOpen, toggleModal, title, loadDataReferenceBook }) => {
  const [dataFileImported, setDataFileImported] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isType, setIsType] = useState('file');
  const dispatch = useDispatch();

  const {
    handleSubmit,
    setValue,
    control,
    watch,
  } = useForm({
    defaultValues: {
      type: listTypeUploadMedia?.[0]?.value
    }
  })

  const handleCreateMedia = (payload) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('Action', "Create");
    formData.append('Title', payload?.name);
    formData.append('Type', watch("type"));
    formData.append('Descriptions', payload?.description || "");
    payload?.url && formData.append('MediaUrl', payload?.url);
    dataFileImported && formData.append('MediaFile', dataFileImported);
    dispatch(createNewMedia(formData))
      .then(response => {
        if (response?.data?.status === 200) {
          loadDataReferenceBook()
          toggleModal();
          setLoading(false);
        }
        console.log('Success:', response);
      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
        toggleModal();
      });
  }

  const onSubmit = (data) => {
    handleCreateMedia(data)
  }

  const TypeUploadFile = (key) => {
    switch (key) {
      case 'URL':
        return (
          <>
            <TitleNoteCommon title="URL" required />
            <Controller
              name="url"
              control={control}
              render={({ field }) => (
                <Input
                  type='text'
                  name='url'
                  placeholder="Paste the URL here"
                  {...field}
                />
              )}
            />
          </>
        )
      case 'Audio':
        return (
          <>
            {isType === "url" ? (
              <Controller
                name="url"
                control={control}
                render={({ field }) => (
                  <Input
                    type='text'
                    name='url'
                    placeholder="Paste the URL here"
                    {...field}
                  />
                )}
              />
            ) : (
              <UploadComponent 
                dataFileImported={dataFileImported}
                setDataFileImported={setDataFileImported}
                typeUpload="MP3, WAV. Max: 10 MB."
                typeFile={["audio/wav", "audio/mpeg"]}
                errorFile="Only Audio MP3, WAV files are allowed."
              />
            )}
            {/* <TitleNoteCommon title="Upload File" required /> */}
          </>
        )
      case 'Image':
        return (
          <>
            {isType === "url" ? (
              <Controller
                name="url"
                control={control}
                render={({ field }) => (
                  <Input
                    type='text'
                    name='url'
                    placeholder="Paste the URL here"
                    {...field}
                  />
                )}
              />
            ) : (
              <UploadComponent 
                dataFileImported={dataFileImported}
                setDataFileImported={setDataFileImported}
                typeUpload="JPG/JPEG, PNG. Max: 10 MB."
                typeFile={["image/png", "image/jpeg"]}
                errorFile="Only JPG/JPEG, PNG files are allowed."
              />
            )}
            {/* <TitleNoteCommon title="Upload File" required /> */}
          </>
        )
      case 'Video':
        return (
          <>
            {isType === "url" ? (
              <Controller
                name="url"
                control={control}
                render={({ field }) => (
                  <Input
                    type='text'
                    name='url'
                    placeholder="Paste the URL here"
                    {...field}
                  />
                )}
              />
            ) : (
              <UploadComponent 
                dataFileImported={dataFileImported}
                setDataFileImported={setDataFileImported}
                typeUpload="MP4, MOV. Max: 10 MB."
                typeFile={["video/mp4", "video/quicktime"]}
                errorFile="Only MP4, MOV files are allowed."
              />
            )}
            {/* <TitleNoteCommon title="Upload File" required /> */}
          </>
        )
      default:
        break;
    }
  }

  const Option = ({ innerProps, label }) => (
    <div
      {...innerProps}
      className='option-detail'
      style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
      <span className="react-select-item">{label}</span>
    </div>
  );  

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={title}
      maxWidth="540px"
      hiddenIcon={true}
      hiddenButtonCancel={true}
      classCustom="modal-media"
    >
      <form className="form-upload-media">
        <div className="create-media__form">
          <div className='input-form'>
            <TitleNoteCommon title="Media Name" required/>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder={`Enter the ${watch("type")} name`}
                  name='name'
                  {...field}
                />
              )}
            />
          </div>
          <div className="d-flex">
            <div className='input-form flex-grow-1'>
              <TitleNoteCommon title="Media Type" required/>
              <Controller
                control={control}
                name="type"
                defaultValue={listTypeUploadMedia?.[0]?.value}
                render={({ field: { onChange } }) => (
                  <Select
                    className="react-select-common w-100"
                    placeholder="Select Model"
                    value={listTypeUploadMedia?.find((c) => c.value === watch("type"))}
                    options={listTypeUploadMedia}
                    onChange={val => {
                      setValue("type", val.value)
                      onChange(val.value)
                      setDataFileImported(null)
                      setValue("URL", null)
                    }}
                    classNamePrefix="react-select-custom"
                    components={{
                      IndicatorSeparator: () => null,
                      Option: Option
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className='input-form'>
            <TitleNoteCommon title="Upload Media" required/>
            <div className='wrap-input'>
              <Label check>
                <Input
                  type="radio"
                  name="type"
                  onChange={() => setIsType("file")}
                  checked={isType === 'file' ? true : false}
                />{" "}
                By File
              </Label>
              <Label check>
                <Input
                  type="radio"
                  name="type"
                  onChange={() => setIsType("url")}
                  checked={isType === 'url' ? true : false}
                />{" "}
                By URL
              </Label>
            </div>
            {TypeUploadFile(watch("type"))}
          </div>
          <div className='input-form'>
            <TitleNoteCommon title="Description" required/>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Input
                  type='textarea'
                  name='description'
                  placeholder="Provide a brief description of the media"
                  style={{ height: '70px' }}
                  {...field}
                />
              )}
            />
          </div>
          <div className='input-form'>
            <TitleNoteCommon title="Source"/>
            <Controller
              name="source"
              control={control}
              render={({ field }) => (
                <Input
                  type='text'
                  name='source'
                  placeholder="Paste the source URL here (if applicable)"
                  {...field}
                />
              )}
            />
          </div>
          <div className="d-flex align-items-center w-100">
            <Button 
              color="secondary"
              onClick={toggleModal}
              className="w-100 mr-2"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="btn btn-brand --bg-primary w-100 ml-2"
              onClick={handleSubmit(onSubmit)}
              disabled={(!watch("url") && !dataFileImported) || loading || !watch("name") || !watch("description")}
            >
              Upload
            </Button>
          </div>
        </div>
      </form>
    </FormModal>
  );
};

export default ModalUploadReferenceMedia;