import {
  teachV2Constants
} from "@constants";
import _ from "lodash";

export function getListCaseScript(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.REMOVE_CASE_SCRIPT_REQUEST:
    case teachV2Constants.GET_LIST_CASE_SCRIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.REMOVE_CASE_SCRIPT_SUCCESS:
    case teachV2Constants.GET_LIST_CASE_SCRIPT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        listCase: action.payload,
        isLoading: false,
      };
    case teachV2Constants.REMOVECASE_SCRIPT_FAILURE:
    case teachV2Constants.GET_LIST_CASE_SCRIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadCaseScript(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.LOAD_CASE_SCRIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.LOAD_CASE_SCRIPT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.LOAD_CASE_SCRIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function saveCaseScript(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.SAVE_CASE_SCRIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.SAVE_CASE_SCRIPT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.SAVE_CASE_SCRIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getTemplateCaseScript(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_TEMPLATE_SCRIPT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_TEMPLATE_SCRIPT_SUCCESS:
      const listTemplate = action.payload?.map((temp) => {
        return {
          value: temp?.Id,
          label: temp?.Name,
        }
      })
      return {
        ...state,
        data: listTemplate,
        isLoading: false,
      };
    case teachV2Constants.GET_TEMPLATE_SCRIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getQuestionBank(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_QUESTION_BANK_REQUEST:
    case teachV2Constants.REMOVE_QUESTION_BANK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_QUESTION_BANK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.REMOVE_QUESTION_BANK_SUCCESS:
      let newListResponse = []
      state?.data?.forEach((question) => {
        if (action?.params?.QuestionItemId !== question?.QuestionItemId) {
          newListResponse.push(question)
        }
      })
      return {
        ...state,
        data: newListResponse,
        isLoading: false,
      };
    case teachV2Constants.REMOVE_QUESTION_BANK_FAILURE:
    case teachV2Constants.GET_QUESTION_BANK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getQuestionBankOrg(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_QUESTION_BANK_ORG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_QUESTION_BANK_ORG_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.GET_QUESTION_BANK_ORG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function shareMediaOrg(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.SHARE_MEDIA_ORG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.SHARE_MEDIA_ORG_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.SHARE_MEDIA_ORG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getListLearner(state = {}, action) {
  let listLearners
  switch (action.type) {
    case teachV2Constants.GET_LIST_LEARNER_REQUEST:
    case teachV2Constants.REMOVE_LEARNER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_LIST_LEARNER_SUCCESS:
    case teachV2Constants.REMOVE_LEARNER_SUCCESS:
      listLearners = action.payload?.Learners
      return {
        ...state,
        data: action.payload?.Learners,
        listLearners,
        isLoading: false,
      };
    case teachV2Constants.GET_LIST_LEARNER_FAILURE:
    case teachV2Constants.REMOVE_LEARNER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case teachV2Constants.ENROLL_EMAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.ENROLL_EMAIL_SUCCESS:
      listLearners = action.payload?.Learners
      return {
        ...state,
        data: action.payload?.Learners,
        listLearners,
        isLoading: false,
      };
    case teachV2Constants.ENROLL_EMAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function enrollEmail(state = {}, action) {
  // switch (action.type) {
  //   case teachV2Constants.ENROLL_EMAIL_REQUEST:
  //     return {
  //       ...state,
  //       isLoading: true,
  //     };
  //   case teachV2Constants.ENROLL_EMAIL_SUCCESS:
  //     return {
  //       ...state,
  //       data: action.payload,
  //       isLoading: false,
  //     };
  //   case teachV2Constants.ENROLL_EMAIL_FAILURE:
  //     return {
  //       ...state,
  //       isLoading: false,
  //       error: action.error,
  //     };
  //   default:
  //     return state;
  // }
}

// Get RAG Document by Account
export function getRAGDocumentByAccount(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_RAG_DOCUMENT_BY_ACCOUNT_REQUEST:
    case teachV2Constants.DELETE_RAG_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case teachV2Constants.UPLOAD_RAG_DOCUMENT_REQUEST:
    case teachV2Constants.EDIT_DOCUMENT_MEDICAL_INFO_REQUEST:
      return state; // Không thay đổi trạng thái isLoading

    case teachV2Constants.GET_RAG_DOCUMENT_BY_ACCOUNT_SUCCESS:
    case teachV2Constants.UPLOAD_RAG_DOCUMENT_SUCCESS:
    case teachV2Constants.DELETE_RAG_DOCUMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        listBookReady: action.payload?.filter((book) => book?.status?.content === "Ready" || book?.status?.content === "Caution"),
        isLoading: false,
      };

    case teachV2Constants.EDIT_DOCUMENT_MEDICAL_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case teachV2Constants.GET_RAG_DOCUMENT_BY_ACCOUNT_FAILURE:
    case teachV2Constants.UPLOAD_RAG_DOCUMENT_FAILURE:
    case teachV2Constants.DELETE_RAG_DOCUMENT_FAILURE:
    case teachV2Constants.EDIT_DOCUMENT_MEDICAL_INFO_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
}

//MCQ
export function getMcqUsage(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.MCQ_USAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.MCQ_USAGE_SUCCESS:
      // const dataConvert = Object.keys(action.payload).map((usage) => {
      //   return {
      //     [usage]: action.payload[usage]
      //   }
      // })
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.MCQ_USAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getDetailedMcqUsage(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.DETAILED_MCQ_USAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.DETAILED_MCQ_USAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.DETAILED_MCQ_USAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getSlideTemplates(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_SLIDE_TEMPLATES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_SLIDE_TEMPLATES_SUCCESS:
      const listTemplate = action.payload?.map((temp) => {
        return {
          value: temp?.Id,
          label: temp?.Name,
        }
      })
      return {
        ...state,
        data: listTemplate,
        isLoading: false,
      };
    case teachV2Constants.GET_SLIDE_TEMPLATES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getListSlideDesks(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_SLIDE_DESKS_REQUEST:
    case teachV2Constants.REMOVE_SLIDE_DESK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_SLIDE_DESKS_SUCCESS:
    case teachV2Constants.REMOVE_SLIDE_DESK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        listSlideDesks: action.payload,
        isLoading: false,
      };
    case teachV2Constants.GET_SLIDE_DESKS_FAILURE:
    case teachV2Constants.REMOVE_SLIDE_DESK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadSlideDesk(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.LOAD_SLIDE_DESK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.LOAD_SLIDE_DESK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.LOAD_SLIDE_DESK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function createEngage(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.CREATE_ENGAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.CREATE_ENGAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.CREATE_ENGAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAllEngages(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.DELETE_ENGAGE_REQUEST:
    case teachV2Constants.GET_ALL_ENGAGE_REQUEST:
    case teachV2Constants.EDIT_ENGAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.DELETE_ENGAGE_SUCCESS:
    case teachV2Constants.GET_ALL_ENGAGE_SUCCESS:
    case teachV2Constants.EDIT_ENGAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.DELETE_ENGAGE_FAILURE:
    case teachV2Constants.GET_ALL_ENGAGE_FAILURE:
    case teachV2Constants.EDIT_ENGAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getReferenceBook(state = {}, action) {
  let newListResponse = []
  const { params } = action
  switch (action.type) {
    case teachV2Constants.GET_REFERENCE_BOOK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.FILTER_REFERENCE_BOOK_REQUEST:
      return {
        ...state,
      };
    case teachV2Constants.GET_REFERENCE_BOOK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataFilter: action.payload,
        isLoading: false,
      };
    case teachV2Constants.FILTER_REFERENCE_BOOK_SUCCESS:
      newListResponse = _.cloneDeep(state.data)
      let listMyReferences = []
      let listInstitutionReferences = []
      if (params?.length) {
        newListResponse?.MyReferences?.forEach((refer) => {
          if (params?.some((tag) => refer?.tags && refer?.tags?.includes(tag.value))) {
            listMyReferences.push(refer)
          }
        })
        newListResponse?.InstitutionReferences?.forEach((refer) => {
          if (params?.some((tag) => refer?.tags && refer?.tags?.includes(tag.value))) {
            listInstitutionReferences.push(refer)
          }
        })
      } else {
        listMyReferences = newListResponse?.MyReferences
        listInstitutionReferences = newListResponse?.InstitutionReferences
      }
      return {
        ...state,
        isLoading: false,
        data: newListResponse,
        dataFilter: {
          ...action.payload,
          MyReferences: listMyReferences,
          InstitutionReferences: listInstitutionReferences,
        }
      };
    case teachV2Constants.GET_REFERENCE_BOOK_FAILURE:
    case teachV2Constants.FILTER_REFERENCE_BOOK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function shareReference(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.SHARE_REFERENCE_BOOK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.SHARE_REFERENCE_BOOK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.SHARE_REFERENCE_BOOK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function uploadFileAnalysis(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.UPLOAD_FILE_ANALYSIS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.UPLOAD_FILE_ANALYSIS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.UPLOAD_FILE_ANALYSIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function createNewAnalysis(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.CREATE_NEW_ANALYSIS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.CREATE_NEW_ANALYSIS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.CREATE_NEW_ANALYSIS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getListAnalysesAvailabel(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_LIST_ANALYSIS_AVAILABLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_LIST_ANALYSIS_AVAILABLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.GET_LIST_ANALYSIS_AVAILABLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAnalysesAnswerBatch(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_ANALYSES_ANSWER_BATCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_ANALYSES_ANSWER_BATCH_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.GET_ANALYSES_ANSWER_BATCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getAnalysesAnswerBatchStatistics(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.GET_ANALYSES_ANSWER_BATCH_STATISTICS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.GET_ANALYSES_ANSWER_BATCH_STATISTICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.GET_ANALYSES_ANSWER_BATCH_STATISTICS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getReferenceMedia(state = {}, action) {
  let newListResponse = []
  const { params } = action
  switch (action.type) {
    case teachV2Constants.GET_REFERENCE_MEDIA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.FILTER_REFERENCE_MEDIA_REQUEST:
      return {
        ...state,
      };
    case teachV2Constants.GET_REFERENCE_MEDIA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataFilter: action.payload,
        isLoading: false,
      };
    case teachV2Constants.FILTER_REFERENCE_MEDIA_SUCCESS:
      newListResponse = _.cloneDeep(state.data)
      let listMyReferences = []
      let listInstitutionReferences = []
      const listTypesFilter = params?.listType?.map(value => value?.value)
      if (params?.listType?.length && params?.listValue?.length) {
        newListResponse?.MyMedia?.forEach((refer) => {
          if (params?.listValue?.some((tag) => refer?.tags && refer?.tags?.includes(tag.value)) && listTypesFilter?.includes(refer?.type)) {
            listMyReferences.push(refer)
          }
        })
        newListResponse?.InstitutionMedia?.forEach((refer) => {
          if (params?.listValue?.some((tag) => refer?.tags && refer?.tags?.includes(tag.value)) && listTypesFilter?.includes(refer?.type)) {
            listInstitutionReferences.push(refer)
          }
        })
      } else if (params?.listValue?.length) {
        newListResponse?.MyMedia?.forEach((refer) => {
          if (params?.listValue?.some((tag) => refer?.tags && refer?.tags?.includes(tag.value))) {
            listMyReferences.push(refer)
          }
        })
        newListResponse?.InstitutionMedia?.forEach((refer) => {
          if (params?.listValue?.some((tag) => refer?.tags && refer?.tags?.includes(tag.value))) {
            listInstitutionReferences.push(refer)
          }
        })
      } else if (params?.listType?.length) {
        newListResponse?.MyMedia?.forEach((refer) => {
          if (listTypesFilter?.includes(refer?.type)) {
            listMyReferences.push(refer)
          }
        })
        newListResponse?.InstitutionMedia?.forEach((refer) => {
          if (listTypesFilter?.includes(refer?.type)) {
            listInstitutionReferences.push(refer)
          }
        })
      } else {
        listMyReferences = newListResponse?.MyMedia
        listInstitutionReferences = newListResponse?.InstitutionMedia
      }
      return {
        ...state,
        isLoading: false,
        data: newListResponse,
        dataFilter: {
          ...action.payload,
          MyMedia: listMyReferences,
          InstitutionMedia: listInstitutionReferences,
        }
      };
    case teachV2Constants.GET_REFERENCE_MEDIA_FAILURE:
    case teachV2Constants.FILTER_REFERENCE_MEDIA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function shareMedia(state = {}, action) {
  switch (action.type) {
    case teachV2Constants.SHARE_REFERENCE_MEDIA_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case teachV2Constants.SHARE_REFERENCE_MEDIA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case teachV2Constants.SHARE_REFERENCE_MEDIA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
