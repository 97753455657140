import React, { useState, useEffect } from 'react';

//components
import {
    UserMessage,
    InstructorMessage
} from '@components/learner/StandardScreen/Chat/Preceptor';

//loading
import { LoadingMessage } from "../Preceptor";

function Framechat(props) {
    //props
    const { chatRecord, messagesEndRef, isLoading, heightFooterChat } = props && props;

    //state
    const [calculatedHeight, setCalculatedHeight] = useState(chatRecord || []);

    // State Load Chat Record
    useEffect(() => {
        setCalculatedHeight(heightFooterChat)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatRecord])

    const heightBottom = `calc(100vh - ${calculatedHeight}px)`;
    return (
        <div className="messengerCt scrollbarStyle" ref={messagesEndRef} style={{ height: heightBottom }}>
            <div className={`messengerCt__messages`}>
                {chatRecord?.map((value, index) => (
                    <React.Fragment key={index}>
                        {(value?.Role === "Instructor") && (
                            <InstructorMessage
                                {...props}
                                index={index}
                                value={value}
                            />
                        )}

                        {value?.Role === "User" && (
                            <UserMessage 
                                {...props}
                                value={value}
                            />
                        )}

                        {/* Loading Item Cuoi Khi Submit */}
                        {chatRecord?.length - 1 === index && (isLoading?.streamData || isLoading?.submitQuestion) && <LoadingMessage {...props} />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Framechat;