import React from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { DataEmptyCase } from "@components-common";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

const TableListGenerated = (props) => {
  const { chartActive } = props
  const { SearchBar } = Search;
  const listMcqUsage = useSelector((state) => state.getDetailedMcqUsage || []);
  const mcqUsage = useSelector((state) => state.getMcqUsage.data || []);

  const columns = [
    // {
    //   dataField: "title",
    //   text: "No",
    //   headerClasses: "--text-primary",
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="d-flex align-items-center justify-content-center">
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "lastname",
      text: "Name",
      headerClasses: "--text-primary",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row.firstname} {row.lastname}
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerClasses: "--text-primary",
    },
    // {
    //   dataField: "count",
    //   text: "Start date",
    //   headerClasses: "--text-primary",
    // },
    {
      dataField: "count",
      text: "Generations",
      headerClasses: "--text-primary",
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="id"
        data={listMcqUsage?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>
                Total Generations: {chartActive === 'mcq_usage' ? mcqUsage?.mcq_usage?.item_cnt : chartActive === 'case_usage' ? mcqUsage?.case_usage?.item_cnt : mcqUsage?.module_usage?.item_cnt}
              </div>
              <div className="boxSearch">
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }} />
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={listMcqUsage?.data || []}
              columns={columns}
              noDataIndication={() => (
                <DataEmptyCase
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: listMcqUsage?.data?.length || [],
  };

  return (
    <div className='table-generated'>
      {listMcqUsage?.isLoading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <ClipLoader color="#0089C2" size={20} />
        </div>
      ) : (
        <PaginationProvider pagination={paginationFactory(options)}>
          {contentTable}
        </PaginationProvider>
      )}
    </div>
  )
}
export default TableListGenerated
