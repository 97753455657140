import React, { useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Row,
    InputGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    FormGroup,
    Label,
    FormFeedback
} from "reactstrap";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//toast
import { toast } from "react-toastify";

//common
import { FormModal } from '@components-common/Modal';

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

//components
import StatusOptions from "./StatusOptions";

function ModalAddTimePoint(props) {
    let { id } = useParams();
    //props
    const { isOpen, toggle, handleActiveTimePointAfterCreate } = props;
    const formikRef = useRef(null);
    const dispatch = useDispatch();

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        days: Yup.number().integer().required("Days is required"),
        hours: Yup.number().integer().required("Hours is required"),
        mins: Yup.number().integer().required("Minutes is required"),
    });

    //state
    const [statusOption, setStatusOption] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    // Function to handle status option change
    const handleStatusOptionChange = (status) => {
        setStatusOption(status);
    };

    const validateTime = () => {
        toast.warning("Time must be larger than 0 second", {
            autoClose: 3000
        });
    }

    const handleSubmit = async (values) => {
        const dataDays = parseInt(values?.days);
        const dataHours = parseInt(values?.hours);
        const dataMins = parseInt(values?.mins);

        // Kiểm tra xem giá trị đã chuyển đổi có là một số hợp lệ không
        if (isNaN(dataDays) || isNaN(dataHours) || isNaN(dataMins)) {
            validateTime();
            return;
        }

        const logic1 = (dataDays === 0 && dataHours === 0 && dataMins === 0);
        const logic2 = (dataDays < 0 || dataHours < 0 || dataMins < 0);
        if (logic1 || logic2) {
            validateTime();
        } else {
            const totalTimeInMinutes = dataDays * 24 * 60 + dataHours * 60 + dataMins;
            const params = {
                "Target": "TimePoint",
                "TimeInMinutes": totalTimeInMinutes,
                "DisplayName": values?.title,
                "DefaultActiveStatus": statusOption
            }
            params[`Action`] = "Create";
            actCreateTimepoint(params);
        }
    };

    const actCreateTimepoint = (params) => {
        const isLoading = true;
        setIsLoading(true);
        //Call Api
        dispatch({ type: storyboardConstants.CREATE_TIMEPOINT_REQUEST, isLoading });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                handleActiveTimePointAfterCreate(data?.NewTimePoint);
                dispatch({ type: storyboardConstants.CREATE_TIMEPOINT_SUCCESS, payload: data });
                setIsLoading(false);
                toggle();
            } else {
                dispatch({ type: storyboardConstants.CREATE_TIMEPOINT_FAILURE, error: 'error' });
                setIsLoading(false);
            }
        });
    }

    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggle}
            title={`Add Time Point`}
            actionText={"Add"}
            maxWidth="900px"
            onAction={() => {
                if (formikRef.current) {
                    formikRef.current.submitForm();
                }
            }}
            isLoading={isLoading}
        >
            <Formik
                innerRef={formikRef}
                initialValues={{
                    title: "",
                    days: "",
                    hours: "",
                    mins: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
                {({ values, errors, handleChange, handleBlur, touched }) => (
                    <Form>
                        <Label className="labeForm">Set the new time point at: </Label>
                        <Row>
                            <FormGroup className="col-md-4">
                                <InputGroup>
                                    <Field
                                        type="number"
                                        name="days"
                                        as={Input}
                                        value={values.days}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={!!errors.days && touched.days}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>days</InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback>{errors.days}</FormFeedback>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className="col-md-4">
                                <InputGroup>
                                    <Field
                                        type="number"
                                        name="hours"
                                        as={Input}
                                        value={values.hours}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={!!errors.hours && touched.days}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>hours</InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback>{errors.hours}</FormFeedback>
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className="col-md-4">
                                <InputGroup>
                                    <Field
                                        type="number"
                                        name="mins"
                                        as={Input}
                                        value={values.mins}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        invalid={!!errors.mins && touched.mins}

                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>mins</InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback>{errors.mins}</FormFeedback>
                                </InputGroup>
                            </FormGroup>
                        </Row>
                        <FormGroup>
                            <Label className="labeForm" for="title">
                                Time Point Name
                            </Label>
                            <Field
                                type="text"
                                name="title"
                                autoComplete="off"
                                as={Input}
                                value={values.title}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={!!errors.title && touched.title}
                            />
                            <FormFeedback>{errors.title}</FormFeedback>
                            <p className="my-2">
                                Leave blank to use the time above as Name
                            </p>
                        </FormGroup>
                        <StatusOptions
                            {...props}
                            handleChange={handleStatusOptionChange}
                            isChecked={statusOption} //DefaultActiveStatus
                        />
                    </Form>
                )}
            </Formik>
        </FormModal>
    );
}

export default ModalAddTimePoint;