import React, { useState } from "react";

// Components
import { ModalFormAddProblem } from "./index";
import { CommonCollapse } from "../../ComponentCommon"
import { TabComponentBorder } from "@components/common/Tabs";
import { BtnAddRow } from '@components-common/elements';

//components
import { OptionProblem } from "./index";

const ProblemComponent = (props) => {
  //state
  const [activeTab, setActiveTab] = useState(1);
  const [modalAddOpen, setModalAddOpen] = useState(false);

  const toggleModal = () => {
    setModalAddOpen(prev => !prev);
  };

  const statusOptions = [
    { key: 1, label: "Options", content: <OptionProblem {...props} /> },
    { key: 2, label: "Concepts", content: "Concepts" },
  ];

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  const tabs = statusOptions?.map((statusOption) => ({
    id: statusOption.key,
    title: statusOption.label,
    content: statusOption.content
  }));

  return (
    <div className="list-problem-doc mb-4">
      <CommonCollapse title="Congestive Heart Failure #2">
        <TabComponentBorder
          tabs={tabs || []}
          activeTab={activeTab}
          onTabChange={handleTabChange}
          classNames="custom-tabs-child"
        />
      </CommonCollapse>
      <BtnAddRow
        handleClickAddRow={toggleModal}
        textBtn="Add Problem"
      />
      <ModalFormAddProblem
        isOpen={modalAddOpen}
        toggleModal={toggleModal}
        title={"Add problem"}
        {...props}
      />
    </div>
  );
};

export default ProblemComponent;
