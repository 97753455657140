import React from 'react'
import { Icon } from '@components/common'
import Error from '@images/login/error.svg'
import Success from '@images/login/success.svg'
import { Button } from 'reactstrap'
import { useHistory } from "react-router-dom";

const ModalResultRegister = (props) => {
  let history = useHistory();
  const { dataMessage } = props
  
  return (
    <div className="modal-completed-form">
      <div className="modal-completed-form__icon">
        {dataMessage?.error ? (
          <Icon src={Error}/>
        ) : (
          <Icon src={Success}/>
        )}
      </div>
      <div className='modal-completed-form__content text-center'>
        {dataMessage?.title && (
          <p>{dataMessage?.title}</p>
        )}
        <div dangerouslySetInnerHTML={{ __html: dataMessage?.error || dataMessage?.message }} />
      </div>
      <div className='modal-completed-form__action'>
        {dataMessage?.buttons?.map((button, index) => (
          <Button
            key={index}
            color={button.ButtonStyle}
            className='modal-completed-form__action-btn'
            onClick={() => history.push(`${button.URL}`)}
          >
            {button?.ButtonTitle}
          </Button>
        ))}
      </div>
    </div>
  )
}

export default ModalResultRegister
