import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

//reactstrap
import { Alert, Row, Col } from "reactstrap";

//actions
import { setSubscriptionAutomaticRenew } from "@actions";

//redux
import { useDispatch } from "react-redux";

function BillingContent(props) {
  
  //dispatch
  const dispatch = useDispatch();
  
  //props
  const { data } = props && props;
  const SubscriptionData = data?.Subscription;
  const AutomaticRenew = SubscriptionData?.AutomaticRenew;
  // const BillingData = data?.Billing;
  // const CurrentBillingData = BillingData?.CurrentBilling;
  const DetailsData = SubscriptionData?.Details;
  const MaxUsers = DetailsData?.MaxUsers;
  const CurrentUsers = DetailsData?.CurrentUsers;
  const MaxCases = DetailsData?.MaxCases;
  const CurrentCases = DetailsData?.CurrentCases;
  const SubscriptionName = SubscriptionData?.SubscriptionName;
  const BillingCycleData = SubscriptionData?.BillingCycle;
  const UserMessage = BillingCycleData?.UserMessage;

  //state
  const [AutomaticallyRenew, setAutomaticallyRenew] = useState((AutomaticRenew && AutomaticRenew) || false);

  useEffect(() => {
    setAutomaticallyRenew((AutomaticRenew && AutomaticRenew) || false);
  }, [AutomaticRenew]);

  const handleChangeSwiches = (e) => {
    const { checked } = e.target;
    setAutomaticallyRenew(!AutomaticallyRenew);
    const params = {
      "Action": "set_automatic_renew",
      "AutomaticRenew": checked
    }
    dispatch(setSubscriptionAutomaticRenew(params));
  }

  return (
    <React.Fragment>
      <div className="d-flex align-items-center mb-2">
        <p className="text-muted text-3">Your current plan</p>
        <Link to={`/plan`} className="btn --btn-primary ml-auto">
          Change Plan
        </Link>
      </div>
      <Row className="pb-4">
        <Col md={5}>
          <h3 className="text-7 mb-4">{SubscriptionName || ""}</h3>
          <ul className="list-unstyled">
            <li className="mb-2">
              <span className="font-weight-500">{`${(MaxUsers && MaxUsers) || ""
                } user licenses`}</span>
              <span className="text-muted ml-1">{`(${(CurrentUsers && CurrentUsers) || ""
                } assigned)`}</span>
            </li>
            <li className="mb-2">
              <span className="font-weight-500">{`${(MaxCases && MaxCases) || ""
                } max # cases`}</span>
              <span className="text-muted ml-1">{`(${(CurrentCases && MaxCases) || ""
                } created)`}</span>
            </li>
          </ul>
        </Col>
        <Col md={7}>
          <div className="btnSwitches">
            <div className="d-flex flex-wrap align-items-center">
              <span className="innerCt__label mr-3 font-weight-500">Automatically renew</span>
              <div className="switches -showText">
                <input
                  type="checkbox"
                  checked={AutomaticallyRenew}
                  onChange={handleChangeSwiches}
                  name="AutomaticallyRenew"
                  id={'AutomaticallyRenew'}
                />
                <label htmlFor={'AutomaticallyRenew'}>
                  <span />
                </label>
              </div>
              {/* /switches */}
            </div>
            <p className="mt-2">
              If this option is ON, your subscription will be automatically renewed
              at the end of each billing cycle
            </p>
          </div>
        </Col>
      </Row>
      {/* <Row className="border-top pt-4">
        <Col md={12}>
          {CurrentBillingData?.map((item, index) => (
            <div
              className="d-flex algin-items-center text-dark text-4 font-weight-500 py-1"
              key={index}
            >
              <div
                className="mr-4"
                dangerouslySetInnerHTML={{ __html: item.Title }}
              />
              <div className="ml-auto text-4 text-nowrap">
                <div dangerouslySetInnerHTML={{ __html: item.Value }} />
              </div>
            </div>
          ))}
        </Col>
      </Row> */}
      {UserMessage && (
        <Alert className="--bg-primary mb-0" color="primary">
          <div dangerouslySetInnerHTML={{ __html: UserMessage }} />
        </Alert>
      )}
    </React.Fragment>
  );
}

export default BillingContent;
