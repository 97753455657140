import React, { useState } from 'react'
import { Icon } from '@components/common'
import Copy from '@images/teachV2/copy.svg'
// import Edit from '@images/teachV2/edit.svg'
// import Reload from '@images/teachV2/reload.svg'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import stick from '@images/teachV2/stick-btn.svg'
import { Button } from 'reactstrap'
import ReactTooltip from 'react-tooltip';
import Feedback from '@images/teachV2/feedback.svg'

const GenerateItem = (props) => {
  const [showData, setShowData] = useState(true)
  const { 
    contentGenerate, 
    titleGenerate, 
    dataCaseScript, 
    handleGenerateScript, 
    loadingGenerateScript, 
    handleSave,
    dataRef,
    index,
    toggleModal,
    setDataFeedback,
  } = props

  const handleShowData = () => {
    setShowData(!showData)
  }

  const handleCopy = (e, title, dataRef) => {
    e.preventDefault()
    const text = title ? title.concat(" \n", dataRef?.current?.innerText) :  "Learning Points:".concat(" \n", dataRef?.current?.innerText)
    navigator.clipboard.writeText(text)
  }

  const handleFeedback = (title, dataRef) => {
    toggleModal("modalGiveFeedback")
    const text = title ? title.concat(" \n", dataRef?.current?.innerText) :  "Learning Points:".concat(" \n", dataRef?.current?.innerText)
    setDataFeedback(text)
  }

  return (
    <>
      <div className='generateItem'>
        <div className="generateItem__header w-100">
          <div className="generateItem__header-title">
            <div className="icon-show" onClick={handleShowData}>
              {showData ? <Icon src={Up}/> : <Icon src={Down}/>}
            </div>
            <p>{titleGenerate ? titleGenerate : 'Learning Points'}</p>
          </div>        
          <div className="generateItem__header-action">
            <Button 
              data-tip
              data-for={`copy-${index}`}
              className="action-copy" 
              onClick={(e) => handleCopy(e, titleGenerate, dataRef)}
            >
              <Icon src={Copy} stroke="#98A2B3"/>
            </Button>
            <ReactTooltip id={`copy-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Copy this Section</span>
            </ReactTooltip>
            <Button 
              data-tip
              data-for={`feedback-${index}`}
              className="action-copy" 
              onClick={() => handleFeedback(titleGenerate, dataRef)}
            >
              <Icon src={Feedback} stroke="#98A2B3"/>
            </Button>
            <ReactTooltip id={`feedback-${index}`} place='top' effect='solid' className="case-tooltip">
              <span>Give Feedback</span>
            </ReactTooltip>
            {/* <div className='action-edit'>
              <Icon src={Edit} stroke="#98A2B3"/>
            </div>
            <div className='action-reload'>
              <Icon src={Reload}/>
            </div> */}
          </div>
        </div>
        {showData && (
          <div
            ref={dataRef}
            id="htmlContainer"
            onBlur={() => handleSave(dataRef, titleGenerate)}
            className="generateItem__content"
            contentEditable
            dangerouslySetInnerHTML={{ __html: contentGenerate?.replace(/\n/g,"<br />") || '' }}>
          </div>
        )}
      </div>
      {!dataCaseScript?.case_sections && !loadingGenerateScript && (
        <div className="caseGenerate">
          <p>Once you’re satisfied with the above learning points, click here to generate the next elements of the case script.</p>
          <div onClick={handleGenerateScript} className={`caseGenerate-btn`}>
            <Icon src={stick}/>
            Generate 
          </div>
        </div>
      )}
      {loadingGenerateScript && !dataCaseScript?.case_sections?.length && 
        <div className="generateItem w-100">
          <Skeleton height={20} width={`100%`} />
          <Skeleton height={20} width={`75%`} />
          <Skeleton height={20} width={`60%`} />
          <Skeleton height={20} width={`80%`} />
          <Skeleton height={20} width={`55%`} />
        </div>
      }
    </>
  )
}

export default GenerateItem
