import React, { forwardRef, useImperativeHandle, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  Form,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";

//call Action
import {
  atcLoadAllCohorts,
  atcConfirmImportAccount,
} from "@actions";

//redux
import { useDispatch, useSelector } from "react-redux";

//paginator
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

const ViewDataImport = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { data, toggle } = props && props;
  let listData = data && data.Data;
  const ExistedAccountNum = data && data.ExistedAccountNum;
  const NewAccountNum = data && data.NewAccountNum;

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
  } = useForm();

  useImperativeHandle(ref, () => ({
    sendApi() {
      handleSubmit(onSubmit)();
    },
  }));

  const onSubmit = (data) => {
    const params = {
      Mode: "Confirm",
      CohortId: data.CohortId || null,
      SharedPassword: data.Password || "",
      Data: listData || [],
    };
    dispatch(atcConfirmImportAccount(params));
    toggle();
    reset({ keepIsValid: false, keepValues: false });
  };

  useEffect(() => {
    const fetchData = async () => {
      //load Cohorts
      dispatch(atcLoadAllCohorts());
    };

    fetchData();
  }, [dispatch]);

  const columns = [
    {
      dataField: "Email",
      text: "Email",
      headerClasses: "align-middle text-left font-weight-500",
    },
    {
      dataField: "FirstName",
      text: "First Name",
      headerClasses: "align-middle text-left font-weight-500",
    },
    {
      dataField: "LastName",
      text: "Last Name",
      headerClasses: "align-middle text-left font-weight-500",
    },
    {
      dataField: "Status",
      text: "Status",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      formatter: (cell, row) => {
        const classColor =
          row.Status === "New" ? "text-success" : "text-danger";
        return <span className={classColor}>{row.Status}</span>;
      },
    },
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="Email"
        data={(data && data.Data) || []}
        columns={columns}
      >
        {(toolkitprops) => (
          <BootstrapTable
            condensed
            striped
            wrapperClasses="table-responsive"
            {...paginationTableProps}
            {...toolkitprops.baseProps}
          />
        )}
      </ToolkitProvider>
      {/* /Table */}
      <div className="d-flex justify-content-center mt-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </div>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: (data && data.Data.length) || [],
  };

  //data store
  const cohortStore = useSelector(
    (state) => (state.listCohort && state.listCohort.data) || []
  );

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col md={12}>
          <ul className="infoAccount">
            <li className="infoAccount__item">
              <span className="font-weight-500 --text-primary mr-2">
                Total:
              </span>
              <span>{NewAccountNum + ExistedAccountNum || ""}</span>
            </li>
            <li className="infoAccount__item">
              <span className="font-weight-500 --text-primary mr-2 text-success">
                New Accounts Created:
              </span>
              <span>{NewAccountNum || ""}</span>
            </li>
            <li className="infoAccount__item">
              <span className="font-weight-500 --text-primary mr-2 text-danger">
                Exited (No Change):
              </span>
              <span>{ExistedAccountNum || ""}</span>
            </li>
          </ul>
        </Col>
      </Row>
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
      <Row>
        <Col md={6} className="mt-4">
          <Label className="font-weight-500" for="Password">
            All new accounts will share sample password below:{" "}
            <span className="m--font-danger">*</span>
          </Label>
          <Form>
            <FormGroup>
              <Input
                type="Password"
                name="Password"
                {...register("Password", { required: true })}
                invalid={errors.Password?.type === "required"}
                onChange={(e) => {
                  setValue("Password", e.target.value, { shouldValidate: true });
                }}
                defaultValue={watch("Password")}
              />
              <p style={{ fontSize: "12px" }} className="text-muted mt-2">
                Users can change the password once they login
              </p>
              <FormFeedback
                invalid={String(errors.Password?.type === "required")}
              >
                This is required.
              </FormFeedback>
            </FormGroup>
          </Form>
        </Col>
        <Col md={6} className="mt-4">
          <FormGroup>
            <Label for="CohortId">Cohorts: </Label>
            <Input
              className="ml-auto"
              type="select"
              name="CohortId"
              {...register("CohortId")}
              onChange={(e) => {
                setValue("CohortId", e.target.value, { shouldValidate: true });
              }}
              defaultValue={watch("CohortId")}
            >
              <option value="">Select a cohort for the new accounts</option>
              {cohortStore &&
                cohortStore.map((value, index) => (
                  <option key={index} value={value.CohortId}>
                    {value.Name}
                  </option>
                ))}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </React.Fragment>
  );
});

export default ViewDataImport;
