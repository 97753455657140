import React, { useState, useEffect, useRef } from 'react';
import { StepByStep } from "@components/common/step";
import { useHistory } from 'react-router-dom';

//boostrap
import {
    Container,
    Row,
    Col,
    Alert
} from 'reactstrap';

//lodash
import _ from "lodash";

//Config
import * as Config from "@constants/Config";

//JoditEditor
import JoditEditor from "jodit-react";

function Third(props) {
    const { params, isLoading, handleCreateNewCase, stepData } = props && props;
    let history = useHistory();

    const editor = useRef(null);

    //state
    const [stateParams, setParams] = useState(params || {});
    const [content, setContent] = useState(params?.CaseScript || "");

    useEffect(() => {
        setContent(params?.CaseScript || "");
    }, [params]);

    // Load Params
    useEffect(() => {
        setParams(params || {});
    }, [params])

    const nextStep = () => {
        let cloneData = _.cloneDeep(stateParams);
        cloneData[`CaseScript`] = content
        cloneData[`Step`] = 3
        handleCreateNewCase(cloneData, "step4");
    };

    const prevStep = e => {
        e.preventDefault();
        history.push(`/teach/new/step2`);
    };

    return (
        <React.Fragment>
            <StepByStep stepActive={3} data={stepData?.addCase} />
            <Container>
                <section className="formCt boxPortlet">
                    <div className="m-subheader py-0 mb-3">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h5 className="m-subheader__title">Create New Case</h5>
                            </div>
                        </div>
                    </div>
                    <Alert className="m-alert--default mb-3 border-0" color="secondary">
                        [OPTIONAL] You can enter your script here. The script can be utilized later by AI to generate different elements of the case. You can also skip this test by leaving the textbox below blank.
                    </Alert>
                    <Row className="mt-5">
                        <Col md={12}>
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={Config.CONFIG_JODIT}
                                tabIndex={1} // tabIndex of textarea
                                onChange={(newContent) => setContent(newContent)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex justify-content-center mt-5">
                                <a href="# " onClick={prevStep} className="btn btn-secondary">&lt; Back</a>
                                <button
                                    type="button"
                                    disabled={isLoading}
                                    onClick={nextStep}
                                    className="btn btn-brand ml-1 --bg-primary"
                                    id="button_next"
                                >
                                    <div className="d-flex align-items-center">
                                        {isLoading &&
                                            <span className="spinner-border spinner-border-sm mr-2"></span>
                                        }
                                        Create Case Now &gt;
                                    </div>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </React.Fragment>
    );
}

export default Third;