import React, { useState } from 'react';

//elements
import { ButtonEdit } from '@components-common/elements';

//components
import { ModalEditDianostic, NegationUpdate } from '../schema';
import { ModalDeleteCommon } from '@components/common/Modal';
import { MainTags } from '../schema/tags';

//actions
import { DeleteConceptRequest } from "@actions";

//redux
import { useDispatch } from "react-redux";

function DiagnosticItem({ ...props }) {
    const dispatch = useDispatch();

    //props
    const { item } = props && props;

    //state
    const [modal, setModal] = useState({
        edit: false,
        delete: false
    });

    const [stateData, setData] = useState((item && item) || {});

    const toggle = (name, item) => {
        setModal((prevState) => ({ ...prevState, [name]: !modal[name] }));
        setData((item && item) || {});
    }

    const handleUpdateNegation = (status) => {
        setData((prevState) => ({ ...prevState, negation: !status }));
    }

    const handleRemove = () => {
        const params = {
            "Action": "DeleteConcept",
            "QuestionId": stateData?.question_id,
            "QuestionConceptId": stateData?.question_concept_id
        }
        dispatch(DeleteConceptRequest(params));
        setModal((prevState) => ({
            ...prevState,
            edit: false,
            delete: false
        }));
    }

    return (
        <React.Fragment>
            <div className="w-100 p-3 border -box-shadow my-2" question-concept-id={stateData?.question_concept_id}>
                <div className="diagnosticItem d-flex align-items-center">
                    <div className="diagnosticItem__left w-50">
                        <div className="d-flex align-items-center">
                            <i className="fa fa-folder-o text-5" aria-hidden="true"></i>
                            <div className="flex-wrap ml-3">
                                <h3 className={`text-info ${stateData?.negation ? "text-danger text-line-through" : ""}`}>{stateData?.name}</h3>
                                <p className="text-muted">CID {stateData?.cui}</p>
                            </div>
                        </div>
                    </div>
                    <div className="diagnosticItem__right d-flex align-items-center flex-wrap ml-3 w-50">
                        <NegationUpdate
                            item={stateData || {}}
                            handleUpdateNegation={handleUpdateNegation}
                        />
                        <div className="ml-auto">
                            <ButtonEdit handleEdit={() => toggle('edit', item)} />
                        </div>
                    </div>
                </div>
                <MainTags {...props} />
            </div>
            <ModalEditDianostic
                data={stateData}
                isOpen={modal?.edit}
                hanldeDelete={() => toggle("delete", item)}
                toggle={() => toggle("edit", '')}
            />
            <ModalDeleteCommon
                title={`Are you sure you want to delete this reference?`}
                sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
                isOpen={modal?.delete}
                toggle={() => toggle("delete", item)}
                buttonText={`Remove Concept`}
                handleRemove={handleRemove}
                centerButton={true}
            />
        </React.Fragment>
    );
};

export default DiagnosticItem;