import React, { useState } from "react";
import { TabComponentBorder } from "@components/common/Tabs";

//components
import {
    ContentIntakeOutput,
    InstructionsIntakeOutput,
} from "../ManageAvailableTasks/IntakeOutput";
import { HeadingTask } from "../ManageAvailableTasks";

const IntakeOutputMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    const statusOptions = [
        { key: 1, label: "Contents / Values", content: <ContentIntakeOutput {...props} /> },
        { key: 2, label: "Instructions", content: <InstructionsIntakeOutput {...props} /> }
    ];

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`Intake/Output`}
                handleClickBtn={() => console.log("handleClickBtn")}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default IntakeOutputMain;