import { Player } from '@lottiefiles/react-lottie-player';

const CongratulationsIcon = () => {
    return (
        <>
            <Player
                autoplay
                loop
                src={process.env.PUBLIC_URL + '/icon-animation/vt0hUbkI1S.json'}
                style={{ width: 220, height: 220 }}
            >
            </Player>
        </>
    );
}

export default CongratulationsIcon;