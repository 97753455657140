import React, { useState, useEffect, useCallback } from "react";

//BootstrapTable
import BootstrapTable from "react-bootstrap-table-next";

//reactstrap
import { Button } from "reactstrap";

//paginator
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//untils
import { LoaderText, apiCaller } from "@utils";

//DataEmty
import { DataEmty } from "@components/common";

//Icon
import { Iconsax } from "@components-common";

//LevelBox
import { LevelBox } from "@components/Makansafe";

//elements
import { BtnIconDeleteV2, BtnIconEditV2 } from '@components-common/elements';

//react-router-dom
import { useHistory } from 'react-router-dom';

//components
import { FilterContainer, TableHeader, DeleteModal, ValidateFoodItem, SortCaret } from "../Home";

//Images Default
import NotAvailable from "@images/makansafe/not-available.png";

//components
import { ModalModification } from "../Home";

//Language
import { useLanguage } from '@context/LanguageContext';

function TableFoods(props) {
    const maxLength = 120; // Độ dài tối đa của chuỗi
    let history = useHistory();
    const { language } = useLanguage();

    //state
    const [modal, setModal] = useState({
        deleteFood: false,
        modification: false
    });
    const [isLoading, setLoading] = useState({
        deleteFood: false,
        getAllData: false
    });
    const [foodsDataState, setFoodsDataState] = useState([]);
    const [rowChecked, setRowChecked] = useState([]);
    const [rowOneChecked, setRowOneChecked] = useState(null);
    const [itemActive, setItemActive] = useState(null);

    // useCallback for fetchDataFoods
    const fetchDataFoods = useCallback((type) => {
        //reset
        setFoodsDataState([]);

        type && setLoading(prevState => ({ ...prevState, [type]: true }));
        const params = {
            action_type: "get_full_data",
            lang: language.value
        };
        //Truyền true hàm apiCaller để sử dụng api makansafe
        apiCaller(`/api/iddsi/functional/`, "POST", params, true).then(res => {
            const resData = res?.data;
            if (res?.status === 200) {
                setFoodsDataState(resData.map(food => ({
                    ...food,
                    modification: "",
                    modifyToLevel: null
                })));
                type && setLoading(prevState => ({ ...prevState, [type]: false }));
            } else {
                console.log("error");
                type && setLoading(prevState => ({ ...prevState, [type]: false }));
            }
        });
    }, [language.value]);

    //load Foods
    useEffect(() => {
        fetchDataFoods("getAllData");
    }, [fetchDataFoods]);

    const columns = [
        {
            dataField: "food_id",
            text: "ID",
            hidden: true,
            headerClasses: "align-middle font-weight-500 text-nowrap text-center",
            classes: "align-middle"
        },
        {
            dataField: "image_url",
            text: "Image",
            headerClasses: "align-middle font-weight-500 text-nowrap",
            formatter: (cell, row) => <div className="food-thumbnail"><img src={cell || NotAvailable} alt={row.FoodItem} /></div>,
            classes: "align-middle"
        },
        {
            dataField: "food_name",
            text: "Food Item",
            headerClasses: "align-middle font-weight-500 text-nowrap",
            classes: "align-middle",
            sort: true,
            sortCaret: (order, column) => <SortCaret order={order} />
        },
        {
            dataField: "default_level",
            text: "Default Level",
            headerClasses: "align-middle font-weight-500 text-nowrap text-center",
            formatter: (cell, row) => (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                    <LevelBox level={cell} />
                </div>
            ),
            classes: "align-middle"
        },
        {
            dataField: "default_level_note",
            text: "Default Level Description",
            headerClasses: "align-middle font-weight-500 text-nowrap",
            classes: "align-middle",
            headerStyle: (colum, colIndex) => {
                return { minWidth: "250px" };
            },
            formatter: (cell, row) => cell?.length > maxLength ? cell.substring(0, maxLength) + "..." : cell
        },
        {
            dataField: "modification",
            text: "Modification",
            headerClasses: "align-middle font-weight-500 text-nowrap",
            classes: "align-middle",
            formatter: (cell, row) => {
                return (
                    <Button
                        className="btn-primary --bg-primary text-nowrap"
                        color="primary"
                        onClick={() => toggle('modification', row)}
                    >
                        View Modification
                    </Button>
                )
            }
        },
        {
            dataField: "validated",
            text: "Reviewed?",
            headerClasses: "align-middle font-weight-500 text-nowrap text-center",
            formatter: (cell, row) => (
                <ValidateFoodItem {...props} row={row} cell={cell} />
            ),
            classes: "align-middle"
        },
        {
            dataField: "Actions",
            text: "",
            style: { width: "100px", whiteSpace: "nowrap" },
            headerClasses: "align-middle font-weight-400 text-center",
            classes: "align-middle",
            formatter: (cell, row) => {
                const handleEditButtonClick = (foodId) => {
                    history.push(`/makansafe/edit-food/${foodId}`);
                };
                return (
                    <div className="d-flex align-items-center justify-content-center">
                        <BtnIconEditV2 handleEditButtonClick={() => handleEditButtonClick(row?.food_id)} />
                        <BtnIconDeleteV2 handleDelete={() => toggle("deleteFood", row)} />
                    </div>
                );
            },
        },
    ];

    // Custom Pagination Component
    const CustomPagination = ({ paginationProps }) => (
        <PaginationListStandalone
            {...paginationProps}
            noBorder={true}
        />
    );

    //toggle
    const toggle = (type, item) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
        if (item?.food_id) {
            setRowOneChecked([item?.food_id]);
            setItemActive(item);
        } else {
            setRowOneChecked(null);
        }
    };

    const SelectionRowRenderer = ({ mode, checked, onChange, ...rest }) => {
        const { rowIndex, ...otherProps } = rest;
        return (
            <div className="checkBox-custom-style --blue-color" style={{ height: "24px" }}>
                <div className="form-group">
                    <input
                        type={mode}
                        checked={checked}
                        onChange={onChange}
                        readOnly
                        {...otherProps}
                    />
                    <label htmlFor="selectAllRows" style={{ width: "24px" }}></label>
                </div>
            </div>
        );
    };

    const handleDeleteMultiplesFood = () => {
        const params = {
            "action_type": "delete_multiples",
            "ids": rowChecked?.length > 0 ? rowChecked : rowOneChecked
        }

        setLoading(prevState => ({ ...prevState, deleteFood: true }));
        //Truyền true hàm apiCaller để sử dụng api makansafe
        apiCaller(`/api/iddsi/functional/`, "POST", params, true).then(res => {
            if (res?.status === 200) {
                setLoading(prevState => ({ ...prevState, deleteFood: false }));
                toggle('deleteFood');
                fetchDataFoods(null); // Reload data after successful delete
                setRowChecked([]);
                setRowOneChecked([]);
            } else {
                console.log("error");
                setLoading(prevState => ({ ...prevState, deleteFood: false }));
                toggle('deleteFood');
            }
        });
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selectionHeaderRenderer: SelectionRowRenderer,
        selectionRenderer: SelectionRowRenderer,
        style: { backgroundColor: '#F2F4F7' },
        onSelect: (row, isSelect) => {
            let newArray = [...rowChecked, row?.food_id];
            if (rowChecked.includes(row?.food_id)) {
                newArray = newArray.filter((item) => item !== row?.food_id);
            }
            setRowChecked(newArray);
        },

        onSelectAll: (isSelect, rows, e) => {
            const newArr = [];
            if (isSelect) {
                rows.map((value) => newArr.push(value?.food_id));
                setRowChecked(newArr);
            } else {
                setRowChecked([]);
            }
        },
        headerColumnStyle: {
            verticalAlign: 'middle'
        },
        selectColumnStyle: {
            verticalAlign: 'middle'
        }
    };

    const { SearchBar } = Search;
    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
            <ToolkitProvider
                keyField="food_id"
                data={foodsDataState || []}
                columns={columns}
                search
            >
                {(toolkitprops) => (
                    <React.Fragment>
                        <div className="filter-header-container d-flex justify-content-between py-4 border-top">
                            <FilterContainer
                                toolkitprops={toolkitprops} // Truyền toolkitprops vào FilterContainer
                                rowChecked={rowChecked}
                                handleDelete={() => toggle("deleteFood")}
                                isLoading={isLoading}
                                rowOneChecked={rowOneChecked}
                            />
                            <div className="serach_field-area ml-auto">
                                <div className="search_inner">
                                    <div className="search_field">
                                        <Iconsax iconName="search" fill="#697586" size={24} />
                                        <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                                            paginationProps.page = 1
                                            toolkitprops.searchProps.onSearch(e)
                                        }} />
                                        {toolkitprops?.searchProps?.searchText &&
                                            <div className="search_field__clear" onClick={toolkitprops.searchProps.onClear}>
                                                <Iconsax iconName="close-box" fill="#697586" size={24} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            wrapperClasses="table-responsive"
                            bordered={false}
                            data={foodsDataState}
                            columns={columns}
                            noDataIndication={() => (
                                <DataEmty content="No Data" />
                            )}
                            selectRow={selectRow}
                            {...toolkitprops.baseProps}
                            {...paginationTableProps}
                        />
                    </React.Fragment>
                )}
            </ToolkitProvider>
            <div className="pagination-custom-style ml-auto d-flex justify-content-end">
                <PaginationListStandalone {...paginationProps} />
            </div>
        </div>
    );

    // Pagination options
    const options = {
        paginationComponent: CustomPagination,
        custom: true,
        paginationSize: window.innerWidth <= 480 ? 1 : 4, // Giảm số lượng trang khi trên mobile
        pageStartIndex: 1,
        showTotal: true,
        totalSize: foodsDataState?.length
    };

    return (
        <React.Fragment>
            <TableHeader
                {...props}
                foodsDataState={foodsDataState}
            />
            <div className="table-food table-custom-new position-relative">
                {isLoading?.getAllData && <LoaderText />}
                <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTable}
                </PaginationProvider>
            </div>
            <DeleteModal
                isOpen={modal?.deleteFood}
                toggle={() => toggle('deleteFood')}
                isLoading={isLoading?.deleteFood}
                handleRemove={handleDeleteMultiplesFood}
            />
            <ModalModification
                isOpen={modal?.modification}
                toggleModal={() => toggle('modification')}
                itemActive={itemActive}
            />
        </React.Fragment>
    );
}

export default TableFoods;