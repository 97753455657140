import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { agreementsConstants } from "@constants";

const ModalEditChatTopic = (props) => {
    const { isOpen, toggle, rowData, item } = props && props;
    const dispatch = useDispatch();

    //state
    const [isLoading, setLoading] = useState(false);

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    useEffect(() => {
        setValue("Name", item?.Name, { shouldDirty: false });
        setValue("StandardResponse", item?.StandardResponse, { shouldDirty: false });
    }, [item, setValue])

    const onSubmit = (data) => {
        setLoading(true);
        const isLoading = true;
        const params = {
            "Action": "EditStandardChatTopic",
            "Name": data?.Name,
            "Type": rowData?.SectionName,
            "StandardResponse": data?.StandardResponse,
            "ChatTopicId": item?.Id
        }

        //Call Api
        dispatch({ type: agreementsConstants.UPDATE_STANDARD_CHAT_TOPICS_REQUEST, isLoading });
        apiCaller(`/api/teach/chat/standard/`, "POST", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({
                    type: agreementsConstants.UPDATE_STANDARD_CHAT_TOPICS_SUCCESS,
                    payload: data,
                    isActive: rowData?.SectionName,
                    ParentChatTopicId: item?.ParentTopic?.Id || ''
                });

                //Close
                toggle();
                setLoading(false);
            } else {
                dispatch({ type: agreementsConstants.UPDATE_STANDARD_CHAT_TOPICS_FAILURE, error: 'error' });

                //close
                toggle();
                setLoading(false);
            }
        });
    }
    
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Update Chat Topic</ModalHeader>
                <ModalBody>
                    <FormGroup className="mt-2">
                        <Label for="Name">Name <span className="m--font-danger">*</span></Label>
                        <Input
                            type="text"
                            name="Name"
                            {...register("Name", { required: true })}
                            invalid={errors.Name?.type === "required"}
                            defaultValue={watch("Name")}
                            onChange={(e) => {
                                setValue("Name", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Name?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Label htmlFor="StandardResponse">StandardResponse:</Label>
                        <Input
                            type="textarea"
                            name="StandardResponse"
                            onChange={(e) => {
                                setValue("StandardResponse", e.target.value, { shouldValidate: true });
                            }}
                            defaultValue={watch("StandardResponse")}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="danger"
                        className="btn btn-brand --bg-primary d-flex align-items-center"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalEditChatTopic;