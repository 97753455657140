import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getListLearner } from "@actions"
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { DataEmptyCase } from "@components-common";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { ModalDeleteCommon } from "@components-common/Modal";
import { apiCaller } from "@utils";
import { teachV2Constants } from "@constants";

const ListLearnerStory = () => {
  const { id } = useParams();
  const { SearchBar } = Search;
  const listLearner = useSelector((state) => state.getListLearner || []);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [modal, setModal] = useState({
    Delete: false
  });

  const hanldeModal = (type, data) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    setCurrentData(data);
  }

  const handleRemove = (type) => {
    setLoading(true);
    const params = {
      "Action": "remove",
      "Emails": [currentData]
    }
    dispatch({ type: teachV2Constants.REMOVE_LEARNER_REQUEST, isLoading });
    apiCaller(`/api/teach/learners/case/${id}/`, "PUT", params).then((res) => {
      if (res?.status === 200) {
        dispatch({ type: teachV2Constants.REMOVE_LEARNER_SUCCESS, payload: res.data });
        setLoading(false);
        hanldeModal("Delete", {})
      } else {
        dispatch({ type: teachV2Constants.REMOVE_LEARNER_FAILURE, error: 'error' });
        setLoading(false);
        console.log('error');
      }
    });
  }

  useEffect(() => {
    dispatch(getListLearner(id))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      dataField: "",
      text: "Learner",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className="learner-info">
            <p className="learner-info__name">{row?.firstname} {row?.lastname}</p>
            <p className="learner-info__email">{row?.email}</p>
          </div>
        );
      },
      filterValue: (cell, row) => row?.email,
    },
    {
      dataField: "status",
      text: "Status",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className={`learner-status ${row.status_color}`}>
            <span></span>
            <p className="">{cell}</p>
          </div>
        );
      },
    },
    {
      dataField: "access_type",
      text: "Access Type",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell) => {
        return (
          <div className="learner-type">
            {cell &&
              <p className="learner-type__text">{cell}</p>
            }
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <div className="learner-remove" onClick={() => hanldeModal("Delete", row?.email)}>
            Remove
          </div>
        );
      },
      filterValue: (cell, row) => row?.lastname,
    }
  ];

  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    classes: "selection-row"
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log({ row, rowIndex });
    }
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="Id"
        data={listLearner?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-learner__filter d-flex align-items-center justify-content-between">
              <p>Total Learners: {listLearner?.data?.length}</p>
              <div className="boxSearch">
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }}/>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive mt-4"
              bordered={false}
              data={listLearner?.data || []}
              columns={columns}
              selectRow={selectRow}
              rowEvents={rowEvents}
              noDataIndication={() => (
                <DataEmptyCase 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: listLearner?.data?.length || [],
  };

  return (
    <div className='table-learner'>
      {listLearner?.isLoading ? (
        <div className='d-flex align-items-center justify-content-center'>
          <ClipLoader color="#0089C2" size={20} />
        </div>
      ) : (
        listLearner?.data?.length === 0 ? (
          <DataEmptyCase 
            title="Your List is Empty"
            text="It looks like no learners have been assigned to this case yet. Start inviting learners by clicking on the 'Share' button located in the top right corner."
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
      <ModalDeleteCommon
        title={`Remove Learner`}
        sub={`Are you sure you want to remove the user ${currentData} from this case? This action cannot be undone.`}
        isOpen={modal?.Delete}
        toggle={() => hanldeModal('Delete', {})}
        handleRemove={handleRemove}
        isLoading={isLoading}
      />
    </div>
  )
}

export default ListLearnerStory
