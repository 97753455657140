import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    FormFeedback,
    Label,
    Col,
    Row,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

import { actCreateIOItem } from "@actions";

const ModalAddIO = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { typeBalance, toggleAdd, isOpen, timePointId } = props && props;

    //form
    const { register, formState: {
        errors
    }, handleSubmit, watch, setValue } = useForm();

    const handleAdd = (data) => {
        const params = {
            Action: "Create",
            Target: "IO",
            TimePointId: timePointId,
            IO: {
                "IOType": typeBalance,
                Name: data.Name,
                Unit: "mL",
                InitialValue: data.InitialValue,
                NormalMax: data.NormalMax,
                NormalMin: data.NormalMin
            }

        }
        
        dispatch(actCreateIOItem(id, params)); 
        toggleAdd();
        
        //Reset data
        setValue('Name', '', { shouldValidate: false });
        setValue('InitialValue', '', { shouldValidate: false });
        setValue('NormalMax', '', { shouldValidate: false });
        setValue('NormalMin', '', { shouldValidate: false });
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggleAdd}>
                <ModalHeader toggle={toggleAdd}>
                    {typeBalance === 'Intake' ? 'Add Intake Item' : 'Add Output Item'}
                </ModalHeader>
                <ModalBody>
                    <FormGroup className="mt-2">
                        <Label for="Name">Name:
                            <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            type="text"
                            name="Name"
                            {...register("Name", { required: true })}
                            invalid={errors.Name
                                ?.type === "required"}
                            defaultValue={watch("Name")}
                            onChange={(e) => {
                                setValue("Name", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback
                            invalid={(errors.Name
                                ?.type === "required").toString()}>
                            This field is required.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Label for="InitialValue">InitialValue:
                            <span className="m--font-danger">*</span>
                        </Label>
                        <Input
                            type="text"
                            name="InitialValue"
                            {...register("InitialValue", { required: true })}
                            invalid={errors.InitialValue
                                ?.type === "required"}
                            defaultValue={watch("InitialValue")}
                            onChange={(e) => {
                                setValue("InitialValue", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback
                            invalid={(errors.InitialValue
                                ?.type === "required").toString()}>
                            This field is required.
                        </FormFeedback>
                    </FormGroup>
                    <Row>
                        <Col sm={12}>
                            <Label for="InitialValue">Reference (Normal) Range:</Label>
                        </Col>
                        <Col sm={6}>
                            <InputGroup className="mt-2">
                                <Input
                                    type="number"
                                    name="NormalMin"
                                    {...register("NormalMin")}
                                    placeholder="" />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <span>mL</span>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                        <Col sm={6}>
                            <InputGroup className="mt-2">
                                <Input
                                    type="number"
                                    name="NormalMax"
                                    {...register("NormalMax")}
                                    placeholder="" />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <span>mL</span>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleAdd}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="danger"
                        className="btn btn-brand --bg-primary"
                        onClick={handleSubmit(handleAdd)}>
                        Add
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalAddIO;