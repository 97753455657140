import React, { useState } from 'react';
import { Iconsax } from '@components-common';
import { BtnIconDeleteV2, BtnIconEditV2 } from "@components-common/elements";

const CommonCollapse = ({ title, handleDelete, handleEdit, children }) => {

  //state
  const [isOpen, setIsOpen] = useState(true);

  //handle toggle collapse
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className={`form-details card-area bg-grey`}>
      <div className={`head-form ${isOpen ? 'expanded' : 'collapsed'}`}>
        <div className='d-flex align-items-center'>
          <div className={`arrow-collapse ${isOpen ? 'expanded' : 'collapsed'} mr-2`} onClick={toggleCollapse}>
            <Iconsax iconName={`${isOpen ? "chevron-up-new" : "chevron-down-new"}`} fill="#0089C2" size={20} />
          </div>
          <h3 className='head-form__title'>{title}</h3>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <BtnIconEditV2
            handleEditButtonClick={handleEdit}
            colorIcon={`#16B364`}
          />
          <BtnIconDeleteV2 handleDelete={handleDelete} />
        </div>
      </div>
      <div className={`inner-ct-collapse ${isOpen ? 'expanded' : 'collapsed'}`} style={{
        overflow: 'hidden',
        transition: 'height 0.3s ease-in-out',
        height: isOpen ? 'auto' : '0',
      }}>
        {children}
      </div>
    </section>
  );
};

export default CommonCollapse;