/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Icon } from '@components/common'
import result from '@images/teachV2/home.svg';
import stick from '@images/teachV2/stick-btn.svg'
import InputComponent from './InputComponent'
import GenerateOption from './GenerateOption'
import ClipLoader from "react-spinners/ClipLoader";
import { listFileAccept } from "@utils";
import { apiCaller } from "@utils";
import { useSelector } from "react-redux";

//components
import { IconCoinBtn } from '@components/Coin';

export default function InputGenerate(props) {
  const { loadingResult, dataFileImported, watch, getCoinMcqByTemplate } = props
  const coinMcq = useSelector((state) => (state.getCoinMcq && state.getCoinMcq.data) || 0);
  const [listTemplate, setListTemplate] = useState([])

  useEffect(() => {
    const params = {
      "action_type": "get_templates"
    }
    apiCaller(`/api/medicalcase/functional/`, "POST", params)
      .then(response => {
        const dataConvert = response.data?.map((temp) => {
          return {
            value: temp?.template_id,
            label: temp?.name,
          }
        })
        getCoinMcqByTemplate(dataConvert[dataConvert?.length - 1]?.value)
        setListTemplate(dataConvert)
      })
      .catch(error => {
        console.error("Error in API call", error);
      });
  }, [])

  return (
    <div className="inputGenerate">
      <div className="title-generate">
        <div className="title-generate__icon">
          <Icon src={result} color="#fff"/>
        </div>
        <p className="title-generate__text">Input Details for Generation</p>
      </div>
      <div className="inputGenerate__form mb-2">
        <InputComponent title="Instructions" textPlaceholder="E.g. Create a multiple choice question for third-year medical students about the pathophysiology of Type 2 Diabetes, focusing on the mechanisms of insulin resistance, based on the ATTACHED DOCUMENT." {...props}/>
        <GenerateOption listTemplate={listTemplate} handleCoin={getCoinMcqByTemplate} {...props}/>
      </div>
      <div className="inputGenerate__submit w-100 position-relative">
        <button type='submit' className={`${loadingResult || (dataFileImported && (!listFileAccept?.includes(dataFileImported?.type) || dataFileImported.size > 10000000)) || !watch("prompt") ? "disabled" : ""} inputGenerate__submit-btn fifth-step`}>
          <Icon src={stick}/>
          Generate 
          {loadingResult && <ClipLoader color="#FFF" size={16}/>}
        </button>
        <IconCoinBtn {...props} coin={coinMcq['btn-generate-mcq']} />
      </div>
    </div>
  )
}
