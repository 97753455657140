import React, { useState } from "react";

//reactstrap
import {
    Row,
    Modal,
    ModalBody,
    Button,
    Input,
    FormGroup,
    Label,
    ModalFooter,
    ModalHeader,
    Form,
} from "reactstrap";

//react-hook-form
import { useForm } from "react-hook-form";

//utils
import { apiCaller } from "@utils";

//textarea
import TextareaAutosize from 'react-textarea-autosize';
function ModalCreateTemplate(props) {
    //props
    const { toggle, isOpen, handleReLoadTemplate } = props;

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    //state
    const [isLoading, setLoading] = useState(false);

    //Handle Submit
    const onSubmit = (data) => {
        if (!data.TemplateName || !data.TemplateName.trim()) {
            // Trường "TemplateName" không được để trống
            return;
        }
        
        setLoading(true);
        const params = {
            "Action": "CreateTemplate",
            "TemplateName": data?.TemplateName
        }
        apiCaller(`/api/teach/interface_template/`, "POST", params).then((res) => {
            if (res?.status === 200) {
                setLoading(false);
                toggle();
                handleReLoadTemplate();
                reset();
            } else {
                console.log('error');
                setLoading(false);
                toggle();
                reset();
            }
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            style={{ maxWidth: "550px" }}
            backdrop={`static`}
            centered
        >
            <ModalHeader toggle={toggle}>
                New Template
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <FormGroup className="col-8">
                            <Label className="font-weight-500" for="TemplateName">
                                Template Name
                            </Label>
                            <Input
                                {...register("TemplateName", { required: "Template Name is required!" })}
                                type="text"
                                name="TemplateName"
                                autoComplete="off"
                                placeholder="TemplateName"
                            />
                            {errors.TemplateName && <span className="text-danger">{errors.TemplateName.message}</span>}
                        </FormGroup>
                        <FormGroup className="col-12">
                            <Label className="font-weight-500" for="TemplateDescription">
                                Description
                            </Label>
                            <TextareaAutosize
                                {...register("TemplateDescription")}
                                className={`w-100 form-control`}
                                maxRows={12}
                                minRows={7}
                                name="TemplateDescription"
                                placeholder="Enter the description for this template"
                            />
                        </FormGroup>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <Button color="secondary">
                    Cancel
                </Button>{" "}
                <Button
                    color="danger"
                    type="submit"
                    className="btn btn-sm --bg-primary d-flex align-items-center ml-3"
                    onClick={handleSubmit(onSubmit)}
                >
                    {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                    Create
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalCreateTemplate;