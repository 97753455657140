import React from 'react';
import { Link } from "react-router-dom";
import { StepByStep } from "@components/common/step";

//boostrap
import {
    Container,
    Row,
    Col
} from 'reactstrap';

function Four(props) {
    const { params, stepData } = props && props;
    const caseId = params?.NewCaseId;
    return (
        <React.Fragment>
            <StepByStep stepActive={3} data={stepData?.addCase} />
            <Container>
                <section className="formCt boxPortlet">
                    <Row>
                        <Col md={12}>
                            <div className="successForm">
                                <h1 className="successForm__title --text-primary">Your new case has been created!</h1>
                                <p className="successForm__content">Click on the button below to start editing the case</p>
                                <div className="d-flex justify-content-center mt-5">
                                    {caseId ? (
                                        <Link to={`/teach/case/${caseId}`} onClick={() => {
                                            //Xoa data o local
                                            localStorage.removeItem("params-step");
                                        }} className="btn btn-brand ml-1 --bg-primary"> Edit Case </Link>
                                    ) : 'loading...'}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </React.Fragment>
    );
}

export default Four;