import React, { useState } from "react";

//reactstrap
import {
    Button,
    FormGroup
} from "reactstrap";

//textarea
import TextareaAutosize from "react-textarea-autosize";

//utils
import { apiCaller, LoaderText } from "@utils";

function Midjourney(props) {

    //state
    const [stateDescription, setDescription] = useState('');
    const [isLoading, setLoading] = useState('');
    const [stateData, setData] = useState('');

    // Handle Change
    const hanldeChange = (e) => {
        const { value } = e.target;
        setDescription(value);
    }

    // Handle Submit
    const handleSubmit = () => {
        const params = {
            "action_type": "drawing_prompt",
            "description": stateDescription,
            "negative_prompt": null,
            "drawing_model": "5",
            "generate_model": "gpt-3.5-turbo",
            "ar": "7:4",
            "temperature": 0.7,
            "max_len": 2048
        }
        setLoading(true);
        apiCaller(`/api/binh/openai/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                setData(data);
                setLoading(false);
            } else {
                console.log('error');
                setLoading(false);
            }
        });
    }

    return (
        <React.Fragment>
            <FormGroup>
                <TextareaAutosize
                    name="content"
                    placeholder="Enter your content..."
                    className="w-100 form-control"
                    maxRows="10"
                    minRows={5}
                    onChange={hanldeChange}
                />
                <div className="text-right">
                    <Button
                        color="primary"
                        className="btn btn-brand --bg-primary mt-3"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading &&
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                        }
                        Submit
                    </Button>
                </div>
            </FormGroup>
            <div className="position-relative mt-3">
                {isLoading && <LoaderText />}
                {stateData && (
                    <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: stateData }} />
                )}
            </div>
        </React.Fragment>
    );
}

export default Midjourney;