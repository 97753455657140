import React from 'react';
import styled, { css } from 'styled-components';
import ReactTooltip from 'react-tooltip';

const StatusField = ({ status, row }) => {
  
  return (
    <>
      <div data-tip data-for={`data-${row?.document_id}`}>
        <StatusContainer color={status.color}>{status.content}</StatusContainer>
      </div>
      {row?.status?.content === "Caution" && (
        <ReactTooltip id={`data-${row?.document_id}`} place='top' effect='solid' className="custom-tooltip">
          <span>This document contains limited readable text, which may affect the generated content quality. Please proceed with caution when using it for generation.</span>
        </ReactTooltip>
      )}
    </>
  )
};

const commonStyles = css`
  display: flex;
  padding: 2px 8px 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 9999px;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  
  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;

const StatusContainer = styled.div`
  ${commonStyles}
  border: 1px solid ${(props) => {
    switch (props.color) {
      case 'success':
        return '#ABEFC6';
      case 'warning':
        return '#FDEBC8';
      case 'error':
        return '#FAD4D4';
      default:
        return '#E5E7EB';
    }
  }};
  background: ${(props) => {
    switch (props.color) {
      case 'success':
        return '#ECFDF3';
      case 'warning':
        return '#FFFBF5';
      case 'error':
        return '#FEF2F2';
      default:
        return '#F9FAFB';
    }
  }};
  color: ${(props) => {
    switch (props.color) {
      case 'success':
        return '#067647';
      case 'warning':
        return '#F79E1C';
      case 'error':
        return '#DC2626';
      default:
        return '#374151';
    }
  }};
  &::before {
    background-color: ${(props) => {
    switch (props.color) {
      case 'success':
        return '#17B26A';
      case 'warning':
        return '#F79E1C';
      case 'error':
        return '#EF4444';
      default:
        return '#9CA3AF';
    }
  }};
  }
`;

export default StatusField;