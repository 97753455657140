import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import image from "@images/common/pic-exit-case.svg";

function ModalExitCase(props) {
  const history = useHistory();
  const { isOpen, toggle } = props || {};
  const [isLoading, setLoading] = useState(false);

  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const ModalExitCase = InterfaceTemplateDetails?.ModalExitCase || {};

  const convertToHTML = (text) => ({ __html: text });

  // Hàm kiểm tra và chuyển đổi HTML
  const convertAndCheckHTML = (text) => {
    const defaultHTML = `<p>You're crushing it! Are you sure you want to exit? 😮🚀</p>`;
    const htmlText = text ?? defaultHTML;
    return convertToHTML(htmlText).__html;
  };


  const handleConfirmExitCase = () => {
    setLoading(true);
    setTimeout(() => {
      history.push("/learn/home");
      setLoading(false);
    }, 2000);
  };

  return (
    <Modal
      className="modal-custom"
      zIndex={16000}
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
    >
      <ModalBody>
        <div className="modal-inner">
          <div className="modal-inner__pic text-center">
            <img src={image} alt="Don't Quit Now! Almost there..." />
          </div>
          <div className="modal-inner__desc text-center">
            <h2 className="headline --size-4 text-center">
              <div dangerouslySetInnerHTML={convertToHTML(ModalExitCase?.m_exit_title?.Text || "Don't Quit Now! Almost there...")} />
            </h2>
            <div className="mt-2" dangerouslySetInnerHTML={{ __html: convertAndCheckHTML(ModalExitCase?.m_exit_msg?.Text) }} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mx-auto" style={{ maxWidth: "335px" }}>
          <Button
            color="primary"
            className="btn-brand-sm -btn-lg w-100 -shadown"
            onClick={toggle}
            disabled={isLoading}
          >
            {isLoading && <span className="spinner-border spinner-border-sm mr-2" />}
            <span dangerouslySetInnerHTML={convertToHTML(ModalExitCase?.m_exit_button_confirm?.Text || "Prescription: Keep Going")} />
          </Button>
          <Button
            color="white"
            className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
            onClick={handleConfirmExitCase}
            disabled={isLoading}
          >
            <span dangerouslySetInnerHTML={convertToHTML(ModalExitCase?.m_exit_button_cancel?.Text || "Exit anyway")} />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ModalExitCase;
