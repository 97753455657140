import React, { useState, useEffect, useCallback } from 'react';
import { HeadingTask } from "../..";

// components
import { BtnAddRow } from "@components-common/elements";
import { ModalDeleteCommon } from "@components-common/Modal";
import { ModalVitalSign } from "../../../CaseTimeline";

//redux
import { useDispatch } from "react-redux";

//utils
import { apiCaller, LoaderText } from "@utils";
//constants
import { storyboardConstants } from "@constants";

//react-router-dom
import { useParams } from "react-router-dom";

//components
import { TableTimepointFirst } from '../TimepointFirst';

const VitalSignTableFirst = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { timepointData } = props && props;

    //state
    const [modalState, setModalState] = useState({
        Update: false,
        Create: false,
        delVital: false
    });
    const [tblvitalSigns, setTblvitalSigns] = useState([]);
    const [isLoadingGenerate, setLoadingGenerate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const vitalSigns = timepointData?.DefaultVitalSigns || [];
        const tblvitalSigns = vitalSigns?.map((value) => ({
            ...value,
            Unit: value.Unit === "℃" ? "℃" : value.Unit,
            referenceRange: `${value.NormalMin} ${value.Unit === "℃" ? "℃" : value.Unit} - ${value.NormalMax} ${value.Unit === "℃" ? "℃" : value.Unit}`,
        }));
        setTblvitalSigns(tblvitalSigns);
    }, [timepointData?.DefaultVitalSigns]);

    // handle Generate Ai
    const handleGenerateAi = () => {
        const params = {
            "Action": "AI_Generate",
            "Target": "VitalSigns",
            "TimePointId": timepointData?.Id
        }
        setLoadingGenerate(true);
        //Call Api
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;
                setTblvitalSigns(data);
                setLoadingGenerate(false);
            } else {
                console.log("error");
                setLoadingGenerate(false);
            }
        });
    }

    // Handle Button Edit and Delete
    const handleModalToggle = useCallback((type, item = {}) => {
        setModalState(prevState => ({
            ...prevState,
            [type]: !prevState[type],
            selectedItem: item
        }));
    }, []);

    // Handle Remove Item 
    const handleRemove = () => {
        const params = {
            Action: "Delete",
            Target: "VitalSign",
            TimePointId: timepointData?.Id,
            VitalSignId: modalState?.selectedItem?.Id,
        };
        setIsLoading(true);
        //Call Api
        dispatch({ type: storyboardConstants.DELETE_VITAL_SIGN_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                const data = res?.data;
                dispatch({
                    type: storyboardConstants.DELETE_VITAL_SIGN_SUCCESS,
                    payload: data
                });
                handleModalToggle('delVital');
                setIsLoading(false);
            } else {
                dispatch({
                    type: storyboardConstants.DELETE_VITAL_SIGN_FAILURE,
                    error: 'error'
                });
                handleModalToggle('delVital');
                setIsLoading(false);
            }
        });
    }

    return (
        <section className="card-area">
            <HeadingTask
                {...props}
                title={`Vital Signs`}
                handleClickBtn={handleGenerateAi}
                isGenerating={isLoadingGenerate}
                GenerateAIButton={true}
            />
            <div className='position-relative'>
                {isLoadingGenerate && <LoaderText />}
                <TableTimepointFirst
                    {...props}
                    tblvitalSigns={tblvitalSigns}
                    handleEditButtonClick={(item) => handleModalToggle('Update', item)}
                    handleDeleteButtonClick={(item) => handleModalToggle('delVital', item)}
                />
                <BtnAddRow
                    handleClickAddRow={() => handleModalToggle('Create')}
                    textBtn={`Add Vital Sign`}
                />
                <ModalVitalSign
                    {...props}
                    isOpen={modalState?.Update || modalState?.Create}
                    toggleModal={() => handleModalToggle(modalState?.Create ? "Create" : "Update")}
                    selectedItem={modalState.selectedItem}
                    items={modalState?.selectedItem || {}}
                    typeModal={modalState?.Create ? "Create" : "Update"}
                />
                <ModalDeleteCommon
                    {...props}
                    title={`Are you sure you want to delete this record ?`}
                    sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
                    isOpen={modalState.delVital}
                    toggle={() => handleModalToggle('delVital')}
                    handleRemove={handleRemove}
                    isLoading={isLoading}
                />
            </div>
        </section>
    );
};

export default VitalSignTableFirst;