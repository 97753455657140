import React from "react";
import { FormModal } from "@components-common/Modal";

//components
import FileUploadComponent from "../ReferenceLiblary/FileUploadComponent";

const ModalUploadReferenceDocument = ({ isOpen, toggleModal, title, loadDataReferenceBook }) => {
    return (
        <FormModal
            isOpen={isOpen}
            toggleModal={toggleModal}
            title={title}
            maxWidth="540px"
            hiddenIcon={true}
            hiddenButtonCancel={true}
        >
            <FileUploadComponent toggleModal={toggleModal} loadDataReferenceBook={loadDataReferenceBook} />
        </FormModal>
    );
};

export default ModalUploadReferenceDocument;