import React, { useState, useCallback, useEffect } from "react";
import { Table, Input } from "reactstrap";

//components
import { BtnAddRow } from '@components-common/elements';

//Icon
import { Iconsax } from "@components-common";

//redux
import { useSelector } from "react-redux";

// Component for Table Row
const TableRow = ({ value, index, onInputChange, onRemove }) => {
  return (
    <tr>
      <td className="align-middle">{index + 1}</td>
      <td>
        <Input
          type="textarea"
          name="AnswerContent"
          rows={1}
          value={value.AnswerContent}
          onChange={(e) => onInputChange(index, e)}
          placeholder="Enter content here"
          style={{ minHeight: '36px' }}
        />
      </td>
      <td>
        <Input
          type="textarea"
          name="Explanation"
          rows={1}
          value={value.Explanation}
          onChange={(e) => onInputChange(index, e)}
          placeholder="Enter content here"
          style={{ minHeight: '36px' }}
        />
      </td>
      <td className="align-middle">
        <div className="d-flex align-items-center justify-content-center">
          <Input
            className="m-0"
            name="isCorrect"
            type="checkbox"
            checked={value.isCorrect}
            onChange={(e) => onInputChange(index, e)}
          />
        </div>
      </td>
      <td>
        <Input
          type="number"
          name="Score"
          value={value.Score}
          onChange={(e) => onInputChange(index, e)}
          step="any"
          style={{ height: '36px' }}
        />
      </td>
      <td className="align-middle">
        <div onClick={() => onRemove(index)} style={{ cursor: "pointer" }}>
          <Iconsax iconName="trash-2" fill="#F04438" size={24} />
        </div>
      </td>
    </tr>
  );
};

function TableAnswerMutipleChoice({ onDataAnswerChange, itemQuestion }) {

  // store
  const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

  // Initialize state with questionData.Answers from props
  const [stateData, setData] = useState([]);

  useEffect(() => {
    setData(questionViewActive === "Edit_Question" ? itemQuestion?.Answers : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionViewActive]);

  // Handle Change
  const handleInputChange = useCallback((index, event) => {
    const { name, value, checked } = event.target;
    setData(prevState => {
      const newData = [...prevState];
      newData[index] = {
        ...newData[index],
        [name]: name === "isCorrect" ? checked : value
      };
      onDataAnswerChange(newData);  // Call onDataAnswerChange with updated state
      return newData;
    });
  }, [onDataAnswerChange]);

  const handleAddAnswer = useCallback(() => {
    setData(prevState => {
      const newState = [
        ...prevState,
        {
          AnswerId: 0,
          AnswerContent: "",
          Explanation: "",
          Score: 0,
          QuestionAnswerId: null,
          isCorrect: false
        }
      ];
      onDataAnswerChange(newState);  // Call onDataAnswerChange with updated state
      return newState;
    });
  }, [onDataAnswerChange]);

  const handleRemoveAnswerGroup = useCallback(index => {
    setData(prevState => {
      const newState = prevState.filter((_, i) => i !== index);
      onDataAnswerChange(newState);  // Call onDataAnswerChange with updated state
      return newState;
    });
  }, [onDataAnswerChange]);

  return (
    <>
      <div className="table-responsive">
        <Table className="custom-table-corner">
          <thead>
            <tr>
              <th className="font-weight-500">#</th>
              <th className="font-weight-500" style={{ width: "30%" }}>
                Answer Text
              </th>
              <th className="font-weight-500" style={{ width: "30%" }}>
                Explanation
              </th>
              <th className="font-weight-500">Correct?</th>
              <th className="font-weight-500" style={{ width: "15%" }}>
                Point
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {stateData?.map((value, index) => (
              <TableRow
                key={index}
                index={index}
                value={value}
                onInputChange={handleInputChange}
                onRemove={handleRemoveAnswerGroup}
              />
            ))}
          </tbody>
        </Table>
      </div>
      <BtnAddRow
        handleClickAddRow={handleAddAnswer}
        textBtn="Add option"
      />
    </>
  );
}

export default TableAnswerMutipleChoice;