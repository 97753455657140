import React from 'react';
import { useSelector } from "react-redux";
import { PopperItem } from "@components/common/Popper";
import { useParams } from "react-router-dom";

function ButtonNextTimepoint(props) {
    const {
        dataTour,
        overRideEffect,
        taskNexTour,
        disableNextTimepoint,
        handleClickBtnNextTimePoint,
        isLoading
    } = props;

    const getStore = useSelector(state => state?.checkMissingTask?.data || {});
    const isMissingTask = getStore.MissingTask;
    const statusOk = getStore?.status === "OK";
    const fileList = dataTour?.filter(event => event?.selector?.includes('step-4'));
    const buttonClassNames = getClassNames(overRideEffect, isMissingTask, disableNextTimepoint, isLoading, statusOk);
    let { id } = useParams();
    return (
        <div className={buttonClassNames} onClick={() => (isMissingTask === 'advance' || statusOk) && handleClickBtnNextTimePoint(id, "advance")}>
            <div
                data-task="advance"
                data-tour={fileList?.[0]?.selector}
            >
                {isLoading?.btnNextTimepoint && <span className="spinner-border spinner-border-sm mr-2"></span>}
                Next Timepoint
            </div>
            <PopperItem
                {...props}
                content={getContent()}
                styleInline={{ right: '220px', top: '-100%', position: 'absolute' }}
                postition="right"
                isOpen={taskNexTour === 'next-timepoint'}
                nextTour="end-tour"
            />
        </div>
    );

    function getClassNames(overRideEffect, isMissingTask, disableNextTimepoint, isLoading, statusOk) {
        return [
            "btn",
            "btn-next-timepoint",
            "w-100",
            overRideEffect && "remove-effect",
            isMissingTask === 'advance' && "show-effect",
            ((disableNextTimepoint || isLoading?.btnNextTimepoint) && !statusOk) && "disabled"
        ].filter(Boolean).join(" ");
    }

    function getContent() {
        return (
            <div>
                When you have finished all tasks, click <strong>Next Timepoint</strong> to advance.
            </div>
        );
    }
}

export default ButtonNextTimepoint;