import React, { useCallback, useState } from "react";

//reactstrap
import {
  Row,
  Col,
  Button
} from "reactstrap";

//elements
import { ButtonEdit, ButtonDelete } from '@components-common/elements';

//DropdownFilter
import { DropdownAddUser, DropdownActions } from '../instructor/Users';

//common
import { DataEmty } from "@components-common";
import { ModalDeleteCommon } from "@components-common/Modal";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//compponents
import {
  ModalInvite,
  ModalEditAccount,
  ModalImportExel,
} from "./Modal";
import { UserFilter } from '../instructor/Users';

//redux
import { useDispatch } from "react-redux";

import { atcAddUserAccount, atcRemoveUserAccount } from "@actions";

function TableLearners({ ...props }) {
  const dispatch = useDispatch();
  const resData = props?.data?.data;
  const listAcc = (resData && resData.Accounts) || [];
  const subscription = (resData && resData.Subscription) || {};
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [dataItem, setdataItem] = useState({});
  const [action, setAction] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    delete: false,
    importExel: false,
    invite: false
  });

  const handleChange = (event) => {
    const { value } = event.target;
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchResults(listAcc);
    }
  };

  const toggle = (data, type) => {
    setdataItem(data);
    setAction(type);
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
  };

  const handleChangeActions = (value) => {
    setModal((prevState) => ({ ...prevState, [value]: true }));
  };

  const filterDataByOptions = useCallback(() => {
    const data = resData && resData.Accounts;
    if (!searchTerm) {
      //reset Data
      setSearchResults(data);
    } else {
      //filter data
      const results =
        data &&
        data.filter(function (elem) {
          return elem.IsInstructor === searchTerm;
        });
      setSearchResults(results || data);
    }
  }, [searchTerm, resData]);

  const handleAdd = (data) => {
    const params = {
      Action: "Add",
      Email: data && data.Email,
    };
    dispatch(atcAddUserAccount(params));
    setModal((prevState) => ({ ...prevState, invite: false }));
  };

  const handleRemove = () => {
    const params = {
      Action: "Remove",
      Email: dataItem && dataItem.Email,
    };
    dispatch(atcRemoveUserAccount(params));
    setModal((prevState) => ({ ...prevState, delete: false }));
  };

  //Search
  React.useEffect(() => {
    filterDataByOptions();
  }, [filterDataByOptions]);

  const columns = [
    {
      dataField: "id",
      text: "ID",
      hidden: true,
      headerClasses: "--bg-primary",
    },
    {
      dataField: "LastName",
      text: "Last Name",
      headerClasses: "--bg-primary",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "FirstName",
      text: "First Name",
      headerClasses: "--bg-primary",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "Email",
      text: "Email",
      headerClasses: "--bg-primary",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "Role",
      text: "Role",
      headerClasses: "--bg-primary",
      classes: "align-middle text-center",
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
      formatter: (cell, row) => {
        const checkClassByRole = (type) => {
          let className = ""
          switch (type) {
            case 'Admin':
              className = "text-danger";
              break;
            case 'Instructor':
              className = "text-info";
              break;
            default:
              className = "";
          }
          return className;
        }
        return <span className={`${checkClassByRole(row.Role)}`}>{row.Role}</span>;
      },
    },
    {
      dataField: "Status",
      text: "Status",
      headerClasses: "--bg-primary",
      classes: "align-middle text-center",
      formatter: (cell, row) => {
        return <span className={`${row.Status === 'Inactive' ? 'text-muted' : ''}`}>{row.Status}</span>;
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "Actions",
      text: "Actions",
      headerClasses: "--bg-primary text-center",
      style: {
        width: "100px",
      },
      formatter: (cell, row) => {
        return (
          <div className="d-flex algins-items-center justify-content-center">
            <ButtonEdit handleEdit={() => toggle(row, "edit")} />
            <ButtonDelete handleDelete={() => toggle(row, "delete")} />
          </div>
        );
      },
    },
  ];

  const { SearchBar } = Search;
  const selectRow = {
    mode: "checkbox"
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <ToolkitProvider
        keyField="Id"
        data={(searchResults && [...searchResults]) || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <>
            <Row className="justify-content-end mb-3">
              <Col md={6} sm={6} className="mb-3 mb-sm-0">
                <DropdownAddUser
                  handleChangeActions={handleChangeActions}
                />
                <DropdownActions />
              </Col>
              <Col md={6} sm={6}>
                <div className="boxSearch">
                  <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                    paginationProps.page = 1
                    toolkitprops.searchProps.onSearch(e)
                  }}/>
                  <Button className="--bg-primary btnSearch" color="primary">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </Button>
                </div>
              </Col>
            </Row>
            <BootstrapTable
              condensed
              striped
              wrapperClasses="table-responsive checkBoxCustom"
              selectRow={selectRow}
              noDataIndication={() => (
                <div className="py-5">
                  <DataEmty content="No Data" />
                </div>
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </>
        )}
      </ToolkitProvider>
      {/* /Table */}
      <div className="d-flex justify-content-center mt-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </div>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: (searchResults && searchResults.length) || [],
  };

  return (
    <React.Fragment>
      <ModalInvite
        isOpen={modal.invite}
        toggle={() => toggle({}, "invite")}
        handleAdd={handleAdd}
      />

      <ModalDeleteCommon
        title={`Are you sure you want to remove this account from your subscription?`}
        sub={`The account will no longer have access to the contents that are
                limited to your subscription`}
        isOpen={modal.delete}
        toggle={() => toggle(dataItem, "delete")}
        handleRemove={handleRemove}
      />
      <ModalEditAccount
        isOpen={modal.edit}
        toggle={() => toggle(dataItem, "edit")}
        data={dataItem}
        action={action}
      />
      <ModalImportExel
        isOpen={modal.importExel}
        toggle={() => toggle({}, "importExel")}
      />
      <Row className="mt-3">
        <UserFilter
          data={subscription}
          listAcc={(listAcc && listAcc) || []}
          handleChange={handleChange}
        />
        <Col md={9}>
          <div className="tableLearners">
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default TableLearners;
