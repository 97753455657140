import React from 'react';

//redux
import { useSelector } from "react-redux";

//icon
import IconCLock from "@images/learnerv2/clock-time.svg";

//AvatarProgressBar
import { AvatarProgressBar } from "../Advance";

//Convert Time
function convertMinutesToHourString(timeSpent) {
    let hours = Math.floor(timeSpent / 60);
    let minutes = timeSpent % 60;

    return `<span class='text-info-yellow'>${hours || 0} <span class='text-info-black'>hr</span></span> <span class='text-info-yellow dot-center'>:</span> <span class='text-info-yellow'>${minutes < 10 ? '0' + minutes : (minutes || 0)} <span class='text-info-black'>mins</span></span>`;
}

function CongratulationsEndCase(props) {
    // Interface Template
    // const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    // const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;

    //Case Compeleted - Reducer
    const CaseCompleteReducer = useSelector((state) => state?.caseCompleted?.data || {});
    const CaseCompleteData = CaseCompleteReducer?.CaseCompleteData;
    const timeString = convertMinutesToHourString(CaseCompleteData?.TimeSpent);
    return (
        <div className="modal-inner">
            <div className="modal-inner">
                <div className="modal-inner__desc text-center">
                    <h2 className="headline --size-4 mb-4 text-center">
                        🏆 Enjoy Your Achievements 🏆
                    </h2>
                    <div className='endCaseBox'>
                        <div className='endCaseBox__item'>
                            <div className="card-time">
                                <h3 className='card-time__title'>This case took you</h3>
                                <div className="card-time__icon">
                                    <img src={IconCLock} alt={`time`} width={60} />
                                </div>
                                <div className="end-case-info">
                                    <div dangerouslySetInnerHTML={{ __html: timeString }} />
                                </div>
                            </div>
                        </div>
                        <div className='endCaseBox__item'>
                            <div className="card-time">
                                <h3 className='card-time__title'>{``}</h3>
                                <div className="card-time__icon">
                                    <AvatarProgressBar
                                        {...props}
                                        sizeSm={true}
                                    />
                                </div>
                                <div className="end-case-info d-flex align-items-center justify-content-center">
                                    <span className='text-info-black text-nowrap'>You gained </span> <span className='text-info-yellow text-nowrap ml-2'>{CaseCompleteData?.XP} XP</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CongratulationsEndCase;
