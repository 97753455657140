import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";

// Icons
import IconMoney from '@images/coin/icon-money.svg';

//components
import { EarnCoin } from "../Header";

const StyledButton = styled.div`
  display: flex;
  padding: 9px 12px;
  align-items: center;
  gap: 4px;
  border-radius: 23px;
  background: #F2F4F7;
  color: #121926;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-right: 15px;
  justify-content: center;
  position: relative;
  z-index: 1;
  &.show-dropdown-coin .earn-coin {
    display: block;
  }
`;

const EarnCoinContainer = styled.div`
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  min-width: 594px;
  background-color: #f2f4f7;
  gap: 24px;
  border-radius: 24px;
  // overflow: hidden;
  margin-top: 12px;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`;

const CountCoin = (props) => {
  const coin = useSelector((state) => (state.getCoin && state.getCoin.data) || 0);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <StyledButton
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`${isHovered ? "show-dropdown-coin" : ""}`}
    >
      <img className='mr-1' src={IconMoney} alt={`${coin?.Remaining} Credits`} />
      {coin?.Remaining} Credits
      <EarnCoinContainer className="earn-coin">
        <EarnCoin {...props} handleCloseDropdown={() => setIsHovered(false)} />
      </EarnCoinContainer>
    </StyledButton>
  );
};

export default CountCoin;