// Logo.js
import React from 'react';
import MakansafeLogo from './MakansafeLogo';
import Med2labLogo from './Med2labLogo';

const Logo = ({ primaryLogo, isSidebarCollapsed, makansafeLayout }) => {
    return (
        <>
            {makansafeLayout ? (
                <MakansafeLogo isSidebarCollapsed={isSidebarCollapsed} />
            ) : (
                <Med2labLogo primaryLogo={primaryLogo} isSidebarCollapsed={isSidebarCollapsed} />
            )}
        </>
    );
};

export default Logo;