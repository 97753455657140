import React, { useEffect, useState, useCallback } from "react";

//lodash
import _ from "lodash";

//router
import { useLocation, useParams } from "react-router-dom";
// import { useHistory } from "react-router-dom";

//redux
import { useSelector } from "react-redux";

//react-responsive
// import { useMediaQuery } from 'react-responsive';

//reactstrap
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown
} from "reactstrap";

//react-router-dom
import { NavLink as RRNavLink } from "react-router-dom";
import { SubNav, MegaMenu } from ".";

//components
// import { DropdownHelper } from '@components-common';
import { ModalShare } from "../common/Modal";
import { Icon } from '@components/common'
import ShareIcon from '@images/common/share.svg'
import PlayIcon from '@images/common/play-run-case.svg'
// import Feedback from '@images/teachV2/feedback.svg'
import { ModalFeedback } from "../teachV2/home/common";

function NavigationComponent(props) {
  const { data, isInstructorViewV2 } = props && props;
  // let history = useHistory();
  let { id } = useParams();
  const location = useLocation();

  const [modalOpen, setModalOpen] = useState({
    sharing: false,
    feedback: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [stateAccount, setAccount] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [dataHelper, setDataHelper] = useState([]);

  // Handle Toogle Modal
  const toggleModal = (type) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
  };

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    const Account = localStorage.getItem("Account");
    if (Account) {
      const jsonAccount = JSON.parse(Account);
      setAccount(jsonAccount);
    }
  }, []);

  //Autorun Helper
  const filterAssistant = useCallback(
    (data) => {
      const currentPath = location.pathname;
      const dataAssistant = data?.Assistant?.Tips;
      //filter By slug
      const indexData = _.findIndex(dataAssistant, function (element) {
        return currentPath.includes(element.ViewCode);
      });

      if (indexData > -1) {
        const dataFilterByPath =
          dataAssistant[indexData] && dataAssistant[indexData].Messages;
        const filterAutorunData = _.filter(
          dataFilterByPath && dataFilterByPath,
          function (value) {
            return value.Autorun === true;
          }
        );
        setDataHelper(filterAutorunData);
      }
    },
    [location]
  );

  //get data store
  const themeData = useSelector((state) => state.theme || {});
  const themeGlobal = themeData && themeData.data;

  useEffect(() => {
    filterAssistant(themeGlobal);
  }, [themeGlobal, filterAssistant]);

  //check regex id
  const regex = /:id/i;
  const pathName = window.location.href;
  const isInstructor = stateAccount?.is_instructor === "None";
  const isAdministrator = stateAccount?.is_administrator === "None";
  // const pathExitCase = stateAccount?.is_instructor === "Yes" ? "/teach/home" : "/";
  // const isMobile = useMediaQuery({ query: `(max-width: 991px)` });
  return (
    <div className={`nav-container`}>
      <Navbar expand="md">
        <NavbarToggler onClick={toggle} />
        <Container>
          <Collapse isOpen={isOpen} navbar>
            <Nav className={`mr-auto navList`} navbar>
              {data?.map((item, index) => {
                //check active menu
                let pathCurrent = item?.path;
                const pathRemoveParamsId = pathCurrent.replace(/:id/g, '');
                const matchPathCurrent = pathName?.includes(pathRemoveParamsId);

                //kiem tra neu co slug thi su dung
                const checkClassBySlug = `${matchPathCurrent ? '' : 'deactiveSlug'}`; //arrow
                const checkActive = `${matchPathCurrent ? "active" : "not-active"}`;

                //Check Role
                const checkRole = () => {
                  let status = "";
                  const checkInstructor = isInstructor && pathCurrent === "/teach/home";
                  const checkAdministrator = isAdministrator && pathCurrent === "/adminstrator-tool";
                  if (checkInstructor || checkAdministrator) {
                    status = false;
                  } else {
                    status = true;
                  }
                  return status;
                };

                let isShowMegaMenu = item?.path?.includes('elements') ? "has-megamenu" : "";
                return (
                  <React.Fragment key={index}>
                    {checkRole() && (
                      <React.Fragment>
                        {item?.subMenu?.length > 0 ? (
                          <UncontrolledDropdown
                            className={`${isShowMegaMenu} navList__item ${checkActive}`}
                            nav
                            inNavbar
                            data-name={item?.name}
                          >
                            <DropdownToggle nav caret>
                              {item?.name}
                            </DropdownToggle>
                            {item?.path?.includes('elements') ? (
                              <DropdownMenu className="megamenu">
                                <MegaMenu data={item?.subMenu || []} idCase={id} />
                              </DropdownMenu>
                            ) : (
                              <DropdownMenu>
                                <SubNav data={item?.subMenu || []} idCase={id} />
                              </DropdownMenu>
                            )}
                          </UncontrolledDropdown>
                        ) : (
                          <>
                            <NavItem className="navList__item">
                              <NavLink
                                className={`--border-color-after ${checkClassBySlug}`}
                                id={item?.idEl || ``}
                                tag={RRNavLink}
                                to={pathCurrent.includes(":id") ? pathCurrent.replace(regex, id) : pathCurrent}
                                activeClassName={'active'}
                                exact={true} //exact
                                target={pathCurrent.includes("/learn/case/") ? "_blank" : "_self"}
                              >
                                {item.name}
                              </NavLink>
                            </NavItem>
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
            </Nav>
            {isInstructorViewV2 && (
              <div className="nav-container__action">
                {/* <div className="nav-container__action-normal" onClick={() => toggleModal("feedback")}>
                  <Icon src={Feedback} stroke="#98A2B3"/>
                </div> */}
                <div className="nav-container__action-normal" onClick={() => toggleModal("sharing")}>
                  <Icon src={ShareIcon} stroke="#98A2B3" />
                  Share
                </div>
                <div className="nav-container__action-btn" onClick={() => window.open(`/learn/case/${id}`, "_blank")}>
                  <Icon src={PlayIcon} />
                  Run Module
                </div>
              </div>
            )}
            {/* <div className="helperView">
              <DropdownHelper
                {...props}
                themeData={themeGlobal}
                data={dataHelper}
                isMobile={isMobile}
              />
            </div> */}
          </Collapse>
        </Container>
      </Navbar>
      <ModalShare
        {...props}
        isOpen={modalOpen?.sharing}
        toggle={() => toggleModal("sharing")}
        idStory={id}
      />
      <ModalFeedback
        isOpen={modalOpen?.feedback}
        toggle={() => toggleModal("feedback")}
      />
    </div>
  );
}

export default NavigationComponent;