import React from 'react';

function OrderList(props) {
    const { data, value, handleChangeItem, heightPatientMedRecord } = props && props;
    return (
        <ul className="lsOrder isShowScroll" style={{maxHeight: `${heightPatientMedRecord - 130}px`}}>
            {data?.map((item, index) => {
                return (
                    <li 
                        key={index}
                        className="lsOrder__item"
                        onClick={(e) =>
                            handleChangeItem(e, item, "add", value)
                        }
                        data-category={value?.Category}
                        data-name={item?.Name}
                        data-id={item?.Id}
                    >
                        <span className="lsOrder__item--title">{item?.Name}</span>
                        <span className="lsOrder__item--icon icon-arrow-right"></span>
                    </li>
                );
            })}
        </ul>
    );
};

export default OrderList;