import React, { useContext } from 'react';
import { Icon } from '@components/common';
import home from '@images/teachV2/home.svg';
import create from '@images/teachV2/create.svg';
import build from '@images/teachV2/build.svg';
import power from '@images/teachV2/power-point.svg';
import engage from '@images/teachV2/engage.svg';
import analyze from '@images/teachV2/analyze.svg';
import { useHistory } from 'react-router-dom';
import { BetaComponent } from '@components/common/Beta';
import UserContext from '@context/UserContext';

const ChoiceItem = ({ icon, text, path, isBeta }) => {
  const history = useHistory(); 
  return (
    <div className="list-choice__item" onClick={() => history.push(path)}>
      <div className="list-choice__item-blue">
        <Icon src={icon} />
      </div>
      <p>{text}</p>
      {isBeta && <div className="list-choice__item-beta"><BetaComponent /></div>}
    </div>
  );
};

export default function HomeChoice(props) {
  const context = useContext(UserContext);
  return (
    <div className="homeChoice">
      <div className="homeChoice-wrap">
        <h1>What would you like to do today?</h1>
        <div className="list-choice">
            <ChoiceItem 
              icon={home} 
              text="Generate Multiple Choice Question" 
              path="/teach/mcq"
            />
            <ChoiceItem 
              icon={analyze} 
              text="Analyze Free-Text Question" 
              path="/teach/free-text"
              isBeta
            />
            <ChoiceItem 
              icon={create} 
              text="Generate Case Scenario" 
              path="/teach/script"
            />
            <ChoiceItem 
              icon={build} 
              text="Build Interactive Module" 
              path="/teach/module/step1" 
            />
            {context.privilege === "alpha" && 
              <ChoiceItem 
                icon={engage} 
                text="Create Engagement Module" 
                path="/teach/engage"
              />
            }
            <ChoiceItem 
              icon={power} 
              text="Generate Teaching Slides" 
              path="/teach/presentation" 
              isBeta
            />
        </div>
      </div>
    </div>
  );
}