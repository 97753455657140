import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import imgUser from '@images/top/user.svg';

//redux
import { useSelector } from "react-redux";

const ProfileHeader = () => {
    const dropdownRef = useRef(null);
    const history = useHistory();
    const location = useLocation();

    //get profile
    const AccountInfoData = useSelector((state) => state?.getAccountInfo?.data || {});
    const AccountData = AccountInfoData?.Account;
    const { profile_photo_url: profilePhoto, firstname, lastname, email } = AccountData || {};
    const is_instructor = AccountData?.is_instructor === "Yes";

    //state
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [activeRole, setActiveRole] = useState("Learner");

    // Load Role based on URL
    useEffect(() => {
        setActiveRole(location.pathname.includes('/teach') ? "Instructor" : "Learner");
    }, [location.pathname]);

    // Handle Click Outside
    const handleOutsideClick = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    }, []);

    // Handle Logout
    const handleLogout = useCallback(() => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/login");
    }, [history]);

    // Handle Role Change
    const handleRoleChange = useCallback((role) => {
        setActiveRole(role);
        setIsDropdownVisible(false); // Close dropdown after selection
    }, []);

    // Handle event Click Outside
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    // Role
    const roles = [
        { label: "View as Learner", link: "/learn/home", role: "Learner" },
        { label: "View as Instructor", link: "/teach/home", role: "Instructor" }
    ];

    return (
        <div className="profile-info">
            <div className="avatar-circle" onMouseEnter={() => setIsDropdownVisible(true)}>
                <div className="avatar-circle__user" style={{ backgroundImage: `url(${profilePhoto || imgUser})` }}></div>
            </div>
            {isDropdownVisible && (
                <div
                    ref={dropdownRef}
                    className="profile-info__inner show-dropdown"
                    onMouseEnter={() => setIsDropdownVisible(true)}
                    onMouseLeave={() => setIsDropdownVisible(false)}
                >
                    <div className="profile-lg-top">
                        <div className="avatar-circle">
                            <div className="avatar-circle__user" style={{ backgroundImage: `url(${profilePhoto || imgUser})` }}></div>
                            <span className="online-green"></span>
                        </div>
                        <div className="profile-lg-top__right d-flex flex-column">
                            <p className="profile-name">{firstname} {lastname}</p>
                            <p className="profile-email">{email}</p>
                        </div>
                    </div>
                    <div className="profile-role">
                        <span className="profile-role__text">Current Role:</span>
                        <span className="profile-role__current ml-2">{activeRole}</span>
                    </div>
                    <ul className="nav-profile">
                        {is_instructor && (
                            <li>
                                <h3 className="nav-profile__heading">SWITCH ROLE</h3>
                            </li>
                        )}
                        {is_instructor && roles.map((role, index) => (
                            <li
                                key={index}
                                className={`nav-profile__item ${activeRole === role.role ? 'active' : ''}`}
                            >
                                <Link
                                    to={role.link}
                                    className="nav-profile__item--link"
                                    onClick={() => handleRoleChange(role.role)}
                                >
                                    {role.label}
                                </Link>
                            </li>
                        ))}
                        <li className="nav-profile__item logout-item">
                            <span className="nav-profile__item--link" onClick={handleLogout} style={{ cursor: "pointer" }}>
                                Logout
                            </span>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ProfileHeader;