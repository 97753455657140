import React from "react";
import styled from "styled-components";

// Icons
import iconChatFeedback from '@images/chatvoice/icon-chat-feedback.svg';

const Button = styled.button`
  display: flex;
  padding: 14px;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: #FFF;
  border: none;
  cursor: pointer;
  margin-right: 12px;
  height: 55px;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const Text = styled.span`
  color: #121926;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-left: 12px;
  white-space: nowrap;
`;

const ButtonChatPC = ({handleChatFeedback}) => {
    return (
        <React.Fragment>
            <Button className="button-chatFeedback-sp" onClick={handleChatFeedback}>
                <Icon src={iconChatFeedback} alt="Chat Feedback Icon" />
                <Text>Chat Feedback</Text>
            </Button>
        </React.Fragment>
    );
};

export default ButtonChatPC;