import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//components 
import { FrameChat } from '@components-common/AIChat';

//apiCaller
import { apiCaller } from "@utils";

//actions
import { atcCallLockElementsChat } from "@actions";

//actions
import { caseConstants } from "@constants";

function ChatFreeText(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { handleOverideEffectMenu } = props && props;

  const responseChatData = props?.data?.ChatData;
  const listRecords = responseChatData && responseChatData.ChatRecords;
  const [dataBoxRight, setDataBoxRight] = useState([]);
  const [quesTionCurrent, setQuesTionCurrent] = useState({});
  const [stateStatus, setStatus] = useState("patient");
  const [dataPreceptor, setPreceptor] = useState({});
  const messageEl = useRef(null);

  //init data
  useEffect(() => {
    setDataBoxRight(listRecords);
  }, [listRecords]);

  //Remove Effect When component render
  useEffect(() => {
    handleOverideEffectMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleQuestionTypePatient = (params) => {
    dispatch({ type: caseConstants.CHAT_SUBMIT_REQUEST, isLoading: true });
    apiCaller(`/api/learn/case/chat/${id}/`, 'PUT', params).then(res => {
      const data = res && res.data;
      if (res && res.status === 200) {
        if (messageEl?.current) {
          dispatch({ type: caseConstants.CHAT_SUBMIT_SUCCESS, payload: data });
          setQuesTionCurrent({ [dataBoxRight?.length - 1]: true });
          messageEl?.current?.scrollToBottom({ behavior: 'smooth' });

          if (data?.ChatStatus === 'preceptor' && data?.PreceptorQuestion) {
            setStatus(data?.ChatStatus);
            setPreceptor(data);
          }

          setTimeout(() => {
            setQuesTionCurrent({ [dataBoxRight?.length - 1]: false });
            messageEl?.current?.scrollToBottom({ behavior: 'smooth' });
          }, 2500);
        }
      } else {
        dispatch({
          type: caseConstants.CHAT_SUBMIT_FAILURE,
          error: "error",
        });
      }
    });
  };

  const handleSubmitTypePreceptor = (content) => {
    dispatch(atcCallLockElementsChat(true));
    
    const params = {
      "CurrentTask": "chat",
      "CaseId": id,
      "QuestionId": dataPreceptor?.PreceptorQuestion?.Id || dataPreceptor?.NextQuestion?.Id,
      "QuestionType": "free_text",
      "Answer": content,
      "Explanation": {}
    }

    apiCaller(`/api/learn/question/submit/`, 'POST', params).then(res => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: caseConstants.CHAT_SUBMIT_SUCCESS, payload: data });

        //scroll and loading
        messageEl?.current?.scrollToBottom({ behavior: 'smooth' });
        setQuesTionCurrent({ [dataBoxRight?.length - 1]: true });

        if (data?.NextQuestion === null || data?.ChatStatus === "patient") {
          setStatus(data?.ChatStatus);
        } else {
          setStatus(data?.ChatStatus);
          setPreceptor(data);
        }

        setTimeout(() => {
          messageEl?.current?.scrollToBottom({ behavior: 'smooth' });
        }, 1000);

        setTimeout(() => {
          setQuesTionCurrent({ [dataBoxRight?.length - 1]: false });
          messageEl?.current?.scrollToBottom({ behavior: 'smooth' });
        }, 2500);

        //Status Done
        dispatch(atcCallLockElementsChat(false));
      } else {
        console.log("error");
        dispatch(atcCallLockElementsChat(false));
      }
    });
  }
  
  return (
    <FrameChat
      {...props}
      dataChatRecords={(dataBoxRight && dataBoxRight) || []}
      messageEl={messageEl}
      quesTionCurrent={quesTionCurrent}
      handleQuestionTypePatient={handleQuestionTypePatient}
      handleSubmitTypePreceptor={handleSubmitTypePreceptor}
      responseChatData={responseChatData}
      ChatStatus={stateStatus}
      dataPreceptor={dataPreceptor}
      showTime={true}
    />
  );
}

export default ChatFreeText;