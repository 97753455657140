import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback,
    Col,
    Row
} from "reactstrap";

//components
import { CropImageAvatar } from "../Avatars";

//api
import { apiCaller } from "@utils";

const ModalEditAvatar = (props) => {
    const { isOpen, toggle, rowRecord, handleUpdateData, defaultFilter } = props && props;

    //state
    const [dataImageCrop, setDataImageCrop] = useState(null);
    const [isImageChanged, setImageChanged] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        setValue,
        reset
    } = useForm();

    useEffect(() => {
        setValue("name", rowRecord?.name, { shouldDirty: false });
        setValue("gender", rowRecord?.tags?.gender, { shouldDirty: false });
        setValue("roletype", rowRecord?.tags?.roletype, { shouldDirty: false });
        setValue("level", rowRecord?.tags?.level, { shouldDirty: false });
        setValue("description", rowRecord?.description, { shouldDirty: false });
    }, [rowRecord, setValue])

    const onSubmit = (data) => {
        const paramsTags = {
            "gender": data?.gender,
            "roletype": data?.roletype,
            "level": data?.level
        }
        const jsonTags = JSON.stringify(paramsTags);
        const formData = new FormData();
        formData.append("action_type", "update_avatar");
        formData.append("name", data?.name);
        dataImageCrop && formData.append("image", dataImageCrop);
        formData.append("tags", jsonTags);
        formData.append("description", data?.description);
        formData.append("avatar_id", rowRecord?.avatar_id);

        setLoading(true);
        //Call Api
        apiCaller(`/api/character/avatar/`, "POST", formData).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                setLoading(false);
                handleUpdateData(data);

                //Close & reset Data
                toggle();
                handleResetData();
            } else {
                console.log('error');
                toggle();
                setLoading(false);
                handleResetData();
            }
        });
    }

    const handleResetData = () => {
        setDataImageCrop(null);
        reset();
    }

    // Render Options
    const renderOptions = (type) => {
        return (
            defaultFilter &&
            defaultFilter[type]?.map((option) => (
                <option value={option} key={option}>
                    {option}
                </option>
            ))
        );
    };

    //Handle Save Image Crop
    const handleCropImage = (blob) => {
        setDataImageCrop(blob);
        setImageChanged(false); // Cho phep nguoi dung save
    }

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: '600px' }}>
                <ModalHeader toggle={toggle}>Update Avatar</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <FormGroup className="mt-2">
                                <Label for="name">Name <span className="m--font-danger">*</span></Label>
                                <Input
                                    type="text"
                                    name="name"
                                    {...register("name", { required: true })}
                                    invalid={errors.name?.type === "required"}
                                    defaultValue={watch("name")}
                                    onChange={(e) => {
                                        setValue("name", e.target.value, { shouldValidate: true });
                                    }}
                                    placeholder=""
                                    autoComplete="off"
                                />
                                <FormFeedback invalid={String(errors.name?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="gender">
                                    Gender <span className="m--font-danger">*</span>
                                </Label>
                                <Input
                                    {...register("gender", { required: true })}
                                    invalid={errors.gender?.type === "required"}
                                    defaultValue={watch("gender")}
                                    onChange={(e) => {
                                        setValue("gender", e.target.value, { shouldValidate: true });
                                    }}
                                    // disabled={isDisable}
                                    type="select"
                                    name="gender"
                                    id="gender"
                                >
                                    <option value="">Select an item below</option>
                                    {renderOptions('gender')}
                                </Input>
                                <FormFeedback invalid={String(errors.gender?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mt-2">
                                <Label for="Image">Image <span className="m--font-danger">*</span></Label>
                                <CropImageAvatar
                                    {...props}
                                    handleCropImage={handleCropImage}
                                    srcImage={rowRecord?.image_url}
                                    handleUploadFile={() => setImageChanged(true)}
                                    handleChangeZoom={() => setImageChanged(true)}
                                />
                                {/* <FormFeedback invalid={String(errors.Name?.type === "required")}>
                                    This is required.
                                </FormFeedback> */}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="roletype">
                                    Role <span className="m--font-danger">*</span>
                                </Label>
                                <Input
                                    {...register("roletype", { required: true })}
                                    invalid={errors.roletype?.type === "required"}
                                    defaultValue={watch("roletype")}
                                    onChange={(e) => {
                                        setValue("roletype", e.target.value, { shouldValidate: true });
                                    }}
                                    // disabled={isDisable}
                                    type="select"
                                    name="roletype"
                                    id="roletype"
                                >
                                    <option value="">Select an item below</option>
                                    {renderOptions('roletype')}
                                </Input>
                                <FormFeedback invalid={String(errors.roletype?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={6}
                        >    <FormGroup>
                                <Label for="level">
                                    Level <span className="m--font-danger">*</span>
                                </Label>
                                <Input
                                    {...register("level", { required: true })}
                                    invalid={errors.level?.type === "required"}
                                    defaultValue={watch("level")}
                                    onChange={(e) => {
                                        setValue("level", e.target.value, { shouldValidate: true });
                                    }}
                                    // disabled={isDisable}
                                    type="select"
                                    name="level"
                                    id="level"
                                >
                                    <option value="">Select an item below</option>
                                    {renderOptions('level')}
                                </Input>
                                <FormFeedback invalid={String(errors.level?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mt-2">
                                <Label htmlFor="description">Description:</Label>
                                <Input
                                    {...register("level", { required: true })}
                                    type="textarea"
                                    name="description"
                                    onChange={(e) => {
                                        setValue("description", e.target.value, { shouldValidate: true });
                                    }}
                                    defaultValue={watch("description")}
                                    invalid={errors.description?.type === "required"}
                                    style={{ height: '100px' }}
                                />
                                <FormFeedback invalid={String(errors.description?.type === "required")}>
                                    This is required.
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="danger"
                        className="btn btn-brand --bg-primary d-flex align-items-center"
                        onClick={handleSubmit(onSubmit)}
                        disabled={isLoading || isImageChanged}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default ModalEditAvatar;