import React, { useState, useEffect } from "react";
import { MedRecordTabContent, PatientMain } from "../ErmView";
import { useSelector } from "react-redux";

function PatientErmContainer(props) {
  // Lấy dữ liệu từ state của Redux
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const PatientEMR = InterfaceTemplateDetails?.PatientEMR;
  const statusTabActiveMedicalRecord = useSelector((state) => state.statusTaskActiveTabMedicalRecord?.activeTab || null);

  // Sử dụng useState để lưu trữ giá trị của isCurrentTask
  const [isCurrentTask, setIsCurrentTask] = useState(statusTabActiveMedicalRecord || "PatientErm");

  // Sử dụng useEffect để kiểm tra sự thay đổi của statusTabActiveMedicalRecord
  useEffect(() => {
    setIsCurrentTask(statusTabActiveMedicalRecord || "PatientErm");
  }, [statusTabActiveMedicalRecord]);

  return (
    <PatientMain
      {...props}
      heading={PatientEMR?.emr_title?.Text || "Patient Electronic Medical Record"}
      iconPanel={`icon-emr-2`}
      isCurrentTask={isCurrentTask}
    >
      <MedRecordTabContent
        {...props}
        isCurrentTask={isCurrentTask}
      />
    </PatientMain>
  );
}

export default PatientErmContainer;