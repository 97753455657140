import React from 'react';
import { Table } from 'reactstrap';
import { BtnAddRow } from "@components-common/elements";
import TableRow from './TableRow';

const IntakeTable = ({ chatData, toggleModal, handleAddRow }) => {
    return (
        <div style={{ backgroundColor: '#F2F6F9', borderRadius: '8px', padding: '12px' }}>
            <Table className='custom-table-corner' responsive>
                <thead>
                    <tr>
                        <th className="align-middle --text-blue">Topic name</th>
                        <th className="align-middle --text-blue">Question text</th>
                        <th className="align-middle --text-blue">Response text</th>
                        <th className="align-middle --text-blue text-center">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {chatData?.length > 0 && chatData?.map((item) => (
                        <TableRow key={item.id} item={item} toggleModal={toggleModal} />
                    ))}
                </tbody>
            </Table>
            <BtnAddRow
                handleClickAddRow={handleAddRow}
                textBtn={`Add Intake item`}
            />
        </div>
    );
};

export default IntakeTable;