import React from "react";

function CurrentOrderList(props) {
  const { data, handleChangeItem, heightPatientMedRecord } = props && props;
  return (
    <div className="pd-order-box bg-white --border-l1-primary">
      <ul className="lsOrder isShowScroll" style={{ height: `${heightPatientMedRecord - 130}px` }}>
        {data?.map((item, index) => (
          <li className="lsOrder__item" key={index} style={{ cursor: 'auto' }}>
            <span className="lsOrder__item--title">{item?.Name}</span>
            <span
              className="lsOrder__item--icon icon-remove text-7 text-danger"
              data-name={item?.Name}
              data-record={item?.OrderRecordId}
              data-id={item?.Id}
              onClick={(e) =>
                handleChangeItem(e, item, "remove", '')
              }
              style={{ cursor: 'pointer' }}
            ></span>
          </li>
        ))}
        {data?.length === 0 && <p className="message-sp text-center">select and add Lab tests to your order</p>}
      </ul>
    </div>
  );
}

export default CurrentOrderList;
