import React, { useEffect, useState } from 'react';

//elements
import { ButtonDelete, ButtonEdit } from '@components-common/elements';

//components
import { ActionAddNotes, ModalAddNotes, ModalEditNotes } from '.';

//redux
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

//components common
import { ModalDeleteCommon } from '@components-common/Modal';

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

function NotesTab(props) {
    const dispatch = useDispatch();
    let { id } = useParams();

    //props
    const { timepointData } = props && props;
    const timepointDataDetail = timepointData?.data;
    const Documentations = timepointDataDetail?.Documentations;
    const DocumentationNotes = Documentations?.DocumentationNotes;

    //state
    const [stateData, setStateData] = useState(DocumentationNotes || []);
    const [itemCurrent, setItemCurrent] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [modal, setModal] = useState({
        add: false,
        edit: false,
        delete: false
    });

    const toggle = (type, data) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
        setItemCurrent(data);
    }

    //load Data
    useEffect(() => {
        const timepointDataDetail = timepointData?.data;
        const Documentations = timepointDataDetail?.Documentations;
        const DocumentationNotes = Documentations?.DocumentationNotes;
        setStateData(DocumentationNotes || []);
    }, [timepointData])

    const handleRemove = () => {
        setLoading(true);
        const isLoading = true;

        const params = {
            "Action": "Delete",
            "Target": "DocumentationNote",
            "DocumentationNoteId": itemCurrent?.DocumentationNoteId
        }

        //Call Api
        dispatch({ type: storyboardConstants.PUT_DOCUMENTATION_NOTE_REQUEST, isLoading });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({
                    type: storyboardConstants.PUT_DOCUMENTATION_NOTE_SUCCESS,
                    payload: data
                });

                //Close
                setModal((prevState) => ({ ...prevState, delete: false }));
                setLoading(false);
            } else {
                dispatch({ type: storyboardConstants.PUT_DOCUMENTATION_NOTE_FAILURE, error: 'error' });
                setLoading(false);
            }
        });
    }

    const listItems = stateData?.map((value, index) => {
        return (
            <li className="lsNotes__item d-flex align-items-center flex-wrap p-3 mb-3 border" key={index}>
                <div className="w-75">
                    <h3 className='font-weight-500 text-2 mb-2'>{value?.Title || ''}</h3>
                    <div className="d-flex align-items-center">
                        <div className="text-1 text-muted">{value?.Type || ''}</div>
                        <div className='mx-1'>-</div>
                        <div className="text-1 text-right text-muted">{value?.Date || ''}</div>
                    </div>
                </div>
                <div className="w-25 text-right">
                    <ButtonEdit
                        handleEdit={() => toggle('edit', value)}
                    />
                    <ButtonDelete
                        handleDelete={() => toggle('delete', value)}
                    />
                </div>
            </li>
        );
    });

    return (
        <React.Fragment>
            <ActionAddNotes
                toggle={() => toggle('add', {})}
            />
            <ul className="lsNotes mt-3">
                {listItems?.length > 0 && listItems}
            </ul>
            {/* Modal */}
            <ModalAddNotes
                {...props}
                isOpen={modal.add}
                toggle={() => toggle('add', {})}
                itemCurrent={itemCurrent || {}}
            />
            <ModalEditNotes
                {...props}
                isOpen={modal.edit}
                toggle={() => toggle('edit', itemCurrent)}
                itemCurrent={itemCurrent || {}}
            />
            <ModalDeleteCommon
                title={`Are you sure you want to delete this note ?`}
                sub={`This action is not reversible.`}
                isOpen={modal?.delete}
                toggle={() => toggle('delete', itemCurrent)}
                handleRemove={handleRemove}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};

export default NotesTab;