import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//call Action
import { actPutItemOrder, updateDataOrders } from "@actions";

//components
import { CurrentOrderList, AvailableOrders, ListTabsOrders } from "../../StandardScreen/Orders";
//OrderSearchBox

//redux
import { useDispatch } from "react-redux";
import _ from "lodash";

function OrderList(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { data, handleOverideEffectMenu } = props && props;
  const availableData = data?.Available || [];
  const timePointData = data?.AddedAtThisTimePoint;
  const storeAvailable = JSON.parse(localStorage.getItem("dataExamAvailable"));

  //state
  const [stateThisTimePointData, setStateThisTimePointData] = useState(
    timePointData || []
  );
  const [stateAvailable, setStateAvailable] = useState(availableData || []);
  const [stateAvailableClone, setStateAvailableClone] = useState(
    storeAvailable || []
  );

  // const [dataMerge, setDataMerge] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const handleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    //Convert arrray
    let mergedData = [];
    for (let i = 0; i < data?.length; i++) {
      mergedData.push(...data[i]?.Orders);
    }
    // setDataMerge(mergedData);
  }, [data])

  //Remove Effect When component render
  useEffect(() => {
    handleOverideEffectMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    const availableData = data?.Available || [];
    const timePointData = data?.AddedAtThisTimePoint;
    setStateThisTimePointData(timePointData || []);
    setStateAvailable(availableData || []);

    //get data default from localStorage
    const storeAvailable = JSON.parse(
      localStorage.getItem("dataExamAvailable")
    );
    setStateAvailableClone(storeAvailable);
  }, [data]);

  //Find Category By Order Id
  const findCategoryByIdOrder = (idItem) => {
    var categorySlug = "";
    //CHECK ID GET SLUG
    for (let i = 0; i < stateAvailableClone.length; i++) {
      var obj = stateAvailableClone[i].Orders;
      for (let j = 0; j < obj.length; j++) {
        if (obj[j].Id === idItem) {
          categorySlug = stateAvailableClone[i].Category;
          return categorySlug;
        }
      }
    }
  };

  //Handle Add/Remove
  const handleChangeItem = (e, item, actionItem, value) => {
    const idItem = item?.Id;
    const typeItem = item?.Type;
    const nameItem = item?.Name;

    e.preventDefault();
    let dataAppend = {
      Id: idItem,
      Name: nameItem,
      Type: typeItem,
    };

    if (actionItem === "remove") {
      const OrderRecordIdItem = item?.OrderRecordId;

      // remove item
      const newList = stateThisTimePointData.filter(
        (item) => item.Id !== idItem
      );
      setStateThisTimePointData(newList);

      // Push to Available Orders
      let categorySlug = findCategoryByIdOrder(idItem);
      dataAppend[`Category`] = categorySlug;

      pushItemRemoveToAvailable(dataAppend);

      // update data from store
      const dataUpdateStore = {
        AddedAtThisTimePoint: [...newList],
        Available: stateAvailable, //data new
      };

      //SAVE API AND UPDATE STORE
      dispatch(updateDataOrders(dataUpdateStore));
      updateActionRemoveDataAPI(idItem, typeItem, OrderRecordIdItem);
    } else {
      //Remove Item
      const removeItem = pushItemDataToCurrentData(idItem);
      dataAppend[`Category`] = value?.Category;
      const pushToListCurrentOrder = [...stateThisTimePointData, dataAppend];

      // update data from store
      const dataUpdateStore = {
        AddedAtThisTimePoint: pushToListCurrentOrder,
        Available: [...removeItem],
      };

      //SAVE API AND UPDATE STORE
      dispatch(updateDataOrders(dataUpdateStore));
      updateActionAddDataAPI(idItem, typeItem);
    }
  };

  //Push Item To Current Data
  const pushItemDataToCurrentData = (idItem) => {
    for (var i = 0; i < stateAvailable.length; i++) {
      var obj = stateAvailable[i].Orders;
      const dataNew = _.remove(obj, function (e) {
        return e.Id === idItem;
      });
      //gan object hien tai thanh array vua remove
      obj = dataNew;
    }
    return stateAvailable;
  };

  //Remove Item
  const pushItemRemoveToAvailable = (data) => {
    let dataAvailableUpdate = [];
    for (var i = 0; i < stateAvailable.length; i++) {
      var obj = stateAvailable[i].Orders;
      var objCategory = stateAvailable[i].Category;
      if (objCategory === data.Category) {
        dataAvailableUpdate = [...obj, data];
        stateAvailable[i].Orders = dataAvailableUpdate;
      }
    }
    setStateAvailable(stateAvailable);
  };

  //Api Remove Item
  const updateActionRemoveDataAPI = (idItem, typeItem, OrderRecordIdItem) => {
    let params = {
      Action: "remove",
      Id: idItem,
      Type: typeItem,
      OrderRecordId: OrderRecordIdItem,
    };
    //Save API
    dispatch(actPutItemOrder(id, params));
  };

  //Api Add Item
  const updateActionAddDataAPI = (idItem, typeItem) => {
    let params = {
      Action: "add",
      OrderId: idItem,
      Type: typeItem,
    };
    //Save API
    dispatch(actPutItemOrder(id, params));
  };


  const navListGroup = [
    {
      Name: "Labs",
      Component: "Labs.....",
      Icon: `icon-labs-2`
    },
    {
      Name: "Studies",
      Component: "Studies.....",
      Icon: `icon-exam-2`
    },
    {
      Name: "Medications",
      Component: "Medications.....",
      Icon: `home-medication`
    },
    // {
    //   Name: "Search",
    //   Component: (
    //     <OrderSearchBox
    //       handleChangeItem={handleChangeItem}
    //       data={(dataMerge && dataMerge) || []}
    //     />
    //   ),
    //   Icon: `fa fa-search`
    // },
  ];

  return (
    <React.Fragment>
      <ListTabsOrders
        {...props}
        navListGroup={navListGroup}
        handleTab={handleTab}
        activeTab={activeTab}
      />
      <div className="interact_panel_order_sheet_copy">
        <div className="order-labs-wrapper">
          <div className="orders-med-grid">
            <div className="orders-med-grid__left isShowScroll">
              <h3 className="orders-med-grid__heading">Available Tests</h3>
              <AvailableOrders
                {...props}
                navListGroup={navListGroup}
                data={stateAvailable}
                handleChangeItem={handleChangeItem}
                activeTab={activeTab}
              />
            </div>
            <div className="orders-med-grid__right">
              <h3 className="orders-med-grid__heading text-center">
                Current Order
              </h3>
              <CurrentOrderList
                {...props}
                data={stateThisTimePointData}
                handleChangeItem={handleChangeItem}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OrderList;