import React, { useState, useEffect } from "react";

//components
import { LabsResult, StudiesResult } from "../Results";

function ListTabsResult(props) {

    //props 
    const { arrDotResult, handleClickHideDot, caseData } = props && props;
    const TaskRecords = caseData?.data?.Case?.TaskRecords;

    const [activeTab, setActiveTab] = useState("1");
    const [statusDot, setStatusDot] = useState(arrDotResult);

    //load status
    useEffect(() => {
        setStatusDot(arrDotResult);
    }, [arrDotResult])

    const handleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const navListGroup = [
        {
            Name: "Labs",
            Component: <LabsResult {...props} />,
            Icon: `icon-labs-2`,
            Type: 'Labs'
        },
        {
            Name: "Studies",
            Component: <StudiesResult {...props} />,
            Icon: `icon-exam-2`,
            Type: 'Studies'
        }
    ];
    
    return (
        <React.Fragment>
            <ul className="tabs-corner">
                {navListGroup?.map((value, index) => (
                    <li
                        className={`tabs-corner__item ${activeTab === (index + 1).toString() ? 'active' : ''}`}
                        key={index}
                        onClick={() => {
                            handleTab(String(index + 1));
                        }}
                        data-type={value?.Type}
                    >
                        {statusDot?.Studies && value?.Type === 'Studies' && !TaskRecords?.StudyResultViewed &&
                            <span className="notic_dot"></span>
                        }
                        {statusDot?.Labs && value?.Type === 'Labs' && !TaskRecords?.LabResultViewed &&
                            <span className="notic_dot"></span>
                        }
                        <div className="eventHiddenDot" onClick={() => handleClickHideDot(value?.Type)}></div>
                        <span className="tabs-corner__item--text">{value.Name}</span>
                        <span className={`tabs-corner__item--icon ${value?.Icon}`}></span>
                    </li>
                ))}
            </ul>
            <div className="result-tab-content">
                {navListGroup?.map((value, index) => (
                    <div key={index} className={`${activeTab === (index + 1).toString() ? 'd-block' : 'd-none'}`}>
                        {value?.Component}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default ListTabsResult;