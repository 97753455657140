import React from "react";

//components
import { ButtonCloseERM } from "../StandardScreen/ErmView";

function PatientMainOnlyHeading(props) {
    const { heading, iconPanel, children, stateType } = props && props;
    const filteredNames = Object.keys(stateType).filter(key => stateType[key] === true);
    const nameView = filteredNames && filteredNames[0];
    return (
        <div className={`medRedcord-container`} is-view="actions">
            <div className={`medRedcord-tabs`}>
                <div className="medRedcord-tabs__heading">
                    <span className={`medRedcord-tabs__heading--icon ${iconPanel} text-white`}></span>
                    <h3 className="text-white">{heading}</h3>
                    <ButtonCloseERM
                        {...props}
                        isCurrentTask={nameView}
                    />
                </div>
                <div className={`medRedcord-tabs__body`}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default PatientMainOnlyHeading;