import React, { useState, useEffect } from "react";
import { TabComponentBorder } from "@components/common/Tabs";

//components
import {
    NotesDocumentation,
    ProblemsDocumentation,
    InstructionsDocumentation,
    TemplateDocumentation,
    QuestionsDocumentation,
} from "./Documentation";
import { HeadingTask } from "../ManageAvailableTasks";

// redux
import { useSelector } from "react-redux";

const DocumentationMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    // store
    const questionViewActive = useSelector((state) => state?.questionViewActive?.questionView || null);

    const statusOptions = [
        { key: 1, label: "Notes", content: <NotesDocumentation {...props} /> },
        { key: 2, label: "Problems", content: <ProblemsDocumentation {...props} /> },
        { key: 3, label: "Instructions", content: <InstructionsDocumentation {...props} /> },
        { key: 4, label: "Template", content: <TemplateDocumentation {...props} /> },
        { key: 5, label: "Questions", content: <QuestionsDocumentation {...props} /> }
    ];

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    // Effect to set the active tab to "Questions" if isOpenCreateQuestion is true
    useEffect(() => {
        if (questionViewActive === "Blank_Question") {
            setActiveTab(5); // 5 corresponds to the "Questions" tab
        }
    }, [questionViewActive]);

    return (
        <div className="available-tasks-main">
            <HeadingTask
                title={`Documentation`}
                handleClickBtn={() => console.log("handleClickBtn")}
                GenerateAIButton={true}
            />
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default DocumentationMain;