import React, { useState, useEffect } from "react";

//api
import { apiCaller } from "@utils";

//react-router-dom
import { useParams } from "react-router-dom";

//common
import { SwitchItem } from "@components-common/Switches";

function ImmediateFeedbackModeSwitch(props) {
    const { questionData, itemQuestion } = props && props;
    let { id } = useParams();

    //state
    const [ImmediateFeedbackMode, setImmediateFeedbackMode] = useState(itemQuestion?.ImmediateFeedbackMode || true);

    // Load
    useEffect(() => {
        if (itemQuestion?.ImmediateFeedbackMode) {
            setImmediateFeedbackMode(itemQuestion?.ImmediateFeedbackMode || false);
        }
    }, [itemQuestion?.ImmediateFeedbackMode])

    //Handle Change Switch
    const handleChangeSwitch = (e) => {
        const { checked } = e.target;
        setImmediateFeedbackMode(checked);
        handleApiUpdate(checked);
    }

    //Case - Set Immediate Feedback Mode for Question
    const handleApiUpdate = (status) => {
        const params = {
            "Target": "Question_ImmediateFeedbackMode",
            "Action": "Update",
            "QuestionId": questionData?.QuestionId,
            "ImmediateFeedbackMode": status
        }
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                console.log('SUCCESS');
            } else {
                console.log('error');
            }
        });
    }

    return (
        <div className="mb-3">
            <SwitchItem
                {...props}
                label={`Give Immediate Feedback`}
                name={`ImmediateFeedbackMode`}
                handleChangeSwitch={handleChangeSwitch}
                value={ImmediateFeedbackMode}
                noPadding={true}
            />
        </div>
    );
}

export default ImmediateFeedbackModeSwitch;