import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { AddUpdateFoodForm } from "@components/Makansafe/AddUpdateFood";

//untils
import { LoaderPage, apiCaller } from "@utils";

//Language
import { useLanguage } from '@context/LanguageContext';

function EditNewFoodPage(props) {
    const { id } = useParams();
    const { language } = useLanguage();

    //state
    const [isLoading, setLoading] = useState({
        GetDetailItem: false
    });
    const [foodItem, setFoodItem] = useState({});

    // useCallback for fetchDataFoods
    const ApiGetDetailItem = useCallback((langActive) => {
        setLoading(prevState => ({ ...prevState, GetDetailItem: true }));
        const params = {
            "action_type": "get_detail_item",
            "food_id": id,
            "lang": langActive
        };
        //Truyền true hàm apiCaller để sử dụng api makansafe
        apiCaller(`/api/iddsi/functional/`, "POST", params, true).then(res => {
            const resData = res?.data;
            if (res?.status === 200) {
                setFoodItem(resData);
                setLoading(prevState => ({ ...prevState, GetDetailItem: false }));
            } else {
                console.log("error");
                setLoading(prevState => ({ ...prevState, GetDetailItem: false }));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        ApiGetDetailItem(language.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language.value]);

    return (
        <div className="makansafe-homepage">
            {isLoading?.GetDetailItem && <LoaderPage />}
            <div className="boxPortlet">
                <AddUpdateFoodForm title={`Edit Food Item`} foodItem={foodItem} />
            </div>
        </div>
    );
}

export default EditNewFoodPage;