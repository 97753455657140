import React, { useState } from "react";
import { TabComponentBorder } from "@components/common/Tabs";

//components
import { EndOfCaseFeedback, TakeHomeMessage } from "../EndingPage";

const EndingPageMain = (props) => {
    //state
    const [activeTab, setActiveTab] = useState(1);

    const statusOptions = [
        { key: 1, label: "Take-home Message", content: <TakeHomeMessage {...props} /> },
        { key: 2, label: "End-of-case Feedback Instructions", content: <EndOfCaseFeedback {...props} /> }
    ];

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const tabs = statusOptions?.map((statusOption) => ({
        id: statusOption.key,
        title: statusOption.label,
        content: statusOption.content
    }));

    return (
        <div className="available-tasks-main">
            <TabComponentBorder
                tabs={tabs || []}
                activeTab={activeTab}
                onTabChange={handleTabChange}
            />
        </div>
    );
};

export default EndingPageMain;