import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Label, FormFeedback } from 'reactstrap';
import { useLocation } from "react-router-dom";
import { apiCaller } from "@utils";
import ClipLoader from "react-spinners/ClipLoader";

// Modal
import { ModalResultSetPassword } from "@components/auth/ModalCompleted";

// Icons
import eyeOff from "@images/auth/eye-off.svg";
import eyeOn from "@images/auth/eye.svg";

const PasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required('Please enter your new password.'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
    .required('Please confirm your new password.'),
});

const FormSetNewPassword = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [emailParam, setEmailParam] = useState('');
  const [otp, setOtp] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('email');
    const otpCode = searchParams.get('otp-code');
    setEmailParam(email || '');
    setOtp(otpCode || '');
  }, [location.search]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    const endpoint = "/api/auth/reset_password/";
    try {
      const response = await apiCaller(endpoint, "POST", {
        Email: emailParam,
        OTP: otp,
        NewPassword: values.newPassword,
        Action: "ChangePassword"
      });
      const dataResponse = response?.data;
      if (dataResponse?.status === "success") {
        setError('');
        setModalMessage({
          type: 'success',
          title: "Password Reset Successfully",
          message: "Your password has been successfully updated. You can now log in with your new password. Click the button below to proceed to login."
        });
        setShowModal(true);
      } else if (dataResponse?.status === "expired") {
        setModalMessage({
          type: 'error',
          title: "Session Expired",
          message: "Your session has expired. Please restart the password reset process to continue."
        });
        setShowModal(true);
      } else {
        if (dataResponse?.status === "failed") {
          setError(dataResponse?.message);
        }
        setShowModal(false);
      }
    } catch (error) {
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="login_panel">
      {showModal ? <ModalResultSetPassword dataMessage={modalMessage} /> : (
        <div className="wrap-login">
          <div className="wrap-login__title">
            <h1>Set Your New Password</h1>
            <p>Please enter your new password below.</p>
          </div>
          {error && <FormFeedback className="d-block text-center" dangerouslySetInnerHTML={{ __html: error }}></FormFeedback>}
          <div className="wrap-login__form">
            <Formik
              initialValues={{
                newPassword: '',
                confirmPassword: '',
              }}
              validationSchema={PasswordSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, errors, touched }) => (
                <Form className='form-custom'>
                  <div className='form-custom__wrap'>
                    <div>
                      <Label for="newPassword">
                        New Password <span>*</span>
                      </Label>
                      <div className="form-input-icon">
                        <Field
                          as={Input}
                          type={showNewPassword ? "text" : "password"}
                          name="newPassword"
                          id="newPassword"
                          placeholder='Enter your new password'
                          invalid={errors.newPassword && touched.newPassword}
                        />
                        <img
                          className='form-input-icon__icn'
                          src={showNewPassword ? eyeOn : eyeOff}
                          alt={showNewPassword ? "show" : "hide"}
                          onClick={toggleNewPasswordVisibility}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <span className="text-err">
                        {errors.newPassword && touched.newPassword && errors.newPassword}
                      </span>
                    </div>
                    <div>
                      <Label for="confirmPassword">
                        Confirm New Password <span>*</span>
                      </Label>
                      <div className="form-input-icon">
                        <Field
                          as={Input}
                          type={showConfirmPassword ? "text" : "password"}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder='Re-enter your password'
                          invalid={errors.confirmPassword && touched.confirmPassword}
                        />
                        <img
                          className='form-input-icon__icn'
                          src={showConfirmPassword ? eyeOn : eyeOff}
                          alt={showConfirmPassword ? "show" : "hide"}
                          onClick={toggleConfirmPasswordVisibility}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <span className="text-err">
                        {errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}
                      </span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-center'>
                    <Button
                      type="submit"
                      color='primary'
                      className='w-50 py-3 d-flex align-items-center justify-content-center'
                      disabled={isSubmitting || loading}
                    >
                      {isSubmitting || loading ? (
                        <ClipLoader color="#FFF" size={16} />
                      ) : (
                        'Reset Password'
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormSetNewPassword;