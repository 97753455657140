import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

//JoditEditor
import JoditEditor from "jodit-react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback,
    Row,
    Col
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//apiCaller
import { apiCaller } from "@utils";
import { problemsConstant } from "@constants";

//Config
import * as Config from "@constants/Config";

const ModalEditOption = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { toggle, isOpen, data, ProblemId } = props && props;
    const OptionId = data?.OptionId;
    const editor = useRef(null);

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    //state
    const [isLoading, setLoading] = useState(false);
    const [content, setContent] = useState(data?.Comments || '');

    //load Data
    useEffect(() => {
        //Reset data
        setValue('Name', data?.OptionName || '', { shouldDirty: false });
        setValue('SearchType', data?.SearchType || '', { shouldDirty: false });
        setValue('SearchString', data?.SearchString, { shouldDirty: false });
        setValue('ICD10', (data && data[`ICD-10`]) || '', { shouldDirty: false });
        setValue('HCC', (data && data[`HCC`]) || '', { shouldDirty: false });
        setValue('RAF', data?.RAF || '', { shouldDirty: false });
        setValue('Score', data?.Score || 0, { shouldDirty: false });
        setContent(data?.Comments || '');
    }, [data, setValue])

    const onSubmit = (value) => {
        const params = {
            "Action": "UpdateProblemOption",
            "CaseId": id,
            "Name": value?.Name,
            "SearchType": value?.SearchType,
            "SearchString": value?.SearchString,
            "DocumentationProblemOptionId": OptionId,
            "DocumentationProblemIds": [ProblemId],
            "HCC": value?.HCC,
            "ICD-10": value?.ICD10,
            "RAF": value?.RAF,
            "Score": value?.Score || 0,
            "Comments": content
        };

        setLoading(true);
        dispatch({ type: problemsConstant.UPDATE_PROBLEM_OPTION_REQUEST, isLoading: true });
        apiCaller(`/api/teach/documentation/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: problemsConstant.UPDATE_PROBLEM_OPTION_SUCCESS, payload: data });
                toggle();
                setLoading(false);
            } else {
                dispatch({ type: problemsConstant.UPDATE_PROBLEM_OPTION_FAILURE, error: "error" });
                setLoading(false);
                toggle();
            }
        });

        //Reset data
        setValue('Name', '', { shouldValidate: false });
        setValue('SearchType', '', { shouldValidate: false });
        setValue('SearchString', '', { shouldValidate: false });
        setValue('ICD10', '', { shouldValidate: false });
        setValue('HCC', '', { shouldValidate: false });
        setValue('RAF', '', { shouldValidate: false });
        setValue('Score', '', { shouldValidate: false });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "800px" }}>
            <ModalHeader toggle={toggle}>Edit Option</ModalHeader>
            <ModalBody>
                <Row>
                    <Col md="6">
                        <FormGroup>
                            <Label for="Name">Name: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="Name"
                                {...register("Name", { required: true })}
                                invalid={errors.Name?.type === "required"}
                                defaultValue={watch("Name")}
                                onChange={(e) => {
                                    setValue("Name", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.Name?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">

                        <FormGroup>
                            <Label for="SearchType">Search Type: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="SearchType"
                                {...register("SearchType", { required: true })}
                                invalid={errors.SearchType?.type === "required"}
                                defaultValue={watch("SearchType")}
                                onChange={(e) => {
                                    setValue("SearchType", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.SearchType?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="SearchString">Search String: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="SearchString"
                                {...register("SearchString", { required: true })}
                                invalid={errors.SearchString?.type === "required"}
                                defaultValue={watch("SearchString")}
                                onChange={(e) => {
                                    setValue("SearchString", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.SearchString?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="ICD10">ICD-10: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="ICD10"
                                {...register("ICD10", { required: true })}
                                invalid={errors.ICD10?.type === "required"}
                                defaultValue={watch("ICD10")}
                                onChange={(e) => {
                                    setValue("ICD10", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.ICD10?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="HCC">HCC: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="text"
                                name="HCC"
                                {...register("HCC", { required: true })}
                                invalid={errors.HCC?.type === "required"}
                                defaultValue={watch("HCC")}
                                onChange={(e) => {
                                    setValue("HCC", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                autoComplete="off"
                            />
                            <FormFeedback
                                invalid={String(errors.HCC?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="RAF">RAF: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="number"
                                name="RAF"
                                {...register("RAF", { required: true })}
                                invalid={errors.RAF?.type === "required"}
                                defaultValue={watch("RAF")}
                                onChange={(e) => {
                                    setValue("RAF", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                inputMode="numeric"
                                pattern="[0-9]+"
                                min="0"
                            />
                            <FormFeedback
                                invalid={String(errors.RAF?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup>
                            <Label for="Score">Score: <span className="m--font-danger">*</span></Label>
                            <Input
                                type="number"
                                name="Score"
                                {...register("Score", { required: true })}
                                invalid={errors.Score?.type === "required"}
                                defaultValue={watch("Score")}
                                onChange={(e) => {
                                    setValue("Score", e.target.value, { shouldValidate: true });
                                }}
                                placeholder=""
                                step="any"
                            />
                            <FormFeedback
                                invalid={String(errors.Score?.type === "required")}
                            >
                                This is required.
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md="12">
                        <FormGroup>
                            <Label for="Comments">Comments:</Label>
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={Config.CONFIG_JODIT}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
                <Button
                    color="danger"
                    className="btn btn-brand --bg-primary"
                    onClick={handleSubmit(onSubmit)}
                >
                    {isLoading &&
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    }
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalEditOption;