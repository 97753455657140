import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { StepByStep } from "@components/common/step";

//Validate Form 
import { useForm } from "react-hook-form";

//boostrap
import {
    Container,
    Alert,
    FormGroup,
    Label,
    Input,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    FormFeedback,
    Form
} from 'reactstrap';

function First(props) {

    //props
    const { submitApi, isLoading, params, stepData } = props && props;

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        reset
    } = useForm();

    useEffect(() => {
        let defaultValues = {};
        defaultValues.Title = params?.Title;
        defaultValues.PatientName = params?.PatientName;
        defaultValues.Age = params?.Age;
        defaultValues.Gender = params?.Gender === '1' ? "Male" : "Female";
        reset({ ...defaultValues });
    }, [params, reset]);

    const onSubmit = (data) => {
        const params = {
            "Step": 2,
            "Title": data?.Title,
            "PatientName": data?.PatientName,
            "Age": data?.Age,
            "Gender": data?.Gender
        }
        submitApi(params);
    };

    return (
        <React.Fragment>
            <StepByStep stepActive={1} data={stepData?.addCase} />
            <Container>
                <Form id="formStep1">
                    <section className="formCt boxPortlet">
                        <div className="m-subheader py-0 mb-3">
                            <div className="d-flex align-items-center">
                                <div className="mr-auto">
                                    <h5 className="m-subheader__title">Create New Case</h5>
                                </div>
                            </div>
                        </div>
                        <Alert className="m-alert--default mb-3 border-0" color="secondary">
                            Let's begin by identifying some key information about the virtual patient for the module you would like to build.
                        </Alert>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label className="labeForm" for="Title">Case Title <span className="m--font-danger">*</span></Label>
                                    <Input
                                        {...register("Title", { required: true })}
                                        type="text"
                                        name="Title"
                                        id="Title"
                                        invalid={errors.Title?.type === "required"}
                                        defaultValue={watch("Title")}
                                        onChange={(e) => {
                                            setValue("Title", e.target.value, { shouldValidate: true });
                                        }}
                                        placeholder="example: a 12-year-old boy with fever"
                                        autoComplete="off"
                                    />
                                    <small className="form-text text-muted">Case title can be revised later</small>
                                    <FormFeedback
                                        invalid={String(errors.Title?.type === "required")}
                                    >
                                        This is required.
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label className="labeForm" for="PatientName">Patient's Name <span className="m--font-danger">*</span></Label>
                                    <Input
                                        {...register("PatientName", { required: true })}
                                        type="text"
                                        name="PatientName"
                                        id="PatientName"
                                        invalid={errors.PatientName?.type === "required"}
                                        defaultValue={watch("PatientName")}
                                        onChange={(e) => {
                                            setValue("PatientName", e.target.value, { shouldValidate: true });
                                        }}
                                        placeholder="example: Mary"
                                        autoComplete="off"
                                    />
                                    <small className="form-text text-muted">The patient’s name can be revised later</small>
                                    <FormFeedback
                                        invalid={String(errors.Title?.type === "required")}
                                    >
                                        This is required.
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                            <Col md={12} lg={6}>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label className="labeForm" for="Age">Age  <span className="m--font-danger">*</span></Label>
                                            <InputGroup>
                                                <Input
                                                    {...register("Age", { required: true })}
                                                    type="number"
                                                    name="Age"
                                                    id="Age"
                                                    invalid={errors.Age?.type === "required"}
                                                    defaultValue={watch("Age")}
                                                    onChange={(e) => {
                                                        setValue("Age", e.target.value, { shouldValidate: true });
                                                    }}
                                                    placeholder=""
                                                    autoComplete="off"
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>Year(s)</InputGroupText>
                                                </InputGroupAddon>
                                                <FormFeedback
                                                    invalid={String(errors.Age?.type === "required")}
                                                >
                                                    This is required.
                                                </FormFeedback>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup tag="fieldset">
                                            <Label className="labeForm" for="Age">Gender  <span className="m--font-danger">*</span></Label>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        {...register("Gender", { required: true })}
                                                        type="radio"
                                                        checked={watch("Gender") === 'Female' ? true : false}
                                                        value='Female'
                                                        name="Gender"
                                                        onChange={(e) => {
                                                            setValue("Gender", e.target.value, { shouldValidate: true });
                                                        }}
                                                    />{' '}Female
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input
                                                        {...register("Gender", { required: true })}
                                                        type="radio"
                                                        checked={watch("Gender") === 'Male' ? true : false}
                                                        value='Male'
                                                        name="Gender"
                                                        onChange={(e) => {
                                                            setValue("Gender", e.target.value, { shouldValidate: true });
                                                        }}
                                                    />{' '}Male
                                                </Label>
                                            </FormGroup>
                                            {errors.Gender?.type === "required" && <div invalid="true" class="invalid-feedback d-block">This is required.</div>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className="d-flex justify-content-center mt-5">
                                    <Link to={`/teach/home`} className="btn btn-secondary">&lt; Back</Link>
                                    <button
                                        type="button"
                                        onClick={handleSubmit(onSubmit)}
                                        className="btn btn-brand ml-1 --bg-primary"
                                        id="button_next"
                                        disabled={isLoading}
                                    >
                                        <div className="d-flex align-items-center">
                                            {isLoading &&
                                                <span className="spinner-border spinner-border-sm mr-2"></span>
                                            }
                                            Next &gt;
                                        </div>
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Form>
            </Container>
        </React.Fragment>
    );
}

export default First;