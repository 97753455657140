import React, { useState, useEffect } from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

//components common
import { AudioFile, VideoLink, ImageFile, DropDownMedia } from "@components-common/media";

//redux
import { useDispatch } from "react-redux";

//useParams
import { useParams } from "react-router-dom";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

function ModalMedia(props) {

  //props
  const { isOpen, toggle, rowForMedia, timePointId } = props && props;

  //react router
  const dispatch = useDispatch();
  let { id } = useParams();

  //state
  const [audioData, setAudioData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [stateData, setStateData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [dataMediaLoad, setDataMediaLoad] = useState("");
  const [isUpload, setIsUpload] = useState(false);

  //load
  useEffect(() => {
    //Save data current
    setDataMediaLoad({
      MediaUrl: rowForMedia?.MediaUrl || '',
      MediaType: rowForMedia?.MediaType || 'None',
    });

    setStateData((prevState) => ({
      ...prevState,
      MediaUrl: rowForMedia?.MediaUrl || '',
      MediaType: rowForMedia?.MediaType || 'None'
    }));
  }, [rowForMedia]);

  const handleType = (e) => {
    const { value } = e.target;
    //ResetData
    setAudioData([]);
    setVideoData([]);
    setImageData([]);
    setStateData((prevState) => ({ ...prevState, MediaType: value }));

    //Nếu type là data load ban đầu
    if (dataMediaLoad.MediaType !== value) {
      setStateData((prevState) => ({ ...prevState, MediaUrl: "" }));
    } else {
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
    }
  };

  //handleUploadImage
  const handleUploadImage = (data) => {
    //Set Image Preview
    data.map((file) =>
      setStateData((prevState) => ({
        ...prevState,
        MediaUrl: URL.createObjectURL(file),
      }))
    );

    //Set File FormData
    data.map((file) => setImageData(file));
    setIsUpload(true);
  };

  //Upload Audio
  const onFileAudioUpload = (event) => {
    const { name } = event.target;
    let fileUpload = event.target.files[0];
    let urlPreview = URL.createObjectURL(fileUpload);

    if (name === "AudioFile") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
      setAudioData(fileUpload);
    }
    if (name === "VideoFile") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: urlPreview }));
      setVideoData(fileUpload);
    }
    setIsUpload(true);
  };

  // handleInput
  const handleInput = (e) => {
    const { value, name } = e.target;
    if (name === "VideoLink") {
      setStateData((prevState) => ({ ...prevState, MediaUrl: value }));
    } else {
      setStateData((prevState) => ({ ...prevState, [name]: value }));
    }
    setIsUpload(false);
  };

  const UpdateData = (newUrl) => {
    setLoading(true);
    const params = {
      Action: "Update",
      Target: "TimePoint",
      TimePointId: timePointId,
      StudyChange: {
        Id: rowForMedia?.Id,
        ChangeMode: rowForMedia?.DefaultChange?.ChangeMode || '',
        ChangeValue: rowForMedia?.DefaultChange?.ChangeValue || '',
        MediaType: stateData?.MediaType
      }
    };

    //Media
    if (stateData.MediaType !== "VideoLink" && isUpload) {
      params.StudyChange.MediaLink = newUrl;
    } else {
      params.StudyChange.MediaLink = stateData.MediaUrl;
    }

    //Call Api
    dispatch({
      type: storyboardConstants.UDPATE_STUDY_CHANGE_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.UDPATE_STUDY_CHANGE_SUCCESS,
          payload: data
        });
        toggle();
        setIsUpload(false);
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.UDPATE_STUDY_CHANGE_FAILURE,
          error: 'error'
        });
        setLoading(false);
        toggle();
        setIsUpload(false);
      }
    });
  }

  //Save
  const handleSubmit = () => {
    const currentType = stateData?.MediaType;
    let formData = new FormData();
    formData.append("Action", "Upload");

    //Check Upload
    if (isUpload) {
      if (currentType === "ImageFile") {
        formData.append("File", imageData);
        //Call API Upload
        requestApiUpload(id, formData);
      } else if (currentType === "AudioFile") {
        formData.append("File", audioData);
        //Call API Upload
        requestApiUpload(id, formData);
      } else if (currentType === "VideoFile") {
        formData.append("File", videoData);
        //Call API Upload
        requestApiUpload(id, formData);
      } else {
        UpdateData();
      }
    } else {
      //data cu
      setStateData((prevState) => ({
        ...prevState,
        MediaType: dataMediaLoad.MediaType,
        MediaUrl: dataMediaLoad.MediaUrl,
      }));
      UpdateData();
    }
  };

  const requestApiUpload = (id, params) => {
    setLoading(true);
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const newUrl = res?.data?.NewUrl;
      if (res?.status === 200) {
        setLoading(false);

        //Send params
        UpdateData(newUrl);
      } else {
        console.log('error');
        setLoading(false);
      }
    });
  };

  const isType = stateData?.MediaType;
  const urlValue = stateData?.MediaUrl;
  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalHeader toggle={toggle}>Set Media</ModalHeader>
      <ModalBody>
        <div className="mediaContainer">
          <DropDownMedia onChange={handleType} value={isType} />
          {isType === "VideoLink" && (
            <VideoLink value={urlValue} onChange={handleInput} />
          )}
          {isType === "ImageFile" && (
            <ImageFile onChange={handleUploadImage} value={urlValue} />
          )}
          {isType === "AudioFile" && (
            <AudioFile onChange={onFileAudioUpload} value={urlValue} />
          )}
          <hr />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-brand d-flex align-items-center" onClick={handleSubmit}>
          {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>} Save
        </Button>
        <Button
          color="primary"
          className="btn btn-brand --bg-primary"
          onClick={toggle}
          disabled={isLoading}
        >
          Cancel
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ModalMedia;