import React from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  FormFeedback,
  Label,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

import { actCreateVitalSign } from "@actions";

const ModalAddVitalSign = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const toggle = props && props.toggle;
  const isOpen = props && props.isOpen;
  const timePointId = props && props.timePointId;

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue
  } = useForm();

  const handleAdd = (data) => {
    const params = {
      Action: "Create",
      Target: "VitalSign",
      TimePointId: timePointId,
      VitalSign: {
        Name: data.Name,
        Unit: data.Unit,
        InitialValue: data.InitialValue,
        NormalMax: data.NormalMax,
        NormalMin: data.NormalMin,
      },
    };

    dispatch(actCreateVitalSign(id, params));
    props && props.toggle();

    //Reset data
    setValue('Name', '', { shouldValidate: false });
    setValue('InitialValue', '', { shouldValidate: false });
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Vital Sign</ModalHeader>
        <ModalBody>
          <FormGroup className="mt-2">
            <Label for="Name">Name: <span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="Name"
              {...register("Name", { required: true })}
              invalid={errors.Name?.type === "required"}
              defaultValue={watch("Name")}
              onChange={(e) => {
                setValue("Name", e.target.value, { shouldValidate: true });
              }}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback
              invalid={(errors.Name?.type === "required").toString()}
            >
              This field is required.
            </FormFeedback>
          </FormGroup>
          <FormGroup className="mt-2">
            <Label for="InitialValue">InitialValue: <span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="InitialValue"
              {...register("InitialValue", { required: true })}
              invalid={errors.InitialValue?.type === "required"}
              defaultValue={watch("InitialValue")}
              onChange={(e) => {
                setValue("InitialValue", e.target.value, { shouldValidate: true });
              }}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback
              invalid={(errors.InitialValue?.type === "required").toString()}
            >
              This field is required.
            </FormFeedback>
          </FormGroup>
          <FormGroup className="mt-2">
            <Label for="Unit">Unit:</Label>
            <Input
              type="text"
              name="Unit"
              {...register("Unit")}
              placeholder=""
              autoComplete="off"
            />
          </FormGroup>
          <Row>
            <Col sm={12}>
              <Label for="InitialValue">Reference (Normal) Range:</Label>
            </Col>
            <Col sm={6}>
              <InputGroup className="mt-2">
                <Input
                  type="number"
                  name="NormalMin"
                  {...register("NormalMin")}
                  placeholder=""
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <span>mL</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col sm={6}>
              <InputGroup className="mt-2">
                <Input
                  type="number"
                  name="NormalMax"
                  {...register("NormalMax")}
                  placeholder=""
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <span>mL</span>
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="danger"
            className="btn btn-brand --bg-primary"
            onClick={handleSubmit(handleAdd)}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddVitalSign;
