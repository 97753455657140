import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from "reactstrap";

//actions
import { atcCreateCohort } from "@actions";

//redux
import { useDispatch } from "react-redux";

const ModalAddCohort = (props) => {
  const toggle = props && props.toggle;
  const isOpen = props && props.isOpen;
  const dispatch = useDispatch();
  
  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue
  } = useForm();

  const onSubmit = (data) => {
    const params= {
        "Action": "Create",
        "Name": data.Name
    }
    dispatch(atcCreateCohort(params));
    props && props.toggle();

    //Reset data
    setValue('Name', '', { shouldValidate: false });
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Add Cohort</ModalHeader>
        <ModalBody>
          <FormGroup className="mt-2">
            <Label for="Name">Name <span className="m--font-danger">*</span></Label>
            <Input
              type="text"
              name="Name"
              {...register("Name", { required: true })}
              invalid={errors.Name?.type === "required"}
              defaultValue={watch("Name")}
              onChange={(e) => {
                setValue("Name", e.target.value, { shouldValidate: true });
              }}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback invalid={String(errors.Name?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button 
            color="danger"
            className="btn btn-brand --bg-primary"
            onClick={handleSubmit(onSubmit)}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddCohort;
