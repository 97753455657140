import React from 'react'
import TitleNoteCommon from '../home/generate/TitleNoteCommon'
import { Input } from 'reactstrap';
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import { listChatGpt, interactionLanguages, interactionMethods } from '@utils/dataDefault'
import { BookListFile } from '../home/generate'
import { Icon } from '@components/common'

export default function FormCommon(props) {
  const { setValue, control, watch, bookId, setBookId, showModalBooks, setShowModalBooks } = props

  const Option = ({ innerProps, label }) => (
    <div
      {...innerProps}
      className='option-detail'
      style={{ padding: '8px 12px', cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: 'space-between' }}>
      <span className="react-select-item">{label}</span>
    </div>
  );  
  
  return (
    <div className="formEngage__form-input">
      <div className='input-form'>
        <TitleNoteCommon title="Module Name" required />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              placeholder='Name for the module (example: Childcare Assistant)'
              name='name'
              {...field}
            />
          )}
        />
      </div>
      <div className='input-form'>
        <TitleNoteCommon title="Instructions for Bot" required />
        <Controller
          name="instruction"
          control={control}
          render={({ field }) => (
            <Input
              type='textarea'
              name='instruction'
              placeholder="Detailed instructions for the AI Bot – including the role, tone, language, etc. (example: YOU are a childcare assistant who speaks in gentle tone to engage with a child at the age between 5 and 10 years old)"
              style={{ height: '80px' }}
              {...field}
            />
          )}
        />
      </div>
      <div className='input-form'>
        <TitleNoteCommon title="Opening Statement" required />
        <Controller
          name="statement"
          control={control}
          render={({ field }) => (
            <Input
              type='textarea'
              name='statement'
              placeholder="The first statement that the bot will say to start conversation (example: Hello! How are you today?)"
              style={{ height: '80px' }}
              {...field}
            />
          )}
        />
      </div>
      <div className="d-flex">
        <div className='input-form flex-grow-1 mr-2'>
          <TitleNoteCommon title="LLM Model" />
          <Controller
            control={control}
            name="model"
            defaultValue={listChatGpt?.[0]?.value}
            render={({ field: { onChange } }) => (
              <Select
                className="react-select-common w-100"
                placeholder="Select Model"
                value={listChatGpt?.find((c) => c.value === watch("model"))}
                options={listChatGpt}
                onChange={val => {
                  setValue("model", val.value)
                  onChange(val.value)
                }}
                classNamePrefix="react-select-custom"
                components={{
                  IndicatorSeparator: () => null,
                  Option: Option
                }}
              />
            )}
          />
        </div>
        <div className='input-form flex-grow-1 ml-2'>
          <TitleNoteCommon title="Interaction Language" />
          <Controller
            control={control}
            name="interactionLanguage"
            defaultValue={interactionLanguages?.[0]?.value || "English"}
            render={({ field: { onChange } }) => (
              <Select
                className="react-select-common w-100"
                placeholder="Select a language"
                value={interactionLanguages?.find((c) => c.value === watch("interactionLanguage")) || interactionLanguages?.[0]}
                options={interactionLanguages}
                onChange={val => {
                  setValue("interactionLanguage", val.value)
                  onChange(val.value)
                }}
                classNamePrefix="react-select-custom"
                components={{
                  IndicatorSeparator: () => null,
                  Option: Option
                }}
              />
            )}
          />
        </div>
      </div>
      <div className='input-form'>
        <TitleNoteCommon title="Interaction Method" />
        <Controller
          control={control}
          name="interactionMethod"
          defaultValue={interactionMethods?.[0]?.value || "Text Only"}
          render={({ field: { onChange } }) => (
            <Select
              className="react-select-common w-100"
              placeholder="Select a method"
              value={interactionMethods?.find((c) => c.value === watch("interactionMethod")) || interactionMethods?.[0]}
              options={interactionMethods}
              onChange={val => {
                setValue("interactionMethod", val.value)
                onChange(val.value)
              }}
              classNamePrefix="react-select-custom"
              components={{
                IndicatorSeparator: () => null,
                Option: Option
              }}
            />
          )}
        />
      </div>
      <div className='input-form'>
        <TitleNoteCommon title="Reference" />
        {bookId?.length > 0 ? (
          <BookListFile
            {...props}
            bookId={bookId}
            setBookId={setBookId}
          />
        ) : (
          <div
            className="wrap-choice__btn d-flex align-items-center justify-content-center w-100"
            onClick={() => setShowModalBooks(!showModalBooks)}
          >
            <Icon src={File} stroke="#0089C2" />
            <span>Choose from Reference Library</span>
          </div>
        )}
      </div>
    </div>
  )
}