export const learnerConstants = {
    PORTFOLIO_REQUEST: 'PORTFOLIO_REQUEST',
    PORTFOLIO_SUCCESS: 'PORTFOLIO_SUCCESS',
    PORTFOLIO_FAILURE: 'PORTFOLIO_FAILURE',

    UPDATE_LEARNER_DEMO_REQUEST: 'UPDATE_LEARNER_DEMO_REQUEST',
    UPDATE_LEARNER_DEMO_SUCCESS: 'UPDATE_LEARNER_DEMO_SUCCESS',
    UPDATE_LEARNER_DEMO_FAILURE: 'UPDATE_LEARNER_DEMO_FAILURE',

    SET_TYPE_CHAT_MOBILE: 'SET_TYPE_CHAT_MOBILE',
    TOGGLE_CHAT_FEEDBACK: 'TOGGLE_CHAT_FEEDBACK',
    TOTAL_CHAT_VOICE_TRIGGER: 'TOTAL_CHAT_VOICE_TRIGGER',

    LOAD_VIRTUAL_PRECEPTOR_STATUS_REQUEST: 'LOAD_VIRTUAL_PRECEPTOR_STATUS_REQUEST',
    LOAD_VIRTUAL_PRECEPTOR_STATUS_SUCCESS: 'LOAD_VIRTUAL_PRECEPTOR_STATUS_SUCCESS',
    LOAD_VIRTUAL_PRECEPTOR_STATUS_FAILURE: 'LOAD_VIRTUAL_PRECEPTOR_STATUS_FAILURE',
};
