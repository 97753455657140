import React from 'react';
import styled from 'styled-components';
import MedicalDescriptionBox from './MedicalDescriptionBox';

const MedicalInfoWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 16px;
`;

const MedicalInfoInner = styled.div`
    height: 100%;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
`;

const MedicalInfoTop = styled.div`
    flex: 1;
`;

const MedicalInfo = (props) => {    
    return (
        <MedicalInfoWrapper>
            <MedicalInfoInner>
                <MedicalInfoTop>
                    <MedicalDescriptionBox {...props} />
                </MedicalInfoTop>
            </MedicalInfoInner>
        </MedicalInfoWrapper>
    );
};

export default MedicalInfo;