import React, { useState } from "react";
import thumbDefault from "@images/common/placeholder.png";

//useParams
import { useParams } from "react-router-dom";

//API
import axios from "axios";
import * as Config from "@constants/Config";
import { authHeader } from "@helpers";

//call Action
import { actFetchTeachCaseRequest } from "@actions";

//redux
import { useDispatch } from "react-redux";

//styled-components
import styled from "styled-components";

//react-dropzone
import { useDropzone } from "react-dropzone";

//boostrap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const ContainerUpload = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function CasePhoto(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { dataResponse } = props && props;

  //state
  const [modal, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept: "image/*", multiple: false });

  const toggle = () => setModal(!modal);

  const handleUpload = () => {
    setLoading(true);
    let formData = new FormData();
    acceptedFiles.map((file) => {
      return formData.append("CaseImage", file);
    });
    formData.append("Action", "Update");
    axios({
      method: "PUT",
      url: `${Config.API_URL}/api/teach/case/${id}/`,
      data: formData,
      headers: authHeader(),
    })
      .then(function (response) {
        //handle success
        if (response && response.status === 200) {
          dispatch(actFetchTeachCaseRequest(id));
          setModal(false);
          setLoading(false);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response);
        setLoading(false);
      });
  };

  return (
    <div className="widgetBox -box-shadow mt-4">
      <div className="widgetBox__head">
        <h3 className="wg-title m--font-brand --text-primary">
          <i className="fa fa-user-circle-o mr-2" aria-hidden="true"></i>
          Case Photo
        </h3>
      </div>
      <div className="widgetBox__body">
        <img
          className="img-fluid"
          src={
            dataResponse && dataResponse.Thumbnail
              ? dataResponse.Thumbnail
              : thumbDefault
          }
          alt="case"
        />
        <div className="mt-4 text-center">
          <button className="btn btn-brand --bg-primary" onClick={toggle}>
            Change Photo
          </button>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Change Case Photo</ModalHeader>
            <ModalBody>
              <ContainerUpload
                {...getRootProps({
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                })}
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                {acceptedFiles.map((file) => (
                  <p>
                    {file.path} - {file.size} bytes
                  </p>
                ))}
              </ContainerUpload>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggle}>
                Cancel
              </Button>{" "}
              <Button
                color="danger"
                disabled={isLoading}
                className="btn btn-brand --bg-primary d-flex align-items-center"
                onClick={handleUpload}
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm mr-2"></span>
                )}
                Save
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default CasePhoto;
