import React, { useState, useEffect } from "react";

//boostrap
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

//components
import { SettingsContent } from '../Modules';

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { instructorConstants } from "@constants";

function ModalCondition(props) {
    const dispatch = useDispatch();

    //props
    const { isOpen, toggle, itemActive, seriesLoadById } = props && props;

    //state
    const [stateData, setData] = useState((seriesLoadById && seriesLoadById) || {});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setData((seriesLoadById && seriesLoadById) || {});
    }, [seriesLoadById]);

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'isDemo') {
            setData((prevState) => ({ ...prevState, [name]: checked }));
        } else {
            setData((prevState) => ({ ...prevState, [name]: value }));
        }
    }

    const handleChangeMessage = (type, params, content) => {
        let newData = { ...stateData }
        newData[`Messages`][type][params] = content;
        setData(newData);
    }

    const handleSubmit = () => {
        const params = {
            Action: "Update",
            RunMode: stateData?.RunMode,
            IntroMessage: stateData?.Messages?.IntroMessage,
            TransitionMessage: stateData?.Messages?.TransitionMessage,
            EndingMessage: stateData?.Messages?.EndingMessage,
            isDemo: stateData?.isDemo,
            Name: stateData?.Name
        }

        const isLoading = true;
        setLoading(isLoading);

        dispatch({ type: instructorConstants.SERIES_UPDATE_SETTINGS_REQUEST, isLoading });
        //Call Api
        apiCaller(`/api/teach/series/${itemActive?.Id}/`, "PUT", params).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({ type: instructorConstants.SERIES_UPDATE_SETTINGS_SUCCESS, payload: data });
                //Close
                toggle();
                setLoading(false);
            } else {
                //close
                toggle();
                setLoading(false);
                dispatch({ type: instructorConstants.SERIES_UPDATE_SETTINGS_FAILURE, error: "error" });
            }
        });
    }
    
    return (
        <Modal
            style={{ maxWidth: "900px" }}
            className="modal-dialog modal-dialog-centered"
            isOpen={isOpen}
            backdrop={`static`}
        >
            <ModalHeader toggle={toggle}>
                Settings for {itemActive?.Name || ''}
            </ModalHeader>
            <ModalBody className="p-4">
                <SettingsContent
                    {...props}
                    handleChange={handleChange}
                    handleChangeMessage={handleChangeMessage}
                    data={(stateData && stateData) || {}}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    className="--bg-primary d-flex align-items-center"
                    onClick={handleSubmit}
                    disabled={isLoading}
                >
                    {isLoading && (
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    )}
                    Save
                </Button>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalCondition;