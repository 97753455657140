import React, { useState } from "react";
import { Input, Table } from "reactstrap";

// Elements
import { BtnIconDeleteV2, BtnIconEditV2, BtnIconAddV2 } from "@components-common/elements";

//Common
import { ModalDeleteCommon } from "@components-common/Modal";

// Component hiển thị mỗi hàng trong bảng
const TableRow = ({ item, toggleModal }) => {
  const handleEditButtonClick = () => {
    toggleModal("editProblem", true, item);
  };

  const handleDeleteButtonClick = () => {
    toggleModal("delProblem", item);
  };

  return (
    <tr>
      <td className="align-middle">{item.name}</td>
      <td className="align-middle">{item.searchType}</td>
      <td className="align-middle">{item.searchString}</td>
      <td className="align-middle">
        <Input
          className="m-0 checkbox-lg"
          defaultChecked={item.required}
          type="checkbox"
        />
      </td>
      <td className="align-middle text-center">
        <div className="d-flex align-items-center justify-content-center">
          <BtnIconAddV2 handleEditButtonClick={handleEditButtonClick}/>
          <BtnIconEditV2
            handleEditButtonClick={handleEditButtonClick}
            colorIcon={`#16B364`}
          />
          <BtnIconDeleteV2 handleDelete={handleDeleteButtonClick} />
        </div>
      </td>
    </tr>
  );
};

const OptionProblem = () => {
  //state
  const [modalOpen, setModalOpen] = useState({
    editProblem: false,
    delProblem: false,
  });

  // Handle Toogle Modal
  const toggleModal = (type, item = null) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
  };

  // Handle Remove
  const handleRemove = () => {
    toggleModal("delete", {});
  };

  // Sample data
  const data = [
    {
      id: 1,
      name: "Go To 1 Hour, Skip 4 Hours (#91158)",
      searchType: "Regex",
      searchString: "ax",
      required: true,
    },
  ];

  return (
    <div className="mb-4">
      <Table className="custom-table-corner" responsive>
        <thead>
          <tr>
            <th className="align-middle --text-blue">Name</th>
            <th className="align-middle --text-blue">Search Type</th>
            <th className="align-middle --text-blue">Search String</th>
            <th className="align-middle --text-blue">Required</th>
            <th className="align-middle --text-blue text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <TableRow key={item.id} item={item} toggleModal={toggleModal} />
          ))}
        </tbody>
      </Table>
      <ModalDeleteCommon
        isOpen={modalOpen.delProblem}
        toggle={() => toggleModal("delProblem", {})}
        title={`Delete Topic`}
        sub={`Are you sure that you would like to delete this topic? This action cannot be undone.`}
        handleRemove={handleRemove}
      />
    </div>
  );
};

export default OptionProblem;
