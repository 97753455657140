import React, { useEffect, useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSelector } from "react-redux";
import { CongratulationsContent } from "../Weitzman";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiCaller } from "@utils";
import { advanceConstants } from "@constants";

function WeitzmanEndCase(props) {
  const { isOpen, handleStopApiSpendTime, hanldeToggleAdvance } = props || {};
  let history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();

  const handleExitCase = () => {
    history.push("/learn/home");
  };

  const handleCaseCompleted = useCallback(() => {
    let params = { "CaseId": id };
    dispatch({ type: advanceConstants.CASE_COMPLETE_REQUEST, isLoading: true });
    apiCaller(`/api/learn/case/complete/`, 'POST', params).then(res => {
      const data = res?.data;
      if (data?.status === "success" && data?.LearnerSessionId) {
        dispatch({ type: advanceConstants.CASE_COMPLETE_SUCCESS, payload: data });
        handleStopApiSpendTime();
      } else {
        dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: "error" });
        handleStopApiSpendTime();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, handleStopApiSpendTime]);

  useEffect(() => {
    if (isOpen) {
      handleCaseCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, handleCaseCompleted]);

  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;

  return (
    <React.Fragment>
      <Modal
        className="modal-custom"
        zIndex={16000}
        isOpen={isOpen}
        backdrop={`static`}
        centered
        backdropClassName="modal-backdrop-custom"
        modalTransition={{ timeout: 700 }}
      >
        <ModalBody>
          {/* Render CongratulationsContent component */}
          <CongratulationsContent {...props} />
        </ModalBody>
        <ModalFooter>
          <div className="mx-auto" style={{ width: "276px" }}>
            <Button
              color='primary'
              className="btn-brand-sm -btn-lg w-100 -shadown"
              onClick={() => handleExitCase()}
            >
              {/* Use dangerouslySetInnerHTML to render HTML content */}
              <div dangerouslySetInnerHTML={{ __html: ModalCaseCompleted?.m_complete_button_confirm?.Text || "Exit and Back to Home" }} />
            </Button>
            <Button
              color='white'
              className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
              onClick={() => { hanldeToggleAdvance("weitzman") }}
            >
              {/* Use dangerouslySetInnerHTML to render HTML content */}
              <div dangerouslySetInnerHTML={{ __html: ModalCaseCompleted?.m_complete_button_cancel?.Text || "Review This Case Again" }} />
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default WeitzmanEndCase;