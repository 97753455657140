import React from 'react';
import styled from 'styled-components';

// Icons
import IconMoney from '@images/coin/icon-money.svg';

// JSON data với 4 item
const data = [
    {
        id: 1,
        amount: 20,
        price: "10 USD",
        icon: IconMoney
    },
    {
        id: 2,
        amount: 50,
        price: "25 USD",
        icon: IconMoney
    },
    {
        id: 3,
        amount: 100,
        price: "45 USD",
        icon: IconMoney
    },
    {
        id: 4,
        amount: 200,
        price: "80 USD",
        icon: IconMoney
    }
];

// Style cho các thành phần
const Panel = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-self: stretch;
    border-radius: 24px;
    background: var(--new-white-color);
    margin-top: 14px;
`;

const Heading = styled.h1`
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
`;

const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
    width: 100%;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    padding: 16px;
    
    border-radius: 8px;
    border: 1px solid #EAECF0;
    background: var(--new-white-color);
    text-align: center;
`;

const CoinIconContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const CoinAmount = styled.div`
    color: var(--new-secondary-color);
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.4px;
`;

const Price = styled.div`
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
`;

const IconWrapper = styled.div`
    width: 22px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Icon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const PurchaseCoinList = () => {
    return (
        <Panel>
            <Heading>Purchase Credits</Heading>
            <Content>
                {data.map((item) => (
                    <Item key={item.id}>
                        <CoinIconContainer>
                            <CoinAmount>{item.amount}</CoinAmount>
                            <IconWrapper>
                                <Icon src={item.icon} alt="Credits" />
                            </IconWrapper>
                        </CoinIconContainer>
                        <Price>{item.price}</Price>
                    </Item>
                ))}
            </Content>
        </Panel>
    );
};

export default PurchaseCoinList;