import React, { useState } from "react";
import { Table } from "reactstrap";

// Elements
import { BtnIconDeleteV2 } from "@components-common/elements";

// Modal
import { ModalFormAdd } from "./index"

//Common
import { ModalDeleteCommon } from "@components-common/Modal";
import { CustomCollapseSection } from "@components-common";
import { Iconsax } from '@components-common';

// Component hiển thị mỗi hàng trong bảng
const TableRow = ({ item, toggleModal }) => {
  const handleDeleteButtonClick = () => {
    toggleModal("delIntake", item);
  };

  return (
    <tr>
      <td className="align-middle">{item.item}</td>
      <td className="align-middle">{item.initial}</td>
      <td className="align-middle">{item.unit}</td>
      <td className="align-middle">{item.reference}</td>
      <td className="align-middle text-center">
        <div className="d-flex align-items-center justify-content-center">
          <BtnIconDeleteV2 handleDelete={handleDeleteButtonClick} />
        </div>
      </td>
    </tr>
  );
};

const IntakeComponent = (props) => {
  const { title } = props;
  //state
  const [modalOpen, setModalOpen] = useState({
    createIntake: false,
    delIntake: false,
  });

  // Handle Toogle Modal
  const toggleModal = (type, item = null) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
  };

  // Sample data
  const data = [
    {
      id: 1,
      item: "Item",
      initial: "Initial Value",
      unit: "Unit",
      reference: "Reference Range",
    },
    {
      id: 2,
      item: "Item",
      initial: "Initial Value",
      unit: "Unit",
      reference: "Reference Range",
    },
  ];

  return (
    <div className="mb-4">
      <CustomCollapseSection title={title}>
        <Table className="custom-table-corner" responsive>
          <thead>
            <tr>
              <th className="align-middle --text-blue">Item</th>
              <th className="align-middle --text-blue">Initial Value</th>
              <th className="align-middle --text-blue">Unit</th>
              <th className="align-middle --text-blue">Reference Range</th>
              <th className="align-middle --text-blue text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <TableRow key={item.id} item={item} toggleModal={toggleModal} />
            ))}
          </tbody>
        </Table>
        <div className="card-area bg-grey p-2">
          <div className="btn-add-item --border-blue --size-sm-v2 text-center m-0" onClick={() => toggleModal("createIntake", {})}>
            <Iconsax iconName="plus" fill="#0089C2" size={15} />
            <span className="ml-2 btn-add-item__text --text-blue">Add {title} item</span>
          </div>
        </div>
      </CustomCollapseSection>
      <ModalFormAdd 
        isOpen={modalOpen.createIntake}
        toggleModal={() => toggleModal("createIntake", {})}
        type={title}
      />
      <ModalDeleteCommon
        isOpen={modalOpen.delIntake}
        toggle={() => toggleModal("delIntake", {})}
        title={`Delete ${title}`}
        sub={`Are you sure that you would like to delete this ${title}? This action cannot be undone.`}
      />
    </div>
  );
};

export default IntakeComponent;
