import React from "react";

// Icon
import IconCase from "@images/learnerv2/case-alert.svg";

const CaseTitle = ({ title }) => {
    return (
        <div className="case-title-block d-flex align-items-center">
            <img src={IconCase} alt="case" className="case info" />
            <h2 className="case-title-block__name ml-2">{title}</h2>
        </div>
    );
};

export default CaseTitle;