import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

//imgUser
import imgUser from '@images/top/user.svg';

//utils
import { messageData, LoaderText } from "@utils";

//redux
import { useSelector } from "react-redux";

//common
import { DataEmty } from "@components-common";

function TeamMembers(props) {
    const { data, isLoading } = props && props;
    const dataTable = data?.team_members;

    const columns = [{
        dataField: 'task_gid',
        text: ' ',
        classes: "align-middle",
        headerClasses: "p-0 border-0",
        hidden: true
    }, {
        dataField: 'gid',
        classes: "align-middle text-left",
        headerClasses: "p-0 border-0",
        text: '',
        formatter: (value, row) => {
            const nameUser = row?.name;
            const photoUser = row?.photo?.image_128x128;
            return (
                <div className="userBox">
                    <div className="userBox__left"><img src={photoUser || imgUser} className="imgUser bg-grey-light" alt="img" /></div>
                    <div className="userBox__right">
                        <p className="text-2">{nameUser}</p>
                    </div>
                </div>
            )
        }
    }, {
        dataField: 'total_scrum_point',
        text: '',
        headerClasses: "p-0 border-0",
        style: {
            width: "69px"
        },
        classes: "align-middle text-center",
        formatter: (value, row) => {
            const scrumPoint = row?.scrum_point;
            const totalScrum_Point = row?.total_scrum_point;
            const completedScrumPoint = row?.completed_scrum_point;
            return (
                <div className="font-weight-500">
                    <span className="text-success">{completedScrumPoint}</span><span className="text-1">&#47;</span>
                    <span className="text-primary">{scrumPoint}</span><span className="text-1">&#47;</span>
                    <span className="text-dark">{totalScrum_Point}</span>
                </div>
            );
        }
    }];

    const ScrumByIdStore = useSelector((state) => state.dataScrumById || []);
    return (
        <div className="widgetBox -box-shadow mb-3">
            <div className="widgetBox__body position-relative">
                {(isLoading || ScrumByIdStore?.isLoading) && (
                    <LoaderText />
                )}
                <BootstrapTable
                    keyField='user_id'
                    wrapperClasses="table-responsive teamMembers"
                    data={(dataTable && dataTable) || []}
                    columns={columns}
                    noDataIndication={() => (
                        <div className="py-5">
                            <DataEmty content={messageData?.default?.content} />
                        </div>
                    )}  
                />
            </div>
        </div>
    );
};

export default TeamMembers;