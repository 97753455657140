import React, { useState } from "react";

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//components
import { AccordionHeader, ButtonAddProblem, ModalEditProblem } from '../../../storyboard/Documentation/ProblemGroup';
import { TabWrap } from '../../../storyboard/Documentation';
import { OptionsTableMain } from '../Options';
import { ConceptsTableMain } from '../Concepts';

//components common
import { ModalDeleteCommon } from '@components/common/Modal';

//apiCaller
import { apiCaller } from "@utils";
import { problemsConstant } from "@constants";

function ProblemAccordionGroup(props) {
    let indexPlus;

    //props
    const { data } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [isLoading, setLoading] = useState(false);

    //state
    const [active, setActive] = useState(0);
    const [dataCurrent, setDataCurrent] = useState({});
    const [modal, setModal] = useState({
        UpdateProblem: false,
        DeleteProblem: false,
    });

    //Modal
    const toggle = (type, value) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
        setDataCurrent(value);
    };

    const indexCount = (index) => {
        indexPlus = index + 1;
        return indexPlus;
    };

    //Handle Panel
    const eventHandler = (e, index) => {
        e.preventDefault();
        setActive(index);
    };

    //Remove Problem
    const handleRemove = () => {
        const params = {
            "Action": "DeleteProblem",
            "CaseId": id,
            "DocumentationProblemId": dataCurrent?.ProblemId
        };

        setLoading(true);
        dispatch({ type: problemsConstant.DELETE_PROBLEM_ITEM_REQUEST, isLoading: true });
        apiCaller(`/api/teach/documentation/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: problemsConstant.DELETE_PROBLEM_ITEM_SUCCESS, payload: data });
                setModal((prevState) => ({ ...prevState, DeleteProblem: false }));
                setLoading(false);
            } else {
                dispatch({ type: problemsConstant.DELETE_PROBLEM_ITEM_FAILURE, error: "error" });
                setLoading(false);
                setModal((prevState) => ({ ...prevState, DeleteProblem: false }));
            }
        });
    }

    return (
        <React.Fragment>
            <ButtonAddProblem />
            {data?.map((value, index) => {
                const ProblemId = value?.ProblemId;
                //props
                const listTab = [
                    {
                        Name: "Options",
                        Component: (
                            <OptionsTableMain
                                {...props}
                                index={index}
                                indexCount={indexCount}
                                active={active}
                                value={value}
                                ProblemId={ProblemId}
                            />
                        ),
                    },
                    {
                        Name: "Concepts",
                        Component: (
                            <ConceptsTableMain
                                {...props}
                                index={index}
                                indexCount={indexCount}
                                active={active}
                                value={value}
                                ProblemId={ProblemId}
                            />
                        ),
                    }
                ];
                return (
                    <div className="accordionExam__item" key={index}>
                        <AccordionHeader
                            {...props}
                            eventHandler={eventHandler}
                            index={index}
                            active={active}
                            indexCount={indexCount}
                            value={value}
                            toggle={toggle}
                        />
                        {active === index && (
                            <TabWrap
                                data={listTab || []}
                            />
                        )}
                    </div>
                )
            })}
            <ModalDeleteCommon
                title={`Are you sure you want <br/> to delete this Problem ?`}
                sub={`This action is not reversible.`}
                isOpen={modal?.DeleteProblem}
                toggle={() => toggle('DeleteProblem', dataCurrent)}
                handleRemove={handleRemove}
                isLoading={isLoading}
            />
            <ModalEditProblem
                {...props}
                isOpen={modal?.UpdateProblem}
                toggle={() => toggle('UpdateProblem', dataCurrent)}
                data={dataCurrent}
            />
        </React.Fragment>
    );
}

export default ProblemAccordionGroup;