import React from "react";

//components
import { ModalAssistantCommon } from "@components-common/Modal";
import { FeedbackContent } from "../Feedback";

function ModalUserFeedback(props) {
    return (
        <ModalAssistantCommon
            {...props}
        >
            <FeedbackContent {...props} />
        </ModalAssistantCommon>
    );
}

export default ModalUserFeedback;