import React from 'react';

//redux
// import { useSelector } from "react-redux";

//lodash
import _ from "lodash";

// components
import { ButtonTaskItem } from "@components/learner/StandardScreen/CaseTask";
import { TasksMain } from ".";

//helpers
import { getIconForObject } from "../../../../helpers";

//react redux
import { useSelector } from "react-redux";

function ActionsLearnerPrimary(props) {
    const { dataInterface, handleExamMenu, dataTour, taskNexTour, isCurrentTask } = props && props; //overRideEffect, questionData, isCurrentTask
    const listItem = dataInterface?.InteractiveItems;
    const fileList = dataTour?.filter(function (event) {
        return event?.selector?.indexOf('step-3') > -1
    });
    // Check Missing Task from Store
    const missingTaskData = useSelector(state => state?.checkMissingTask?.data) || {};
    const isMissingTask = missingTaskData?.MissingTask;
    const isDisabled = isCurrentTask === "history" && isMissingTask === undefined;
    return (
        <React.Fragment>
            {listItem?.length > 0 && (
                <TasksMain heading={'Case tasks'}>
                    {listItem?.map((value, index) => {
                        let dataPopper = _.filter(listItem, function (o) { return o.Task === value?.Task });
                        const iconPath = getIconForObject(value?.Task);
                        return (
                            <ButtonTaskItem
                                {...props}
                                key={index}
                                label={value?.Title}
                                icon={iconPath}
                                attributeTour={`${fileList && fileList[0]?.selector}`}
                                handleClickItem={() => handleExamMenu(value?.Task)}
                                attributeTask={value?.Task}
                                // focusActions={`${isMissingTask === value?.Task ? "show-effect" : ""}`}
                                dataPopper={dataPopper}
                                isOpenPopper={taskNexTour === value?.Task}
                                isDisabled={isDisabled}
                            />
                        )
                    })}
                </TasksMain>
            )}
        </React.Fragment>
    );
};

export default ActionsLearnerPrimary;