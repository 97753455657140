import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useWhisperSTT, PulseButtonAnimate } from '../ChatVoice';
import { useSelector } from "react-redux";

const WhisperSTTComponent = (props) => {
    const { apiKey, handleSubmitVoice } = props;
    const { isRecording, startRecording, stopRecording } = useWhisperSTT(apiKey);

    const caseStore = useSelector((state) => state.cases || []);
    const CurrentTimePointId = caseStore?.data?.Case?.Timeline?.CurrentTimePointId;

    const [permissionGranted, setPermissionGranted] = useState(false);
    const [error, setError] = useState(null); // State to manage errors
    const mainRef = useRef(null);
    const audioVisualizerRef = useRef(null);

    const handleTranscriptionFinish = (text) => {
        const params = {
            "Action": "Submit",
            "Message": text,
            "withVoice": true,
            "TimePointId": CurrentTimePointId
        };
        handleSubmitVoice(params);
    };

    const requestPermission = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            stream.getTracks().forEach(track => track.stop());
            setPermissionGranted(true);
            setError(null); // Reset error if permission is granted
        } catch (error) {
            console.error('Error accessing the microphone:', error);
            setPermissionGranted(false);
            setError('Microphone access denied or not available. Please enable microphone access and try again.');
        }
    };

    const handleButtonClick = async () => {
        if (!permissionGranted) {
            await requestPermission();
        }
        if (permissionGranted) {
            if (isRecording) {
                stopRecording(handleTranscriptionFinish);
                if (audioVisualizerRef.current) {
                    audioVisualizerRef.current.stop();
                }
            } else {
                startRecording();
                initAudioVisualizer();
            }
        } else {
            setError('Microphone permission is not granted.');
        }
    };

    useEffect(() => {
        requestPermission();
    }, []);

    const initAudioVisualizer = () => {
        const visualMainElement = mainRef.current;
        const visualValueCount = 16;
        let visualElements;

        const createDOMElements = () => {
            for (let i = 0; i < visualValueCount; ++i) {
                const elm = document.createElement('div');
                elm.style.background = `linear-gradient(90deg, #00EBFC ${(i / visualValueCount) * 100}%, #003EFF 100%)`;
                visualMainElement.appendChild(elm);
            }
            visualElements = visualMainElement.querySelectorAll('div');
        };

        const init = () => {
            const audioContext = new AudioContext();
            visualMainElement.innerHTML = '';
            createDOMElements();

            const dataMap = { 0: 15, 1: 10, 2: 8, 3: 9, 4: 6, 5: 5, 6: 2, 7: 1, 8: 0, 9: 4, 10: 3, 11: 7, 12: 11, 13: 12, 14: 13, 15: 14 };
            const processFrame = (data) => {
                const values = Object.values(data);
                for (let i = 0; i < visualValueCount; ++i) {
                    const value = values[dataMap[i]] / 255;
                    const elmStyles = visualElements[i].style;
                    elmStyles.transform = `scaleY(${value})`;
                    elmStyles.opacity = Math.max(.25, value);
                }
            };

            audioVisualizerRef.current = new AudioVisualizer(audioContext, processFrame);
        };

        init();
    };

    return (
        <React.Fragment>
            <WrapperDiv>
                <StyledMain ref={mainRef} className={`${isRecording ? 'd-block': 'd-none'}`}></StyledMain>
                <PulseButtonAnimate
                    {...props}
                    onButtonClick={handleButtonClick}
                    disabled={!permissionGranted}
                    isRecording={isRecording}
                />
                <Text>{isRecording && 'Listening... Press to Stop'}</Text>
                {error && <Alert>{error}</Alert>} {/* Display error alert */}
            </WrapperDiv>
        </React.Fragment>
    );
};

class AudioVisualizer {
    constructor(audioContext, processFrame) {
        this.audioContext = audioContext;
        this.processFrame = processFrame;
        this.connectStream = this.connectStream.bind(this);
        this.rafId = null;
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(this.connectStream)
            .catch((error) => {
                console.error('Microphone access error:', error);
            });
    }

    connectStream(stream) {
        this.analyser = this.audioContext.createAnalyser();
        const source = this.audioContext.createMediaStreamSource(stream);
        source.connect(this.analyser);
        this.analyser.smoothingTimeConstant = 0.5;
        this.analyser.fftSize = 32;
        this.initRenderLoop();
    }

    initRenderLoop() {
        const frequencyData = new Uint8Array(this.analyser.frequencyBinCount);
        const renderFrame = () => {
            this.analyser.getByteFrequencyData(frequencyData);
            this.processFrame(frequencyData);
            this.rafId = requestAnimationFrame(renderFrame);
        };
        this.rafId = requestAnimationFrame(renderFrame);
    }

    stop() {
        if (this.rafId) {
            cancelAnimationFrame(this.rafId);
            this.rafId = null;
        }
    }
}

const StyledMain = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: inline-block;
    width: 3px;
    height: 44px;
    margin: 0 7px;
    transform: scaleY(.5);
    opacity: .25;
  }
`;

const WrapperDiv = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  @media (max-width: 768px) {
    bottom: 15px;
    width: 90%;
    text-align: center;
  }
`;

const Text = styled.div`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Alert = styled.div`
  color: red;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
`;

export default WhisperSTTComponent;