import * as Types from "@constants/ActionTypes";

var initialState = [];

const orders = (state = initialState, action) => {
    var { payload } = action;
    switch (action.type) {
        case Types.FETCH_ORDERS:
            return payload;
        case Types.UPDATE_STATE_ORDERS:
            return state = payload;
        default: return state;
    }
};

export default orders;