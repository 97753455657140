import React, { useRef, useState } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import { DataEmptyCase } from "@components-common";
import { useDispatch, useSelector } from "react-redux";
import { shareMedia } from "@actions";
import Select, { components } from 'react-select';

//utils
import { LoaderText } from "@utils";

//moment
import moment from "moment";
import CreatableSelect from 'react-select/creatable';

//components
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { listTypeUploadMedia } from '@utils/dataDefault'
// import { StatusType } from './ModalMedia';
import ModalWatchMedia from './ModalMedia/ModalWatchMedia';
import styled from 'styled-components';

const OrgReferenceMediaWrap = (props) => {
  const { loadDataReferenceBook, listValue, setListValue, inputValue, setInputValue, setListType, listType } = props;
  const { SearchBar } = Search;
  const { dataFilter: documents, isLoading } = useSelector((state) => state.getReferenceMedia) || {};
  const isSearchingOrPaging = useRef(false);
  const dispatch = useDispatch();
  const [dataView, setDataView] = useState(null)
  const [modal, setModal] = useState({
    modalView: false
  });

  //toggle
  const toggle = (type, value = null) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  const handleUnshareReference = async (data) => {
    const dataShare = await dispatch(shareMedia({
      "Action": "UnShareToInstitution",
      "MediaItemId": data?.id
    }))
    if (dataShare?.data?.status === 200) {
      toast.success('Successfully unshared from Organization Media', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loadDataReferenceBook()
    }
  }

  const componentsCommon = {
    DropdownIndicator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleAddTag = (value) => {
    setListValue(value)
  }

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const handleOpenUrl = (row) => {
    if (row.type === 'URL') {
      window.open(`${row?.url}`, "_blank")
    } else {
      toggle('modalView')
      setDataView(row)
    }
  }

  const columns = [
    {
      dataField: "title",
      text: "Media Name",
      headerClasses: "--text-primary text-nowrap",
      headerStyle: { width: "200px" },
      formatter: (cell, row) => {
        return (
          <React.Fragment>
            <div 
              className='text-w-600' 
              onClick={() => {
                if (row.url.includes("med2lab-frontend-media.s3.amazonaws.com")) {
                  handleOpenUrl(row)
                } else {
                  window.open(`${row?.url}`, "_blank")
                }
              }}
              style={{ cursor: "pointer" }}  
            >
              {row?.title}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      dataField: "descriptions",
      text: "Description",
      headerClasses: "--text-primary text-nowrap",
    },
    {
      dataField: "type",
      text: "View Media",
      headerClasses: "--text-primary text-nowrap",
      headerStyle: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <TextLink>
            {cell &&
              <span 
                className='text-link'
                style={{wordBreak: 'break-all'}}
                onClick={() => {
                  if (row.url.includes("med2lab-frontend-media.s3.amazonaws.com")) {
                    handleOpenUrl(row)
                  } else {
                    window.open(`${row?.url}`, "_blank")
                  }
                }}
              >
                {cell}
              </span>
            }
          </TextLink>
        );
      },
    },
    
    {
      Field: "tags",
      text: "Tags",
      headerClasses: "--text-primary align-middle text-center",
      headerStyle: { width: "200px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {row?.tags?.length > 0 ? (
              <>
                <div className="tag-library">
                  {row?.tags?.[0]}
                </div>
                {row?.tags?.length > 1 && (
                  <div className="tag-library ml-1" data-tip data-for={`tag-${row?.document_id}`}>
                    +{row?.tags?.length - 1}
                    <ReactTooltip id={`tag-${row?.document_id}`} place='top' effect='solid' className="tooltip-tags">
                      <div className='list-tags'>
                        {row?.tags?.map((tag, index) => (
                          index > 0 && tag && <span>{tag}</span>
                        ))}
                      </div>
                    </ReactTooltip>
                  </div>
                )}
              </>
            ) : (<></>)}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      headerClasses: "--text-primary text-nowrap",
      headerStyle: { width: "200px" },
      formatter: (cell, row) => {
        return (
          <div className='format-time'>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "--text-primary text-center",
      headerStyle: { width: "50px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-orgRefer">
            {row?.owned_by_account && (
              <p onClick={() => handleUnshareReference(row)}>
                Unshare
              </p>
            )}
          </div>
        );
      },
    }
  ];

  const handleBlur = () => {
    if (inputValue) {
      setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
    }
  }

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <ToolkitProvider keyField="id" data={documents?.InstitutionMedia || []} columns={columns} search>
      {(toolkitprops) => (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className='tb-heading-total'>Total Documents: {documents?.InstitutionMedia?.length}</div>
            <div className='d-flex align-items-center'>
              <div className="boxSearch">
                <SearchBar
                  {...toolkitprops.searchProps}
                  onSearch={(e) => {
                    paginationProps.page = 1;
                    toolkitprops.searchProps.onSearch(e);
                    isSearchingOrPaging.current = false;
                  }}
                  onKeyUp={() => { isSearchingOrPaging.current = true; }}
                />
              </div>
              <div className="email-share__form-input ml-2">
                <CreatableSelect
                  components={componentsCommon}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => {
                    handleAddTag(newValue)}
                  }
                  onInputChange={(newValue) => {
                    setInputValue(newValue)
                  }}
                  onKeyDown={(e) => {
                    paginationProps.onPageChange(1, 10);
                    handleKeyDown(e)
                  }}
                  onBlur={handleBlur}
                  placeholder="Filter by Tags"
                  value={listValue}
                  classNamePrefix={`filter-tag`}
                />
              </div>
              <div className="email-share__form-input ml-2">
                <Select
                  components={componentsCommon}
                  isMulti
                  placeholder="Filter by Type"
                  options={listTypeUploadMedia}
                  classNamePrefix="filter-tag"
                  onChange={(newValue) => {
                    paginationProps.onPageChange(1, 10);
                    setListType(newValue)
                  }}
                  value={listType}
                />
              </div>
            </div>
          </div>
          <BootstrapTable
            condensed
            wrapperClasses="table-custom-common table-responsive mt-4"
            bordered={false}
            data={documents?.InstitutionMedia}
            columns={columns}
            noDataIndication={() => (
              <DataEmptyCase
                title="No Results Found"
                text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
              />
            )}
            {...paginationTableProps}
            {...toolkitprops.baseProps}
          />
          <div className="pagination-custom-case d-flex justify-content-end ml-4">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: documents?.InstitutionMedia?.length || [],
  };

  return (
    <React.Fragment>
      <div className={`table-refer bg-white ${documents?.InstitutionMedia?.length === 0 ? 'position-relative' : ''}`} style={{ height: documents?.InstitutionMedia?.length === 0 ? 'calc(100vh - 72px)' : 'auto' }}>
        {isLoading ? (
          <LoaderText />
        ) : (
          (documents?.InstitutionMedia?.length > 0 || listValue?.length > 0 || listType?.length > 0) &&
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        )}
        {documents?.InstitutionMedia?.length === 0 && !isLoading && listValue?.length === 0 && listType?.length === 0 &&(
          <DataEmptyCase 
            title="Your Library is Empty"
            text="It looks like there are no shared media yet. Start sharing media from 'My Media' to see them here."
          />
        )}
      </div>
      <ModalWatchMedia
        isOpen={modal.modalView}
        toggleModal={() => toggle("modalView")}
        dataView={dataView}
        {...props} 
      />
    </React.Fragment>
  );
};

const TextLink = styled.p`
  color: #1570EF;
  font-size: 14px;
  font-weight: 400;
  text-decoration-line: underline;
  .text-link {
    cursor: pointer;
  }
`

export default OrgReferenceMediaWrap;