import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ThemeContext from "../../../context/ThemeContext";

// Components
import SidebarItem from "./SidebarItem";
import CreateNewQuick from "./CreateNewQuick";
import Logo from './Logo';
import { ModalFeedback } from "@components/teachV2/home/common";

// Icons
import { Iconsax } from "@components-common";
import imgLogo from '@images/common/med2lab_logo.png';
import UserContext from '@context/UserContext';

const SidebarMain = ({ isSidebarCollapsed }) => {
    const location = useLocation();
    const pathName = location.pathname;
    const context = useContext(UserContext);
    const [modalOpen, setModalOpen] = useState({ feedback: false });

    const toggleModal = (type) => {
        setModalOpen((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    };

    //theme
    const theme = useContext(ThemeContext);
    const primaryLogo = theme?.PrimaryLogo;

    const sidebarItems = pathName.startsWith('/learn')
        ? [
            { icon: <Iconsax iconName="sidebar-home" fill="#697592" />, name: 'Home', to: '/learn/home' },
            { icon: <Iconsax iconName="sidebar-book" fill="#697592" />, name: 'Assignments', to: '/learn/assignments' },
            { icon: <Iconsax iconName="sidebar-validate" fill="#697592" />, name: 'Validate', to: '/validate', isHidden: true },
            { icon: <Iconsax iconName="sidebar-manage" fill="#697592" />, name: 'Manage', to: '/adminstrator-tool', isHidden: true },
        ]
        : context?.privilege === "alpha" ? [
            { icon: <Iconsax iconName="sidebar-home" fill="#697592" />, name: 'Home', to: '/teach/home' },
            { icon: <Iconsax iconName="inventory" fill="#697592" />, name: 'My Inventory', to: '/teach/inventory' },
            { icon: <Iconsax iconName="reference-library" fill="#697592" />, name: 'Reference Library', to: '/teach/reference-library' },
            { icon: <Iconsax iconName="reference-media" fill="#697592" />, name: 'Media Library', to: '/teach/reference-media', isBeta: true },
        ] : [
            { icon: <Iconsax iconName="sidebar-home" fill="#697592" />, name: 'Home', to: '/teach/home' },
            { icon: <Iconsax iconName="inventory" fill="#697592" />, name: 'My Inventory', to: '/teach/inventory' },
            { icon: <Iconsax iconName="reference-library" fill="#697592" />, name: 'Reference Library', to: '/teach/reference-library' },
        ];

    const accountItems = [
        {
            icon: <Iconsax iconName={`feedback`} fill="#697592" />,
            name: 'Feedback',
            onClick: () => toggleModal("feedback")
        }
    ];

    return (
        <nav className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
            <Logo primaryLogo={primaryLogo || imgLogo} isSidebarCollapsed={isSidebarCollapsed} />
            {pathName.startsWith('/teach') && <CreateNewQuick isSidebarCollapsed={isSidebarCollapsed} />}
            <ul className="sidebar-menu">
                {!isSidebarCollapsed &&
                    <p className="sidebar__title">MAIN MENU</p>
                }
                {sidebarItems.map((item, index) => (
                    <SidebarItem key={index} item={item} isSidebarCollapsed={isSidebarCollapsed} />
                ))}
            </ul>
            <div className="sidebar-menu of-account">
                {!isSidebarCollapsed &&
                    <p className="sidebar__title">ACCOUNT</p>
                }
                {accountItems.map((item, index) => (
                    <SidebarItem 
                        handleClickAction={item.onClick}
                        key={index}
                        item={item}
                        isSidebarCollapsed={isSidebarCollapsed}
                    />
                ))}
            </div>
            <ModalFeedback 
                isOpen={modalOpen.feedback} 
                toggle={() => toggleModal("feedback")} 
                type="general"
            />
        </nav>
    );
};

export default SidebarMain;