import { cohortsConstant } from "@constants";
import {apiCaller} from "@utils";

//Load All Cohorts
export const atcLoadAllCohorts = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.GET_COHORT_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.GET_COHORT_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.GET_COHORT_FAILURE, error: error } }
}

//Load Cohort By Id
export const atcLoadCohortsById = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.LOAD_COHORT_BY_ID_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.LOAD_COHORT_BY_ID_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.LOAD_COHORT_BY_ID_FAILURE, error: error } }
}

//Create Cohort
export const atcCreateCohort = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.CREATE_COHORT_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.CREATE_COHORT_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.CREATE_COHORT_FAILURE, error: error } }
}

//Update Cohort
export const atcUpdateCohort = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.UPDATE_COHORT_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.UPDATE_COHORT_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.UPDATE_COHORT_FAILURE, error: error } }
}

//Delete Cohort
export const atcDeleteCohort = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.DELETE_COHORT_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.DELETE_COHORT_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.DELETE_COHORT_FAILURE, error: error } }
}

//Add Learners To Cohort
export const atcAddLearnersToCohort = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.ADD_LEARNERS_TO_COHORT_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.ADD_LEARNERS_TO_COHORT_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.ADD_LEARNERS_TO_COHORT_FAILURE, error: error } }
}

export const atcDeleteLearnerToCohort = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/cohorts/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
                //load api
                const paramsReload = {
                    Action: "Load",
                    CohortId: params.CohortId
                };
                dispatch(atcLoadCohortsById(paramsReload));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: cohortsConstant.REMOVE_LEARNERS_TO_COHORT_REQUEST, isLoading } }
    function success(data) { return { type: cohortsConstant.REMOVE_LEARNERS_TO_COHORT_SUCCESS, payload: data } }
    function failure(error) { return { type: cohortsConstant.REMOVE_LEARNERS_TO_COHORT_FAILURE, error: error } }
}