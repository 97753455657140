const API_ANALYZE = "http://ec2-54-176-3-150.us-west-1.compute.amazonaws.com:8000/api/freetext/analyze";
const API_NLP = "http://nlp.med2lab.com";
const KEY_CAPTCHA = "6LesE3AbAAAAAMol_86G85cUhbHO01QOfw6QDsl5";
const YOUR_CLIENT_ID = "278149915403-5okjc11mjsgj49kuspk9om7qlmtfpvsj.apps.googleusercontent.com";

const SYSTEM_NAME = {
  Med2lab: "Med2lab",
  Web_Admin_Makansafe: "web_admin_makansafe"
};

const API_URLS = {
  MAKANSAFE: {
    DEV: `https://dev-api.med2lab.com`,
    QA: `https://api-qa.med2lab.com`,
    PROD: `https://api-qa.med2lab.com`
  },
  MED2LAB: {
    DEV: `https://dev-api.med2lab.com`,
    QA: `https://cai-api-qa.med2lab.com`,
    PROD: `https://stage.med2lab.com`
  }
};

const CONFIG_JODIT = {
  direction: "ltr",
  readonly: false,
  placeholder: "",
  toolbarButtonSize: 'small',
  toolbarAdaptive: true,
  askBeforePasteFromWord: false,
};

const hostname = window.location.hostname;
let apiUrl;
let makanSafeApiUrl;

if (hostname.includes('dev-us.med2lab.com')) {
  apiUrl = API_URLS.MED2LAB.DEV;
  makanSafeApiUrl = API_URLS.MAKANSAFE.DEV;
} else if (hostname.includes('app.med2lab.com')) {
  apiUrl = API_URLS.MED2LAB.PROD;
  makanSafeApiUrl = API_URLS.MAKANSAFE.PROD;
} else if (hostname.includes('app-qa.med2lab.com')) {
  apiUrl = API_URLS.MED2LAB.QA;
  makanSafeApiUrl = API_URLS.MAKANSAFE.QA;
} else {
  apiUrl = API_URLS.MED2LAB.DEV;
  makanSafeApiUrl = API_URLS.MAKANSAFE.DEV;
}

const API_URL = apiUrl;
const MAKANSAFE_API_URL = makanSafeApiUrl;

export {
  API_ANALYZE,
  API_NLP,
  KEY_CAPTCHA,
  CONFIG_JODIT,
  API_URL,
  SYSTEM_NAME,
  YOUR_CLIENT_ID,
  MAKANSAFE_API_URL
};