import React, { useState } from 'react';

//reactstrap
import { Button } from 'reactstrap';

//components
import { ModalDeleteCommon } from "@components-common/Modal";

//utils
import { apiCaller } from "@utils";

const DeleteTemplateMain = (props) => {

    //props
    const { templateData, handleResetWhenDel } = props && props;

    const [modal, setModal] = useState({ Delete: false });
    const [isLoading, setLoading] = useState(false);

    //Handle Modal
    const hanldeModal = (type) => {
        setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    }

    //Handle Delete Template
    const handleDeleteTemplate = () => {
        setLoading(true);
        const params = { "Action": "DeleteTemplate" }
        apiCaller(`/api/teach/interface_template/${templateData?.InterfaceTemplateId}/`, "PUT", params).then((res) => {
            if (res?.status === 200) {
                setModal((prevState) => ({ ...prevState, "Delete": false }));
                setLoading(false);
                handleResetWhenDel();
            } else {
                console.log('error');
                setLoading(false);
                setModal((prevState) => ({ ...prevState, "Delete": false }));
            }
        });
    }

    return (
        <React.Fragment>
            <Button
                className="btn btn-danger d-flex align-items-center mt-2"
                onClick={() => hanldeModal('Delete')}
            >
                Delete Template
            </Button>
            <ModalDeleteCommon
                title={`Delete Template`}
                sub={`Are you sure you want to delete this template? This action is not reversible.`}
                isOpen={modal?.Delete}
                toggle={() => hanldeModal('Delete')}
                handleRemove={handleDeleteTemplate}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};

export default DeleteTemplateMain;