import React, { useRef } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormModal } from "@components-common/Modal";
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

const ModalFormAdd = ({ isOpen, toggleModal, title, timepointData }) => {
  const formikRef = useRef(null);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    type: Yup.string().required("Initial Value is required"),
    dataOffset: Yup.string().required("Date Offset is required"),
    text: Yup.string().required("Text is required"),
  });

  const handleSaveButtonClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={title}
      onAction={handleSaveButtonClick}
      actionText={"Save"}
      maxWidth="860px"
    >
      <Formik
        initialValues={{
          title: "",
          type: "",
          dataOffset: "",
          text: timepointData?.DefaultNarrative?.Content,
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          touched,
        }) => (
          <Form className="form-intake">
            <FormGroup>
              <Label className="labeForm" for="title">
                Title <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="CT - Abdomen ADAD test image"
                type="text"
                name="title"
                id="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.title && touched.title}
              />
              <FormFeedback>{errors.title}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="type">
                Initial Value <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder=""
                type="text"
                name="type"
                id="type"
                value={values.type}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.type && touched.type}
              />
              <FormFeedback>{errors.type}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="dataOffset">
                Reference (Nomal) Range: <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder=""
                type="text"
                name="dataOffset"
                id="dataOffset"
                value={values.dataOffset}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.dataOffset && touched.dataOffset}
              />
              <FormFeedback>{errors.dataOffset}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <JoditEditor
                // ref={editor}
                value={values.text}
                config={Config.CONFIG_JODIT}
                placeholder={''}
                tabIndex={1}
                onBlur={(newContent, e) => handleBlur(newContent, e)}
              />
              <FormFeedback>{errors.text}</FormFeedback>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </FormModal>
  );
};

export default ModalFormAdd;
