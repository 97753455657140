import React from 'react';

//boostrap
import { Button } from "reactstrap";

function BtnViewEmail({ handleToggleEmailTemplate, values }) {
    return (
        <Button
            className="btn btn-brand mt-2 mr-2 text-1"
            color="primary"
            onClick={() => handleToggleEmailTemplate(values)}
        >
            <i className="fa fa-envelope mr-2" aria-hidden="true"></i>View Email
        </Button>
    );
};

export default BtnViewEmail;