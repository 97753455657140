import React from 'react'
import { ListLearnerStory } from "@components/teachV2/ListLearnerStory";

const ListLearner = () => {

  return (
    <div className="wrapper -listLearner">
      <ListLearnerStory />
    </div>
  )
}

export default ListLearner
