import React from 'react';

//common
import { SwitchItem } from "@components-common/Switches";

function ButtonSwitchChatWrapup(props) {
    return (
        <SwitchItem
            {...props}
            label={`Enable Chat Wrap-up in Learner Interface`}
            name={`ChatWrapup`}
        />
    );
};

export default ButtonSwitchChatWrapup;