import React from 'react';
import { CongratulationsIcon } from ".";
import { useSelector } from "react-redux";

function Congratulationscontent() {
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;

    // Chuyển đổi HTML
    const convertToHTML = (text) => ({ __html: text });

    return (
        <div className="modal-inner">
            <div className="modal-inner">
                <div className="modal-inner__pic d-flex justify-content-center">
                    <CongratulationsIcon />
                </div>
                <div className="modal-inner__desc text-center">
                    <h2 className="headline --size-4 mb-4 text-center" dangerouslySetInnerHTML={convertToHTML(ModalCaseCompleted?.m_complete_title?.Text || 'Bravo! You’ve Completed a Complex COVID-19 Case!')} />
                    <div dangerouslySetInnerHTML={convertToHTML(ModalCaseCompleted?.m_complete_msg?.Text || 'Ready for more? Engage with other intriguing cases to expand your knowledge!')} />
                </div>
            </div>
        </div>
    );
}

export default Congratulationscontent;