import React, { useState } from 'react'
import { Icon } from '@components/common'
import SaveModal from '@images/teachV2/save-modal.svg'
import SuccessModal from '@images/teachV2/success-modal.svg'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { saveCaseScript } from "@actions";
import { useHistory } from 'react-router-dom'

const ModalConfirm = ({ isOpen, toggle, dataContentFile, dataCaseScript}) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const dataSaveScript = useSelector((state) => state?.saveCaseScript || []);
  const [showSuccess, setShowSuccess] = useState(false)

  const handleSave = () => {
    if (dataCaseScript?.case_sections) {
      dispatch(saveCaseScript(dataCaseScript))
      setShowSuccess(true)
    } else {
      dispatch(saveCaseScript(dataContentFile))
      setShowSuccess(true)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size='lg'
      className='modal-custom modal-size-lg'
      maxWidth="860px"
    >
      {showSuccess ? (
        <>
          <ModalBody>
            <div className='w-100 d-flex align-items-center justify-content-center mb-3'>
              <Icon src={SuccessModal} />
            </div>
            <div className='modal-body__title text-center'>
              <p className='title-note'>Save Successful!</p>
            </div>
            <p className='modal-body__note text-center'>Your case script has been saved successfully.</p>
          </ModalBody>
          <ModalFooter className='m-0'>
            <div className='d-flex w-100'>
              <div className={'w-100'}>
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    color="secondary"
                    className="button-cta button-sm"
                    onClick={() => {
                      toggle()
                      setShowSuccess(false)
                    }}
                    // disabled={isLoading}
                  >
                    Continue Editing
                  </Button>
                  <Button
                    color="primary ml-3"
                    className="button-cta button-sm"
                    onClick={() => history.push(`/teach/home`)}
                    disabled={dataSaveScript?.isLoading}
                  >
                    View Library
                  </Button>
                </div>
              </div>
            </div>
          </ModalFooter>
        </>
      ) : (
        <>
          <ModalHeader className='header-script' toggle={toggle}>
            <Icon src={SaveModal} />
            <p className='modal-title__text'>Save Case Script </p>
          </ModalHeader>
          <ModalBody>
            <p className='modal-body__note'>This action will save the current case script to your library, allowing you to access it later.</p>
            <div className='modal-body__title'>
              <p className='title-note'>Case Script Title</p>
              <p className='title-content'>55-year-old man with chest pain |</p>
            </div>
          </ModalBody>
          <ModalFooter>
              <div className='d-flex w-100'>
                <div className={'w-100'}>
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      color="secondary"
                      className="button-cta button-sm"
                      onClick={() => {
                        toggle()
                        setShowSuccess(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary ml-3"
                      className="button-cta button-sm"
                      onClick={handleSave}
                      disabled={dataSaveScript?.isLoading}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

export default ModalConfirm
