import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory, Route, withRouter, Switch } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

// Page defaults
import { NotFoundPage, ForbiddenPage } from "@pages/other";

// Action
import { atcLoadStateTheme } from "@actions";

// Utils
import { checkAuth } from "@utils/";

// Contexts
import { LockScrollBodyProvider } from '@context/LockScrollBodyProvider';
import { LanguageProvider } from '@context/LanguageContext';
import { UserProvider } from "@context/UserContext";

// Layouts
import { MasterLayoutChat, MasterLayout } from "@components/layouts";

// Config
import * as Config from "@constants/Config";

const excludedUrls = [
  "/register", "/google-callback", "/verify", "/demo", "/demo-register",
  "/access-token", "/auth", "/weitzman", "/ucr", "/kachi", "vinuni",
  "/engage", "/makansafe/auth/", "/aspet2024", "/sso"
];

const RenderRoutes = ({ routes }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname, search } = location;

  const [isMounted, setIsMounted] = useState(false);

  const redirectLogin = useCallback(() => {
    if (!checkAuth() && !search && (pathname === "/login" || pathname === "/")) {
      history.push(`/login`);
    } else if (!checkAuth() && !excludedUrls.some(url => pathname.includes(url)) && pathname !== "/login") {
      history.push(`/login?redirect=${pathname + search}`);
    }
  }, [history, pathname, search]);

  useEffect(() => {
    setIsMounted(true);
    document.body.style.backgroundColor = '#fff';

    const localTheme = JSON.parse(localStorage.getItem("themeData"));
    if (localTheme) dispatch(atcLoadStateTheme(localTheme));

    redirectLogin();

    return () => setIsMounted(false);
  }, [dispatch, redirectLogin, pathname]);

  if (!isMounted || !routes) return null;

  const Account = JSON.parse(localStorage.getItem("Account"));
  const app = Account?.app;

  const filteredRoutes = routes.filter(route => {
    if (app === Config.SYSTEM_NAME.Web_Admin_Makansafe) {
      return route.makansafeLayout;
    } else {
      return !route.makansafeLayout;
    }
  });

  return (
    <LockScrollBodyProvider>
      <LanguageProvider>
        <UserProvider>
          <Switch>
            {filteredRoutes.map((route, i) => {
              return (
                <Route
                  key={i}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => (
                    route.ladingPage ? (
                      <MasterLayoutChat>
                        <route.component {...props} route={route} routes={route.routes} />
                      </MasterLayoutChat>
                    ) : (
                      <MasterLayout {...route} AccountData={Account}>
                        <route.component {...props} route={route} routes={route.routes} />
                      </MasterLayout>
                    )
                  )}
                />
              );
            })}
            <Route
              path="/403-forbidden"
              exact
              render={(props) => (
                <MasterLayout {...props}>
                  <ForbiddenPage />
                </MasterLayout>
              )}
            />
            <Route
              path="*"
              render={(props) => (
                <MasterLayout {...props}>
                  <NotFoundPage />
                </MasterLayout>
              )}
            />
          </Switch>
        </UserProvider>
      </LanguageProvider>
    </LockScrollBodyProvider>
  );
};

RenderRoutes.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default withRouter(connect(null, null)(RenderRoutes));