import React, { useState } from "react";

//boostrap
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//react-router-dom
import { useParams } from "react-router-dom";

//api
import { apiCaller } from "@utils";
import { caseConstants } from "@constants";

//image
import image from "@images/common/pic-reset-case.svg";

function ModalResetCase(props) {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const { isOpen, toggle, isView } = props && props;

  //state
  const [isLoading, setLoading] = useState(false);

  const requestResetCase = () => {
    setLoading(true);
    const params = {
      "CaseId": id
    }
    apiCaller(`/api/learn/case/reset/`, "POST", params).then((res) => {
      const data = res?.data;
      if (data?.status === "success") {
        //data task Orders
        localStorage.removeItem("dataExamAvailable");
        dispatch({
          type: caseConstants.RESET_CASE_SUCCESS,
          payload: data,
          params: params
        });
        setLoading(false);

        //Reload Page
        if (isView === 'feedback') {
          window.location.href = `/learn/case/${params?.CaseId}`;
        } else {
          window.location.reload();
        }
      } else {
        dispatch({
          type: caseConstants.RESET_CASE_FAILURE,
          error: 'error',
        });
        setLoading(false);
      }
    });
  }

  return (
    <Modal
      className="modal-custom"
      zIndex={16000}
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
    >
      <ModalBody>
        <div className="modal-inner">
          <div className="modal-inner__pic text-center">
            <img
              src={image}
              alt="Hold Your Scrubs!"
            />
          </div>
          <div className="modal-inner__desc text-center">
            <h2 className="headline --size-4 text-center">
              Hold Your Scrubs!
            </h2>
            <p>
              Are you sure about resetting? This action will erase all progress you’ve made so far. 🥹
              Ready for a medical do-over? 🩺🪄
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mx-auto" style={{ maxWidth: "335px" }}>
          <Button
            color='primary'
            className="btn-brand-sm -btn-lg w-100 -shadown"
            onClick={() => requestResetCase()}
            disabled={isLoading}
          >
            {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>} Reset and Roll
          </Button>
          <Button
            color='white'
            className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
            onClick={toggle}
            disabled={isLoading}
          >
            No, take me back
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ModalResetCase;