import React, { useState, useEffect } from 'react';

//router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

const ModalEffect = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const params = props && props.data;
  const [nameState, setNameState] = useState(params);
  const { categoryType, timePointId, interventionId, activeCategory, toggle, isOpen } = props && props;
  const idCategory = activeCategory;

  //state
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setNameState(params);
  }, [params])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNameState((prevState) => ({ ...prevState, [name]: value }));
  }

  const checkReloadApiForElements = (type) => {
    const params = {}
    const pathName = window.location.pathname.includes("/elements");
    if (pathName) {
      params["ReloadData"] = type;
    }
    return params;
  }

  const handleSubmit = () => {
    setLoading(true);
    const reload = checkReloadApiForElements(categoryType === "Action" ? "Actions" : "Medications");
    const params = {
      "Action": "Update",
      "Target": "Effects",
      "TimePointId": String(timePointId), //string
      "InterventionId": String(interventionId), //string
      "EffectId": String(nameState.EffectId), //string
      "ChangeMode": nameState.ChangeMode,
      "ChangeValue": nameState.ChangeValue,
      "ChangeLimit": nameState.ChangeLimit,
      "DurationInMinutes": nameState.DurationInMinutes,
      "TimeToEffectInMinutes": nameState.TimeToEffectInMinutes,
      ...reload && reload
    }
    atcUpdateEffect(id, params, idCategory, interventionId);
  }

  //Update Effect
  const atcUpdateEffect = (id, params, idCategory, interventionId) => {
    const isLoading = true;
    //Call Api
    dispatch({
      type: storyboardConstants.UPDATE_EFFECT_REQUEST,
      isLoading
    });
    apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: storyboardConstants.UPDATE_EFFECT_SUCCESS,
          payload: data,
          idCategory: idCategory,
          interventionId: interventionId
        });

        //Close
        toggle();
        setLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.UPDATE_EFFECT_FAILURE,
          error: 'error'
        });
        setLoading(false);
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={`static`}
      style={{ maxWidth: "1000px" }}
    >
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">Configure An Effect of Prepare Epinephrine Drip</span>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col md={6}>
              <h5 className="heading-5 --text-primary">Effect Settings</h5>
              <FormGroup>
                <Label for="AttributeName">Attribute</Label>
                <Input
                  type="text"
                  name="AttributeName"
                  defaultValue={nameState && nameState.AttributeName}
                  id="AttributeName"
                  placeholder=""
                  disabled
                  onChange={handleChange}
                  autoComplete="off"
                />
                <small className="form-help mt-2 d-block">
                  (create a new effect if you would like to include a different attribute)
                </small>
              </FormGroup>
              <FormGroup>
                <Label for="AttributeInitialValue">Initial Value</Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="AttributeInitialValue"
                    defaultValue={nameState && nameState.AttributeInitialValue}
                    id="AttributeInitialValue"
                    placeholder={`Initial value of ${nameState && nameState.AttributeInitialValue}`}
                    disabled
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>{nameState && nameState.AttributeUnit}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <small className="form-help mt-2 d-block">
                  this is the initial value of O2 saturation at the beginning of the case
                </small>
              </FormGroup>
              <FormGroup>
                <Label for="TimeToEffectInMinutes">Time To Effect:</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="TimeToEffectInMinutes"
                    defaultValue={nameState && nameState.TimeToEffectInMinutes}
                    id="TimeToEffectInMinutes"
                    placeholder="Time to effect in minutes"
                    onChange={handleChange}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mins</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <small className="form-help mt-2 d-block">
                  the number of minutes it will take for Prepare Epinephrine Drip to take the effect on O2 saturation
                </small>
              </FormGroup>
              <FormGroup>
                <Label for="duration">Duration of Effect:</Label>
                <InputGroup>
                  <Input
                    type="number"
                    name="DurationInMinutes"
                    defaultValue={nameState && nameState.DurationInMinutes}
                    id="DurationInMinutes"
                    placeholder="DurationInMinutes"
                    onChange={handleChange}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>mins</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <small className="form-help mt-2 d-block">
                  the number of minutes during which Prepare Epinephrine Drip will have an effect on O2 saturation
                </small>
              </FormGroup>
            </Col>
            <Col md={6}>
              <h5 className="heading-5 --text-primary">Effect Details</h5>
              <FormGroup>
                <Label for="ChangeMode">Effect Type</Label>
                <Input
                  type="select"
                  defaultValue={nameState && nameState.ChangeMode}
                  data-active={nameState && nameState.ChangeMode}
                  name="ChangeMode"
                  onChange={handleChange}
                >
                  <option value="set">
                    Set {nameState && nameState.AttributeName} equal to ...
                  </option>
                  <option value="decrease">
                    Decrease {nameState && nameState.AttributeName} by ...
                  </option>
                  <option value="increase">
                    Increase {nameState && nameState.AttributeName} by ...
                  </option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ChangeValue">... the Effect Value</Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="ChangeValue"
                    defaultValue={nameState && nameState.ChangeValue}
                    id="ChangeValue"
                    placeholder="Value for the effect"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {nameState && nameState.AttributeUnit &&
                    <InputGroupAddon addonType="append">
                      <InputGroupText>{nameState && nameState.AttributeUnit}</InputGroupText>
                    </InputGroupAddon>
                  }
                </InputGroup>
              </FormGroup>
              <h5 className="heading-5 --text-primary">Effect Limits</h5>
              <FormGroup>
                <Label for="ChangeLimit">... but the value of O2 saturation should not rise above:</Label>
                <InputGroup>
                  <Input
                    type="text"
                    name="ChangeLimit"
                    defaultValue={nameState && nameState.ChangeLimit}
                    id="ChangeLimit"
                    placeholder={`The maximum limit for ${nameState && nameState.ChangeLimit}`}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                  {nameState && nameState.AttributeUnit &&
                    <InputGroupAddon addonType="append">
                      <InputGroupText>{nameState && nameState.AttributeUnit}</InputGroupText>
                    </InputGroupAddon>
                  }
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          className="btn btn-brand --bg-primary d-flex align-items-center"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEffect;
