import React from "react";
import styled from "styled-components";
import iconChatFeedback from "@images/learnerv2/icon-chat-feedback-sp.svg";

//redux
import { useDispatch, useSelector } from "react-redux";

//actions
import { toggleChatFeedbackMobile } from "@actions";

const Button = styled.button`
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  background: #F79E1C;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;

const Icon = styled.img`
  width: 20px;
`;

const ButtonChatFeedback = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.chatFeedbackReducer.isOpen);

  const togglePopup = () => {
    dispatch(toggleChatFeedbackMobile(!isOpen));
  };

  return (
    <React.Fragment>
      <Button className="button-chatFeedback-sp" onClick={togglePopup}>
        <Icon src={iconChatFeedback} alt="Chat Feedback Icon" />
      </Button>
    </React.Fragment>
  );
};

export default ButtonChatFeedback;