import React, { useState, useEffect, useRef } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";
import { ReferenceRange } from "../TimepointFirst";

// Components
import { SwitchShowVitalSign } from "../../VitalSigns";
import { BtnIconDeleteV2 } from '@components-common/elements';
import { DataEmty } from "@components-common";

const TableTimepointFirst = (props) => {
    const dispatch = useDispatch();
    const { id } = useParams();

    // Props
    const { tblvitalSigns: initialTblVitalSigns, handleDeleteButtonClick, timepointData } = props || {};

    const isSavingReferenceRangeRef = useRef(false);
    const [isLoading, setLoading] = useState(false);
    const [tblvitalSigns, setTblVitalSigns] = useState(initialTblVitalSigns || []);

    // Update tblvitalSigns when props change
    useEffect(() => {
        setTblVitalSigns(initialTblVitalSigns || []);
    }, [initialTblVitalSigns]);

    // Function to fetch API and update timepoint data
    const fetchApiUpdateDataForTimePoint = (id, params) => {
        setLoading(true);
        return new Promise((resolve, reject) => {
            apiCaller(`/api/teach/case/${id}/`, "PUT", params)
                .then((res) => {
                    const data = res?.data;
                    if (res?.status === 200) {
                        dispatch({
                            type: storyboardConstants.UDPATE_VALUE_VITAL_SUCCESS,
                            payload: data
                        });
                        setLoading(false);
                        resolve(data); // Successful data retrieval
                    } else {
                        dispatch({
                            type: storyboardConstants.UDPATE_VALUE_VITAL_FAILURE,
                            error: "error",
                        });
                        setLoading(false);
                        reject("error"); // Error handling
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: storyboardConstants.UDPATE_VALUE_VITAL_FAILURE,
                        error: error.message,
                    });
                    setLoading(false);
                    reject(error); // Error handling
                });
        });
    };

    // Function to handle cell update
    const afterSaveCell = (oldValue, newValue, row) => {
        if (!isSavingReferenceRangeRef.current) {
            const params = {
                Action: "Update",
                Target: "TimePoint",
                TimePointId: timepointData?.Id,
                VitalSign: {
                    Id: row.Id,
                    Value: row.Value,
                    Unit: row.Unit
                },
            };
            fetchApiUpdateDataForTimePoint(id, params);
        }
    };

    // Function to save reference range
    const handleSaveReferenceRange = async (data) => {
        isSavingReferenceRangeRef.current = true;
        const params = {
            Action: "Update",
            Target: "TimePoint",
            TimePointId: timepointData?.Id,
            VitalSign: {
                Id: data.Id,
                NormalMax: data.NormalMax || 0,
                NormalMin: data.NormalMin || 0
            },
        };
    
        try {
            const responseData = await fetchApiUpdateDataForTimePoint(id, params);
            isSavingReferenceRangeRef.current = false;
            return responseData; // Trả về dữ liệu từ API sau khi cập nhật thành công
        } catch (error) {
            console.error("Error from fetchApiUpdateDataForTimePoint:", error);
            // Handle errors here if needed
            isSavingReferenceRangeRef.current = false;
            throw error; // Throw error để component có thể xử lý
        }
    };
    
    const columns = [
        { dataField: 'Name', text: 'Items', headerClasses: "align-middle", classes: "align-middle", editable: false },
        { dataField: 'Value', text: 'Initial Value', headerClasses: "align-middle text-center text-nowrap", classes: "align-middle text-nowrap", formatter: (cell) => <div className="text-center">{cell}</div> },
        { dataField: 'Unit', text: 'Unit', headerClasses: "align-middle text-center", classes: "align-middle text-nowrap", formatter: (cell) => <div className="text-center">{cell}</div> },
        { dataField: 'referenceRange', text: 'Reference Range', headerClasses: "align-middle text-center", classes: "align-middle text-nowrap", formatter: (cell, row) => <div className="text-center">{cell}</div>, style: { width: '300px' }, editorRenderer: (editorProps, value, row) => <ReferenceRange {...editorProps} handleSaveReferenceRange={handleSaveReferenceRange} isLoading={isLoading} row={row} /> },
        {
            dataField: 'Id', text: 'Action', editable: false, headerClasses: "align-middle text-center", classes: "align-middle text-center", headerStyle: (colum, colIndex) => { return { width: "80px" }; }, formatter: (cell, row) => (
                <div className="d-flex align-items-center justify-content-center">
                    <BtnIconDeleteV2 handleDelete={() => handleDeleteButtonClick(row)} />
                </div>
            ),
        },
    ];
    
    return (
        <React.Fragment>
            <SwitchShowVitalSign {...props} />
            <ToolkitProvider keyField="Id" data={tblvitalSigns} columns={columns}>
                {(toolkitprops) => (
                    <BootstrapTable
                        condensed wrapperClasses="table-responsive custom-table-corner"
                        keyField="Id" 
                        data={tblvitalSigns} 
                        columns={columns}
                        bordered={false} 
                        cellEdit={cellEditFactory({ mode: 'click', blurToSave: true, afterSaveCell })}
                        noDataIndication={() => <DataEmty content="No Data" />}
                        {...toolkitprops.baseProps}
                    />
                )}
            </ToolkitProvider>
        </React.Fragment>
    );
};

export default TableTimepointFirst;