import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const SwitchRoleView = (props) => {
    const location = useLocation();
    const isTeachingPath = location?.pathname.includes('/teach');
    return (
        <div className="btn-switch-role">
            {isTeachingPath ? (
                <Link to="/learn/home">Switch to Learner View</Link>
            ) : (
                <Link to="/teach/home">Switch to Instructor View</Link>
            )}
        </div>
    );
};

export default SwitchRoleView;