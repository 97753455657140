// Avatar
import imageAdvance from "@images/weitzman/pic-weitzman-advance.png";

const CongratulationsIcon = () => {
    return (
        <div className="Congratulations-img">
            <img src={imageAdvance} alt="Bravo! You’ve Completed a Complex COVID-19 Case!" />
        </div>
    );
}

export default CongratulationsIcon;