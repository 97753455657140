import imgLogo from '@images/common/med2lab_logo.png';
// SidebarMain.js
import React, { useContext } from 'react';
import ThemeContext from "../../../context/ThemeContext";

//components
import SidebarItem from "./SidebarItem";
import Logo from './Logo';

//icon
import { Iconsax } from "@components-common";

const SidebarMakansafe = (props) => {

    //props
    const { isSidebarCollapsed } = props && props;

    //Themes
    const theme = useContext(ThemeContext);
    const primaryLogo = theme?.PrimaryLogo;

    const sidebarItems = [
        {
            icon: <Iconsax iconName="sidebar-home" fill="#697592" />,
            name: 'Home',
            to: '/',
            isHidden: false
        }
    ];

    return (
        <nav className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
            <Logo {...props} primaryLogo={primaryLogo || imgLogo} isSidebarCollapsed={isSidebarCollapsed} />
            <ul className="sidebar-menu">
                {sidebarItems.map((item, index) => (
                    <SidebarItem key={index} item={item} index={index} isSidebarCollapsed={isSidebarCollapsed} />
                ))}
            </ul>
        </nav>
    );
};

export default SidebarMakansafe;