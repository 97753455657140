import React, { useState } from "react";
import { FormModal } from "@components-common/Modal";
import { TitleNoteCommon } from "../../home/generate";
import { useForm, Controller } from "react-hook-form";
import { Button, Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { createNewAnalysis } from '@actions';
import { apiCaller } from "@utils";
import ClipLoader from "react-spinners/ClipLoader";

const ModalCreateNewAnalysis = ({ isOpen, toggleModal, title, dataAnalyze, dataAnalysis, handleGetListAnalyses }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  
  const {
    handleSubmit,
    control,
    watch,
  } = useForm()

  const onSubmit = (data) => {
    const params = {
      Action: "CreateNewAnswerBatchAnalysis",
      QuestionAnswerBatchId: dataAnalysis?.QuestionAnswerBatchId,
      Rubric: data?.rubrics
    }
    setLoading(true);
    dispatch(createNewAnalysis(params))
      .then(response => {
        if (response?.data?.status === 200) {
          apiCaller(`/api/feedback/question/analysis/`, "POST", {
            Action: "ExecuteAnswerBatchAnalysis",
            QuestionAnswerBatchAnalysisId: response.data?.data?.QuestionAnswerBatchAnalysisId,
          }).then((res) => {
            if (res?.status === 200) {
              handleGetListAnalyses();
              toggleModal();
            }
          });
        }
        console.log('Success:', response);
      })
      .catch(error => {
        setLoading(false);
        toggleModal();
      });
  }


	return (
		<FormModal
			isOpen={isOpen}
			toggleModal={toggleModal}
			title={title}
			maxWidth="700px"
			hiddenIcon={true}
			hiddenButtonCancel={true}
			classCustom="modal-create-analysis"
		>
			<form className="form-create-analysis">
        <div className='input-form'>
          <TitleNoteCommon title="Question Text"/>
          <p className="question-text">{dataAnalyze?.Contents?.question}</p>
        </div>
        <div className='input-form'>
          <TitleNoteCommon title="Rubrics" required/>
          <Controller
            name="rubrics"
            control={control}
            render={({ field }) => (
              <Input
                type='textarea'
                name='rubrics'
                placeholder="Enter a new rubric for this analysis here - this rubric will only apply to this analysis only, allowing you to evaluate the effectiveness of different rubrics / prompts on the same answer set"
                style={{ height: '100px' }}
                {...field}
              />
            )}
          />
        </div>
        <div className="d-flex align-items-center justify-content-end">
          <Button 
            color="secondary"
            onClick={toggleModal}
            className="mr-2"
            disabled={loading}
          >
            Back
          </Button>
          <Button
            color="primary"
            className="btn btn-brand --bg-primary ml-2 d-flex align-items-center"
            onClick={handleSubmit(onSubmit)}
            disabled={loading || !watch("rubrics")}
          >
            Analyze
            {loading && (
              <span className="ml-2 d-flex">
                <ClipLoader color="#FFF" size={14}/>
              </span>
            )}
          </Button>
        </div>
      </form>
		</FormModal>
	);
};

export default ModalCreateNewAnalysis;