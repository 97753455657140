import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; //Link

//imgUser
import imgUser from '@images/top/user.svg';

const ProfileHeader = (props) => {
    //props
    const { profileInfo } = props && props;
    const profilePhoto = profileInfo?.profile_photo_url;
    const firstname = profileInfo?.firstname;
    const lastname = profileInfo?.lastname;
    const email = profileInfo?.email;

    //state
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);
    let history = useHistory();

    // Sự kiện click ngoài dropdown
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownVisible(false);
        }
    };

    //Logout
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/makansafe/auth/login");
    }

    useEffect(() => {
        // Thêm event listener khi component mount
        document.addEventListener('click', handleOutsideClick);
        return () => {
            // Gỡ bỏ event listener khi component unmount
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="profile-info">
            <div className="avatar-circle" onMouseEnter={() => setIsDropdownVisible(true)}>
                <div className="avatar-circle__user" style={{ backgroundImage: `url(${profilePhoto || imgUser})` }}></div>
            </div>
            <div
                ref={dropdownRef}
                className={`profile-info__inner ${isDropdownVisible ? 'show-dropdown' : ''}`}
                onMouseEnter={() => setIsDropdownVisible(true)}
                onMouseLeave={() => setIsDropdownVisible(false)}
            >
                <div className="profile-lg-top">
                    <div className="profile-lg-top__left">
                        <div className="avatar-circle">
                            <div className="avatar-circle__user" style={{ backgroundImage: `url(${profilePhoto || imgUser})` }}></div>
                            <span className="online-green"></span>
                        </div>
                    </div>
                    <div className="profile-lg-top__right d-flex flex-column">
                        <p className="profile-name">{firstname + " " + lastname || ""}</p>
                        <p className="profile-email">{email}</p>
                    </div>
                </div>
                <ul className="nav-profile">
                    <li className="nav-profile__item logout-item">
                        <span className="nav-profile__item--link" onClick={handleLogout} style={{ cursor: "pointer" }}>
                            Logout
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default ProfileHeader;