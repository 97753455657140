import React, { useState } from 'react';
import { Button, Row, Col } from "reactstrap";
import LinesEllipsis from "react-lines-ellipsis";
import { ModalAddTemplate } from '../templates';
import { atcDeleteCollectionId } from "@actions";
import { useDispatch } from "react-redux";
import { ModalDeleteCommon } from '@components-common/Modal';

function ButtonTemplateToCollection(props) {
    const { itemCollection, handleReloadTemplate } = props;
    const dispatch = useDispatch();

    const [modal, setModal] = useState({
        add: false,
        delete: false
    });

    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    const handleRemove = () => {
        const params = {
            Action: "DeleteCollection",
            TemplateCollectionId: parseFloat(itemCollection?.TemplateCollectionId)
        }
        dispatch(atcDeleteCollectionId(params));
        handleReloadTemplate();
        toggle("delete");
    }

    return (
        <React.Fragment>
            <ModalAddTemplate
                isOpen={modal.add}
                toggle={() => toggle('add')}
                {...props}
            />
            <ModalDeleteCommon
                {...props}
                title={`Are you sure you want to delete this collection ${itemCollection?.TemplateCollectionName}?`}
                sub={`The templates will not be deleted, <br/> but will be removed from the collection`}
                isOpen={modal.delete}
                toggle={() => toggle('delete')}
                handleRemove={handleRemove}
            />
            <Row className="d-flex align-items-center">
                <Col md={7} sm={12}>
                    <h5 className="heading-5 font-weight-600">
                        <LinesEllipsis
                            text={itemCollection?.TemplateCollectionName || ""}
                            maxLine="2"
                            ellipsis="..."
                            trimRight
                            basedOn="words"
                        />
                    </h5>
                </Col>
                <Col md={5} sm={12} className="d-flex align-items-center justify-content-end">
                    <Button
                        className="btn-style2 btn-outline-danger d-inline-block mr-2"
                        onClick={() => toggle("delete")}
                    >
                        <i className="fa fa-times" aria-hidden="true"></i> Delete Collection
                    </Button>
                    <Button
                        className="btn-style2 btn-outline-brand d-inline-block"
                        onClick={() => toggle("add")}
                    >
                        <i className="fa fa-plus mr-2" aria-hidden="true" /> Add Template
                    </Button>
                </Col>
            </Row>
            <hr />
        </React.Fragment>
    );
};

export default ButtonTemplateToCollection;