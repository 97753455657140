import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

//components
import { TableMcqBank, TableMcqBankOrg } from "@components/teachV2/teachMcqBank";
import { getQuestionBank } from "@actions";

const WrapMcqBank = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const listQuestionBank = useSelector((state) => state.getQuestionBank || []);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  
  const tabsOption = [
    { id: 1, key: "my-references", label: "My Question Bank" },
    { id: 2, key: "org-references", label: "Organization Question Bank" },
  ] 

  useEffect(() => {
    dispatch(getQuestionBank())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="wrapper -ReferenceLiblaryPage p-0">
      <div className='mcq-bank__tabs'>
        {tabsOption?.map((tab, index) => (
          <div 
            key={index}
            className={`tab-detail ${tab.id === activeTab ? "tab-active" : ""}`} 
            onClick={() => handleTabChange(tab.id)}
          >
            <p>{tab?.label}</p>
          </div>
        ))}
      </div>
      <div>
        {activeTab === 1 && (
          <TableMcqBank 
            loadDataMcqBank={listQuestionBank?.data || []}
          />
        )}
        {activeTab === 2 && (
          <TableMcqBankOrg />
        )}
      </div>
    </div>
  )
}

export default WrapMcqBank
