// AiGenerateButton.js
import React from 'react';
import { Iconsax } from '@components-common';

const AiGenerateButton = ({ handleClickBtn, isGenerating }) => {
    const handleClick = () => {
        if (!isGenerating) { // Kiểm tra xem không có quá trình tạo hoặc tải
            handleClickBtn(); // Gọi hàm xử lý khi nhấp vào nút
        }
    };

    return (
        <div className="btn-ai-generate" onClick={handleClick}>
            <div className="btn-ai-generate__icon">
                <Iconsax iconName="ai-generate" fill="#F79E1C" size={24} />
            </div>
            <div className="btn-ai-generate__text">AI Generate{isGenerating && <span className="loading-dots"></span>}</div>
        </div>
    );
};

export default AiGenerateButton;