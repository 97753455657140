import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import imgPdf from "@images/common/pdf.svg";
import imgHtml from "@images/common/html.svg";
import { Row, Col, Button } from "reactstrap";

//router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//call Action
import { atcDeleteReference } from "@actions";

//components
import { ModalAddreferences } from "../references";
import { ModalDeleteCommon } from "@components-common/Modal";

import { ButtonDelete, ButtonEdit } from '@components-common/elements';

function ReferencesList(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { data } = props && props;
  const [itemSort, setItemSort] = useState((data && data) || []);
  const [dataCurrent, setDataCurrent] = useState({});
  const [modal, setModal] = useState({
    add: false,
    edit: false,
    delete: false,
  });

  //Update Array Store
  useEffect(() => {
    setItemSort((data && data) || []);
  }, [data]);

  //Modal
  const toggle = (type, value) => {
    setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    setDataCurrent(value);
  };

  //Remove
  const handleRemove = () => {
    const params = {
      Action: "Delete",
      Target: "Reference",
      ReferenceId: (dataCurrent && dataCurrent.ReferenceId),
    };
    dispatch(atcDeleteReference(params, id));
    setModal((prevState) => ({ ...prevState, delete: false }));
  };

  const SortableItem = SortableElement(({ data, indexItem }) => {
    const typeData = data && data.Type;
    const name = data && data.ReferenceName;
    return (
      <div className="listSort__item">
        <i className="fa fa-ellipsis-v iconDrag" aria-hidden="true"></i>
        {typeData === "pdf" && <img width={32} src={imgPdf} alt="pdf" />}
        {typeData === "html" && <img width={32} src={imgHtml} alt="html" />}
        <h3 className="mx-3">{name}</h3>
        <div className="ml-auto">
          <ButtonEdit handleEdit={() => toggle("edit", data)} />
          <ButtonDelete handleDelete={() => toggle("delete", data)} />
        </div>
      </div>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div className="listSort">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            indexItem={index}
            data={value}
          />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setItemSort(arrayMove(itemSort, oldIndex, newIndex));
  };
  
  return (
    <div>
      <Row>
        <Col className="text-right" sm={12}>
          <Button
            type="submit"
            className="--btn-primary m-3"
            name="add"
            onClick={() => toggle("add", {})}
          >
            <i className="fa fa-plus mr-2"></i>
            Add Reference
          </Button>
        </Col>
      </Row>
      <SortableList
        lockAxis="y"
        helperClass="SortableDiv"
        distance={1}
        items={itemSort}
        onSortEnd={onSortEnd}
      />
      <ModalAddreferences isOpen={modal.add} type={'add'} toggle={() => toggle("add", {})} />
      <ModalAddreferences 
        isOpen={modal.edit}
        type={'edit'}
        toggle={() => toggle("edit", {})}
        dataCurrent={dataCurrent}
      />
      <ModalDeleteCommon
        title={`Are you sure you want to delete this reference?`}
        sub={`This action is not reversible. The reference will be permanently deleted from the system.`}
        isOpen={modal.delete}
        toggle={() => toggle("delete", dataCurrent)}
        handleRemove={handleRemove}
      />
    </div>
  );
}

export default ReferencesList;
