import React from 'react';
import { MainContentContainer } from "@components/layouts";

const MainContentIner = ({ isLearner, isLockCase, children, ...props }) => {
    if (isLearner) {
        const className = isLockCase ? 'position-relative overflow-hidden' : '';
        const style = isLockCase ? { height: 'calc(100vh - 70px)' } : {};

        return (
            <div
                id="main-learner-view"
                className={className}
                style={style}
            >
                {children}
            </div>
        );
    }

    return (
        <MainContentContainer {...props}>
            {children}
        </MainContentContainer>
    );
};

export default MainContentIner;