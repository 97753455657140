import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//boostrap
import {
    FormGroup,
    Label,
    Input,
    Col,
    Button,
    FormFeedback,
    Row,
} from "reactstrap";
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

//actions
import { atcUpdateFeedbackTemplate } from "@actions";

function UpdateEndingTimePoint(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const editor = useRef(null);

    //state
    const [stateMessage, setMessage] = useState("");
    
    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
    } = useForm();

    const onSubmit = (data) => {
        const params = {
            Action: "Create",
            Target: "FeedbackTemplate",
            Name: data.Name,
            Title: data.Title,
            Message: stateMessage,
        };
        dispatch(atcUpdateFeedbackTemplate(id, params));
    };

    return (
        <Row>
            <Col sm={6}>
                <FormGroup>
                    <Label for="Name">
                        Name: <span className="m--font-danger">*</span>
                    </Label>
                    <Input
                        type="text"
                        name="Name"
                        {...register("Name", { required: true })}
                        invalid={errors.Name?.type === "required"}
                        onChange={(e) =>
                            setValue("Name", e.target.value, { shouldValidate: false })
                        }
                        defaultValue={watch("Name")}
                        placeholder=""
                        autoComplete="off"
                    />
                    <FormFeedback invalid={String(errors.Name?.type === "required")}>
                        This is required.
                    </FormFeedback>
                </FormGroup>
            </Col>
            <Col sm={6}>
                <FormGroup>
                    <Label for="Title">
                        Title: <span className="m--font-danger">*</span>
                    </Label>
                    <Input
                        type="text"
                        name="Title"
                        {...register("Title", { required: true })}
                        invalid={errors.Title?.type === "required"}
                        onChange={(e) =>
                            setValue("Title", e.target.value, { shouldValidate: false })
                        }
                        defaultValue={watch("Title")}
                        placeholder=""
                        autoComplete="off"
                    />
                    <FormFeedback invalid={String(errors.Title?.type === "required")}>
                        This is required.
                    </FormFeedback>
                </FormGroup>
            </Col>
            <Col sm={12}>
                <FormGroup>
                    <Label for="FeedbackText">Feedback Text:</Label>
                    <JoditEditor
                        ref={editor}
                        value={stateMessage || ""}
                        config={Config.CONFIG_JODIT}
                        tabIndex={1}
                        onBlur={(newContent) => setMessage(newContent)}
                    />
                </FormGroup>
                <div className="d-flex">
                    <Button
                        type="submit"
                        className="btn btn-brand --bg-primary ml-auto"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Add
                    </Button>
                </div>
            </Col>
        </Row>
    );
}

export default UpdateEndingTimePoint;