import React, { useState, useEffect } from 'react';
import { StepByStep } from "@components/common/step";

//lodash
import _ from "lodash";

//Validate Form 
import { useForm } from "react-hook-form";

//react-router-dom
import { useHistory } from "react-router-dom";

//boostrap
import {
    Container,
    Row,
    Col,
    Alert,
    FormGroup,
    Label,
    Input
} from 'reactstrap';

function Second(props) {
    const { params, isLoading, hanldeNextStep, stepData } = props && props;
    let history = useHistory();
    const [stateParams, setParams] = useState(params || {});
    const [stateItem, setItem] = useState({});

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
        reset
    } = useForm();

    //Set default
    useEffect(() => {
        let defaultValues = {};
        defaultValues.TemplateId = params?.TemplateId;
        reset({ ...defaultValues });
    }, [params, reset]);

    // Load Params
    useEffect(() => {
        setParams(params || {});
    }, [params])

    const onSubmit = (data) => {
        let cloneData = _.cloneDeep(stateParams);
        cloneData[`TemplateId`] = data?.TemplateId;
        hanldeNextStep(cloneData, "step3");
    };

    const prevStep = e => {
        e.preventDefault();
        history.push(`/teach/new/step1`);
    };

    return (
        <React.Fragment>
            <StepByStep stepActive={2} data={stepData?.addCase} />
            <Container>
                <section className="formCt boxPortlet">
                    <div className="m-subheader py-0 mb-3">
                        <div className="d-flex align-items-center">
                            <div className="mr-auto">
                                <h5 className="m-subheader__title">Create New Case</h5>
                            </div>
                        </div>
                    </div>
                    <Alert className="m-alert--default mb-3 border-0" color="secondary">
                        The following templates can help you quickly building your case.
                    </Alert>
                    <Row className="mt-5">
                        <Col md={6}>
                            <FormGroup tag="fieldset">
                                <Label className="labeForm" for="Age">Select a template  <span className="m--font-danger">*</span></Label>
                                {params?.AvailableTemplates?.map((item, index) =>
                                    <FormGroup check className="mb-2" key={index}>
                                        <Label check>
                                            <Input
                                                {...register("TemplateId", { required: true })}
                                                type="radio"
                                                checked={item?.TemplateId === watch("TemplateId") ? true : false}
                                                value={watch("TemplateId")}
                                                name="TemplateId"
                                                onChange={(e) => {
                                                    setValue("TemplateId", item?.TemplateId, { shouldValidate: true });
                                                    setItem(item);
                                                }}
                                            />{item.Name}
                                        </Label>
                                    </FormGroup>
                                )}
                                {errors.TemplateId?.type === "required" && <div invalid="true" class="invalid-feedback d-block">This is required.</div>}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            {stateItem?.Photo &&
                                <div className="template_photo d-flex flex-wrap">
                                    <div className="text-center">
                                        <img className="img-fluid" src={stateItem?.Photo} alt="#" />
                                        <h3 className="mt-3 text-center">
                                            {stateItem?.Description}
                                        </h3>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex justify-content-center mt-5">
                                <a href="# " onClick={prevStep} className="btn btn-secondary">&lt; Back</a>
                                <button
                                    type="button"
                                    disabled={isLoading}
                                    onClick={handleSubmit(onSubmit)}
                                    className="btn btn-brand ml-1 --bg-primary"
                                    id="button_next"
                                >
                                    <div className="d-flex align-items-center">
                                        {isLoading &&
                                            <span className="spinner-border spinner-border-sm mr-2"></span>
                                        }
                                        Next &gt;
                                    </div>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </React.Fragment>
    );
}

export default Second;