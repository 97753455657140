import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

//beta component
import { BetaComponent } from '@components/common/Beta';

const SidebarItemMain = ({ item, isActive, isSidebarCollapsed, handleMainItemClick, index }) => {
    const tooltipId = `tooltip-${index}-${item.name.replace(/\s+/g, '-').toLowerCase()}`;
    const isBeta = item?.isBeta;

    return (
        <Link
            to={item?.to || `#`}
            data-tip={isSidebarCollapsed ? item.name : undefined}
            data-for={isSidebarCollapsed ? tooltipId : undefined}
            className={`sidebar-menu__item--link d-flex align-items-center ${isActive ? 'active' : 'not-active'}`}
            onClick={handleMainItemClick}
        >
            {React.cloneElement(item.icon, {
                color: isActive ? '#ffffff' : "#697586",
            })}
            {!isSidebarCollapsed && (
                <span className={`sidebar-menu__item--name`}>{item.name}</span>
            )}
            {isBeta && !isSidebarCollapsed &&
                <div className="ml-auto">
                    <BetaComponent fontSize={10} />
                </div>
            }
            {isSidebarCollapsed && (
                <ReactTooltip id={tooltipId} className='tooltip-bar' effect="solid" place="right">
                    {item.name}
                </ReactTooltip>
            )}
        </Link>
    );
};

export default SidebarItemMain;