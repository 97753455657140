import React, { useState } from 'react';

//reactstrap
import { Input } from "reactstrap";

//Icon
import { Iconsax } from "@components-common";

//redux
import { useSelector } from "react-redux";

//react-responsive
import { useMediaQuery } from 'react-responsive';

//components
import { SendIcon } from "../FreeText";

function InputFreeText(props) {
  const { handleQuestionTypePatient, disabled, caseData, valueOptions } = props && props;
  const TimePointId = caseData?.data?.Case?.Timeline?.CurrentTimePointId;
  // Interface Template
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const VirtualPreceptor = InterfaceTemplateDetails?.VirtualPatient;
  const patient_reply = VirtualPreceptor?.patient_reply?.Text || "Enter your question to the patient ...";

  // Check Lock Elements
  const isChatPending = useSelector((state) => state.isChatPending || {});
  const isLock = isChatPending?.isLock;

  //state
  const [value, setValue] = useState('');

  //send Api
  const handleSubmit = () => {
    const params = {
      "Action": "Submit",
      "Message": value || valueOptions,
      "TimePointId": TimePointId
    }
    if (value || valueOptions) {
      handleQuestionTypePatient(params);
      setValue('');
    }
  }

  //handlechange
  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
  }

  //Handle Key Press
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  }

  // Check if the device is mobile
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <div className="chatFooter mt-3">
      <div className="position-relative w-100">
        <Input
          name="answer"
          placeholder={patient_reply}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={valueOptions || value}
          disabled={disabled || isLock}
          autoComplete="off"
        />
        {isMobile && <SendIcon onClick={handleSubmit} />}
      </div>
      <button
        type="button"
        className="btn-send ml-2"
        disabled={disabled || isLock}
        onClick={handleSubmit}
      >
        <span className="mr-2">Send</span>  <Iconsax iconName="send" fill="#fff" />
      </button>
    </div>
  );
};

export default InputFreeText;