import React from 'react'
import { Icon } from '@components/common'
import info from '@images/teachV2/info.svg'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip';

const WrapNote = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  p {
    color: #1D2939;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    span {
      color: red;
    }
  }
  .custom-tooltip {
    padding: 8px 12px;
    width: 270px;
    border-radius: 8px;
    &.custom-width {
      width: 340px;
    }
    div {
      text-align: start;
    }
    span {
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
    }
  }
`

const TitleNoteCommon = (props) => {
  const { title, content, dataFor, required, slides } = props
  return (
    <WrapNote>
      <p>
        {title}
        {required && <span>*</span>}
      </p>
      {content && (
        <>
          <div data-tip data-for={dataFor}>
            <Icon src={info} />
          </div>
          <ReactTooltip id={dataFor} place='top' effect='solid' className={`${slides && "custom-width"} custom-tooltip`}>
            {slides ? (
              <div>
                Example: “Create 15 slides on ‘Cardiovascular System’ for first-year med students.” <br />
                Tips:
                <ul>
                  <li>- Specify number of slides needed</li>
                  <li>- Specify topic</li>
                  <li>- Specify target audience</li>
                  <li>- Set content depth</li>
                </ul>
              </div>
            ) : (
              <span>{content}</span>
            )}
          </ReactTooltip>
        </>
      )}
    </WrapNote>
  )
}

export default TitleNoteCommon
