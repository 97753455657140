import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import LoaderPage from "@utils/LoaderPage";
import { StandardScreenMain } from "@components/learner";
import { CaseAccessMessage } from "@components/layouts";
import { apiCaller } from "@utils";
import { caseConstants } from "@constants";

function MainLearnerPage(props) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  // State
  const [isLoading, setLoading] = useState(true);
  const [isLockCase, setIsLockCase] = useState(false);
  const pingIntervalRef = useRef();

  // Lấy dữ liệu từ Redux store
  const caseData = useSelector((state) => state.cases || []);
  const dataResponse = caseData?.data;

  // Hàm lưu ClinicalStatus vào localStorage
  const handleClinicalStatus = (ClinicalStatus, TimePointNum) => {
    ClinicalStatus[`TimePointNum`] = TimePointNum;
    const ClinicalStatusLocalGet = localStorage.getItem("ClinicalStatusLocal");
    const jsonClinicalStatusLocalGet = JSON.parse(ClinicalStatusLocalGet);
    let ClinicalStatusLocal = [];

    if (jsonClinicalStatusLocalGet) {
      ClinicalStatusLocal = [...jsonClinicalStatusLocalGet];
    }
    ClinicalStatusLocal.push(ClinicalStatus);

    const uniqueObjArray = [
      ...new Map(ClinicalStatusLocal.map((item) => [item["TimePointNum"], item])).values(),
    ];

    localStorage.setItem("ClinicalStatusLocal", JSON.stringify(uniqueObjArray));
  };

  // Hàm lưu dữ liệu khác vào localStorage
  const setDatalocalStorage = (data, nameLocal) => {
    const uniqueObjArray = [
      ...new Map(data?.map((item) => [item["TimePointNum"], item])).values(),
    ];
    localStorage.setItem(nameLocal, JSON.stringify(uniqueObjArray));
  };

  // Hàm gọi API và tải case
  const fetchCase = async () => {
    setLoading(true);
    try {
      dispatch({ type: caseConstants.CASE_REQUEST, isLoading: true });
      const res = await apiCaller(`/api/learn/case/${id}/`, 'GET', null);
      const data = res?.data;

      if (res?.status === 200 && data?.Case) {
        const { CurrentTask, Timeline, ClinicalStatus } = data?.Case;
        const TimePointNum = Timeline?.TimePointNum;

        // Xử lý ClinicalStatus và lưu trữ vào localStorage
        handleClinicalStatus(ClinicalStatus, TimePointNum);

        // Xử lý PriorDocuments
        const PriorDocumentsStore = data?.Case?.PriorDocuments;
        const PriorDocumentLocalGet = localStorage.getItem("PriorDocumentsLocal");
        const jsonPriorDocumentLocalGet = JSON.parse(PriorDocumentLocalGet);
        let PriorDocumentsClone = [];
        if (jsonPriorDocumentLocalGet) {
          PriorDocumentsClone = [...jsonPriorDocumentLocalGet];
          PriorDocumentsClone.push({ PriorDocumentsStore, TimePointNum });
          setDatalocalStorage(PriorDocumentsClone, "PriorDocumentsLocal");
        } else {
          PriorDocumentsClone.push({ PriorDocumentsStore, TimePointNum });
          setDatalocalStorage(PriorDocumentsClone, "PriorDocumentsLocal");
        }

        dispatch({
          type: caseConstants.CASE_SUCCESS,
          payload: data,
          isLoading: false,
        });

        sessionStorage.setItem("CurrentTask", CurrentTask);
        setIsLockCase(false);
      } else {
        // Xử lý lỗi khi không tìm thấy case
        if (res?.data?.error?.includes("Cannot locate the case") || res?.data?.error?.includes("not_authorized")) {
          setIsLockCase(true);
        }
        dispatch({
          type: caseConstants.CASE_FAILURE,
          isLoading: false,
          ...res?.data,
        });
      }
    } catch (error) {
      console.error("Error fetching case data:", error);
    } finally {
      setLoading(false); // Dừng loading dù có lỗi hay không
    }
  };

  // Đảm bảo Case được load đầu tiên khi back hoặc load page này
  useEffect(() => {
    // Gọi fetchCase lần đầu khi vào trang
    fetchCase();
  
    // Lắng nghe sự kiện "back" và gọi lại API nếu người dùng quay lại trang
    const unlisten = history.listen((location, action) => {
      if (action === "POP") {
        fetchCase(); // Gọi lại API khi người dùng back về trang
      }
    });
  
    return () => {
      unlisten(); // Dọn dẹp sự kiện
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, history]);  

  // Ping API mỗi 60 giây nếu có dataResponse
  useEffect(() => {
    if (dataResponse) {
      const pingCase = () => {
        apiCaller(`/api/learn/case/ping/${id}/`, 'GET', null).then((res) => {
          if (res?.status === 200) {
            console.log("Ping success");
          } else {
            console.log("Ping error");
          }
        });
      };

      pingIntervalRef.current = setInterval(pingCase, 60000);

      return () => {
        clearInterval(pingIntervalRef.current);
      };
    }
  }, [dataResponse, id]);

  // Hàm dừng việc gọi API ping
  const handleStopApiSpendTime = () => {
    clearInterval(pingIntervalRef.current);
  };

  return (
    <React.Fragment key={id}>
      {isLoading && <LoaderPage />}
      {isLockCase && <CaseAccessMessage />}
      {!isLockCase && !isLoading && (
        <StandardScreenMain
          {...props}
          caseData={caseData || {}}
          handleStopApiSpendTime={handleStopApiSpendTime}
        />
      )}
    </React.Fragment>
  );
}

export default React.memo(MainLearnerPage);