import React, { useState } from 'react';

//boostrap
import { Button } from "reactstrap";

//components
import { ModalViewMedia } from '.';

function ViewMediaButton(props) {

    //state
    const [modal, setModal] = useState({
        viewMedia: false
    });

    const toggle = (type) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
    };

    return (
        <React.Fragment>
            <Button
                className="btn btn-brand mt-2 text-1"
                onClick={() => toggle("viewMedia")}
                color="primary"
            >
                <i className="fa fa-eye mr-2" aria-hidden="true"></i>View Media
            </Button>
            <ModalViewMedia
                {...props}
                isOpen={modal?.viewMedia}
                toggle={() => toggle("viewMedia")}
            />
        </React.Fragment>
    );
};

export default ViewMediaButton;