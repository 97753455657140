import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { CongratulationsBox } from "../Advance";
import { advanceConstants } from "@constants";

function InteractAdvanceCompleted(props) {
  const { isOpen, hanldeToggleAdvance, handleStopApiSpendTime } = props || {};
  let { id } = useParams();
  const dispatch = useDispatch();
  const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
  const [isLoading, setLoading] = useState(false);

  const handleCaseCompleted = () => {
    let params = { "CaseId": id }
    setLoading((prevState) => ({ ...prevState, completedCase: true }));
    dispatch({ type: advanceConstants.CASE_COMPLETE_REQUEST, isLoading: true });
    apiCaller(`/api/learn/case/complete/`, 'POST', params).then(res => {
      const data = res?.data;
      if (data?.status === "success" && data?.LearnerSessionId) {
        dispatch({ type: advanceConstants.CASE_COMPLETE_SUCCESS, payload: data });
        setLoading((prevState) => ({ ...prevState, completedCase: false }));
        hanldeToggleAdvance("compeletedTimepoint");
        handleStopApiSpendTime();
        hanldeToggleAdvance("infoCompeleted");
      } else {
        setLoading((prevState) => ({ ...prevState, completedCase: false }));
        dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: "error" });
        handleStopApiSpendTime();
      }
    });
  }

  const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;

  // Chuyển đổi HTML
  const convertToHTML = (text) => ({ __html: text });

  return (
    <Modal
      className="modal-custom"
      zIndex={16000}
      isOpen={isOpen}
      backdrop={`static`}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
    >
      <ModalBody>
        <CongratulationsBox {...props} />
      </ModalBody>
      <ModalFooter>
        <div className="mx-auto" style={{ width: "276px" }}>
          <Button
            color='primary'
            className="btn-brand-sm -btn-lg w-100 -shadown"
            onClick={() => handleCaseCompleted()}
            disabled={isLoading.completedCase}
          >
            {isLoading.completedCase && <span className="spinner-border spinner-border-sm mr-2"></span>}
            <span dangerouslySetInnerHTML={convertToHTML(ModalCaseCompleted?.m_complete_button_confirm?.Text || "Proceed to Feedback")} />
          </Button>
          <Button
            color='white'
            className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
            onClick={() => hanldeToggleAdvance("compeletedTimepoint")}
            disabled={isLoading.completedCase}
          >
            <span dangerouslySetInnerHTML={convertToHTML(ModalCaseCompleted?.m_complete_button_cancel?.Text || "Stay at this Timepoint")} />
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default InteractAdvanceCompleted;