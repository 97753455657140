import React from 'react';
import styled from 'styled-components';

//imgUser
import MessageImage from '@images/message/message_case_access.png';
import { Link } from 'react-router-dom';

const FullScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
`;
const ContentWrapper = styled.div`
  max-width: 500px;
  text-align: center;
  margin: 24px 0;
`;

const ErrorMessageTitle = styled.h3`
  color: #121926;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 10px;
`;

const ErrorMessageText = styled.p`
  color: #697586;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

const ImageContainer = styled.div`
  max-width: 194px;
  margin: 0 auto 16px; /* Căn giữa và margin dưới */
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: contain; /* Đảm bảo ảnh không bị kéo dãn ra ngoài khung */
`;

const CaseAccessMessage = (props) => {
    return (
        <FullScreenContainer>
            <ContentWrapper>
                <ImageContainer>
                    <Image src={MessageImage} alt="Access Denied Image" />
                </ImageContainer>
                <ErrorMessageTitle>Oops! Access Denied</ErrorMessageTitle>
                <ErrorMessageText>We're sorry, but it looks like you don't have permission to access this interactive case. Please click the button below to return to the Home Page.</ErrorMessageText>
                <Link
                    className='mt-5 mx-auto btn btn-primary btn-lg d-flex align-items-center justify-content-center'
                    style={{ maxWidth: '353px'}}
                    to={`/`}
                >
                    Return to Home
                </Link>
            </ContentWrapper>
        </FullScreenContainer>
    );
};

export default CaseAccessMessage;