import { instructorConstants, seriesConstants } from "@constants";

//lodash
import _ from "lodash";

//Update Number Of Cases
function updateNumberOfCase(state, totalCase, seriesId) {
    let seriesUpdate = _.cloneDeep(state?.data?.Series);
    _.forEach(seriesUpdate, function (item) {
        if (parseFloat(item?.Id) === parseFloat(seriesId)) {
            item.NumberOfCases = totalCase;
        }
    });
    return seriesUpdate;
}

export function instructor(state = {}, action) {
    var newState = {};
    let seriesId = action?.seriesId;
    switch (action.type) {
        case instructorConstants.INSTRUCTOR_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case instructorConstants.SERIES_UPDATE_SETTINGS_SUCCESS:
            newState = { ...state };
            const listDataClone = _.cloneDeep(newState?.data?.Series);
            const indexSeriesCurrent = _.findIndex(listDataClone, function (o) { return o.Id === action?.payload?.SeriesId; });
            if (indexSeriesCurrent >= 0) {
                listDataClone[indexSeriesCurrent][`Name`] = action?.payload?.Name
                newState.data.Series = listDataClone;
            }
            return {
                ...newState,
                isLoading: false
            };
        case instructorConstants.INSTRUCTOR_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case instructorConstants.ADD_CASE_TO_SERIES_SUCCESS:
            newState = { ...state };
            const totalCaseAdd = action?.payload?.Cases?.length;
            const newDataForAdd = updateNumberOfCase(state, totalCaseAdd, seriesId);
            newState.data.Series = newDataForAdd;
            return {
                ...newState,
                isLoading: false
            };
        case seriesConstants.DELETE_CASE_FROM_SERIES_SUCCESS:
            newState = { ...state };
            const totalCaseDelete = action?.payload?.Cases?.length;
            const newDataForDel = updateNumberOfCase(state, totalCaseDelete, seriesId);
            newState.data.Series = newDataForDel;
            return {
                ...newState,
                isLoading: false
            };
        case seriesConstants.CREATE_SERIES_SUCCESS:
            newState = { ...state };
            newState.data.Series = action.payload;
            return {
                ...newState,
                isLoading: false
            };
        case seriesConstants.DELETE_SERIES_SUCCESS:
            newState = { ...state };
            newState.data.Series = action.payload;
            return {
                ...newState,
                isLoading: false
            };
        case instructorConstants.FILTER_CASE_SUCCESS:
            var stateFilter = { ...state };
            stateFilter.data.Cases = action.payload && action.payload.Cases;
            return {
                ...stateFilter,
                isLoading: false,
                seriesId: action.seriesId
            };
        case instructorConstants.INSTRUCTOR_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function accountInstitution(state = {}, action) {
    var newState = {};
    switch (action.type) {
        case instructorConstants.ACCOUNT_INSTITUTION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case instructorConstants.ACCOUNT_INSTITUTION_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case instructorConstants.ADD_USER_ACCOUNT_SUCCESS:
            newState = { ...state };
            newState.data.Accounts = action.payload && action.payload.Accounts
            newState.data.Subscription = action.payload && action.payload.Subscription
            return {
                ...newState,
                data: action.payload,
                isLoading: false
            };
        case instructorConstants.ADD_CASE_TO_SERIES_REQUEST:
            newState = { ...state };
            return {
                ...newState,
                isLoading: false
            };
        case instructorConstants.IMPORT_EXEL_ACCOUNT_CONFIRM_SUCCESS:
            newState = { ...state };
            newState.data.Accounts = action.payload && action.payload.Accounts
            newState.data.Subscription = action.payload && action.payload.Subscription
            return {
                ...newState,
                data: action.payload
            };
        case instructorConstants.REMOVE_USER_ACCOUNT_SUCCESS:
            newState = { ...state };
            newState.data.Accounts = action.payload && action.payload.Accounts
            newState.data.Subscription = action.payload && action.payload.Subscription
            return {
                ...newState,
                data: action.payload,
                isLoading: false
            };
        case instructorConstants.SET_ACCOUNT_PRIVILEGE_SUCCESS:
            newState = { ...state };
            if (action.payload) {
                const clistAccount = newState.data && newState.data.Accounts;
                const idAccount = action.payload && action.payload[0] && action.payload[0].Id
                const indexAcc = _.findIndex(clistAccount, function (o) { return o.Id === idAccount; });
                clistAccount[indexAcc] = action.payload && action.payload[0];
            }
            return {
                ...newState,
                isLoading: false
            };
        case instructorConstants.SET_ACCOUNT_STATUS_PRIVILEGE_SUCCESS:
            newState = { ...state };
            if (action.payload) {
                const clistAccount = newState.data && newState.data.Accounts;
                const idAccount = action.payload && action.payload[0] && action.payload[0].Id
                const indexAcc = _.findIndex(clistAccount, function (o) { return o.Id === idAccount; });
                clistAccount[indexAcc] = action.payload && action.payload[0];
            }
            return {
                ...newState,
                isLoading: false
            };
        case instructorConstants.ACCOUNT_INSTITUTION_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadInstitutionAllCases(state = {}, action) {
    switch (action.type) {
        case instructorConstants.LOAD_INSTITUTION_ALL_CASES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case instructorConstants.LOAD_INSTITUTION_ALL_CASES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case instructorConstants.LOAD_INSTITUTION_ALL_CASES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};


export function seriesLoadById(state = {}, action) {
    switch (action.type) {
        case instructorConstants.FILTER_CASE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case instructorConstants.FILTER_CASE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                seriesId: action.seriesId
            };
        case seriesConstants.DELETE_CASE_FROM_SERIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                seriesId: action.seriesId
            };
        case instructorConstants.ADD_CASE_TO_SERIES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                seriesId: action.seriesId
            };
        case seriesConstants.SERIES_ADD_MANAGERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                seriesId: action.seriesId
            };
        case seriesConstants.SERIES_REMOVE_MANAGERS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
                seriesId: action.seriesId
            };
        case instructorConstants.SERIES_UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case instructorConstants.FILTER_CASE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function loadCaseBySeriesId(state = {}, action) {
    switch (action.type) {
        case instructorConstants.LOAD_INSTITUTION_ALL_CASES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case instructorConstants.LOAD_INSTITUTION_ALL_CASES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case instructorConstants.LOAD_INSTITUTION_ALL_CASES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};