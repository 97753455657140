import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ModalFormChatTopic } from '..';
import { ModalDeleteCommon } from "@components-common/Modal";
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";
import { IntakeTable } from '../InteractiveChat/IntakeChatContents';

const IntakeComponent = (props) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    // props
    const { timepointData } = props && props;

    //state
    const [modalOpen, setModalOpen] = useState({
        Update: false,
        Delete: false,
        Create: false
    });
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setLoading] = useState(false);

    const toggleModal = (type, item = null) => {
        setModalOpen(prevState => ({
            ...prevState,
            [type]: !modalOpen[type]
        }));
        item && setSelectedItem(item);
    };

    const handleRemove = () => {
        const params = {
            Action: "Delete",
            Target: "Chat",
            TimePointId: timepointData?.Id,
            TopicId: selectedItem.TopicId,
        };
        setLoading(true);
        dispatch({ type: storyboardConstants.REMOVE_CHAT_REQUEST, isLoading: true });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({
                    type: storyboardConstants.REMOVE_CHAT_SUCCESS,
                    payload: data
                });
                toggleModal('Delete');
                setLoading(false);
            } else {
                dispatch({
                    type: storyboardConstants.REMOVE_CHAT_FAILURE,
                    error: 'error'
                });
                setLoading(false);
                toggleModal('Delete');
            }
        });
    }

    const chatData = useSelector((state) => (state?.loadChat?.data) || []);
    return (
        <div className="mb-4">
            <IntakeTable
                chatData={chatData}
                toggleModal={toggleModal}
                handleAddRow={() => toggleModal('Create')}
            />
            <ModalFormChatTopic
                {...props}
                isOpen={modalOpen.Update || modalOpen.Create}
                toggleModal={() => toggleModal(modalOpen.Update ? "Update" : "Create")}
                selectedItem={selectedItem}
                titleModal={modalOpen.Update ? "Edit chat topic" : "Add chat topic"}
                typeModal={modalOpen.Update ? "Update" : "Create"}
            />
            <ModalDeleteCommon
                {...props}
                isOpen={modalOpen.Delete}
                toggle={() => toggleModal('Delete', {})}
                title={`Delete Topic`}
                sub={`Are you sure that you would like to delete this topic? This action cannot be undone.`}
                handleRemove={handleRemove}
                isLoading={isLoading}
            />
        </div>
    );
};

export default IntakeComponent;