import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from "reactstrap";

//components
import { ColorPicker } from "../Tags";

const ModalAdd = (props) => {
    const { isOpen, toggle } = props && props;
    const [stateColor, setColor] = useState({
        hex: '#1a1a1a'
    });

    //state
    // const [isLoading, setLoading] = useState(false);

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
        setValue
    } = useForm();

    const onSubmit = (data) => {
        console.log(data, "data....");
    }

    const handleChangeComplete = (color, event) => {
        setColor(color);
    }

    console.log(stateColor, "stateColor...");
    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add tag</ModalHeader>
                <ModalBody>
                    <FormGroup className="mt-2">
                        <Label for="Name">Name <span className="m--font-danger">*</span></Label>
                        <Input
                            type="text"
                            name="Name"
                            {...register("Name", { required: true })}
                            invalid={errors.Name?.type === "required"}
                            defaultValue={watch("Name")}
                            onChange={(e) => {
                                setValue("Name", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Name?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup className="mt-2">
                        <Label for="Category">Category <span className="m--font-danger">*</span></Label>
                        <Input
                            type="text"
                            name="Category"
                            {...register("Category", { required: true })}
                            invalid={errors.Category?.type === "required"}
                            defaultValue={watch("Category")}
                            onChange={(e) => {
                                setValue("Category", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback invalid={String(errors.Name?.type === "required")}>
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="Color">Color <span className="m--font-danger">*</span></Label>
                        <ColorPicker
                            handleChangeComplete={handleChangeComplete}
                            stateColor={stateColor}
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="danger"
                        className="btn btn-brand --bg-primary d-flex align-items-center"
                        onClick={handleSubmit(onSubmit)}
                    // disabled={isLoading}
                    >
                        {/* {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>} */}
                        Add
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalAdd;