import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { AssignmentTop } from "../Details";
import { LevelSection } from "../Details/Level";

//Icon
import { Iconsax } from "@components-common";

//components
// import { OtherAssignmentsSection, LeaderboardSection } from "../Details"; //CertificateProcessSection

function AssignmentDetails(props) {

    //props
    const { dataAssignmentDetails, handleClickBackAssignments } = props && props;

    //state
    const [portfolioDetails, setPortfolioDetails] = useState({});

    //Filter Item
    useEffect(() => {
        setPortfolioDetails(dataAssignmentDetails);
    }, [dataAssignmentDetails])

    // const launchPlansList = [
    //     {
    //         "LaunchPlanId": 333,
    //         "LaunchPlanName": "ASME Demo",
    //         "SeriesId": 174,
    //         "StartTime": "2021-10-25T13:48:13+00:00",
    //         "EndTime": "2023-08-28T13:48:13+00:00",
    //         "Series": {
    //             "Id": 174,
    //             "Description": null,
    //             "Name": "ASME Demo",
    //             "Photo": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screenshot_2023-06-11_at_8.03.41_AM.png",
    //             "Progress": null,
    //             "ProgressStatus": {
    //                 "PercentCompleted": 0.25
    //             },
    //             "Cases": [
    //                 {
    //                     "Id": 782,
    //                     "Title": "Simple Demo Case",
    //                     "Thumbnail": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screenshot_2023-06-11_at_8.03.41_AM.png",
    //                     "BrandLogo": "https://med2lab-frontend-media.s3.amazonaws.com/media/logo/med2lab-logo-small.png",
    //                     "Description": null,
    //                     "CaseSettings": null,
    //                     "Progress": {
    //                         "status": "in_progress",
    //                         "last_complete_time": null,
    //                         "start_time": "2023-06-11T13:37:15.677381Z",
    //                         "progress_percent": null
    //                     }
    //                 },
    //                 {
    //                     "Id": 784,
    //                     "Title": "Sample Case #2",
    //                     "Thumbnail": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screenshot_2023-06-11_at_1.24.51_PM.png",
    //                     "BrandLogo": "https://med2lab-frontend-media.s3.amazonaws.com/media/logo/med2lab-logo-small.png",
    //                     "Description": null,
    //                     "CaseSettings": null,
    //                     "Progress": {
    //                         "status": "in_progress",
    //                         "last_complete_time": null,
    //                         "start_time": "2023-07-27T08:49:32.451051Z",
    //                         "progress_percent": null
    //                     }
    //                 }
    //             ]
    //         },
    //         "Institution": null,
    //         "Manager": null
    //     },
    //     {
    //         "LaunchPlanId": 298,
    //         "LaunchPlanName": "Demo for Weitzman Institute",
    //         "SeriesId": 144,
    //         "StartTime": "2021-10-25T13:48:13+00:00",
    //         "EndTime": "2023-08-28T13:48:13+00:00",
    //         "Series": {
    //             "Id": 144,
    //             "Description": null,
    //             "Name": "Weitzman Demo",
    //             "Photo": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screen_Shot_2023-01-18_at_9.19.04_PM.png",
    //             "Progress": null,
    //             "ProgressStatus": {
    //                 "PercentCompleted": 0.44
    //             },
    //             "Cases": [
    //                 {
    //                     "Id": 672,
    //                     "Title": "19-year-old girl with abdominal pain",
    //                     "Thumbnail": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screen_Shot_2023-01-18_at_9.19.04_PM.png",
    //                     "BrandLogo": "https://med2lab-frontend-media.s3.amazonaws.com/media/logo/med2lab-logo-small.png",
    //                     "Description": null,
    //                     "CaseSettings": null,
    //                     "Progress": {
    //                         "status": "in_progress",
    //                         "last_complete_time": null,
    //                         "start_time": "2023-03-24T03:44:16.602521Z",
    //                         "progress_percent": null
    //                     }
    //                 },
    //                 {
    //                     "Id": 459,
    //                     "Title": "7-year-old boy with bruises all over his body",
    //                     "Thumbnail": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screen_Shot_2020-10-22_at_8.04.18_AM.png",
    //                     "BrandLogo": "https://med2lab-frontend-media.s3.amazonaws.com/media/logo/med2lab-logo-small.png",
    //                     "Description": null,
    //                     "CaseSettings": null,
    //                     "Progress": {
    //                         "status": "in_progress",
    //                         "last_complete_time": null,
    //                         "start_time": "2022-12-06T10:17:58.147798Z",
    //                         "progress_percent": null
    //                     }
    //                 },
    //                 {
    //                     "Id": 652,
    //                     "Title": "58-year-old cancer patient who has continued pain",
    //                     "Thumbnail": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screen_Shot_2021-12-23_at_10.16.05_AM.png",
    //                     "BrandLogo": "https://med2lab-frontend-media.s3.amazonaws.com/media/logo/med2lab-logo-small.png",
    //                     "Description": null,
    //                     "CaseSettings": null,
    //                     "Progress": {
    //                         "status": "in_progress",
    //                         "last_complete_time": null,
    //                         "start_time": "2022-09-07T02:06:46.000329Z",
    //                         "progress_percent": null
    //                     }
    //                 },
    //                 {
    //                     "Id": 453,
    //                     "Title": "20-year-old man with abdominal pain",
    //                     "Thumbnail": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screen_Shot_2020-12-01_at_6.25.33_PM.png",
    //                     "BrandLogo": "https://med2lab-frontend-media.s3.amazonaws.com/media/logo/nus_logo.png",
    //                     "Description": null,
    //                     "CaseSettings": null,
    //                     "Progress": {
    //                         "status": "completed",
    //                         "last_complete_time": "2022-08-23T03:20:12.356949Z",
    //                         "start_time": null,
    //                         "progress_percent": null
    //                     }
    //                 }
    //             ]
    //         },
    //         "Institution": null,
    //         "Manager": null
    //     },
    //     {
    //         "LaunchPlanId": 59,
    //         "LaunchPlanName": "Pain Management Demo - Dec 2021",
    //         "SeriesId": 123,
    //         "StartTime": "2021-10-25T13:48:13+00:00",
    //         "EndTime": "2023-08-28T13:48:13+00:00",
    //         "Series": {
    //             "Id": 123,
    //             "Description": null,
    //             "Name": "Pain Management Demo",
    //             "Photo": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screen_Shot_2021-12-21_at_4.52.25_PM.png",
    //             "Progress": null,
    //             "ProgressStatus": {
    //                 "PercentCompleted": 0.25
    //             },
    //             "Cases": [
    //                 {
    //                     "Id": 651,
    //                     "Title": "70-year-old gentleman with a persistent lower back pain",
    //                     "Thumbnail": "https://med2lab-frontend-media.s3.amazonaws.com/media/case_photo/Screen_Shot_2021-12-21_at_4.52.25_PM.png",
    //                     "BrandLogo": "https://med2lab-frontend-media.s3.amazonaws.com/media/logo/med2lab-logo-small.png",
    //                     "Description": null,
    //                     "CaseSettings": null,
    //                     "Progress": {
    //                         "status": "in_progress",
    //                         "last_complete_time": null,
    //                         "start_time": "2022-01-06T04:47:52.733499Z",
    //                         "progress_percent": null
    //                     }
    //                 }
    //             ]
    //         },
    //         "Institution": null,
    //         "Manager": null
    //     }
    // ]

    const LevelsData = portfolioDetails?.LaunchPlanContents?.Series?.Levels;
    return (
        <Container>
            <Row>
                <Col md={12}>
                    <span style={{ cursor: 'pointer' }} onClick={handleClickBackAssignments} className="back-router">
                        <Iconsax iconName="arrow-back" fill="#1C274C" />
                        <span className="back-router__text ml-2">Back</span>
                    </span>
                </Col>
                <Col className="custom-padding-x" md={12}>
                    <AssignmentTop
                        {...props}
                    />
                    {LevelsData?.map((item, index, array) => (
                        <LevelSection
                            key={index}
                            item={item}
                            headingLevel={item?.Name}
                            activePath={true}
                            isLastItem={index === array.length - 1}
                        />
                    ))}
                </Col>
                {/* <Col className="custom-padding-x" md={5}>
                    <CertificateProcessSection />
                    <LeaderboardSection {...props} leaderboardData={dataAssignmentDetails?.Leaderboard || []} />
                    <OtherAssignmentsSection launchPlansList={launchPlansList} />
                </Col> */}
            </Row>
        </Container>
    );
}

export default AssignmentDetails;