import React from 'react';
import { Iconsax } from "@components-common";

const SearchHeader = () => {
    return (
        <div className="serach_field-area mr-3">
            <div className="search_inner">
                <form action="#">
                    <div className="search_field">
                        <Iconsax iconName="search" fill="#697586" size={24} />
                        <input type="text" placeholder="Search" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SearchHeader;