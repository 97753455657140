import React from 'react';
import { Button } from 'reactstrap';
import { AvatarProgressBar } from '../Home';
// import { Link } from 'react-router-dom';

//redux
import { useSelector } from "react-redux";

function OverviewProfile(props) {
    const { firstname, lastname, institution } = props && props;
    const portfolioData = useSelector((state) => state.portfolio || []);
    const AccountDetails = portfolioData?.data?.Account;
    const XPData = AccountDetails?.xp_data;
    const TotalXP = XPData?.TotalXP;
    return (
        <section className="card-area bg-white overview-profile-container position-relative">
            <div className="overview-profile">
                <div className="overview-profile__left">
                    <div className="wrapper-avatar">
                        <AvatarProgressBar {...props} XPData={XPData} />
                    </div>
                </div>
                <div className="overview-profile__right">
                    <div className="profile-home">
                        <h2 className="profile-home__name">{`${firstname} ${lastname}`}</h2>
                        <p className="profile-home__school">{institution}</p>
                    </div>
                    <Button className="btn-xp mt-3">
                        <span className="mr-2">Your XP: <span className="ml-1">{TotalXP}</span></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <path d="M15.9185 12.3201C15.6595 12.5711 15.5405 12.9341 15.5995 13.2901L16.4885 18.2101C16.5635 18.6271 16.3875 19.0491 16.0385 19.2901C15.6965 19.5401 15.2415 19.5701 14.8685 19.3701L10.4395 17.0601C10.2855 16.9781 10.1145 16.9341 9.93951 16.9291H9.66851C9.57451 16.9431 9.48251 16.9731 9.39851 17.0191L4.96851 19.3401C4.74951 19.4501 4.50151 19.4891 4.25851 19.4501C3.66651 19.3381 3.27151 18.7741 3.36851 18.1791L4.25851 13.2591C4.31751 12.9001 4.19851 12.5351 3.93951 12.2801L0.32851 8.78012C0.0265096 8.48712 -0.0784904 8.04712 0.0595096 7.65012C0.19351 7.25412 0.53551 6.96512 0.94851 6.90012L5.91851 6.17912C6.29651 6.14012 6.62851 5.91012 6.79851 5.57012L8.98851 1.08012C9.04051 0.980122 9.10751 0.888122 9.18851 0.810122L9.27851 0.740122C9.32551 0.688122 9.37951 0.645122 9.43951 0.610122L9.54851 0.570122L9.71851 0.500122H10.1395C10.5155 0.539122 10.8465 0.764122 11.0195 1.10012L13.2385 5.57012C13.3985 5.89712 13.7095 6.12412 14.0685 6.17912L19.0385 6.90012C19.4585 6.96012 19.8095 7.25012 19.9485 7.65012C20.0795 8.05112 19.9665 8.49112 19.6585 8.78012L15.9185 12.3201Z" fill="#FAC515" />
                        </svg>
                    </Button>
                </div>
            </div>
            {/* <Link to={`/profile`} className="btn-view-profile ps-btn" style={{pointerEvents: 'none'}}>
                View profile
            </Link> */}
        </section>
    );
}

export default OverviewProfile;