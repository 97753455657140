import React from 'react';
import imgPerson from "@images/common/person.png";

//check Html
import { hasTextInside } from "@utils";

function LoadingMessage(props) {
    const { isStreamDataLoading, contentStream, data, patientNameText } = props || {};
    const Interface = data?.Interface;
    const PatientAvatar = Interface?.PatientAvatar;
    return (
        <div className={`messengerCt__wrapper ${isStreamDataLoading ? "isShow" : ""}`}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={PatientAvatar || imgPerson}
                        alt=""
                    />
                </div>
                <div className="messengerCt__message-body">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username">
                            {/* Kiểm tra nếu có nội dung text trong HTML, sử dụng giá trị đó */}
                            {hasTextInside(patientNameText) ? (
                                <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: patientNameText }} />
                            ) : (
                                // Sử dụng nội dung text trực tiếp
                                <div class="role-chat">Patient:</div>
                            )}
                        </div>
                        <div className="messengerCt__message-text">
                            {contentStream && (
                                <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: contentStream }} />
                            )}
                            <div className="typing-loader ml-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingMessage;