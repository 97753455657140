import React, { useRef } from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormModal } from "@components-common/Modal";
import JoditEditor from "jodit-react";

//Config
import * as Config from "@constants/Config";

const ModalFormAddProblem = ({ isOpen, toggleModal, title, timepointData }) => {
  const formikRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    comment: Yup.string().required("Comment is required"),
  });

  const handleSaveButtonClick = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={title}
      onAction={handleSaveButtonClick}
      actionText={"Save"}
      maxWidth="860px"
    >
      <Formik
        initialValues={{
          name: "",
          comment: timepointData?.DefaultNarrative?.Content,
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          touched,
        }) => (
          <Form className="form-intake">
            <FormGroup>
              <Label className="labeForm" for="name">
                Name <span className="text-danger">*</span>
              </Label>
              <Input
                placeholder="CT - Abdomen ADAD test image"
                type="text"
                name="name"
                id="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                invalid={!!errors.name && touched.name}
              />
              <FormFeedback>{errors.name}</FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label className="labeForm" for="comment">
                Comment
              </Label>
              <JoditEditor
                // ref={editor}
                value={values.comment}
                config={Config.CONFIG_JODIT}
                placeholder={''}
                tabIndex={1}
                onBlur={(newContent, e) => handleBlur(newContent, e)}
                name="comment"
              />
              <FormFeedback>{errors.text}</FormFeedback>
            </FormGroup>
          </Form>
        )}
      </Formik>
    </FormModal>
  );
};

export default ModalFormAddProblem;
