import React, { useState, useCallback } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { apiCaller } from "@utils";
import { advanceConstants } from "@constants";
import { CongratulationsContent } from "../default";
import { ModalResetCase } from '@components/common/ResetAndBack';

function DefaultEndCase(props) {
  const { isOpen } = props || {};
  let history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const handleCaseCompleted = useCallback(async () => {
    setLoading(true); // Set loading to true when API call starts
    let params = { "CaseId": id };
    dispatch({ type: advanceConstants.CASE_COMPLETE_REQUEST, isLoading: true });

    try {
      const res = await apiCaller(`/api/learn/case/complete/`, 'POST', params);

      // Check for 200 OK response
      if (res?.status === 200) {
        const data = res?.data;
        if (data?.status === "success" && data?.LearnerSessionId) {
          dispatch({ type: advanceConstants.CASE_COMPLETE_SUCCESS, payload: data });
          return true; // Return true on success
        } else {
          dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: "error" });
        }
      } else {
        dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: "Failed to complete case" });
      }
    } catch (error) {
      dispatch({ type: advanceConstants.CASE_COMPLETE_FAILURE, error: error.message });
    } finally {
      setLoading(false); // Set loading to false when API call ends
    }

    return false; // Return false if the process did not succeed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  const handleExitCase = async () => {
    // Complete the case and check if it was successful
    const isCompleted = await handleCaseCompleted();

    // Proceed with navigation only if the case was successfully completed
    if (isCompleted) {
      if (ButtonExit?.CaseId) {
        // If CaseId exists, navigate to the next case
        window.location.href = `/learn/case/${ButtonExit.CaseId}`;
      } else {
        // If CaseId doesn't exist, navigate back to the home page
        history.push("/learn/home");
      }
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  // Get data from store
  const caseData = useSelector((state) => state.cases || []);
  const caseResponse = caseData?.data;
  const EndCaseViewDetails = caseResponse?.Case?.EndCaseViewDetails;
  const ButtonExit = EndCaseViewDetails?.ButtonExit;
  const ButtonReset = EndCaseViewDetails?.ButtonReset;

  return (
    <React.Fragment>
      <Modal
        className="modal-custom"
        zIndex={16000}
        isOpen={isOpen}
        backdrop={`static`}
        centered
        backdropClassName="modal-backdrop-custom"
        modalTransition={{ timeout: 700 }}
      >
        <ModalBody>
          {/* Render CongratulationsContent component */}
          <CongratulationsContent {...props} />
        </ModalBody>
        <ModalFooter>
          <div className="mx-auto" style={{ width: "276px" }}>
            {ButtonExit && (
              <Button
                color='white'
                className="btn-brand-sm -btn-lg w-100 -shadown my-2"
                onClick={handleExitCase}
                disabled={loading} // Disable button when loading
              >
                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                <div className='text-white' dangerouslySetInnerHTML={{ __html: ButtonExit?.Label || "Exit and Back to Home" }} />
              </Button>
            )}
            {ButtonReset &&
              <Button
                color='primary'
                className="btn-white-sm my-2 -btn-lg w-100 -shadown"
                onClick={toggle}
              >
                <div dangerouslySetInnerHTML={{ __html: ButtonReset?.Label }} />
              </Button>
            }
          </div>
        </ModalFooter>
      </Modal>
      <ModalResetCase
        {...props}
        isOpen={modal}
        toggle={toggle}
        isView="case"
      />
    </React.Fragment>
  );
}

export default DefaultEndCase;