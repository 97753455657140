import React, { useEffect, useState } from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Alert } from "reactstrap";

//components
import { TableSheet } from "../AssistantMessage";

function ShowBillingSheet(props) {
    //props
    const { isOpen, toggle, caseData } = props && props;
    const resCaseData = caseData?.data;
    const caseDetail = resCaseData?.Case;
    const Documentation = caseDetail?.Documentation;
    const BillingSheet = Documentation?.BillingSheet;
    const OptimalBillingSheet = Documentation?.OptimalBillingSheet;

    //state
    const [stateBillingSheet, setStateBillingSheet] = useState(
        (BillingSheet && BillingSheet) || {}
    );
    const [stateOptimalBillingSheet, setOptimalBillingSheet] = useState(
        (OptimalBillingSheet && OptimalBillingSheet) || {}
    );
    const [showOptinal, setShowOptinal] = useState();

    useEffect(() => {
        const resCaseData = caseData?.data;
        const caseDetail = resCaseData?.Case;
        const Documentation = caseDetail?.Documentation;
        const BillingSheet = Documentation?.BillingSheet;
        const OptimalBillingSheet = Documentation?.OptimalBillingSheet;
        setStateBillingSheet((BillingSheet && BillingSheet) || {});
        setOptimalBillingSheet((OptimalBillingSheet && OptimalBillingSheet) || {});
    }, [caseData]);

    //handle Show Optinal Billing Sheet
    const handleShowOptinalBillingSheet = () => {
        setShowOptinal(!showOptinal);
    };

    const message = `The following billing sheet is generated from the note that you wrote.<br> To view the "optimal" billing sheet, click on the button below.`;
    const messageNotData = `Billing sheet has not been generated.<br> Click "Check My Note" button in the Documentation tab to generate the billing sheet based on your note!`;

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            backdrop={`static`}
            zIndex={16000}
            size={'lg'}
        >
            <ModalHeader toggle={toggle}>Show Billing Sheet</ModalHeader>
            <ModalBody>
                <React.Fragment>
                    {BillingSheet ? (
                        <React.Fragment>
                            <div
                                className="text-muted mb-4"
                                dangerouslySetInnerHTML={{ __html: message || "" }}
                            />
                            <TableSheet {...props} propsData={stateBillingSheet || {}} />
                            {stateOptimalBillingSheet && (
                                <React.Fragment>
                                    <div
                                        onClick={handleShowOptinalBillingSheet}
                                        className="d-flex justify-content-between align-items-center my-3"
                                        style={{cursor: 'pointer'}}
                                    >
                                        {`${showOptinal ? "Hide" : "Show"} Optional Billing Sheet`}
                                        {showOptinal ? (
                                            <i className="fa fa-2x fa-angle-up ml-2" aria-hidden="true"></i>
                                        ) : (
                                            <i className="fa fa-2x fa-angle-down ml-2" aria-hidden="true"></i>
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            opacity: showOptinal ? "1" : "0",
                                            transition: "all .2s",
                                            visibility: showOptinal ? "visible" : "hidden",
                                            height: showOptinal ? "auto" : "0",
                                        }}
                                    >
                                        <TableSheet
                                            {...props}
                                            propsData={stateOptimalBillingSheet || {}}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (
                        <Alert
                            className="m-alert--default border-0 text-center"
                            color="secondary"
                        >
                            <div dangerouslySetInnerHTML={{ __html: messageNotData || "" }} />
                        </Alert>
                    )}
                </React.Fragment>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="primary"
                    className="btn btn-brand --bg-primary"
                    onClick={toggle}
                >
                    Close
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ShowBillingSheet;