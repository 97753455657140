import React from 'react';
import { CongratulationsIcon } from ".";
import { useSelector } from "react-redux";

function Congratulationscontent() {
    // const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    // const ModalCaseCompleted = InterfaceTemplateDetails?.ModalTimePointCleared;

    //get data store
    const caseData = useSelector((state) => state.cases || []);
    const caseResponse = caseData?.data;
    const EndCaseViewDetails = caseResponse?.Case?.EndCaseViewDetails;

    // Chuyển đổi HTML
    const convertToHTML = (text) => ({ __html: text });
    const commentsDefault = `You can now click the “Proceed to Feedback” button below to view feedback on your performance and start another case, or choose “Stay at this Timepoint” to review it again 🎯`;
    return (
        <div className="modal-inner">
            <div className="modal-inner">
                <div className="modal-inner__pic d-flex justify-content-center">
                    {EndCaseViewDetails?.BadgeImage ? <img src={EndCaseViewDetails?.BadgeImage} alt="BadgeImage" /> : <CongratulationsIcon />}
                </div>
                <div className="modal-inner__desc text-center">
                    <h2 className="headline --size-4 mb-4 text-center" dangerouslySetInnerHTML={convertToHTML(EndCaseViewDetails?.Title ||
                        `Bravo! You Have Completed a Case in this Exercise!`
                    )} />
                    <div dangerouslySetInnerHTML={convertToHTML(EndCaseViewDetails?.Comments || commentsDefault)} />
                </div>
            </div>
        </div>
    );
}

export default Congratulationscontent;
