import React, { useEffect } from 'react'
import { Col, Row } from "reactstrap";
import InputGenerate from './generate/InputGenerate';
import ResultGenerate from './result/ResultGenerate';
import { useTour } from '@reactour/tour'
import {apiCaller} from "@utils";

const HomeGenerate = (props) => {
  const { setIsOpen } = useTour()

  useEffect(() => {
    const params = {
      "Action": "ShowPageToolTips",
      "ExtraAction": "TurnOff",
      "Page": "teach-generate_case_script_start"
    }
    apiCaller(`/api/account/tour_guide/`, "POST", params).then((res) => {
      if (res && res.status === 200) {
        if (res.data) {
          setIsOpen(true)
        }
      } else {
        console.log('error');
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className=''>
      <Row className="mb-3">
        <Col md={6} className="pr-2">
          <InputGenerate {...props} />
        </Col>
        <Col md={6} className="pl-2">
          <ResultGenerate {...props} />
        </Col>
      </Row>
    </div>
  )
}

export default HomeGenerate
