/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormModal } from "@components-common/Modal";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getAnalysesAnswerBatchStatistics } from "@actions";
import ProgressBar from "./ProgressBar";
import ChartStatistics from "./ChartStatistics";

const WrapDataComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .wrap-title {
    display: flex;
    align-items: center;
    padding: 6px 0; 
    gap: 10px;
    &__note {
      padding: 4.5px;
      border-radius: 50%;
      background-color: #17B26A;
    }
    &__text {
      color: #101828;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .wrap-content {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #D0D5DD;
    display: flex;
    align-items: center;
    gap: 60px;
    justify-content: center;
  }
  .data-match {
    display: flex;
    align-items: center;
    gap: 20px;
    &__text {
      color: #081B2A;
      font-size: 16px;
      font-weight: 400;
    }
  }
`

const ModalStatistics = ({ isOpen, toggleModal, title, idAnalyses }) => {
  const dispatch = useDispatch();
  const dataAnswerBatchAnalysisStatistics = useSelector((state) => state?.getAnalysesAnswerBatchStatistics?.data || []);
  console.log("🚀 ~ ModalStatistics ~ dataAnswerBatchAnalysisStatistics:", dataAnswerBatchAnalysisStatistics)

  useEffect(() => {
    dispatch(getAnalysesAnswerBatchStatistics({
      Action: "LoadAnswerBatchAnalysisStatistics",
      QuestionAnswerBatchAnalysisId: idAnalyses
    }));
  }, [])

  return (
    <FormModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      title={title}
      maxWidth="800px"
      hiddenIcon={true}
      hiddenButtonCancel={true}
      classCustom="modal-media"
    >
      <div className="wrap-statistics">
        <WrapDataComponent>
          <div className="wrap-title">
            <span className="wrap-title__note"></span>
            <p className="wrap-title__text">AI vs Human Score Agreement (%)</p>
          </div>
          <div className="wrap-content data-match">
            <ProgressBar percent={dataAnswerBatchAnalysisStatistics?.AgreementPercent}/>
            <p className=" data-match__text">of AI and human scores match</p>
          </div>
        </WrapDataComponent>
        <WrapDataComponent>
          <div className="wrap-title">
            <span className="wrap-title__note"></span>
            <p className="wrap-title__text">Comparison of AI and Human Score Distributions</p>
          </div>
          <div className="wrap-content">
            <ChartStatistics dataStatistics={dataAnswerBatchAnalysisStatistics?.AI_Score} title="AI Score"/>
            <ChartStatistics dataStatistics={dataAnswerBatchAnalysisStatistics?.Human_Score} title="Human Score"/>
          </div>
        </WrapDataComponent>
      </div>
    </FormModal>
  );
};

export default ModalStatistics;