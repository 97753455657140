import React from 'react';

//bootstrap
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

// Icons
import BannerMoney from '@images/coin/money-banner.svg';
import IconMoney from '@images/coin/icon-money.svg';

// redux
import { useDispatch } from "react-redux";
import { getCoin } from '@actions';

//components
import { ButtonCloseModal } from "@components/common/Modal";

function ModalCoinsEarned(props) {
  const { isOpen, toggle, rewardAmount } = props;
  const dispatch = useDispatch();

  // Ensure rewardAmount is an array and take the first object
  const reward = Array.isArray(rewardAmount) ? rewardAmount[0] : { amount: 0, statement: "" };

  const handleClaimCoins = () => {
    dispatch(getCoin());
    toggle(); // Close the modal after dispatching the action
  };

  return (
    <Modal
      className="modal-custom modal-size-lg"
      zIndex={16000}
      isOpen={isOpen}
      backdrop={true}
      centered
      backdropClassName="modal-backdrop-custom"
      modalTransition={{ timeout: 700 }}
      toggle={toggle}
    >
      <ButtonCloseModal {...props} handleCloseModal={toggle} />
      <ModalBody>
        <div className="coin-modal">
          <img className='coin-modal__baner' src={BannerMoney} alt="Credits Earned" />
          <h3 className='coin-modal__heading'>Credits Earned</h3>
          <div className="status-icon">
            <div className='status-icon__number'>
              <span className='symbol'>+</span> {reward.amount}
            </div>
            <img className='coin-modal__baner' src={IconMoney} alt="#" />
          </div>
          <p className='coin-modal__desc'>
            {reward.statement}
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="mx-auto" style={{ width: "276px" }}>
          <Button
            color='primary'
            className="btn-brand-sm -btn-lg w-100 -shadown"
            onClick={handleClaimCoins}
          >
            {`Claim Credits`}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ModalCoinsEarned;