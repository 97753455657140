import React, { useEffect } from 'react'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { DataEmptyCase } from "@components-common";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionBankOrg, shareMediaOrg, getQuestionBank } from "@actions"
import { LoaderText } from "@utils";
import { toast } from 'react-toastify';

//moment
import moment from "moment";

const TableMcqBank = (props) => {
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const listQuestionBank = useSelector((state) => state.getQuestionBankOrg || []);

  useEffect(() => {
    dispatch(getQuestionBankOrg({
      Action: "LoadOrganizationQuestionBank"
    }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUnshareReference = async (data) => {
    const dataShare = await dispatch(shareMediaOrg({
      Action: "UnShareToOrganization",
      QuestionItemId: data?.QuestionItemId
    }))
    if (dataShare?.data?.status === 200) {
      toast.success('Successfully shared to Organization Question Bank', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(getQuestionBankOrg({
        Action: "LoadOrganizationQuestionBank"
      }))
      dispatch(getQuestionBank())
    }
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      headerClasses: "--text-primary",
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center">
            {row?.Contents?.question}
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "QuestionType",
      text: "Question Type",
      headerClasses: "--text-primary",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <div className={`d-flex align-items-center question-type ${cell === "MCQ" ? "type-mcq" : ""}`}>
            <span></span>
            <p>
              {cell}
            </p>
          </div>
        );
      },
      filterValue: (cell, row) => row.Contents.question
    },
    {
      dataField: "CreatedAt",
      text: "Date Created",
      headerClasses: "--text-primary",
      headerStyle: { width: "190px" },
      formatter: (cell, row) => {
        return (
          <div className='format-time text-nowrap'>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "--text-primary text-center",
      headerStyle: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-orgRefer">
            {(row?.Media?.owned_by_account || row?.owned_by_account) && (
              <p onClick={() => handleUnshareReference(row)}>
                Unshare
              </p>
            )}
          </div>
        );
      },
    }
  ];

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionItemId"
        data={listQuestionBank?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-bank__filter d-flex align-items-center justify-content-between">
              <div className='tb-heading-total'>Total Questions: {listQuestionBank?.data?.length}</div>
              <div className="boxSearch">
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }}/>
              </div>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={listQuestionBank?.data || []}
              columns={columns}
              // selectRow={selectRow}
              noDataIndication={() => (
                <DataEmptyCase 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end ml-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: listQuestionBank?.data?.length || [],
  };

  return (
    <div className='table-bank position-relative'>
      {listQuestionBank?.isLoading ? (
        <div className="height-loader mt-5">
          <LoaderText />
        </div>
        ) : (
        listQuestionBank?.data?.length === 0 ? (
          <DataEmptyCase 
            title="Your Question Bank is Empty"
            text="It looks like you haven't saved any questions yet. Start creating a new one to see it here."
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
    </div>
  )
}

export default TableMcqBank
