import React, { useRef, useState } from "react";
import { Icon } from '@components/common'
import Module from '@images/teachV2/interact-module.svg'
import { Modal, ModalBody, Button } from 'reactstrap';
import { apiCaller } from "@utils";
import ClipLoader from "react-spinners/ClipLoader";
import Error from '@images/login/error.svg'
import Success from '@images/login/success.svg'
// import FileWaitGenerate from '@images/teachV2/file-generate.svg'
import { useHistory } from "react-router-dom";
import McqWaiting from '@images/teachV2/waiting-mcq.gif'

const ModalConfirmConvert = ({ isOpen, toggle, idScript }) => {
  let history = useHistory();

  const [dataResult, setDataResult] = useState();
  const [loadingResult, setLoadingResult] = useState();
  //---------stream Data-------//
  const [contentStream, setContentStream] = useState();
  let intervalIDRef = useRef(null);

  
  const handleImportVoc = (file) => {
    setLoadingResult(true)
    const params = {
      "action_type": "interactive_module_creation_pipeline",
      "gpt_model": "gpt-4o",
      "case_script_id": idScript
    }
    const filePromise = apiCaller(`/api/medicalcase/functional/`, "POST", params)
      .then(response => {
        if (response.status === 200) {
          setDataResult(response.data)
          setTimeout(() => {
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }, 1000)
        }
        setLoadingResult(false)
      })
      .catch(error => {
        console.error("Error in API call", error);
        setLoadingResult(false)
      });
    handleStreamData(filePromise)
  };

  // stream Data
  const handleStreamData = (questionPromise) => {
    Promise.race([
      questionPromise,
      new Promise((resolve) => setTimeout(resolve, 2000, "timeout")),
    ]).then((result) => {
      if (result !== "timeout") {
        console.log("API question returned before timeout");
        return;
      }

      console.log("API question timed out. Calling API stream_data...");
      intervalIDRef.current = setInterval(() => {
        apiCaller(`/api/account/stream/`, "GET").then((res) => {
          const dataStream = res?.data;
          if (res?.status === 200) {
            setContentStream(dataStream);
          } else {
            setContentStream({});
            // Xoa Stream
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
          }
        }).catch((err) => {
          console.log(err);
        });
      }, 2000);
    });
  };

  
  const handleError = () => {
    setDataResult(null)
    setContentStream(null)
    toggle()
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered={true}
      className='modal-convert'
      maxWidth="600px"
    >
      <ModalBody className='convert__content'>
      {(contentStream || dataResult) ? (
          !dataResult ? (
            <div className="waiting-generate">
              {/* <Icon src={FileWaitGenerate} width={192} height={192}/> */}
              <img src={McqWaiting} alt="mcq" />
              <div className="waiting-generate__content">
                <h1>Generating Interactive Module</h1>
                <p>{contentStream?.status ? contentStream?.status : "We are setting up your new interactive module! It might take a few minutes..."}</p>
              </div>
            </div>
          ) : (
            <div className='waiting-generate'>
              {dataResult?.status === "success" ? (
                <>
                  <Icon src={Success}/>
                  <div className="waiting-generate__content">
                    <h1>Your Interactive Module Has Been Created!</h1>
                    <p>Click "Run Module" to see it in action or "Edit Module" to <br /> make any necessary edits.<br /> You can find this module in your Module Library.</p>
                  </div>
                  <div className="waiting-generate__action">
                    <Button
                      onClick={() => history.push(`/teach/module/storyboard/case/${dataResult?.case_id}`)}
                    >
                      Edit Module
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => window.open(`/learn/case/${dataResult?.case_id}`, "_blank")}
                    >
                      Run Module
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <Icon src={Error}/>
                  <div className="waiting-generate__content">
                    <h1>Incomplete Case Scenario</h1>
                    <p>Some elements of your case scenario required for interactive module creation are missing. Please complete them to proceed.</p>
                  </div>
                  <div className="waiting-generate__action">
                    <Button
                      onClick={handleError}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleError}
                    >
                      Update Scenario
                    </Button>
                  </div>
                </>
              )}
            </div>
          )
        ) : (
          <>
            <div>
              <Icon src={Module}/>
            </div>
            <div className='convert__content-text'>
              <h1>Confirm Conversion to Interactive Module</h1>
              <p>Are you sure you want to convert this case scenario into an interactive module? This process may take a few minutes.</p>
            </div>
            <div className='convert__content-footer'>
              <Button 
                className="btn btn-secondary" 
                onClick={toggle}
              >
                Cancel
              </Button>
              <Button 
                className="btn d-flex align-items-center" 
                color="primary"
                onClick={handleImportVoc}
                disabled={loadingResult}
              >
                Confirm
                {loadingResult && <ClipLoader color="#FFF" size={16}/>}
              </Button>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalConfirmConvert;
