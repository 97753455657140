import React from 'react';
import styled from 'styled-components';

//components
import { EarnCoinList } from '@components/Coin';

const CoinPanel = () => {
    const coinHistory = [
        {
            title: "Transaction 1",
            description: "You earned 50 Credits from completing a task.",
            iconWidth: 24,
            iconAlt: "Money Icon",
            coinValue: 50,
        },
        {
            title: "Transaction 2",
            description: "You earned 30 Credits from a referral.",
            iconWidth: 24,
            iconAlt: "Money Icon",
            coinValue: 30,
        },
    ];

    const earningOptions = [
        {
            title: "Task Completion",
            description: "Complete tasks to earn Credits.",
            iconWidth: 24,
            iconAlt: "Money Icon",
            coinValue: -10,
        },
        {
            title: "Refer a Friend",
            description: "Earn Credits by referring friends.",
            iconWidth: 24,
            iconAlt: "Money Icon",
            coinValue: 50,
        },
    ];

    return (
        <Panel>
            <Column>
                <Heading>Credit History</Heading>
                <EarnCoinList items={coinHistory} />
            </Column>
            <Column>
                <Heading>How To Earn Credits</Heading>
                <EarnCoinList items={earningOptions} />
            </Column>
        </Panel>
    );
};

const Panel = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 24px;
    background: var(--new-white-color);
    margin-top: 14px;
    padding: 24px;
    gap: 24px;
`;

const Column = styled.div`
    flex: 1;
`;

const Heading = styled.h3`
    color: rgba(0, 0, 0, 0.80);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
`;

export default CoinPanel;