// Med2labLogo.js
import React from 'react';
import { Link } from 'react-router-dom';

//Logo small
import LogoSmall from '@images/top/logo_med2lab_sm.svg';

const Med2labLogo = ({ primaryLogo, isSidebarCollapsed }) => {
    return (
        <div className={`logo d-flex justify-content-center mb-5`}>
            <Link className={`${isSidebarCollapsed ? "small_logo" : "large_logo"}`} to={"/"}>
                <img
                    src={isSidebarCollapsed ? LogoSmall : primaryLogo}
                    alt="Med2lab Logo"
                    style={{ maxWidth: isSidebarCollapsed ? '72px' : '100%' }}
                />
            </Link>
        </div>
    );
};

export default Med2labLogo;