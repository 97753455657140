import React, { useState, useEffect, useRef } from "react";

//reactstrap
import {
    Button,
    FormGroup
} from "reactstrap";

//utils
import { apiCaller, LoaderText } from "@utils";

//Config
import * as Config from "@constants/Config";

//JoditEditor
import JoditEditor from "jodit-react";

function CDIPrompts(props) {
    const editor = useRef(null);

    //state
    const [isLoading, setLoading] = useState('');
    const [stateData, setData] = useState({});
    const [statePrompt, setStatePrompt] = useState(stateData?.prompt_json?.cdi_principles || "");

    // Load Text
    useEffect(() => {
        setStatePrompt(stateData?.prompt_json?.cdi_principles || "");
    }, [stateData]);

    // Load data
    useEffect(() => {
        const fetchData = () => {
            const params = {
                "action_type": "get_by_category",
                "category": "cdi_edit_suggestion"
            }
            setLoading(true);
            apiCaller(`/api/binh/promptstore/`, 'POST', params).then(res => {
                const data = res?.data;
                if (res?.status === 200) {
                    setData(data);
                    setLoading(false);
                } else {
                    console.log('error');
                    setLoading(false);
                }
            });
        }
        fetchData();
    }, [])

    // Handle Change
    const hanldeChange = (value) => {
        setStatePrompt(value);
    }

    // Handle Submit
    const handleSave = () => {
        const params = {
            "action_type": "add_prompt",
            "prompt_json": {
                "cdi_principles": statePrompt
            },
            "category": "cdi_edit_suggestion"
        }
        setLoading(true);
        apiCaller(`/api/binh/promptstore/`, 'POST', params).then(res => {
            const data = res?.data;
            if (res?.status === 200) {
                setData(data);
                setLoading(false);
            } else {
                console.log('error');
                setLoading(false);
            }
        });
    }
    
    return (
        <React.Fragment>
            <FormGroup>
                <div className="position-relative">
                    <JoditEditor
                        ref={editor}
                        value={stateData?.prompt_json?.cdi_principles || ''}
                        config={Config.CONFIG_JODIT}
                        tabIndex={1} // tabIndex of textarea
                        onChange={(newContent) => hanldeChange(newContent)}
                    />
                    {isLoading && <LoaderText />}
                </div>
                <div className="text-right">
                    <Button
                        color="primary"
                        className="btn btn-brand --bg-primary mt-3"
                        onClick={handleSave}
                        disabled={isLoading}
                    >
                        {isLoading &&
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                        }
                        Save
                    </Button>
                </div>
            </FormGroup>
        </React.Fragment>
    );
}

export default CDIPrompts;