import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, CustomInput } from "reactstrap";
import Slider from "@material-ui/core/Slider";
import getCroppedImg from "@utils/cropimage";

//api
import { apiCaller } from "@utils";

//constant
import { profileConstant } from "@constants";

//redux
import { useDispatch } from 'react-redux';

function ModalUpload(props) {
  const { isOpen, toggle } = props && props;
  const dispatch = useDispatch();
  const dataAcc = props && props.dataAcc;

  //state
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  //Submit Api
  const handleUploadAvatar = useCallback(async (formData) => {
    setLoading(true);
    dispatch({ type: profileConstant.UPDATE_PROFILE_PHOTO_REQUEST, isLoading: true });
    //Call Api
    apiCaller(`/api/account/update/`, "POST", formData).then((res) => {
      const data = res?.data;
      if (res?.status === 200) {
        dispatch({ type: profileConstant.UPDATE_PROFILE_PHOTO_SUCCESS, payload: data });
        setLoading(false);
        toggle();
      } else {
        dispatch({ type: profileConstant.UPDATE_PROFILE_PHOTO_FAILURE, error: "error" });
        setLoading(false);
        toggle();
      }
    });
  }, [dispatch, toggle]);

  const cropImageAndSave = useCallback(async () => {
    try {
      const rotation = 0;
      const blobData = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
      const formData = new FormData();
      formData.append('ProfilePhoto', blobData);
      handleUploadAvatar(formData);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, handleUploadAvatar]);

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setImageSrc(null);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Change Profile Photo</ModalHeader>
      <ModalBody>
        {imageSrc ? (
          <div className="cropImgCt">
            <div className="crop-container">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                cropShape="round"
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                aspect={1 / 1}
              />
            </div>
            <div className="controls">
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom) => setZoom(zoom)}
                className="slider"
              />
            </div>
          </div>
        ) : (
          <React.Fragment>
            {(dataAcc && dataAcc.profile_photo_url) &&
              <img className="img-fluid mb-4" src={dataAcc.profile_photo_url} alt="profile" />
            }
            <CustomInput
              className="text-left"
              type="file"
              name="imageFile"
              id="imageFile"
              onChange={onFileChange}
              accept="image/*"
            />
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="btn btn-brand --bg-primary"
          onClick={cropImageAndSave}
          disabled={!imageSrc || isLoading}
        >
          {isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )} Save
        </Button>
        <Button color="secondary" onClick={toggle} disabled={isLoading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalUpload;
