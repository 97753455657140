import React, { useEffect, useState } from "react";
import { Container, Badge, Button } from "reactstrap";

//react-bootstrap-table-next
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";

//imgUser
import imgUser from '@images/top/user.svg';

//common
import { DataEmty } from "@components-common";

//lodash
import _ from "lodash";

//truncate
import { truncate } from "@utils";

//format time
import moment from "moment";

//redux
import { useDispatch } from "react-redux";

//actions
import { actUpdateTicket } from "@actions" ;

//message
import { messageData } from "@utils";

//components
import { ModalDetails } from '..';
import { Filter } from "../Ticket";

function TableTicket(props) {
  //props
  const { data } = props && props;

  //redux
  const dispatch = useDispatch();

  //setState
  useEffect(() => {
    setData(data || []);
  }, [data]);

  //state
  const [stateData, setData] = useState(data || []);
  const [itemObject, setObject] = useState({});
  const [arrFilter, setFilter] = useState({
    Status: '',
    Priority: '',
    Type: ''
  });
  const [onFilter, setOnFilter] = useState(false);

  const [modal, setModal] = useState({
    details: false
  });

  const toggle = (row, type) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
    setObject(row);
  };

  const columns = [
    {
      dataField: "TicketId",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "Message",
      text: "Message",
      editable: false,
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      formatter: (value, row) => {
        return truncate(value, 50);
      },
    },
    {
      dataField: "SubmittingAccount",
      text: "Account Submitted",
      editable: false,
      headerClasses: "align-middle font-weight-500",
      classes: "align-middle",
      style: {
        width: "250px",
      },
      formatter: (value, row) => {
        const firstname = value.firstname;
        const lastname = value.lastname;
        const photo = value.profile_photo_url;
        const email = value.email;
        return (
          <div className="userBox">
            <div className="userBox__left">
              <img src={photo || imgUser} className="imgUser bg-grey-light" alt="img" />
            </div>
            <div className="userBox__right">
              <p className="m--font-brand branchText">{email}</p>
              <p className="userName">
                {firstname} {lastname}
              </p>
            </div>
          </div>
        );
      },
    },
    {
      dataField: "Status",
      text: "Status",
      editable: true,
      headerClasses: "align-middle font-weight-500 text-center",
      classes: "align-middle text-center",
      style: {
        width: "130px",
      },
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "New",
            label: "New",
          },
          {
            value: "Confirmed",
            label: "Confirmed",
          },
          {
            value: "In Progress",
            label: "In Progress",
          },
          {
            value: "Deployed",
            label: "Deployed",
          },
        ],
      },
      formatter: (value, row) => {
        const statusTicket = (status) => {
          let statusBlock = "";
          switch (status) {
            case "New":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="success"
                >
                  {value}
                </Badge>
              );
              break;
            case "Confirmed":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="info"
                >
                  {value}
                </Badge>
              );
              break;
            case "In Progress":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="primary"
                >
                  {value}
                </Badge>
              );
              break;
            default:
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="info"
                >
                  {value}
                </Badge>
              );
          }
          return statusBlock;
        };
        return <div>{statusTicket(value)}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "Priority",
      text: "Priority",
      headerClasses: "align-middle font-weight-500 text-center",
      classes: "align-middle text-center",
      style: {
        width: "130px",
      },
      editor: {
        type: Type.SELECT,
        options: [
          {
            value: "Medium",
            label: "Medium",
          },
          {
            value: "High",
            label: "High",
          },
          {
            value: "Low",
            label: "Low",
          },
        ],
      },
      formatter: (value, row) => {
        const statusPriority = (status) => {
          let statusBlock = "";
          switch (status) {
            case "Medium":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="warning"
                >
                  {value}
                </Badge>
              );
              break;
            case "High":
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="danger"
                >
                  {value}
                </Badge>
              );
              break;
            default:
              statusBlock = (
                <Badge
                  style={{ minWidth: "80px" }}
                  className="font-weight-500 p-2"
                  color="success"
                >
                  {value}
                </Badge>
              );
          }
          return statusBlock;
        };
        return <div>{statusPriority(value)}</div>;
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
    },
    {
      dataField: "CreatedAt",
      text: "Submitted Date",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      sort: true,
      editable: false,
      style: {
        width: "160px",
      },
      formatter: (value, row) => {
        return (
          <span>{moment(value).format('YYYY-MM-DD')}</span>
        );
      },
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      }
    },
    {
      dataField: "DueDate",
      text: "Due Date",
      headerClasses: "align-middle text-center font-weight-500",
      classes: "align-middle text-center",
      editable: true,
      style: {
        width: "150px",
      },
      sort: true,
      sortCaret: (order, column) => {
        if (!order)
          return (
            <span className="order pl-2" style={{ color: "#cccccc" }}>
              <i className="fa fa-sort" aria-hidden="true"></i>
            </span>
          );
        else if (order === "asc")
          return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
        else if (order === "desc")
          return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
        return null;
      },
      formatter: (cell) => {
        const dateObj = cell;
        return (dateObj ? (moment(dateObj).format("YYYY-MM-DD")) : (
          <Button
            className="btn btn-brand btn-sm"
            color="primary"
          ><i className="fa fa-calendar mr-1" aria-hidden="true"></i> Pick a day</Button>))
      },
      editor: {
        type: Type.DATE,
        defaultValue: new Date()
      },
    },
    {
      dataField: "Actions",
      text: "",
      editable: false,
      headerClasses: "font-weight-400 align-middle",
      classes: "align-middle text-center text-nowrap",
      formatter: (cell, row) => {
        return (
          <Button
            className="btn btn-brand btn-sm"
            color="primary"
            onClick={() => toggle(row, 'details')}
          >
            <i className="fa fa-eye mr-2" aria-hidden="true"></i> Details
          </Button>
        );
      },
    }
  ];

  //Edit Cell With API
  const afterSaveCell = (oldValue, newValue, row, column) => {
    const params = {
      Action: "Update",
      TicketId: row.TicketId,
      Status: row.Status,
      Priority: row.Priority,
      DueDate: row.DueDate
    };
    dispatch(actUpdateTicket(params));
  };

  const handleChange = (e, key) => {
    const { value } = e.target;
    setFilter((prevState) => ({ ...prevState, [key]: value }));
    setOnFilter(true);
  }

  //filter
  useEffect(() => {
    const filterByDropdown = () => {
      const listDataClone = _.cloneDeep(data);
      const valueStatus = arrFilter?.Status;
      const valuePriority = arrFilter?.Priority;
      const valueType = arrFilter?.Type;

      if (valueStatus) {
        const filterData = _.filter(listDataClone, { 'Status': valueStatus });
        setData(filterData);
      } else if (valuePriority) {
        const filterData = _.filter(listDataClone, { 'Priority': valuePriority });
        setData(filterData);
      } else if (valueType) {
        const filterData = _.filter(listDataClone, { 'Type': valueType });
        setData(filterData);
      } else if (valuePriority && valueStatus) {
        const filterData = _.filter(listDataClone, { 'Priority': valuePriority, 'Status': valueStatus });
        setData(filterData);
      } else {
        setData(listDataClone);
      }
      setOnFilter(false);
    }

    if (onFilter) {
      filterByDropdown();
    }
  }, [arrFilter, data, onFilter]);

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="TicketId"
          data={stateData || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <BootstrapTable
              condensed
              wrapperClasses="table-responsive"
              keyField="TicketId"
              data={(stateData && stateData) || []}
              columns={columns}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                afterSaveCell,
              })}
              noDataIndication={() => <DataEmty content={messageData?.filter} />}
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          )}
        </ToolkitProvider>
        <div className="d-flex justify-content-center mt-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    sizePerPageList: [20],
    totalSize: stateData.length || []
  };
  return (
    <Container className="-box-shadow bg-white p-4">
      <Filter {...props} handleChange={handleChange} />
      <PaginationProvider pagination={paginationFactory(options)}>
        {contentTable}
      </PaginationProvider>
      <ModalDetails
        isOpen={modal.details}
        toggle={() => toggle({}, 'details')}
        item={itemObject}
      />
    </Container>
  );
}

export default TableTicket;
