import { instructorConstants } from "@constants";
import {apiCaller} from "@utils";

//alert
import { Toasts } from '@components/common/Toasts';
import { toast } from 'react-toastify';

//fetch pnorder
export const actFetchTeachPortfolioRequest = (test) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/portfolio/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.INSTRUCTOR_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.INSTRUCTOR_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.INSTRUCTOR_FAILURE, error: error } }
}

//fetch series
export const actFetchCaseWithSeriesIdRequest = (id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/series/${id}/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(data) {
                dispatch(success(data, id));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.FILTER_CASE_REQUEST, isLoading } }
    function success(data) { 
        return { 
            type: instructorConstants.FILTER_CASE_SUCCESS, 
            payload: data,
            seriesId: id
        }
    }
    function failure(error) { return { type: instructorConstants.FILTER_CASE_FAILURE, error: error } }
}

//fetch account
export const actFetchAccountInstitutionRequest = (id) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/institution/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.ACCOUNT_INSTITUTION_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.ACCOUNT_INSTITUTION_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.ACCOUNT_INSTITUTION_FAILURE, error: error } }
}

//PUT sort data
export const actSortDataCase = (id, params) => {
    return dispatch => {
        return apiCaller(`/api/teach/series/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(data) {
                console.log(data);
            } else {
                console.log("error");
            }
        });
    };
}

//Add User
export const atcAddUserAccount = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/institution/`, 'POST', params).then(res => {
            const data = res && res.data;
            const dataAlert = data && data.Alert;
            const alertSeverity= dataAlert && dataAlert.Severity;
            const alertDuration = dataAlert && dataAlert.ShowDuration;
            if(data) {
                dispatch(success(data));
                if(alertSeverity && alertSeverity === "success") {
                    toast.success(<Toasts data={dataAlert} />, {
                            autoClose: alertDuration * 1000,
                            toastId: 'ADD_USER_ACCOUNT_REQUEST'
                    });
                }
                if(alertSeverity && alertSeverity === "warning") {
                    toast.warning(<Toasts data={dataAlert} />, {
                        autoClose: alertDuration * 1000,
                        toastId: 'ADD_USER_ACCOUNT_REQUEST'
                    });
                }
                if(alertSeverity && alertSeverity === "danger") {
                    toast.error(<Toasts data={dataAlert} />, {
                        autoClose: alertDuration * 1000,
                        toastId: 'ADD_USER_ACCOUNT_REQUEST'
                    });
                }
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.ADD_USER_ACCOUNT_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.ADD_USER_ACCOUNT_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.ADD_USER_ACCOUNT_FAILURE, error: error } }
}

//remove account
export const atcRemoveUserAccount = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/institution/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.REMOVE_USER_ACCOUNT_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.REMOVE_USER_ACCOUNT_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.REMOVE_USER_ACCOUNT_FAILURE, error: error } }
}

//Series - All Case
export const actFetchAllCase = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/admin/cases/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.LOAD_INSTITUTION_ALL_CASES_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.LOAD_INSTITUTION_ALL_CASES_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.LOAD_INSTITUTION_ALL_CASES_FAILURE, error: error } }
}

// Import Accounts from Excel - CONFIRMED
export const atcConfirmImportAccount = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/import/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.IMPORT_EXEL_ACCOUNT_CONFIRM_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.IMPORT_EXEL_ACCOUNT_CONFIRM_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.IMPORT_EXEL_ACCOUNT_CONFIRM_FAILURE, error: error } }
}

// Update Account -Request Instructor Privilege
export const atcRequestInstructor = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/update/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.REQUEST_INSTRUCTOR_PRIVILEGE_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.REQUEST_INSTRUCTOR_PRIVILEGE_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.REQUEST_INSTRUCTOR_PRIVILEGE_FAILURE, error: error } }
}

//Set Account Privilege
export const atcSetAccountPrivilege = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/privilege/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.SET_ACCOUNT_PRIVILEGE_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.SET_ACCOUNT_PRIVILEGE_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.SET_ACCOUNT_PRIVILEGE_FAILURE, error: error } }
}

// Set Account Privilege Status
export const atcSetAccountPrivilegeStatus = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/account/status/`, 'POST', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                if(params.Status === 'Inactive') {
                    toast.success(`Account associated with email ${params.Email} has been deactivated.`, {
                        position: "top-right",
                        autoClose: 5000
                    });
                } else {
                    toast.success(`Account associated with email ${params.Email} has been reactivated.`, {
                        position: "top-right",
                        autoClose: 5000
                    });
                }
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.SET_ACCOUNT_STATUS_PRIVILEGE_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.SET_ACCOUNT_STATUS_PRIVILEGE_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.SET_ACCOUNT_STATUS_PRIVILEGE_FAILURE, error: error } }
}

// Case - Update Instructor Note
export const updateInstructorNote = (id, params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller(`/api/teach/case/${id}/`, 'PUT', params).then(res => {
            const data = res && res.data;
            if(res && res.status === 200) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: instructorConstants.UPDATE_INSTRUCTOR_NOTE_REQUEST, isLoading } }
    function success(data) { return { type: instructorConstants.UPDATE_INSTRUCTOR_NOTE_SUCCESS, payload: data } }
    function failure(error) { return { type: instructorConstants.UPDATE_INSTRUCTOR_NOTE_FAILURE, error: error } }
}