import React from "react";

//BootstrapTable
import BootstrapTable from "react-bootstrap-table-next";

//boostrap
import { Row, Col } from "reactstrap";

//paginator
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

//format time
import moment from "moment";

//DataEmty
import { DataEmty } from "@components-common";

function TableErrors(props) {
    const { stateData } = props && props;
    const columns = [
        {
            dataField: "user",
            text: "ID",
            hidden: true,
            headerClasses: "--text-primary font-weight-500",
        },
        {
            dataField: "Time",
            text: "Time",
            headerClasses: "--text-primary font-weight-500",
            formatter: (value, row) => <span>{moment(value).format("LLL")}</span>,
            sort: true,
            style: {
                whiteSpace: "nowrap",
            },
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span className="order pl-2" style={{ color: "#cccccc" }}>
                            <i className="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    );
                else if (order === "asc")
                    return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
                else if (order === "desc")
                    return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
                return null;
            }
        },
        {
            dataField: "user",
            text: "User",
            headerClasses: "--text-primary font-weight-500",
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span className="order pl-2" style={{ color: "#cccccc" }}>
                            <i className="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    );
                else if (order === "asc")
                    return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
                else if (order === "desc")
                    return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
                return null;
            }
        },
        {
            dataField: "error_type",
            text: "Error Type",
            headerClasses: "--text-primary font-weight-500",
            sort: true,
            sortCaret: (order, column) => {
                if (!order)
                    return (
                        <span className="order pl-2" style={{ color: "#cccccc" }}>
                            <i className="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    );
                else if (order === "asc")
                    return <i className="fa fa-sort-asc pl-2" aria-hidden="true"></i>;
                else if (order === "desc")
                    return <i className="fa fa-sort-desc pl-2" aria-hidden="true"></i>;
                return null;
            }
        },
        {
            dataField: "details",
            text: "Details",
            headerClasses: "--text-primary font-weight-500",
        }
    ];

    const contentTable = ({ paginationProps, paginationTableProps }) => (
        <div>
            <ToolkitProvider
                keyField="food_id"
                data={stateData?.table_errors || []}
                columns={columns}
            >
                {(toolkitprops) => (
                    <BootstrapTable
                        condensed
                        striped
                        wrapperClasses="table-responsive mt-5"
                        noDataIndication={() => <DataEmty content={'No Data'} />}
                        {...paginationTableProps}
                        {...toolkitprops.baseProps}
                    />
                )}
            </ToolkitProvider>
            {/* /Table */}
            <div className="d-flex justify-content-center mt-4">
                <PaginationListStandalone {...paginationProps} />
            </div>
        </div>
    );

    const options = {
        custom: true,
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: "First",
        prePageText: "Back",
        nextPageText: "Next",
        lastPageText: "Last",
        nextPageTitle: "First page",
        prePageTitle: "Pre page",
        firstPageTitle: "Next page",
        lastPageTitle: "Last page",
        showTotal: true,
        sizePerPageList: [20],
        totalSize: (stateData?.table_errors?.length) || [],
    };

    return (
        <Row>
            <Col md={12}>
                <PaginationProvider pagination={paginationFactory(options)}>
                    {contentTable}
                </PaginationProvider>
            </Col>
        </Row>
    );
}

export default TableErrors;