import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getListAnalysesAvailabel } from "@actions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Icon } from '@components/common'
import { DataEmptyCase } from "@components-common";
import { LoaderText } from "@utils";
import Plus from '@images/teachV2/add-series.svg'
import Down from '@images/teachV2/chevron-down.svg'
import Up from '@images/teachV2/chevron-up.svg'

//moment
import { Button } from 'reactstrap';
import ModalUploadFileAnalysis from './ModalUploadFileAnalysis';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ModalCreateNewAnalysis from './ModalCreateNewAnalysis';
import ViewAnalysis from './ViewAnalysis';

const AnswerAnalyses = (props) => {
  const { dataAnalyze, isBank } = props
  let history = useHistory();
  const dispatch = useDispatch();
  const listCase = useSelector((state) => state?.getListAnalysesAvailabel || []);
  console.log("🚀 ~ AnswerAnalyses ~ listCase:", listCase)
  const [dataDetail, setDataDetail] = useState(null)
  const [modal, setModal] = useState({
    uploadDocument: false,
    createAnalysis: false,
    reviewAnalysis: false
  });

  //toggle
  const toggle = (type, value = null) => {
    setDataDetail(value)
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  const handleGetListAnalyses = () => {
    dispatch(getListAnalysesAvailabel({
      Action: "ListAnalyses",
      QuestionItemId: dataAnalyze?.QuestionItemId
    }));
  }

  useEffect(() => {
    handleGetListAnalyses()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      dataField: "Name",
      text: "Name",
      headerClasses: "--text-primary font-weight-500",
      headerStyle: { width: "500px" },
      formatter: (cell, row) => {
        return (
          <span className='font-weight-600'>{cell}</span>
        );
      },
    },
    {
      dataField: "CreatedAt",
      text: "Date / Time",
      headerClasses: "--text-primary font-weight-500",
      headerStyle: { width: "300px" },
      formatter: (cell, row) => {
        return (
          <div className='format-time text-nowrap'>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
        );
      },
    },
    {
      dataField: "Progress",
      text: "",
      headerClasses: "--text-primary font-weight-500 text-center",
      formatter: (cell, row) => {
        return (
          <div 
            className="d-flex align-items-center justify-content-start btn-add-new" 
            onClick={() => toggle("createAnalysis", row)}
          >
            {/* {cell === "Completed" ? (
              <div 
                className={`item-status ${cell}`} 
                onClick={() => history.push({
                  pathname: `/teach/analysis/${dataAnalyze?.QuestionItemId}/${row?.QuestionAnswerBatchId}`, 
                  state: {  // location state
                    isBank, 
                  },
                })}  
              >
                Show Details
              </div>
            ) : (
              <div className={`item-status ${cell}`}>
                {cell}
              </div>
            )} */}
            <div className="wrap-add">
              <Icon src={Plus} stroke="#079455" width="12" height="12"/>
            </div>
            <p>Add New Analysis</p>
          </div>
        );
      },
    }
  ];

  const expandRow = {
    renderer: row => {
      return (
        <div className='row-expand'>
          {row?.Analyses?.map((analysis, index) => (
            <div className='row-expand__detail' key={index}>
              <div className='row-expand__detail-name'>{analysis?.Name}</div>
              <div className='row-expand__detail-date'>{moment(analysis?.CreatedAt).format('DD/MM/YYYY hh:mm A')}</div>
              <div className='d-flex align-items-center justify-content-center'>
                <p 
                  className={`item-status`} 
                  onClick={() => history.push({
                    pathname: `/teach/analysis/${dataAnalyze?.QuestionItemId}/${analysis?.QuestionAnswerBatchAnalysisId}`, 
                    state: {  // location state
                      isBank, 
                    },
                  })}  
                >
                  Show Data
                </p>
                <p 
                  className={`item-status`} 
                  onClick={() => toggle("reviewAnalysis", analysis)}
                >
                  Show Settings
                </p>
              </div>
            </div>
          ))}
        </div>
      )
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
      return (
        expanded ? (
          <Icon src={Up}/>
        ) : (
          <Icon src={Down} stroke="#475467"/>
        )
      )
    }
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionAnswerBatchId"
        data={listCase?.data || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-responsive mt-4"
              bordered={false}
              data={(listCase?.data && listCase?.data) || []}
              columns={columns}
              noDataIndication={() => (
                <DataEmptyCase 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              expandRow={ expandRow }
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    showTotal: true,
    totalSize: listCase?.data?.length || [],
  };


  return (
    <div className="table-library position-relative">
      <div className="d-flex align-items-center justify-content-end">
        <Button
          color="primary"
          className='d-flex align-items-center p-2'
          onClick={() => toggle("uploadDocument")}
        >
          <Icon src={Plus} width={20} height={20} stroke="#fff"/>
          <p className='ml-1'>Add New Group of Answers</p>
        </Button>
      </div>
      {listCase?.isLoading ? (
        <div className="h-100 mt-5">
          <LoaderText />
        </div>
      ) : (
        listCase?.data?.length === 0 ? (
          <DataEmptyCase
            title="No Analyses Existed for This Question"
            text="Click the ‘Add New Group of Answers’ button above to utilize Al to analyze or score a batch of learners' answers to this question."
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
      <ModalUploadFileAnalysis
        isOpen={modal.uploadDocument}
        toggleModal={() => toggle("uploadDocument")}
        title="Upload File for Analysis"
        handleGetListAnalyses={handleGetListAnalyses}
        {...props}
      />
      <ModalCreateNewAnalysis
        isOpen={modal.createAnalysis}
        toggleModal={() => toggle("createAnalysis")}
        title="Set Up New Analysis"
        dataAnalysis={dataDetail}
        handleGetListAnalyses={handleGetListAnalyses}
        {...props}
      />
      <ViewAnalysis
        isOpen={modal.reviewAnalysis}
        toggleModal={() => toggle("reviewAnalysis")}
        title="Review Analysis Settings"
        dataAnalysis={dataDetail}
        {...props}
      />
    </div>
  )
}

export default AnswerAnalyses
