
// Auth Login
import {
    LoginPageV2,
    RegisterPageV2,
    RequestDemoPage,
    ConfirmPage,
    VerifyPage,
    AutoLoginPage,
    LoginTokenPage,
    ResetPasswordPage,
    OTPPage,
    SetNewPasswordPage
} from '@pages/med2lab/authMed2lab';
import LoadingAccess from "@components/auth/LoginBySocial/LoadingAccess";

const authRoutes = [
    // Auth Med2lab
    {
        path: "/login",
        exact: true,
        classPage: '',
        component: LoginPageV2,
        isLayout: false,
    },
    {
        path: "/google-callback",
        exact: true,
        classPage: '',
        component: LoadingAccess,
        isLayout: false,
    },
    {
        path: "/register",
        exact: true,
        classPage: '',
        component: RegisterPageV2,
        isLayout: false,
    },
    {
        path: "/register/demo",
        exact: true,
        classPage: '',
        component: RequestDemoPage,
        isLayout: false,
    },
    {
        path: "/register/:slug",
        exact: true,
        classPage: '',
        component: ConfirmPage,
        isLayout: true,
    },
    {
        path: "/verify/:slug",
        exact: true,
        classPage: '',
        component: VerifyPage,
        isLayout: true,
    },
    {
        path: "/access-token",
        exact: true,
        classPage: '',
        component: LoginTokenPage,
        isLayout: false,
    },
    {
        path: "/auth",
        exact: true,
        classPage: '',
        component: AutoLoginPage,
        isLayout: false,
    },
    {
        path: "/auth/reset-password",
        exact: true,
        classPage: '',
        component: ResetPasswordPage,
        isLayout: false,
    },
    {
        path: "/auth/check-otp",
        exact: true,
        classPage: '',
        component: OTPPage,
        isLayout: false,
    },
    {
        path: "/auth/set-new-password",
        exact: true,
        classPage: '',
        component: SetNewPasswordPage,
        isLayout: false,
    },
];

export { authRoutes };