import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from "react-redux";

const MedicalDescriptionWrapper = styled.div`
    width: 100%;
    height: calc(100% - 24px);
    border-radius: 16px;
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
`;

const Tab = styled.div`
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: ${({ isActive }) => (isActive ? 'none' : 'none')};
  border-radius: ${({ isActive }) => (isActive ? '6px' : '6px')};
  border: 1.5px solid ${({ isActive }) => (isActive ? '#0089C2' : '#0089C2')};
  margin-right: 8px;
  background-color: ${({ isActive }) => (isActive ? '#0089C2' : '#FFF')};
  color: ${({ isActive }) => (isActive ? '#FFF' : '#081B2A')};
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
`;

const TabContent = styled.div`
  border-radius: 0 0 12px 12px;
`;

const MedicalDescription = styled.div`
  border-radius: 8px;
`;

const MedicalDescriptionContent = styled.div`
  font-size: 14px;
  font-weight: 400;
  height: calc(100vh - 360px);
  overflow: auto;
  color: #081B2A;
  font-weight: 400;
`;

const MedicalDescriptionBox = () => {
    const caseData = useSelector((state) => state.cases || []);
    const dataResponse = caseData?.data;
    const narratives = dataResponse?.Case?.PastNarratives || [];
    const [activeTab, setActiveTab] = useState(narratives.length ? narratives[0].TimePointId : '');
    return (
        <MedicalDescriptionWrapper>
            <Tabs className='mb-3'>
                {narratives.map((narrative) => (
                    <Tab
                        key={narrative.TimePointId}
                        isActive={activeTab === narrative.TimePointId}
                        onClick={() => setActiveTab(narrative.TimePointId)}
                    >
                        {narrative.TimePointName}
                    </Tab>
                ))}
            </Tabs>
            {narratives.map(
                (narrative) =>
                    activeTab === narrative.TimePointId && (
                        <TabContent key={narrative.TimePointId}>
                            <MedicalDescription>
                                <MedicalDescriptionContent dangerouslySetInnerHTML={{ __html: narrative.NarrativeText }} />
                            </MedicalDescription>
                        </TabContent>
                    )
            )}
        </MedicalDescriptionWrapper>
    );
};

export default MedicalDescriptionBox;