import React from 'react';
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";

//check Html
import { hasTextInside } from "@utils";

function InstructorMessage(props) {
    const { content, loadingIndex, index, caseData, preceptorNameText } = props || {};
    const PreceptorAvatar = caseData?.data?.Case?.Interface?.PreceptorAvatar;
    return (
        <div className={`messengerCt__wrapper isShow`} data-index={loadingIndex === index}>
            <div className="messengerCt__message messengerCt__message--in">
                <div className="messengerCt__message-pic">
                    <img
                        src={PreceptorAvatar || imageInstructor}
                        alt="PreceptorAvatar"
                    />
                </div>
                <div className="messengerCt__message-body position-relative">
                    <div className={`messengerCt__message-content --arrow-left`}>
                        <div className="messengerCt__message-username">
                            {hasTextInside(preceptorNameText) ? (
                                <div dangerouslySetInnerHTML={{ __html: preceptorNameText }} />
                            ) : (
                                <div className='role-chat'>Instructor:</div>
                            )}
                        </div>
                        <div className="messengerCt__message-text">
                            {content && <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: content }} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InstructorMessage;