import React from 'react';

//Component
import { IconListContainer } from "@components/learner/StandardScreen/LearnerMobile";

const LearnerGridLeftParent = (props) => {
  const { sizePatientPhoto } = props && props;
  return (
    <div className={`learner-grid__left position-relative`} style={{ maxHeight: `${sizePatientPhoto?.height || 500}px` }}>
      <IconListContainer {...props} />
      {props.children}
    </div>
  );
};

export default LearnerGridLeftParent;