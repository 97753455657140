import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { uploadRAGDocument } from '@actions'; // Đảm bảo đường dẫn đúng
import FileUpload from './FileUpload';
import FileDisplay from './FileDisplay';
import FileError from './FileError';

const WrapFormUpload = styled.div`
  .wrap-option {
    .four-step {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
`;

const FileUploadComponent = ({ toggleModal, loadDataReferenceBook }) => {
  const dispatch = useDispatch();
  const hiddenFileInputText = useRef(null);
  const [error, setError] = useState('');
  const [dataFileImported, setDataFileImported] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    hiddenFileInputText.current.click();
  };

  const handleDrag = e => {
    e.preventDefault();
  };

  const handleFileChange = file => {
    if (file.type !== 'application/pdf') {
      setError('Only PDF files are allowed.');
      setDataFileImported(null);
    } else {
      setError('');
      setDataFileImported(file);
    }
  };

  const handleChange = (event, type) => {
    event.preventDefault();
    event.stopPropagation();
    const file = type === "change" ? event.target.files[0] : event.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleConfirm = () => {
    if (dataFileImported) {
      setLoading(true);
      const formData = new FormData();
      formData.append('document_file', dataFileImported);
      formData.append('action_type', 'upload_rag_document');
      dispatch(uploadRAGDocument(formData))
        .then(response => {
          if (response?.status === 200) {
            loadDataReferenceBook()
            toggleModal();
          }
          console.log('Success:', response);
        })
        .catch(error => {
          console.error('Error:', error);
          setLoading(false);
          setError('Upload failed. Please try again.');
          toggleModal();
        });
    }
  };

  return (
    <WrapFormUpload>
      <div className='wrap-option four-step'>
        {!dataFileImported && !error && (
          <FileUpload
            handleChange={handleChange}
            handleClick={handleClick}
            handleDrag={handleDrag}
            hiddenFileInputText={hiddenFileInputText}
            type="PDF Only"
          />
        )}
        {dataFileImported && !error && (
          <FileDisplay
            dataFileImported={dataFileImported}
            setDataFileImported={setDataFileImported}
            loading={loading}
            handleConfirm={handleConfirm}
          />
        )}
        {error && (
          <FileError
            error={error}
            setError={setError}
            fileName={dataFileImported ? dataFileImported.name : 'Unknown file'}
          />
        )}
      </div>
    </WrapFormUpload>
  );
};

export default FileUploadComponent;