import React from 'react';
import { useMediaQuery } from 'react-responsive';

const LearnMainLayout = ({ children }) => {
  // Detect if the screen size is mobile
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <div className={`learner-main-inner ${isMobile ? 'is-mobile' : ''}`}>
      {children}
    </div>
  );
};

export default LearnMainLayout;