import React, { useState, useEffect } from 'react';

//Table Components
import { TheadOptions } from '../Options';

//react-router-dom
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//components
import { ButtonDelete, ButtonEdit } from '@components-common/elements';
import { ModalEditOption } from '../Options';

//common
import { DataEmty } from "@components-common";

//components common
import { ModalDeleteCommon } from '@components/common/Modal';

//apiCaller
import { apiCaller } from "@utils";
import { problemsConstant } from "@constants";

function TableOption(props) {
    const { value } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    //state
    const [stateData, setStateData] = useState(value?.Options || []);
    const [itemCurrent, setItemCurrent] = useState('');
    const [modal, setModal] = useState({
        UpdateProblemOption: false,
        DeleteProblemOption: false
    });
    const [isLoading, setLoading] = useState(false);

    //load Data
    useEffect(() => {
        setStateData(value?.Options || []);
    }, [value]);

    //Modal
    const toggle = (type, value) => {
        setModal(prevState => ({ ...prevState, [type]: !prevState[type] }));
        setItemCurrent(value);
    };

    //remove
    const handleRemove = () => {
        const params = {
            "Action": "DeleteProblemOption",
            "CaseId": id,
            "DocumentationProblemOptionId": itemCurrent?.OptionId
        };

        setLoading(true);
        dispatch({ type: problemsConstant.DELETE_PROBLEM_OPTION_REQUEST, isLoading: true });
        apiCaller(`/api/teach/documentation/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: problemsConstant.DELETE_PROBLEM_OPTION_SUCCESS, payload: data });
                setModal((prevState) => ({ ...prevState, DeleteProblemOption: false }));
                setLoading(false);
            } else {
                dispatch({ type: problemsConstant.DELETE_PROBLEM_OPTION_FAILURE, error: "error" });
                setLoading(false);
                setModal((prevState) => ({ ...prevState, DeleteProblemOption: false }));
            }
        });
    }

    const RowItems = stateData?.map((value, index) => {
        return (
            <tr key={index} data-id={value?.OptionId || ''}>
                <td>{value?.OptionName || ''}</td>
                <td>{value?.SearchType || ''}</td>
                <td>{value?.SearchString || ''}</td>
                <td className='text-center'>{value[`ICD-10`] || ''}</td>
                <td className='text-center'>{value[`HCC`] || ''}</td>
                <td className='text-center'>{value[`RAF`] || ''}</td>
                <td className='text-right'>
                    <div className="d-flex justify-content-end">
                        <ButtonEdit handleEdit={() => toggle('UpdateProblemOption', value)} />
                        <ButtonDelete handleDelete={() => toggle('DeleteProblemOption', value)} />
                    </div>
                </td>
            </tr>
        )
    });

    return (
        <React.Fragment>
            <table className="table mb-0">
                {stateData?.length > 0 && <TheadOptions />}
                {stateData?.length > 0 && (
                    <tbody>
                        {RowItems}
                    </tbody>
                )}
            </table>
            {stateData?.length === 0 && <DataEmty content={`No Data`} />}
            <ModalEditOption
                {...props}
                isOpen={modal?.UpdateProblemOption}
                toggle={() => toggle('UpdateProblemOption')}
                data={itemCurrent}
            />
            <ModalDeleteCommon
                title={`Are you sure you want <br/> to delete this Problem Option ? `}
                sub={`This action is not reversible.`}
                isOpen={modal?.DeleteProblemOption}
                toggle={() => toggle('DeleteProblemOption', itemCurrent)}
                handleRemove={handleRemove}
                isLoading={isLoading}
            />
        </React.Fragment>
    );
};

export default TableOption;