import React, { useState } from "react";
import { useParams } from "react-router-dom";

//Boostrap
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Dropzone
import { useDropzone } from "react-dropzone";

//style
import styled from "styled-components";

//redux
import { useDispatch } from "react-redux";

//api
import { apiCaller } from "@utils";
import { storyboardConstants } from "@constants";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const ContainerUpload = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const ModalUpload = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { toggle, isOpen, timePointId } = props && props;
  const [isLoading, setIsLoading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({ accept: "image/*", multiple: false });

  const handleUpload = () => {
    setIsLoading(true);
    let formData = new FormData();

    //params
    acceptedFiles.map((file) => {
      return formData.append("PatientProfileImage", file);
    });

    formData.append("Action", "Update");
    formData.append("Target", "TimePoint");
    formData.append("TimePointId", timePointId);

    //Call Api
    dispatch({ type: storyboardConstants.UPLOAD_EXAM_PROFILE_REQUEST, isLoading: true });
    apiCaller(`/api/teach/case/${id}/`, "PUT", formData).then((res) => {
      if (res?.status === 200) {
        const data = res?.data;
        dispatch({
          type: storyboardConstants.UPLOAD_EXAM_PROFILE_SUCCESS,
          payload: data
        });
        toggle();
        setIsLoading(false);
      } else {
        dispatch({
          type: storyboardConstants.UPLOAD_EXAM_PROFILE_FAILURE,
          error: 'error'
        });
        setIsLoading(false);
        console.error("Error uploading:");
      }
    });

  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={`static`}>
      <ModalHeader toggle={toggle}>Change Case Photo</ModalHeader>
      <ModalBody>
        <ContainerUpload
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
          })}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
          {acceptedFiles.map((file, index) => (
            <p key={index}>
              {file.path} - {file.size} bytes
            </p>
          ))}
        </ContainerUpload>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          className="btn btn-brand --bg-primary d-flex align-items-center"
          onClick={handleUpload}
          disabled={isLoading}
        >
          {isLoading &&
            <span className="spinner-border spinner-border-sm mr-2"></span>
          }
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalUpload;
