import React, { useState, useEffect } from "react";

//common
import { DataEmty } from "@components/common/";

//utils
import { LoaderText, messageData } from "@utils";

//reactstrap
import { Row, Col } from "reactstrap";

//react-lines-ellipsis
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

//call Action
import {
  actCreateSeries,
  actDeleteSeries
} from "@actions";

//untils
import { apiCaller } from "@utils";

//redux
import { useDispatch, useSelector } from "react-redux";

//constants
import { instructorConstants, seriesConstants } from "@constants";

//lodash
import _ from "lodash";

//components
import {
  ModalSeries,
  ModalDeleteSeries,
  ModalAddCase,
  ModalDeleteCaseToSeries,
} from "@components/instructor/Modal";

import {
  SortBy,
  FilterCaseId,
  ModulesLeft,
  ModulesTop,
  CaseList,
  NewSeriesButton
} from "../Modules";

function Modules(props) {
  const dispatch = useDispatch();

  //props
  const { dataSeries, instructorData } = props && props;

  //store Series active
  const seriesLoadById = useSelector(
    (state) => (state.seriesLoadById && state.seriesLoadById.data) || []
  );

  //Load All
  const dataInstitution = useSelector(
    (state) => state?.loadInstitutionAllCases?.data || []
  );

  //setState
  const [seriesData, setSeriesData] = useState(dataSeries || []);
  const [modal, setModal] = useState(false);
  const [isBrief, setBriefView] = useState(false);
  const [activeSeries, setActiveSeries] = useState("all_case");
  const [objectSeries, setObjectSeries] = useState({});
  const [dataCase, setCase] = useState(seriesLoadById?.Cases || []);
  const [itemActive, setItemActive] = useState({});
  const [actionsModal, setActionsModal] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isLoadingAll, setLoadingAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [openModalAddCase, setOpenModalAddCase] = useState(false);
  const [modalDel, setModalDel] = useState({
    deleteSeries: false
  });

  //onLoad Series
  useEffect(() => {
    setSeriesData(dataSeries || []);
  }, [dataSeries]);

  const toggleAddCase = () => {
    setOpenModalAddCase(!openModalAddCase);
    setActionsModal("add-case");
  };

  const toggleAddSeries = () => {
    setModal(!modal);
    setActionsModal("add");
  };

  const filterCase = (item) => {
    setItemActive(item);
    setIsFilter(true);
    setObjectSeries(item);
    actFetchCaseWithSeriesId(item?.Id);
  };

  const actFetchCaseWithSeriesId = (seriesId) => {
    setLoading(true);
    dispatch({
      type: instructorConstants.FILTER_CASE_REQUEST,
      isLoading: true,
    });
    apiCaller(`/api/teach/series/${seriesId}/`, "GET", null).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: instructorConstants.FILTER_CASE_SUCCESS,
          payload: data,
          seriesId: seriesId
        });
        setLoading(false);
      } else {
        dispatch({
          type: instructorConstants.FILTER_CASE_FAILURE,
          error: "error",
        });
        setLoading(false);
      }
    });
  }

  //load all case
  const handelLoadAllCase = () => {
    setIsFilter(false);
    setLoadingAll(true);

    dispatch({ type: instructorConstants.INSTRUCTOR_REQUEST, isLoading: true });
    apiCaller(`/api/teach/portfolio/`, "GET", null).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({ type: instructorConstants.INSTRUCTOR_SUCCESS, payload: data });

        //set loading and data
        setCase(data?.Cases);
        setLoadingAll(false);
        setActiveSeries("all_case");
      } else {
        dispatch({ type: instructorConstants.INSTRUCTOR_FAILURE, error: "error" });

        //set loading
        setLoadingAll(false);
      }
    });
  };

  const handleAddSeries = (params) => {
    dispatch(actCreateSeries(params));
    setModal(false);
  };

  useEffect(() => {
    const allCalse = instructorData?.data?.Cases;
    setCase(allCalse || []);
    if (isFilter) {
      setActiveSeries(instructorData?.seriesId);
    }
  }, [instructorData, isFilter]);

  const handleSort = (e) => {
    const { value } = e.target;
    const allCase = instructorData?.data?.Cases;
    if (allCase?.length > 0) {
      if (parseFloat(value) === 1) {
        //Sort By Id
        setCase(_.orderBy(allCase, ["Title"], ['asc']));
      } else if (parseFloat(value) === 2) {
        //Sort By Title
        setCase(_.orderBy(allCase, ["Title"], ['desc']));
      } else {
        setCase(allCase);
      }
    }
  };

  //Series
  const itemSeries = seriesData?.map(function (item, i) {
    const title = `${item?.Name}`;
    return (
      <li
        className="py-2 --hover-text-primary"
        key={i}
        data-id={item.Id}
        onClick={() => filterCase(item)}
        style={{ cursor: 'pointer' }}
      >
        <div
          className={`d-flex align-items-center ${activeSeries === item.Id ? "active --text-primary font-weight-500" : " not-active"}`}
        >
          <HTMLEllipsis
            unsafeHTML={title}
            maxLine='2'
            ellipsis='...'
            basedOn='letters'
          />
          <div className="ml-auto d-flex align-items-center">
            <span className={`ml-2 text-1 font-weight-500 mr-2 ${activeSeries === item.Id ? "active --text-primary" : "text-muted"}`}>({item?.NumberOfCases || 0})</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </div>
      </li>
    );
  });

  const toggleModalDel = (item, type) => {
    setModalDel((prevState) => ({ ...prevState, [type]: !modalDel[type] }));
    setActionsModal(type);
    setItemActive(item);
  };

  const handleRemove = (type) => {
    let seriesId = objectSeries && objectSeries.Id;
    if (type === "deleteSeries") {
      const params = {
        Action: "Delete",
        SeriesId: seriesId,
      };
      dispatch(actDeleteSeries(params));

      //reload
      handelLoadAllCase();
    } else if (type === "deleteCase") {
      const params = {
        Action: "RemoveCase",
        CaseId: itemActive.Id,
      };
      deleteCasefromSeries(seriesId, params);
    }

    //close modal
    setModalDel((prevState) => ({ ...prevState, [type]: false }));
  };

  //Remove case by seriesId
  const deleteCasefromSeries = (seriesId, params) => {
    setLoading(true);
    apiCaller(`/api/teach/series/${seriesId}/`, 'PUT', params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: seriesConstants.DELETE_CASE_FROM_SERIES_SUCCESS,
          payload: data,
          seriesId: seriesId
        });

        //update data and close
        setTimeout(() => {
          setCase(data?.Cases);
          setLoading(false);
        }, 2000);
      } else {
        dispatch({
          type: seriesConstants.DELETE_CASE_FROM_SERIES_FAILURE,
          error: "error",
        });
        setLoading(false);
      }
    });
  }

  //Add Case by seriesId
  const addCaseToSeries = (params, seriesId) => {
    setLoading(true);
    dispatch({
      type: instructorConstants.ADD_CASE_TO_SERIES_REQUEST,
      isLoading: true
    });
    apiCaller(`/api/teach/series/${seriesId}/`, 'PUT', params).then((res) => {
      const data = res && res.data;
      if (res && res.status === 200) {
        dispatch({
          type: instructorConstants.ADD_CASE_TO_SERIES_SUCCESS,
          payload: data,
          seriesId: seriesId
        });

        //update data and close
        setTimeout(() => {
          setCase(data?.Cases);
          setLoading(false);
          setOpenModalAddCase(false);
        }, 2000);
      } else {
        dispatch({
          type: instructorConstants.ADD_CASE_TO_SERIES_FAILURE,
          error: "error",
        });

        //Close and set Loading
        setLoading(false);
        setOpenModalAddCase(false);
      }
    });
  }

  let dataDropdown = dataInstitution.filter(
    (o1) => !dataCase.some((o2) => o1.Id === o2.Id)
  );

  const contentEmty = isFilter ? messageData?.modulesEmty?.messageSeries : messageData.modulesEmty.messageAllCase;
  return (
    <div className="wrapper -instructorPage -layout-tools position-relative">
      {(instructorData?.isLoading || isLoadingAll) && <LoaderText />}
      <Row>
        <Col sm={12}>
          <div className="modulesTab">
            <ModulesLeft
              handleAllCase={handelLoadAllCase}
            >
              {itemSeries}
              <NewSeriesButton
                toggleAddSeries={toggleAddSeries}
              />
            </ModulesLeft>
            <div className="modulesTab__right position-relative">
              {isLoading && <LoaderText />}
              <React.Fragment>
                <div className="caseTop mb-3 mb-md-0">
                  <ModulesTop />
                  {activeSeries === "all_case" ? (
                    <SortBy
                      handleSort={handleSort}
                    />
                  ) : (
                    <FilterCaseId
                      itemActive={itemActive}
                      activeSeries={activeSeries}
                      toggleModalDel={toggleModalDel}
                      toggleAddCase={toggleAddCase}
                      seriesLoadById={seriesLoadById}
                      handleChangeBriefView={(e) => setBriefView(e.target.checked)}
                      isBrief={isBrief}
                    />
                  )}
                  <hr />
                </div>
                {/* /top */}
                <div className="caseBottom position-relative">
                  {dataCase?.length > 0 && (
                    <React.Fragment>
                      {isLoading && (
                        <div style={{ height: "300px" }}>
                          <LoaderText className="h-100" />
                        </div>
                      )}
                      {!isLoading && (
                        <CaseList
                          data={(dataCase && dataCase) || []}
                          activeSeries={activeSeries}
                          toggleModalDel={toggleModalDel}
                          isBrief={isBrief}
                        />
                      )}
                    </React.Fragment>
                  )}
                  {dataCase?.length === 0 && (
                    <DataEmty content={contentEmty} />
                  )}
                </div>
                {/* /bottom */}
              </React.Fragment>
            </div>
          </div>
        </Col>
      </Row>
      {/* Modal */}
      <ModalSeries
        isOpen={modal}
        toggle={toggleAddSeries}
        handleAddSeries={handleAddSeries}
        actionsModal={actionsModal}
      />
      <ModalDeleteSeries
        isOpen={modalDel.deleteSeries}
        toggle={() => toggleModalDel(itemActive, "deleteSeries")}
        data={itemActive}
        handleRemove={() => handleRemove("deleteSeries")}
        actionsModal={actionsModal}
      />
      <ModalDeleteCaseToSeries
        isOpen={modalDel.deleteCase}
        toggle={() => toggleModalDel(itemActive, "deleteCase")}
        data={itemActive}
        handleRemove={() => handleRemove("deleteCase")}
        objectSeries={objectSeries}
      />
      <ModalAddCase
        isOpen={openModalAddCase}
        toggle={toggleAddCase}
        seriesId={activeSeries}
        actionsModal={actionsModal}
        dataCase={dataDropdown}
        addCaseToSeries={addCaseToSeries}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Modules;