import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Icon } from '@components/common'
import FileCase from '@images/teachV2/file-case.svg'
import Error from '@images/teachV2/error-circle.svg'
import Close from '@images/teachV2/close.svg'
import { getReferenceBook } from "@actions";
import { DataEmptyCase } from "@components-common";
import { useHistory } from "react-router-dom";

//toast
import { toast, Slide } from 'react-toastify';

const ModalListBook = (props) => {
  const { isOpen, toggle, setBookId, chooseOne, dataDetail } = props;
  const { SearchBar } = Search;
  let history = useHistory();
  const dispatch = useDispatch();
  const { data: documents } = useSelector((state) => state.getReferenceBook) || {};
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    if (dataDetail?.reference_id) {
      const book = documents?.Combined?.filter((doc) => doc?.document_id === dataDetail?.reference_id)
      setBookId(book)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetail?.reference_id, documents?.Combined])

  useEffect(() => {
    dispatch(getReferenceBook())
  }, [dispatch])

  const columns = [
    {
      dataField: "title",
      text: "",
      headerClasses: "align-middlefont-weight-500",
      classes: "align-middle",
      sort: true,
      editable: false,
      formatter: (value, row) => {
        return (
          <div className={`d-flex justify-content-between item-book`}>
            <div className='d-flex'>
              <Icon src={FileCase} />
              <p className="ml-2">{value}</p>
            </div>
            {row?.status?.content === 'Caution' && 
              <div className="item-caution">
                <Icon src={Error} />
              </div>
            }
          </div>
        );
      },
    },
  ]

  const showToast = (message) => {
    toast(
      <span className='d-flex align-items-center' style={{ color: "#000", fontSize: "16px", fontWeight: "400" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" style={{ marginRight: '8px' }}>
          <g clipPath="url(#clip0_3032_6505)">
            <path d="M10 0.3125C4.65012 0.3125 0.3125 4.65168 0.3125 10C0.3125 15.3514 4.65012 19.6875 10 19.6875C15.3499 19.6875 19.6875 15.3514 19.6875 10C19.6875 4.65168 15.3499 0.3125 10 0.3125ZM10 4.60938C10.9061 4.60938 11.6406 5.34391 11.6406 6.25C11.6406 7.15609 10.9061 7.89062 10 7.89062C9.09391 7.89062 8.35938 7.15609 8.35938 6.25C8.35938 5.34391 9.09391 4.60938 10 4.60938ZM12.1875 14.5312C12.1875 14.7901 11.9776 15 11.7188 15H8.28125C8.02238 15 7.8125 14.7901 7.8125 14.5312V13.5938C7.8125 13.3349 8.02238 13.125 8.28125 13.125H8.75V10.625H8.28125C8.02238 10.625 7.8125 10.4151 7.8125 10.1562V9.21875C7.8125 8.95988 8.02238 8.75 8.28125 8.75H10.7812C11.0401 8.75 11.25 8.95988 11.25 9.21875V13.125H11.7188C11.9776 13.125 12.1875 13.3349 12.1875 13.5938V14.5312Z" fill="#5296D5"/>
          </g>
          <defs>
            <clipPath id="clip0_3032_6505">
              <rect width="20" height="20" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        {message}
      </span>,
      {
        position: "bottom-center",
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        theme: "colored",
        toastId: "LIMIT_DOCUMENTS_TOAST",
        transition: Slide,
      }
    );
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    classes: 'active-item',
    hideSelectAll: true,
    hideSelectColumn: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect && selectedBooks.length >= 5) {
        showToast("Please select up to 5 documents at a time.");
        e.preventDefault();
        return false;
      }
      if (chooseOne) {
        setSelectedBooks([row]);
      } else if (isSelect) {
        setSelectedBooks([...selectedBooks, row]);
      } else {
        setSelectedBooks(selectedBooks.filter((book) => book.document_id !== row.document_id));
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        const selected = rows.slice(0, 5);
        setSelectedBooks(selected);
        if (rows.length > 5) {
          showToast("Please select up to 5 documents at a time.");
        }
      } else {
        setSelectedBooks([]);
      }
    },
    selected: selectedBooks.map(book => book.document_id)
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => {
    return (
      <React.Fragment>
        <ToolkitProvider
          keyField="document_id"
          data={documents?.Combined || []}
          columns={columns}
          search
        >
          {(toolkitprops) => (
            <div>
              <div className="boxSearch w-100 mw-100" style={{ marginBottom: '24px' }}>
                <SearchBar {...toolkitprops.searchProps} onSearch={(e) => {
                  paginationProps.page = 1
                  toolkitprops.searchProps.onSearch(e)
                }} />
              </div>
              <BootstrapTable
                condensed
                wrapperClasses="table-responsive list-books"
                data={documents?.Combined || []}
                columns={columns}
                noDataIndication={() => (
                  <DataEmptyCase
                    title="No Results Found"
                    text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                  />
                )}
                {...paginationTableProps}
                {...toolkitprops.baseProps}
                selectRow={selectRow}
              />
            </div>
          )}
        </ToolkitProvider>
        {selectedBooks?.some((book) => book?.status?.content === "Caution") && showError && (
          <div className="note-reference">
            <Icon src={Error} />
            <p>This document contains limited readable text, which may affect the generated content quality. Please proceed with caution when using it for generation.</p>
            <div className="note-reference__close" onClick={() => setShowError(false)}>
              <Icon src={Close} stroke="#DC6803" />
            </div>
          </div>
        )}
        <div className="pagination-custom-case d-flex justify-content-end ml-4">
          <PaginationListStandalone {...paginationProps} />
        </div>
      </React.Fragment>
    )
  };

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: documents?.Combined.length || [],
  };

  const handleClose = () => {
    setShowError(true)
    setSelectedBooks([]);
    toggle()
  }

  return (
    <Modal centered={true} isOpen={isOpen} toggle={handleClose} backdrop={`static`} style={{ maxWidth: '830px' }} className='modal-scenario'>
      <ModalHeader toggle={handleClose}></ModalHeader>
      <ModalBody className="p-3">
        {documents?.Combined.length ? (
          <>
            <div className="title-scenario">
              <h1>Select a Document from Your Reference Library</h1>
              {!chooseOne && 
                <p>You can choose a maximum of 5 documents for generation. Use the search bar to find your documents.</p>
              }
            </div>
            <PaginationProvider pagination={paginationFactory(options)}>
              {contentTable}
            </PaginationProvider>
          </>
        ) : (
          <DataEmptyCase
            title="Your List is Empty"
            text="It looks like you haven't uploaded any reference documents yet. Click ‘Go to Reference Library’ and upload your document to add new reference materials."
          />
        )}
      </ModalBody>
      {documents?.Combined.length ? (
        <ModalFooter>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            className="btn d-flex align-items-center"
            color="primary"
            onClick={() => {
              setBookId(selectedBooks);
              handleClose();
            }}
            disabled={selectedBooks.length === 0}
          >
            Confirm
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter className='footer-empty'>
          <Button
            className="btn"
            color="primary"
            onClick={() => history.push("/teach/reference-library")}
          >
            Go to Reference Library
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={() => {
              handleClose()
            }}
          >
            Back
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default ModalListBook;