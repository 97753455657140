import React from 'react';

//imgPerson
import imgPerson from "@images/common/person.png";

function UserMessage(props) {
    const { content, profile_photo_url } = props && props;
    return (
        <div className={`messengerCt__wrapper`}>
            <div className="messengerCt__message messengerCt__message--out">
                <div className="messengerCt__message-body">
                    <div className="messengerCt__message-content --arrow-right">
                        <div className="messengerCt__message-text">
                            <div className="whiteSpace-break" dangerouslySetInnerHTML={{ __html: content }} />
                        </div>
                    </div>
                </div>
                <div className="messengerCt__message-pic">
                    <img src={profile_photo_url || imgPerson} alt="" />
                </div>
            </div>
        </div>
    );
};

export default UserMessage;