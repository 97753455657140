import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";

//router
import { useParams } from "react-router-dom";

//boostrap
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";

//components
import { UploadFile } from "@components-common";

//redux
import { useDispatch } from "react-redux";

//Html Component
import JoditEditor from "jodit-react";

//call Action
import { atcCreateReference } from "@actions";

//Config
import * as Config from "@constants/Config";

function ModalAddreferences(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { isOpen, toggle, type, dataCurrent } = props && props;

  //state
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [isType, setType] = useState("html");
  const [imageData, setImageData] = useState([]); //files 

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("Action", "Create");
    formData.append("Type", isType);
    formData.append("Target", "Reference");
    formData.append("Name", data?.Name);

    if (isType === 'pdf') {
      formData.append("File", imageData);
    }

    if (isType === 'html') {
      formData.append("Contents", imageData);
    }

    if (isType === 'avomd') {
      formData.append("Contents", data?.avomdUrl);
    }

    dispatch(atcCreateReference(formData, id));

    //Reset data
    setValue("Name", "", { shouldValidate: false });
    setValue("avomdUrl", "", { shouldValidate: false });
    toggle();
  };

  const handleChangeRaido = (name) => {
    setType(name);
  };

  //handleUploadImage
  const handleUploadImage = (data) => {
    //Set File FormData
    data.map((file) => setImageData(file));
  };

  return (
    <Modal
      style={{ maxWidth: type === 'add' ? "900px" : "600px" }}
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      backdrop={`static`}
    >
      <ModalHeader toggle={toggle}>
        <span className="--text-primary">{type === 'add' ? "Add Reference" : "Edit Reference"}</span>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row>
            <Col sm={8}>
              <FormGroup>
                <Label for="Name">Name</Label>
                <Input
                  type="text"
                  name="Name"
                  {...register("Name", { required: true })}
                  invalid={errors.Name?.type === "required"}
                  defaultValue={dataCurrent?.ReferenceName}
                  onChange={(e) => {
                    setValue("Name", e.target.value, { shouldValidate: true });
                  }}
                  placeholder="Enter the name for the new period"
                  autoComplete="off"
                />
                <FormFeedback
                  invalid={String(errors.Name?.type === "required")}
                >
                  This is required.
                </FormFeedback>
              </FormGroup>
            </Col>
            {type === 'add' &&
              <React.Fragment>
                <Col className="border-right" sm={12}>
                  <FormGroup tag="fieldset">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="type"
                          onChange={() => handleChangeRaido("html")}
                          checked={isType === 'html' ? true : false}
                        />{" "}
                        HTML Contents
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="type"
                          onChange={() => handleChangeRaido("pdf")}
                          checked={isType === 'pdf' ? true : false}
                        />{" "}
                        PDF File
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="type"
                          onChange={() => handleChangeRaido("avomd")}
                          checked={isType === 'avomd' ? true : false}
                        />{" "}
                        AvoMD
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
                {isType === "html" && (
                  <Col sm={12}>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={Config.CONFIG_JODIT}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    />
                  </Col>
                )}
                {isType === "pdf" && (
                  <Col sm={12}>
                    <UploadFile acceptFile="application/pdf" onChange={handleUploadImage} />
                  </Col>
                )}
                {isType === "avomd" && (
                  <Col sm={8}>
                    <FormGroup>
                      <Label for="avomdUrl">AvoMD module URL</Label>
                      <Input
                        type="url"
                        name="avomdUrl"
                        {...register("avomdUrl", {
                          required: {
                            value: true,
                            message: 'Url is required'
                          }
                        })}
                        invalid={errors.avomdUrl}
                        defaultValue={watch("avomdUrl")}
                        onChange={(e) => {
                          setValue("avomdUrl", e.target.value, { shouldValidate: true });
                        }}
                        placeholder="AvoMD module URL"
                        autoComplete="off"
                      />
                      {errors.avomdUrl &&
                        <FormFeedback className="d-block">
                          {errors?.avomdUrl?.message}
                        </FormFeedback>
                      }
                    </FormGroup>
                  </Col>
                )}
              </React.Fragment>
            }
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          className="btn btn-brand --bg-primary d-flex align-items-center"
          onClick={handleSubmit(onSubmit)}
        >
          {type === 'add' ? "Add" : "Update"}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAddreferences;