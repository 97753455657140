import React from 'react';

//components
import { SwitchItem } from '@components/common/SwitchButton';

//boostrap
import { Row } from "reactstrap";

function GroupSettings(props) {
    return (
        <Row>
            <SwitchItem {...props} labelClassName="font-weight-500" showBorder={false} />
        </Row>
    );
};

export default GroupSettings;