import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

//JoditEditor
import JoditEditor from "jodit-react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from "reactstrap";

//Config
import * as Config from "@constants/Config";

//redux
import { useDispatch } from "react-redux";

//apiCaller
import { apiCaller } from "@utils";
import { problemsConstant } from "@constants";

const ModalAddProblem = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const editor = useRef(null);
    const { toggle, isOpen, data } = props && props;

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    //state
    const [isLoading, setLoading] = useState(false);
    const [stateData, setData] = useState(data || {});
    const [content, setContent] = useState(data?.Comments || '');

    //Load Data
    useEffect(() => {
        setData(data || {});
        setValue("Name", data?.ProblemName, { shouldDirty: false });
        setContent(data?.Comments || '');
    }, [data, setValue])

    const onSubmit = (data) => {
        const params = {
            "Action": "UpdateProblem",
            "CaseId": id,
            "DocumentationProblemId": stateData?.ProblemId,
            "Name": data?.Name,
            "Comments": content || ''
        };

        setLoading(true);
        dispatch({ type: problemsConstant.UPDATE_PROBLEM_ITEM_REQUEST, isLoading: true });
        apiCaller(`/api/teach/documentation/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: problemsConstant.UPDATE_PROBLEM_ITEM_SUCCESS, payload: data });
                toggle();
                setLoading(false);
            } else {
                dispatch({ type: problemsConstant.UPDATE_PROBLEM_ITEM_FAILURE, error: "error" });
                setLoading(false);
                toggle();
            }
        });

        //Reset data
        setValue('Name', '', { shouldValidate: false });
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Edit Problem</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="Name">Name: <span className="m--font-danger">*</span></Label>
                        <Input
                            type="text"
                            name="Name"
                            {...register("Name", { required: true })}
                            invalid={errors.Name?.type === "required"}
                            defaultValue={watch("Name")}
                            onChange={(e) => {
                                setValue("Name", e.target.value, { shouldValidate: true });
                            }}
                            placeholder=""
                            autoComplete="off"
                        />
                        <FormFeedback
                            invalid={String(errors.Name?.type === "required")}
                        >
                            This is required.
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="Comments">Comments:</Label>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            config={Config.CONFIG_JODIT}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Cancel
                    </Button>{" "}
                    <Button
                        color="danger"
                        className="btn btn-brand --bg-primary"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {isLoading &&
                            <span className="spinner-border spinner-border-sm mr-2"></span>
                        }
                        Save
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default ModalAddProblem;
