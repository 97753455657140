import { apiCaller } from "@utils";
import { learnerConstants } from "@constants";

// Update Learner Demo
export const atcUpdateLearnerDemo = (params) => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller('/api/account/update/', 'POST', params).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: learnerConstants.UPDATE_LEARNER_DEMO_REQUEST, isLoading } }
    function success(data) { return { type: learnerConstants.UPDATE_LEARNER_DEMO_SUCCESS, payload: data } }
    function failure(error) { return { type: learnerConstants.UPDATE_LEARNER_DEMO_FAILURE, error: error } }
}

//Get Learner Portfolio
export const getPortfolioInfo = () => {
    const isLoading = true;
    return dispatch => {
        dispatch(request(isLoading));
        return apiCaller('/api/learn/portfolio/', 'GET', null).then(res => {
            const data = res && res.data;
            if (data) {
                dispatch(success(data));
            } else {
                dispatch(failure("error"));
            }
        });
    };
    function request(isLoading) { return { type: learnerConstants.PORTFOLIO_REQUEST, isLoading } }
    function success(data) { return { type: learnerConstants.PORTFOLIO_SUCCESS, payload: data } }
    function failure(error) { return { type: learnerConstants.PORTFOLIO_FAILURE, error: error } }
}

// setTypeChatActive
export const setTypeChatActive = (isOpen) => ({
    type: learnerConstants.SET_TYPE_CHAT_MOBILE,
    payload: isOpen,
});

// toggleChatFeedbackMobile
export const toggleChatFeedbackMobile = (isOpen) => ({
    type: learnerConstants.TOGGLE_CHAT_FEEDBACK,
    payload: isOpen,
});

// toggleChatFeedbackMobile
export const NumberFeedbackTrigger = (data) => ({
    type: learnerConstants.TOTAL_CHAT_VOICE_TRIGGER,
    payload: data,
});

// Load Virtual Preceptor Status without loading state
export const LoadVirtualPreceptorStatus = (SessionId) => {
    return dispatch => {
        return apiCaller(`/api/learn/case/virtual_preceptor/${SessionId}/`, 'GET', null)
            .then(res => {
                const data = res?.data;
                if (res?.status === 200) {
                    dispatch(success(data));
                } else {
                    dispatch(failure("Error occurred"));
                }
            })
            .catch(error => {
                dispatch(failure(error.message || "An error occurred"));
            });
    };

    function success(data) {
        return {
            type: learnerConstants.LOAD_VIRTUAL_PRECEPTOR_STATUS_SUCCESS,
            payload: data
        };
    }

    function failure(error) {
        return {
            type: learnerConstants.LOAD_VIRTUAL_PRECEPTOR_STATUS_FAILURE,
            error: error
        };
    }
};