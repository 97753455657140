import React, { useState, useEffect } from "react";
import { Iconsax } from "@components-common";
import { LockView } from "../StandardScreen/InteractAssistant/AssistantQuestion";
import { useSelector, useDispatch } from "react-redux";
import { actActiveTabsMedicalRecord } from "@actions";

// Popper
import { PopperItem } from "@components/common/Popper";

// components Mobile
import { ButtonChatFBMobile } from "@components/learner/StandardScreen/LearnerMobile";

// components PC
import { ButtonChatPC } from "@components/learner/StandardScreen/ChatFeedback";

// Utility function to detect if the device is mobile
const isMobile = () => {
    return window.innerWidth <= 768; // You can adjust this width threshold as needed
};

function ButtonClosePanel(props) {
    const { handleExamMenu, isCurrentTask, isViewChatFullScreen } = props && props;
    const dispatch = useDispatch();

    // Case Data
    const caseData = useSelector((state) => state.cases || []);
    const statusTabActiveMedicalRecord = useSelector((state) => state.statusTaskActiveTabMedicalRecord?.activeTab || null);
    const InstantChatFeedbackOption = caseData?.data?.Case?.ChatSettings?.InstantChatFeedbackOption;

    const chatRecordFreeText = useSelector((state) => (state?.dataLoadRecordPatient?.data) || []);
    const chatRecordVoice = useSelector((state) => (state?.countChatVoice?.data) || []);

    // Check Lock Elements
    const isChatPending = useSelector((state) => state.isChatPending || {});
    const isLock = isChatPending?.isLock;

    const [showPopper, setShowPopper] = useState(false);
    const [popperDismissed, setPopperDismissed] = useState(false); // Trạng thái khi người dùng tắt popper

    useEffect(() => {
        const FREE_TEXT_LIMIT = 6;
        const VOICE_LIMIT = 3;

        const isFreeTextLimitReached = Array.isArray(chatRecordFreeText) && chatRecordFreeText.length === FREE_TEXT_LIMIT;
        const isVoiceLimitReached = Array.isArray(chatRecordVoice) && chatRecordVoice.length === VOICE_LIMIT;

        // Chỉ hiển thị popper nếu đạt điều kiện và chưa bị tắt thủ công
        if ((isFreeTextLimitReached || isVoiceLimitReached) && !popperDismissed) {
            setShowPopper(true);
        } else {
            setShowPopper(false);
        }
    }, [chatRecordFreeText, chatRecordVoice, popperDismissed]);

    const handleClosePopper = () => {
        setShowPopper(false); // Manually close the popper
        setPopperDismissed(true); 
    };

    const handleClose = () => {
        handleExamMenu(isCurrentTask, ['closeTask', isViewChatFullScreen]);

        if (statusTabActiveMedicalRecord === "labs") {
            dispatch(actActiveTabsMedicalRecord(null));
        }
    };

    const handleButtonClick = () => {
        // Prevent clicking if isLock is true
        if (isLock) return;

        handleClose();
    };

    const isOpen = useSelector((state) => state.typeChatforChatFeedback.isOpen);
    return (
        <div className="box-closeChat d-flex align-items-center">
            {/* Chat with patient */}
            {isMobile() && isOpen === "chat" && (chatRecordFreeText?.length > 0 || chatRecordVoice?.length > 0) && InstantChatFeedbackOption && <ButtonChatFBMobile {...props} />}
            {!isMobile() && isOpen === "chat" && (chatRecordFreeText?.length > 0 || chatRecordVoice?.length > 0) && InstantChatFeedbackOption && <ButtonChatPC {...props} />}
            <button
                className="btn btn-closeChat position-relative"
                onClick={handleButtonClick}
                current-task={isCurrentTask ? 'true' : 'false'}
                style={{
                    width: isMobile() ? '40px' : undefined,
                    height: isMobile() ? '40px' : undefined,
                    borderRadius: isMobile() ? '50%' : undefined
                }}
            >
                <Iconsax iconName="close" fill="#9AA4B2" />
                {isLock && <LockView {...props} />}
            </button>
            {showPopper && !isMobile() && (
                <PopperItem
                    {...props}
                    content={`Click here to close the chat once you've gathered enough information and are ready to proceed to the next task.`}
                    styleInline={{ right: '70px', top: '0', position: 'absolute', maxWidth: '340px' }}
                    postition="right"
                    alignItems="start"
                    isOpen={showPopper}
                    buttonClose={true}
                    icon={<Iconsax iconName="icon-idea" size={32} fill="#F79E1C" />}
                    fontSizeContent="12px"
                    handleClosePopper={handleClosePopper}
                />
            )}
        </div>
    );
}

export default ButtonClosePanel;