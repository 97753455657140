import React from "react";
import { Row, Col } from "reactstrap";

//components
import { SwitchItem } from '@components-common/SwitchButton';

function GroupSwitch(props) {
  const { title, subTitle } = props && props;
  return (
    <div className="inforGroup mt-3">
      {title &&
        <Row>
          <Col md={12}>
            <h5 className="heading-5 font-weight-500 --text-primary mb-4">
              {title}
            </h5>
          </Col>
        </Row>
      }
      <Row className="pl-3">
        {subTitle &&
          <Col md={12} className="mb-3">
            <h6 className="heading-6 font-weight-500 --text-primary">{subTitle}</h6>
          </Col>
        }
        <SwitchItem 
          {...props}
          fullWidth={true}
          showBorder={true}
        />
      </Row>
    </div>
  );
}

export default GroupSwitch;