import React from "react";
import { Button } from "reactstrap";

//components
import { CheckBoxVital, DropDownGenerate } from '../VitalSignsBlock';

function Header(props) {
  //props
  const { activeTabCt, value, toggle, timepointData } = props && props;
  const isTime = timepointData?.data?.Time;
  
  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        <h2 className="heading-2 -heading-primary --text-primary">
          {value.Label}
        </h2>
        <div className="ml-auto">
          {/* Chi hien thi timepoint dau */}
          {isTime === 0 && <DropDownGenerate {...props} />}
          {activeTabCt === "1" && (
            <Button
              onClick={toggle}
              color="primary"
              className="btn btn-brand ml-2"
            >
              <i className="fa fa-plus mr-2"></i> Add Vital Sign
            </Button>
          )}
        </div>
      </div>
      <CheckBoxVital {...props} />
    </React.Fragment>
  );
}

export default Header;
