import React, { useState } from 'react';

//reactstrap
import { Input } from "reactstrap";

//redux
import { useSelector } from "react-redux";

function InputFreeText(props) {
    const { handleQuestionTypePatient, disabled } = props && props;

    //state
    const [value, setValue] = useState('');

    //send Api
    const handleSubmit = (currentValue) => {
        const params = {
            "Action": "Submit",
            "Message": value || currentValue,
        }
        if (value || currentValue) {
            handleQuestionTypePatient(params);
            setValue('');
        }
    }

    //handlechange
    const handleChange = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    //Handle Key Press
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit();
        }
    }

    // Interface Template
    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const VirtualPreceptor = InterfaceTemplateDetails?.VirtualPreceptor;
    const preceptor_reply = VirtualPreceptor?.preceptor_reply?.Text || "Enter your question to the patient ...";
    return (
        <React.Fragment>
            <div className="freeTextTyping mt-3">
                <div className="freeTextTyping__input">
                    <Input
                        name="answer"
                        placeholder={preceptor_reply}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        value={value}
                        disabled={disabled}
                        autoComplete="off"
                    />
                </div>
                <button
                    type="button"
                    className="freeTextTyping__btn btn --btn-primary px-4 d-flex align-items-center justify-content-center"
                    disabled={disabled}
                    onClick={handleSubmit}
                >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    <span className="text-nowrap ml-2">Ask</span>
                </button>
            </div>
        </React.Fragment>
    );
};

export default InputFreeText;