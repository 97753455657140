import React from "react";
import { Link } from "react-router-dom";
import { Iconsax } from "@components-common";

const FormHeader = ({ title }) => {
    return (
        <div className="headingPage py-2 border-bottom">
            <div className="d-flex align-items-center">
                <Link to={`/`}>
                    <Iconsax iconName="back" fill="#101828" size={37} />
                </Link>
                <h2>{title}</h2>
            </div>
        </div>
    );
};

export default FormHeader;