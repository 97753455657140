// Routes
import { kachiRoutes } from "./Med2lab/kachiRoutes";
import { weitzmanRoutes } from "./Med2lab/weitzmanRoutes";
import { adminRoutes } from "./Med2lab/adminRoutes";
import { reliRoutes } from "./Med2lab/reliRoutes";
import { learnerRoutes } from "./Med2lab/learnerRoutes";
import { ucrRoutes } from "./Med2lab/ucrRoutes";
import { nusRoutes } from "./Med2lab/nusRoutes";
import { playgroundRoutes } from "./Med2lab/playgroundRoutes";
import { authRoutes } from "./Med2lab/authRoutes";
import { planRoutes } from "./Med2lab/planRoutes";
import { demoRoutes } from "./Med2lab/demoRoutes";
import { vinuniRoutes } from "./Med2lab/vinuniRoutes";
import { portfolioRoutes } from "./Med2lab/portfolioRoutes";

// Teach routes
import { teachRoutes } from "../routes/Med2lab/Teach";

//Other
import { otherRoutes } from "./Med2lab/otherRoutes";
import {paymentRoutes} from "./Med2lab/paymentRoute";

const LearnerRoutes = [
    ...learnerRoutes
];

const ModuleRoutes = [
    ...kachiRoutes,
    ...weitzmanRoutes,
    ...vinuniRoutes,
    ...reliRoutes,
    ...ucrRoutes,
    ...nusRoutes,
    ...adminRoutes,
    ...playgroundRoutes,
    ...authRoutes,
    ...planRoutes,
    ...demoRoutes,
    ...portfolioRoutes,
    ...paymentRoutes
];

const OtherRoutes = [
    ...otherRoutes
];

// Combining all grouped routes into one export
const Med2lab = [
    ...teachRoutes,
    ...LearnerRoutes,
    ...ModuleRoutes,
    ...OtherRoutes
];

export { Med2lab };
