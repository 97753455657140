import React, { useState, useRef } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import { DataEmptyCase } from "@components-common";
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { shareReference } from "@actions";
import { components } from 'react-select';

//utils
import { LoaderText } from "@utils";

//components
import { UploadButton, WrapModalUpload, ModalUploadReferenceDocument } from "../ReferenceLiblary";

//moment
import moment from "moment";
import CreatableSelect from 'react-select/creatable';

//components
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

const OrgReferenceLibraryWrap = (props) => {
  const { loadDataReferenceBook, listValue, setListValue, inputValue, setInputValue } = props;
  const { SearchBar } = Search;
  const { dataFilter: documents, isLoading } = useSelector((state) => state.getReferenceBook) || {};
  const [modal, setModal] = useState({
    uploadDocument: false,
  });
  const isSearchingOrPaging = useRef(false);
  const dispatch = useDispatch();

  //toggle
  const toggle = (type, value = null) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  const handleUnshareReference = async (data) => {
    const dataShare = await dispatch(shareReference({
      "Action": "Unshare",
      "Target": "Organization",
      "ReferenceId": data?.document_id
    }))
    if (dataShare?.data?.status === 200) {
      toast.success('Successfully unshared from Organization References', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loadDataReferenceBook()
    }
  }

  const componentsCommon = {
    DropdownIndicator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleAddTag = (value) => {
    setListValue(value)
  }

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };


  const columns = [
    {
      dataField: "document_id",
      text: "ID",
      hidden: true,
      headerClasses: "--text-primary align-middle font-weight-500",
      classes: "align-middle",
      headerStyle: {}
    },
    {
      dataField: "title",
      text: "Document",
      headerClasses: "--text-primary text-nowrap",
      headerStyle: { width: "500px" },
      formatter: (cell, row) => {
        return (
          <React.Fragment>
            <div className='text-w-600'>
              {row?.title}
            </div>
            <AuthorList>{row.author_list}</AuthorList>
            <JournalTitle>{row.journal_citation}</JournalTitle>
          </React.Fragment>
        );
      },
    },
    {
      Field: "tags",
      text: "Tags",
      headerClasses: "--text-primary align-middle text-center",
      headerStyle: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {row?.tags?.length > 0 ? (
              <>
                <div className="tag-library">
                  {row?.tags?.[0]}
                </div>
                {row?.tags?.length > 1 && (
                  <div className="tag-library ml-1" data-tip data-for={`tag-${row?.document_id}`}>
                    +{row?.tags?.length - 1}
                    <ReactTooltip id={`tag-${row?.document_id}`} place='top' effect='solid' className="tooltip-tags">
                      <div className='list-tags'>
                        {row?.tags?.map((tag, index) => (
                          index > 0 && tag && <span>{tag}</span>
                        ))}
                      </div>
                    </ReactTooltip>
                  </div>
                )}
              </>
            ) : (<></>)}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      headerClasses: "--text-primary text-nowrap",
      headerStyle: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <div className='format-time text-nowrap'>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "--text-primary text-center",
      headerStyle: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-orgRefer">
            {row?.owned_by_account && (
              <p onClick={() => handleUnshareReference(row)}>
                Unshare
              </p>
            )}
          </div>
        );
      },
    }
  ];

  const handleBlur = () => {
    if (inputValue) {
      setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
    }
  }

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <ToolkitProvider keyField="document_id" data={documents?.InstitutionReferences || []} columns={columns} search>
      {(toolkitprops) => (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className='tb-heading-total'>Total Documents: {documents?.InstitutionReferences?.length}</div>
            <div className='d-flex align-items-center'>
              <div className="boxSearch">
                <SearchBar
                  {...toolkitprops.searchProps}
                  onSearch={(e) => {
                    paginationProps.page = 1;
                    toolkitprops.searchProps.onSearch(e);
                    isSearchingOrPaging.current = false;
                  }}
                  onKeyUp={() => { isSearchingOrPaging.current = true; }}
                />
              </div>
              <div className="email-share__form-input ml-2">
                <CreatableSelect
                  components={componentsCommon}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => {
                    handleAddTag(newValue)}
                  }
                  onInputChange={(newValue) => {
                    setInputValue(newValue)
                  }}
                  onKeyDown={(e) => {
                    paginationProps.onPageChange(1, 10);
                    handleKeyDown(e)
                  }}
                  onBlur={handleBlur}
                  placeholder="Filter by Tags"
                  value={listValue}
                  classNamePrefix={`filter-tag`}
                />
              </div>
              <WrapModalUpload
                title="Upload Reference Document"
                {...props}
              >
                {({onOpen}) => (
                  <UploadButton {...props} toggle={onOpen} />
                )}
              </WrapModalUpload>
            </div>
          </div>
          <BootstrapTable
            condensed
            wrapperClasses="table-custom-common table-responsive mt-4"
            bordered={false}
            data={documents?.InstitutionReferences}
            columns={columns}
            noDataIndication={() => (
              <DataEmptyCase
                title="No Results Found"
                text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
              />
            )}
            {...paginationTableProps}
            {...toolkitprops.baseProps}
          />
          <div className="pagination-custom-case d-flex justify-content-end ml-4">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: documents?.InstitutionReferences?.length || [],
  };

  return (
    <React.Fragment>
      <div className={`table-refer bg-white ${documents?.InstitutionReferences?.length === 0 ? 'position-relative' : ''}`} style={{ height: documents?.InstitutionReferences?.length === 0 ? 'calc(100vh - 72px)' : 'auto' }}>
        {isLoading ? (
          <LoaderText />
        ) : (
          (documents?.InstitutionReferences?.length > 0 || listValue?.length > 0) &&
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        )}
        {documents?.InstitutionReferences?.length === 0 && !isLoading && listValue?.length === 0 && (
          <DataEmptyCase 
            title="Your Library is Empty"
            content="It looks like there are no shared reference documents yet. Start sharing documents from 'My References' to see them here."
          />
        )}
      </div>
      <ModalUploadReferenceDocument
        isOpen={modal.uploadDocument}
        toggleModal={() => toggle("uploadDocument")}
        title="Upload Reference Document"
      />
    </React.Fragment>
  );
};

const AuthorList = styled.p`
  color: #475467;
  font-weight: 400;
`;

const JournalTitle = styled.p`
  color: #475467;
  font-style: italic;
  font-weight: 400;
`;

// const FileName = styled.div`
//   display: flex;
//   align-items: center;
//   color: #475467;
//   font-weight: 400;
//   letter-spacing: 0.15px;
// `;

export default OrgReferenceLibraryWrap;