import React from 'react';
import { Row } from 'reactstrap';
import { CaseListItem } from '../Home';

//redux
import { useSelector } from "react-redux";

//common
import { DataEmty } from "@components-common";

function CaseSection(props) {
    //portfolioData
    const portfolioReducer = useSelector((state) => state?.portfolio || {});
    const InProgressModules = portfolioReducer?.data?.InProgressModules;
    return (
        <section className={`mt-4 position-relative`}>
            <div className="card-area bg-white">
                <div className="d-flex mb-4">
                    <h2 className="headline --size-6">In-Progress</h2>
                </div>
                {InProgressModules?.length > 0 ? (
                    <Row className="case-list">
                        {InProgressModules?.map((caseItem) => (
                            <React.Fragment key={caseItem?.Id}>
                                <CaseListItem
                                    {...caseItem}
                                />
                            </React.Fragment>
                        ))}
                    </Row>
                ) : (
                    <DataEmty content="You’re currently not working on any cases! Start one today 🤸‍♂️" />
                )}
            </div>
        </section>
    );
}

export default CaseSection;