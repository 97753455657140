import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CustomInput, Label } from "reactstrap";
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";

function NatureOfInteraction(props) {
    const { timepointData, timePointCurrent } = props && props;
    let { id } = useParams();
    const dispatch = useDispatch();

    const options = [
        {
            label: 'History Talking',
            value: 'history_taking'
        },
        {
            label: 'Patient Education',
            value: 'patient_education'
        }
    ]

    //state
    const InteractionMode = timepointData?.data?.ChatSettings?.InteractionMode;
    const [stateChecked, setChecked] = useState(InteractionMode);

    //Load
    useEffect(() => {
        const InteractionMode = timepointData?.data?.ChatSettings?.InteractionMode;
        setChecked(InteractionMode);
    }, [timepointData])

    //Update Chat Settings of a Case (InteractionMode Only)
    const handleChange = (type) => {
        setChecked(type);
        const params = {
            "Action": "Update",
            "Target": "ChatSettings",
            "InteractionMode": type,
            "TimePointId": timePointCurrent?.Id
        }
        dispatch({
            type: storyboardConstants.CHAT_SETTINGS_REQUEST,
            isLoading: true,
        });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: 'error' });
            }
        });
    }
    
    return (
        <div className='d-flex flex-column'>
            <Label className="font-weight-500">Nature of interaction:</Label>
            {options?.map((item, index) =>
                <Label className="d-inline-flex align-items-center" key={index}>
                    <CustomInput
                        id={`${item?.value}`}
                        type="radio"
                        name="InteractionMode"
                        checked={stateChecked === item.value} // Check if the current option's value matches the stateChecked
                        onChange={() => handleChange(`${item?.value}`)}
                    />
                    {item?.label}
                </Label>
            )}
        </div>
    );
};

export default NatureOfInteraction;