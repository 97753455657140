import { Player } from '@lottiefiles/react-lottie-player';

const TimePointIcon = () => {
    return (
        <>
            <Player
                autoplay
                loop
                src={process.env.PUBLIC_URL + '/icon-animation/FD3LXsh1aE.json'}
                style={{ width: 220, height: 220 }}
            >
            </Player>
        </>
    );
}

export default TimePointIcon;