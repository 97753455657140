import React, { useEffect } from "react";

//redux
import { useDispatch, useSelector } from "react-redux";

//boostrap
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

//action
import { atcGetAgreement } from "@actions";

//Scrollbar
import { Scrollbar } from "react-scrollbars-custom";

function ModalAgreement(props) {
  const dispatch = useDispatch();
  const { isOpen, handleClose, activeAgreement } = props && props;

  //Load Agreements
  useEffect(() => {
    const fetchData = async () => {
      dispatch(atcGetAgreement());
    };
    fetchData();
  }, [dispatch]);

  const dataStore = useSelector((state) => (state.getDetailAgreements && state.getDetailAgreements.data) || {});
  const PlatformUseAgreementData = dataStore && dataStore.PlatformUseAgreement;
  const PrivacyPolicyData = dataStore && dataStore.PrivacyPolicy;
  const detailModal = (activeAgreement === 'Privacy Policy' ? (PrivacyPolicyData && PrivacyPolicyData.Contents) : (PlatformUseAgreementData && PlatformUseAgreementData.Contents));
  return (
    <Modal
      style={{ maxWidth: "800px" }}
      className="modal-dialog modal-dialog-centered"
      isOpen={isOpen}
      backdrop={`static`}
    >
      <ModalHeader toggle={handleClose}>
        {activeAgreement}
      </ModalHeader>
      <ModalBody>
        <Scrollbar style={{ height: 300 }}>
          <div dangerouslySetInnerHTML={{ __html: detailModal || '' }}></div>
        </Scrollbar>
      </ModalBody>
      <ModalFooter>
        <Button className="mx-auto" color="primary" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModalAgreement;
