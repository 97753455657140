import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import thumbDefault from "@images/common/placeholder.png";

//reactstrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";

//common
import { MessageNotCase } from "@components-common";
import { ModalDeleteCommon } from "@components-common/Modal";

//redux
import { useDispatch } from "react-redux";

//react-hook-form
import { useForm, Controller } from "react-hook-form";

//React Select
import Select from "react-select";

//call Action
import { atcCreateTemplate, atcDeleteTemplate } from "@actions";

const ModalUpdateTemplate = (props) => {
  const dispatch = useDispatch();

  //props
  const { toggle, isOpen, actions, templatesOptions, resetFilter } = props && props;
  const templateParameters = templatesOptions?.SystemTemplateParameters;
  const dataEdit = props && props.data;

  //options
  const ageData = templateParameters?.AgeGroups;
  const ethnicityData = templateParameters?.EthnicityGroups;
  const genderData = templateParameters?.GenderGroups;
  const availableCases = templatesOptions?.AvailableCasesForTemplates;

  //state
  const [modalDel, setModalDel] = useState(false);
  const [stateAge, setStateAge] = useState(ageData || []);
  const [stateEthnicity, setStateEthnicity] = useState(ethnicityData || []);
  const [stateGender, setStateGender] = useState(genderData || []);
  const [stateCases, setStateCase] = useState(availableCases || []);

  //validate
  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
  } = useForm();

  //Setstate
  useEffect(() => {
    setStateAge(ageData);
    setStateEthnicity(ethnicityData);
    setStateGender(genderData);
    setStateCase(availableCases);

    if (actions === "editTemplate") {
      setValue("Name", dataEdit?.TemplateName, {
        shouldDirty: false,
      });
      setValue(
        "Age",
        dataEdit?.AgeGroup && dataEdit.AgeGroup.AgeGroupId,
        { shouldDirty: false }
      );
      setValue(
        "Ethnicity",
        dataEdit &&
        dataEdit.EthnicityGroup &&
        dataEdit.EthnicityGroup.EthnicityGroupId,
        { shouldDirty: false }
      );
      setValue(
        "Gender",
        dataEdit?.Gender && dataEdit.Gender.GenderId,
        { shouldDirty: false }
      );
    }

    if (actions === "addTemplate") {
      reset({ keepIsValid: false, keepValues: false });
    }
  }, [
    ageData,
    ethnicityData,
    genderData,
    availableCases,
    actions,
    dataEdit,
    setValue,
    reset,
  ]);

  const convertDataOptions = (data) => {
    let listOptions = [];
    data?.map(function (item) {
      const dataReturn = { value: item?.CaseId, label: item?.CaseTitle };
      return listOptions.push(dataReturn);
    });
    return listOptions;
  };

  const onSubmit = (data) => {
    if (actions === "addTemplate") {
      const caseId = data?.Case?.value;
      const params = {
        Action: "Create",
        Template: {
          Name: data?.Name,
          GenderId: data?.Gender,
          AgeGroupId: data?.Age,
          EthnicityGroupId: data?.Ethnicity,
          SourceCaseId: caseId,
        },
      };
      dispatch(atcCreateTemplate(params));
      toggle();
    }

    //Reset filter
    resetFilter(true);
  };

  const caseOptions = convertDataOptions((stateCases && stateCases) || []);
  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #dc3545",
    }),
  };

  const toggleDel = () => {
    setModalDel(!modalDel);
  };

  const handleRemove = () => {
    const params = {
      Action: "Delete",
      TemplateId: dataEdit?.TemplateId,
    };
    dispatch(atcDeleteTemplate(params));
    setModalDel(false);
    toggle();
  };

  return (
    <React.Fragment>
      <ModalDeleteCommon
        title={`Are you sure that you want to delete this template?`}
        sub={`The template will be permanently deleted. <br/> However, cases that have been created from this template will not be affected.`}
        isOpen={modalDel}
        toggle={() => toggleDel()}
        handleRemove={handleRemove}
      />
      <Modal
        isOpen={isOpen}
        toggle={() => toggle({}, actions)}
        style={{ maxWidth: "750px", padding: "0 10px" }}
        backdrop={`static`}
      >
        <ModalHeader toggle={() => toggle({}, actions)}>
          <span className="--text-primary">
            {actions === "addTemplate"
              ? "Create New Template"
              : "Edit Template"}
          </span>
        </ModalHeader>
        <ModalBody>
          {caseOptions && caseOptions.length === 0 && <MessageNotCase />}
          {caseOptions && caseOptions.length > 0 && (
            <React.Fragment>
              <FormGroup>
                <Label for="Name">
                  Template Name: <span className="m--font-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="Name"
                  placeholder=""
                  {...register("Name", { required: true })}
                  invalid={errors.Name?.type === "required"}
                  onChange={(e) => {
                    setValue("Name", e.target.value, { shouldValidate: true });
                  }}
                  defaultValue={watch("Name")}
                  autoComplete="off"
                />
                <FormFeedback
                  invalid={String(errors.Name?.type === "required")}
                >
                  This is required.
                </FormFeedback>
              </FormGroup>
              {actions === "addTemplate" && (
                <FormGroup>
                  <Label for="SourceCase">
                    Source Case: <span className="m--font-danger">*</span>
                  </Label>
                  <Controller
                    control={control}
                    rules={{ required: true }} //validate
                    name="Case"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <React.Fragment>
                        <Select
                          closeMenuOnSelect={true}
                          defaultValue={value}
                          onChange={onChange}
                          options={caseOptions || []}
                          placeholder={`Select an item below`}
                          styles={invalid ? customStyles : ""}
                        />
                        {errors.Case?.type === "required" && (
                          <div className="invalid-feedback d-block">
                            This is required.
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  />
                </FormGroup>
              )}
              <Row>
                <Col md={6}>
                  <FormGroup row>
                    <Label for="Age" sm={4}>
                      Age: <span className="m--font-danger">*</span>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="Age"
                        {...register("Age", { required: true })}
                        invalid={errors.Age?.type === "required"}
                        onChange={(e) => {
                          setValue("Age", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        defaultValue={watch("Age")}
                      >
                        <option value="">Select an item below</option>
                        {stateAge &&
                          stateAge.map((item, index) => (
                            <option
                              key={index}
                              data-id={item.AgeGroupId}
                              value={item.AgeGroupId}
                            >
                              {item.AgeGroupName}
                            </option>
                          ))}
                      </Input>
                      <FormFeedback
                        invalid={String(errors.Age?.type === "required")}
                      >
                        This is required.
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup row>
                    <Label for="Ethnicity" sm={4}>
                      Ethnicity: <span className="m--font-danger">*</span>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="Ethnicity"
                        {...register("Ethnicity", { required: true })}
                        invalid={errors.Ethnicity?.type === "required"}
                        onChange={(e) => {
                          setValue("Ethnicity", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        defaultValue={watch("Ethnicity")}
                      >
                        <option value="">Select an item below</option>
                        {stateEthnicity &&
                          stateEthnicity.map((item, index) => (
                            <option
                              key={index}
                              data-id={item.EthnicityGroupId}
                              value={item.EthnicityGroupId}
                            >
                              {item.EthnicityGroupName}
                            </option>
                          ))}
                      </Input>
                      <FormFeedback
                        invalid={String(errors.Ethnicity?.type === "required")}
                      >
                        This is required.
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup row>
                    <Label for="Gender" sm={4}>
                      Gender: <span className="m--font-danger">*</span>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="Gender"
                        {...register("Gender", { required: true })}
                        invalid={errors.Gender?.type === "required"}
                        onChange={(e) => {
                          setValue("Gender", e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                        defaultValue={watch("Gender")}
                      >
                        <option value="">Select an item below</option>
                        {stateGender &&
                          stateGender.map((item, index) => (
                            <option
                              key={index}
                              data-id={item.GenderId}
                              value={item.GenderId}
                            >
                              {item.GenderName}
                            </option>
                          ))}
                      </Input>
                      <FormFeedback
                        invalid={String(errors.Gender?.type === "required")}
                      >
                        This is required.
                      </FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              {actions === "editTemplate" && (
                <div className="content">
                  <hr />
                  <Label for="SourceCase">Source Case:</Label>
                  <Row className="mt-3">
                    <Col sm={4}>
                      <img
                        className="img-thumbnail"
                        src={(dataEdit?.Thumbnail) || thumbDefault}
                        alt={(dataEdit?.TemplateName) || ""}
                      />
                    </Col>
                    <Col sm={8}>
                      <div className="d-flex flex-column">
                        <h5 className="heading-5 mb-3 mt-0">
                          {(dataEdit?.TemplateName) || ""}
                        </h5>
                        <Link
                          style={{ width: "200px" }}
                          target="_blank"
                          className="btn btn-brand --btn-primary"
                          to={`/teach/case/${dataEdit.CaseId}`}
                        >
                          Edit Source Case
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
            </React.Fragment>
          )}
        </ModalBody>
        <ModalFooter>
          {caseOptions && caseOptions.length > 0 ? (
            <React.Fragment>
              {actions === "editTemplate" && (
                <Button className="btn btn-danger mr-auto" onClick={toggleDel}>
                  Delete Template
                </Button>
              )}
              <Button
                color="primary"
                className="btn --bg-primary d-flex align-items-center"
                onClick={handleSubmit(onSubmit)}
              >
                {actions === "addTemplate" ? "Add New" : "Save Template"}
              </Button>
              <Button color="secondary" onClick={() => toggle({}, actions)}>
                Cancel
              </Button>{" "}
            </React.Fragment>
          ) : (
            <Button
              color="primary"
              className="btn --bg-primary px-4" onClick={() => toggle({}, actions)}>
              OK
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ModalUpdateTemplate;