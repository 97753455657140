import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storyboardConstants } from "@constants";
import { apiCaller } from "@utils";

//reactstrap
import { FormGroup, Input, Label } from "reactstrap";

function NatureOfInteraction(props) {
    let { id } = useParams();
    const dispatch = useDispatch();

    //props
    const { timepointData } = props && props;
    const options = [
        {
            label: 'History Talking',
            value: 'history_taking'
        },
        {
            label: 'Patient Education',
            value: 'patient_education'
        }
    ]

    //state
    const InteractionMode = timepointData?.ChatSettings?.InteractionMode;
    const [stateChecked, setChecked] = useState(InteractionMode);

    //Load
    useEffect(() => {
        const InteractionMode = timepointData?.ChatSettings?.InteractionMode;
        setChecked(InteractionMode);
    }, [timepointData])

    //Update Chat Settings of a Case (InteractionMode Only)
    const handleChange = (type) => {
        setChecked(type);
        const params = {
            "Action": "Update",
            "Target": "ChatSettings",
            "InteractionMode": type,
            "TimePointId": timepointData?.Id
        }
        dispatch({
            type: storyboardConstants.CHAT_SETTINGS_REQUEST,
            isLoading: true,
        });
        apiCaller(`/api/teach/case/${id}/`, "PUT", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_SUCCESS, payload: data });
            } else {
                dispatch({ type: storyboardConstants.CHAT_SETTINGS_FAILURE, error: 'error' });
            }
        });
    }

    return (
        <div className='d-flex flex-column'>
            <FormGroup tag="fieldset">
                <Label className='labeForm --label-blue mb-2'>Nature of interaction:</Label>
                {options?.map((item, index) =>
                    <FormGroup check key={index} className='mb-2'>
                        <Label check>
                            <Input
                                id={`${item?.value}`}
                                type="radio"
                                name="InteractionMode"
                                checked={stateChecked === item.value} // Check if the current option's value matches the stateChecked
                                onChange={() => handleChange(`${item?.value}`)}
                            />{' '}
                            {item.label}
                        </Label>
                    </FormGroup>
                )}
            </FormGroup>
        </div>
    );
};

export default NatureOfInteraction;