import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Label } from 'reactstrap';
import { apiCaller, checkAuth } from "@utils";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

// More comprehensive email validation regex
const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const SignupSchema = Yup.object().shape({
  email: Yup.string().matches(emailValidationRegex, 'Please enter a valid email address.').required('Please enter your email.'),
  password: Yup.string().required('Please enter your password.'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match').required('Please confirm your password.')
});

const FormRegister = ({ setDataRegister }) => {
  const history = useHistory();
  const [loadingRegister, setLoadingRegister] = useState(false);

  useEffect(() => {
    if (checkAuth()) {
      history.push("/");
    }
  }, [history]);

  const handleSubmit = (data) => {
    setLoadingRegister(true);
    const endpoint = "/api/auth/register/";
    const params = {
      Mode: "RegisterIndividualSubscription",
      Email: data.email,
      FirstName: data.firstName,
      LastName: data.lastName,
      Organization: data.organization,
      Password: data.password,
      PrivacyPolicyVersion: "1.0",
      PlatformUseAgreementVersion: "1.0"
    };
    apiCaller(endpoint, "POST", params).then((response) => {
      const dataResponse = response?.data;
      if (response?.status === 200) {
        setDataRegister(dataResponse);
      }
      setLoadingRegister(false);
    });
  };

  const handleRedirectLogin = () => {
    history.push("/login");
  };

  const FormField = ({ validate, label, name, type = "text", ...props }) => {
    return (
      <div>
        <Label for={name}>{label} {validate && <span>*</span>} </Label>
        <Field as={Input} id={name} name={name} type={type} {...props} />
        <ErrorMessage name={name} component="span" className="text-err" />
      </div>
    )
  };

  return (
    <div className="wrap-login">
      <div className="wrap-login__title">
        <h1>Register for a Free Trial</h1>
        <p className="text-center">
          Explore the future of healthcare training with CognitusAI. Fill <br /> out the form below to begin your journey today!
        </p>
      </div>
      <div className="wrap-login__form">
        <Formik
          initialValues={{ firstName: '', lastName: '', email: '', password: '', confirmPassword: '', organization: '' }}
          validationSchema={SignupSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="form-custom">
              <div className="form-custom__wrap">
                <div className="form-custom__wrap-db d-flex align-items-center w-100">
                  <div className="w-100">
                    <FormField label="First Name" name="firstName" />
                  </div>
                  <div className="w-100">
                    <FormField label="Last Name" name="lastName" />
                  </div>
                </div>
                <FormField label="Email" validate={true} name="email" type="email" />
                <FormField label="Password" validate={true} name="password" type="password" />
                <FormField label="Confirm Password" validate={true} name="confirmPassword" type="password" />
                <FormField label="Organization" name="organization" />
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <Button type="submit" color="primary" className="w-100 py-3 d-flex align-items-center justify-content-center" disabled={loadingRegister}>
                  {loadingRegister && <ClipLoader color="#FFF" size={16} className="mr-1" />}
                  Register
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        <div className="wrap-login__form-register">
          <p>Already have an account? <span onClick={handleRedirectLogin}>Log in</span></p>
        </div>
        <div className="wrap-login__form-register">
          <p>By registering, you agree to our <a href='https://cognitusai.com/privacy-policy/' rel="noreferrer" target="_blank">Privacy Policy</a> and <span>Terms of Service.</span></p>
        </div>
      </div>
    </div>
  );
};

export default FormRegister;