import React, {useState, useEffect} from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    FormFeedback,
    Form
} from "reactstrap";
import {useForm} from "react-hook-form";

//textarea
import TextareaAutosize from "react-textarea-autosize";

//components
import {UploadFile} from "@components-common";
import {Confirm} from "../ContactUs";

//redux
import {useDispatch, useSelector} from "react-redux";

//router
import {useParams} from "react-router-dom";

//api
import {apiCaller} from "@utils";
import {ticketConstant} from "@constants";

const ContactUs = (props) => {
    let {id} = useParams();
    const dispatch = useDispatch();
    const [isSubmit,
        setSubmit] = useState(false);
    const [isLoading,
        setLoading] = useState(false);
    const [seconds,
        setSeconds] = useState(5);
    const [imageData,
        setImageData] = useState([]); //files
    const [urlImage,
        setUrlImage] = useState("");
    const timepointId = useSelector((state) => (state.timepoint && state.timepoint.timepointId) || null);

    //form
    const {register, formState: {
            errors
        }, handleSubmit, reset, setValue} = useForm();

    const isOpen = props && props.isOpen;
    const toggle = props && props.toggle;

    //handleUploadImage
    const handleUploadImage = (data) => {
        //Set Image Preview
        data.map((file) => setUrlImage(URL.createObjectURL(file)));

        //Set File FormData
        data.map((file) => setImageData(file));
    };

    //submit
    const onSubmit = (data) => {
        const formData = new FormData();
        formData.append("Attachment", imageData);
        formData.append("Message", data.answer);

        if (timepointId && id) {
            formData.append("CaseId", id);
            formData.append("TimePointId", timepointId);
        }

        setLoading(true);
        //Call Api
        apiCaller(`/api/learn/ticket/submit/`, "POST", formData).then((res) => {
            const data = res && res.data;
            if (res && res.status === 200) {
                dispatch({type: ticketConstant.SUBMIT_TICKET_SUCCESS, payload: data});
                setTimeout(() => {
                    setLoading(false);

                    //start view
                    setSubmit(true);
                    setSeconds(5);
                }, 3000);
            } else {
                dispatch({type: ticketConstant.SUBMIT_TICKET_FAILURE, error: "error"});
            }
        });
    };

    useEffect(() => {
        if (seconds > 0 && isSubmit) {
            let timer1 = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => {
                clearTimeout(timer1);
            };
        }

        if (isSubmit && seconds === 0) {
            toggle();
            setSubmit(false);

            //reset Data
            reset({keepIsValid: false, keepValues: false});
            setUrlImage("");
            setSeconds(5);
        }
    }, [seconds, toggle, isSubmit, reset, setValue]);

    const closeViewConfirm = () => {
        setSubmit(false);
        setSeconds(5);
        setUrlImage("");
        toggle();

        //reset Data
        reset({keepIsValid: false, keepValues: false});
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
            <ModalBody>
                {!isSubmit && (
                    <Form>
                        <FormGroup>
                            <Label for="answer">
                                How can we help you?
                                <span className="m--font-danger">*</span>
                            </Label>
                            <p
                                className="text-muted mb-3"
                                style={{
                                fontSize: "12px"
                            }}>
                                Questions or issues that you’d like assistance with, or suggestions that you’d
                                like to make for improving the system.
                            </p>
                            <TextareaAutosize
                                {...register("answer", { required: "This field is required.", })}
                                invalid={(errors.answer
                                ?.type === "answer").toString()}
                                name="answer"
                                placeholder="Enter your answer"
                                className="w-100 form-control"
                                maxRows="10"
                                minRows={5}/> {errors.answer
                                ?.message && (
                                    <FormFeedback className="d-block">
                                        {errors.answer
                                            ?.message}
                                    </FormFeedback>
                                )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="imageFile">
                                [Optional] Attach image of screen shot or file with additional details:
                            </Label>
                            <UploadFile onChange={handleUploadImage} value={urlImage}/>
                        </FormGroup>
                    </Form>
                )}
                {isSubmit && <Confirm/>}
            </ModalBody>
            <ModalFooter>
                <React.Fragment>
                    {!isSubmit && (
                        <Button
                            className="--btn-primary d-flex align-items-center"
                            color="primary"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isLoading}>
                            {isLoading && (
                                <span className="spinner-border spinner-border-sm mr-2"></span>
                            )}
                            Submit
                        </Button>
                    )}
                    <Button color="secondary" onClick={closeViewConfirm}>
                        Cancel {isSubmit && `(${seconds})`}
                    </Button>
                </React.Fragment>
            </ModalFooter>
        </Modal>
    );
};

export default ContactUs;
