import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import imgLogo from '@images/common/med2lab_logo.png';

//Themes
import ThemeContext from "../../../context/ThemeContext";

const HeaderLeft = ({ isLearner }) => {
    // Themes
    const theme = useContext(ThemeContext);
    const primaryLogo = theme?.PrimaryLogo;
    return (
        <div className="header_left">
            {isLearner && (
                <div className={`logo d-flex`}>
                    <Link to={"/"}>
                        <img style={{ height: '60px' }} src={primaryLogo || imgLogo} alt="Med2lab Logo" className="img-fluid" />
                    </Link>
                </div>
            )}
            {/* <h2 className="heading-page">Overview</h2> */}
        </div>
    );
};

export default HeaderLeft;