import { feedbackConstant, learnerConstants } from "@constants";

export function loadFeedbackTemplate(state = {}, action) {
    switch (action.type) {
        case feedbackConstant.LOAD_FEEDBACK_TEMPLATE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case feedbackConstant.LOAD_FEEDBACK_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: (action.payload && action.payload.FeedbackTemplate),
                isLoading: false
            };
        case feedbackConstant.CREATE_FEEDBACK_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: (action.payload && action.payload.FeedbackTemplate),
                isLoading: false
            };
        case feedbackConstant.ASSIGN_FEEDBACK_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: (action.payload && action.payload.FeedbackTemplate),
                isLoading: false
            };
        case feedbackConstant.UPDATE_FEEDBACK_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: (action.payload && action.payload.FeedbackTemplate),
                isLoading: false
            };
        case feedbackConstant.LOAD_FEEDBACK_TEMPLATE_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function ListAvailableFeedbackTemplates(state = {}, action) {
    switch (action.type) {
        case feedbackConstant.LOAD_AVAILABLE_FEEDBACK_TEMPLATES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case feedbackConstant.LOAD_AVAILABLE_FEEDBACK_TEMPLATES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false
            };
        case feedbackConstant.LOAD_AVAILABLE_FEEDBACK_TEMPLATES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default: return state;
    }
};

export function typeChatforChatFeedback(state = {}, action) {
    switch (action.type) {
        case learnerConstants.SET_TYPE_CHAT_MOBILE:
            return {
                ...state,
                isOpen: action.payload,
            };
        default: return state;
    }
};

export function chatFeedbackReducer(state = {}, action) {
    switch (action.type) {
        case learnerConstants.TOGGLE_CHAT_FEEDBACK:
            return {
                ...state,
                isOpen: action.payload,
            };
        default: return state;
    }
};

export function countChatVoice(state = {}, action) {
    switch (action.type) {
        case learnerConstants.TOTAL_CHAT_VOICE_TRIGGER:
            return {
                ...state,
                data: action.payload,
            };
        default: return state;
    }
};