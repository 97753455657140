import React from 'react';
import styled from 'styled-components';
import { Icon } from '@components/common';
import Error from '@images/teachV2/error.svg';

const FileWrap = styled.div`
  padding: 16px;
  border-radius: 12px;
  background: #FFF;
  display: flex;
  align-items: start;
  gap: 8px;
  border: 1px solid #EAECF0;
`;

const FileInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  gap: 8px;

  h4 {
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  p {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const ErrorMessage = styled.p`
  overflow: hidden;
  color: #475467;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const SupportedFormat = styled.p`
  color: #475467;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`;

const ErrorText = styled.p`
  color: #475467;
  font-size: 14px;
  font-style: normal; /* Không in nghiêng */
  font-weight: 400;
  line-height: 20px;
`;

const Button = styled.button`
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  color: #344054;
  padding: 10px 24px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background: #F8F9FA;
  }
`;

const WrapOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const FileError = ({ error, setError, fileName }) => {
  return (
    <WrapOption className="wrap-option__file">
      <FileWrap className="wrap-option__file-wrap">
        <FileInfo className="wrap-option__file-info">
          <Icon src={Error} />
          <div className='progress-loading'>
            <h4>{fileName}</h4>
            <ErrorText>Invalid format. Please try again.</ErrorText>
            {error === 'Only PDF files are allowed.' ? (
              <SupportedFormat>Supported format: .pdf.</SupportedFormat>
            ) : (
              <ErrorMessage>{error}</ErrorMessage>
            )}
          </div>
        </FileInfo>
      </FileWrap>
      <div className="d-flex justify-content-end">
        <Button onClick={() => setError(null)}>
          Try Again
        </Button>
      </div>
    </WrapOption>
  );
};

export default FileError;