import React, { useState, useEffect, useRef } from "react";

//Select
import Select from "react-select";

//redux
import { useDispatch, useSelector } from "react-redux";

//JoditEditor
import JoditEditor from "jodit-react";

//router
import { useParams } from "react-router-dom";

//Config
import * as Config from "@constants/Config";

import {
  atcCreateCheckPoints,
  atcEditCheckPoints,
  loadItemTypeForCheckPoint,
} from "@actions";

//Boostrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";

const ModalCheckPoints = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const itemsDropdown = props && props.items && props.items.data;
  const actions = props && props.actions;
  const timePointId = props && props.timePointId;
  const paramsData = props && props.paramsData;

  //editor
  const editor = useRef(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [stateForm, setStateForm] = useState({
    Title: "",
    Condition: "",
    Message: "",
    Feedback_Status: "",
    Feedback: "",
  });

  //valid
  const [titleValid, setTitleValid] = useState(false);
  const [itemsValid, setItemsValid] = useState(false);
  const [conditionValid, setConditionValid] = useState(false);

  const checkTitleValid = (value) => {
    if (value.length > 0) {
      setTitleValid(false);
      return false;
    } else {
      setTitleValid(true);
      return true;
    }
  };

  const checkItemsValid = (value) => {
    if (value.length > 0) {
      setItemsValid(false);
      return false;
    } else {
      setItemsValid(true);
      return true;
    }
  };

  const checkConditionValid = (value) => {
    if (value.length > 0) {
      setConditionValid(false);
      return false;
    } else {
      setConditionValid(true);
      return true;
    }
  };

  useEffect(() => {
    //Reset Data
    if (actions === "add") {
      setStateForm({
        Title: "",
        Condition: "",
        Message: "",
        Feedback_Status: "",
        Feedback: "",
      });
      setSelectedOption([]);

      //reset valid
      setTitleValid(false);
      setItemsValid(false);
    } else {
      setStateForm({
        Title: paramsData && paramsData.Title,
        Condition: paramsData && paramsData.Condition,
        Message: paramsData && paramsData.Message,
        Feedback_Status: "",
        Feedback: paramsData && paramsData.Feedback,
      });

      //Objects
      setSelectedOption(convertDataOptions(paramsData && paramsData.Objects));

      //reset valid
      setTitleValid(false);
      setItemsValid(false);
    }
  }, [actions, paramsData]);

  //convert data dropdown
  const listDropdown =
    itemsDropdown &&
    itemsDropdown.map(function (item) {
      const dataReturn = { value: item, label: item };
      return dataReturn;
    });

  const convertDataOptions = (data) => {
    let listOptions = [];
    data &&
      data.map(function (item) {
        const dataReturn = { value: item, label: item };
        return listOptions.push(dataReturn);
      });
    return listOptions;
  };

  const handleSubmitData = () => {
    if (
      !checkTitleValid(stateForm.Title) &
      !checkItemsValid(selectedOption) &
      !checkConditionValid(stateForm.Condition)
    ) {
      if (actions === "add") {
        const ObjectsConvert = convertDataReactSelect(selectedOption);
        //Call api
        const params = {
          Action: "Create",
          Target: "Checkpoints",
          TimePointId: timePointId,
          Title: stateForm.Title,
          Objects: ObjectsConvert,
          Condition: stateForm.Condition,
          Message: stateForm.Message,
          Feedback: stateForm.Feedback,
        };
        dispatch(atcCreateCheckPoints(id, params));
        props && props.toggle();
      } else {
        const ObjectsConvert = convertDataReactSelect(selectedOption);
        const paramsEdit = {
          Action: "Update",
          Target: "Checkpoints",
          TimePointId: timePointId,
          CheckPointId: paramsData && paramsData.CheckPointId,
          Title: stateForm.Title,
          Objects: ObjectsConvert,
          Condition: stateForm.Condition,
          Message: stateForm.Message,
          Feedback: stateForm.Feedback,
        };
        dispatch(atcEditCheckPoints(id, paramsEdit));
        props && props.toggle();
      }
    }
  };

  const convertDataReactSelect = (data) => {
    let newArr = [];
    data && data.map((item, index) => newArr.push(item.value));
    return newArr;
  };

  //Load Names Only
  const handleChange = (e) => {
    const { name, value } = e.target;
    setStateForm((prevState) => ({ ...prevState, [name]: value }));
    if (
      name === "Item_Type" &&
      (value === "Labs" ||
        value === "Studies" ||
        value === "Medications" ||
        value === "Actions" ||
        value === "PN")
    ) {
      const params = {
        Action: "Load",
        Target: value,
        ViewMode: "NamesOnly",
        TimePointId: timePointId,
      };
      dispatch(loadItemTypeForCheckPoint(id, params, value));
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #dc3545",
    }),
  };

  const checkpoints = useSelector((state) => state.loadCheckpoints || []);
  return (
    <Modal
      isOpen={props && props.isOpen}
      toggle={props && props.toggle}
      backdrop={`static`}
      style={{ maxWidth: "1200px" }}
    >
      <ModalHeader toggle={props && props.toogle}>
        {actions === "add"
          ? "Add Check Point Condition"
          : "Edit Check Point Condition"}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="Title">
                Title / Name of the Condition{" "}
                <span className="m--font-danger">*</span>
              </Label>
              <Input
                type="text"
                name="Title"
                placeholder="Example: Order X needs to be ordered"
                onChange={handleChange}
                value={stateForm.Title}
                invalid={titleValid}
                autoComplete="off"
              />
              {titleValid && (
                <FormFeedback>This field is required.</FormFeedback>
              )}
              <small className="form-help mt-2 d-block">
                A title for the condition, which will be showed in the warning
                message to the learners
              </small>
            </FormGroup>
            {/* /FormGroup */}
            {/* /FormGroup */}
            <FormGroup>
              <Label for="Feedback_Status">Item Type</Label>
              <Input
                type="select"
                defaultValue={stateForm.Item_Type}
                name="Item_Type"
                onChange={handleChange}
              >
                <option value="">Select an item type</option>
                <option value="Labs">Labs</option>
                <option value="Studies">Studies</option>
                <option value="Medications">Medications</option>
                <option value="Actions">Actions</option>
                <option value="PN">PN Orders</option>
              </Input>
            </FormGroup>
            {/* /FormGroup */}
            <FormGroup>
              <Label for="items">
                Items <span className="m--font-danger">*</span>
              </Label>
              <Select
                closeMenuOnSelect={false}
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={listDropdown || []}
                placeholder={`Select an item below`}
                isMulti
                styles={itemsValid ? customStyles : ""}
              />
              {itemsValid && (
                <FormFeedback className="d-block">
                  This field is required.
                </FormFeedback>
              )}
            </FormGroup>
            {/* /FormGroup */}
            <FormGroup>
              <Label for="Condition">
                Condition <span className="m--font-danger">*</span>
              </Label>
              <Input
                type="select"
                name="Condition"
                invalid={conditionValid}
                defaultValue={stateForm.Condition}
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select an option below ...
                </option>
                <option value="must_order">Must Be Ordered Now</option>
                <option value="must_have_ordered">
                  Must Have Been Ordered By Now
                </option>
                <option value="must_not_order">Must NOT Be Ordered</option>
              </Input>
              {conditionValid && (
                <FormFeedback>This field is required.</FormFeedback>
              )}
            </FormGroup>
            {/* /FormGroup */}
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="Message">Message</Label>
              <JoditEditor
                ref={editor}
                value={stateForm.Message}
                name="Message"
                config={Config.CONFIG_JODIT}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setStateForm((prevState) => ({ ...prevState, Message: newContent }))}
              />
            </FormGroup>
            {/* /FormGroup */}
            <FormGroup>
              <Label for="Feedback_Status">Feedback Status</Label>
              <Input
                type="select"
                defaultValue={stateForm.Feedback_Status}
                name="Feedback_Status"
                onChange={handleChange}
              >
                <option value="" disabled>
                  Select an option below ...
                </option>
                <option value="neutral">Neutral</option>
                <option value="positive">Positive</option>
                <option value="negative">Negative</option>
              </Input>
            </FormGroup>
            {/* /FormGroup */}
            {/* <FormGroup>
              <Label for="Feedback">Feedback Message</Label>
              <JoditEditor
                ref={editor}
                value={stateForm.Feedback}
                name="Message"
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setStateForm((prevState) => ({ ...prevState, Feedback: newContent }))}
              />
            </FormGroup> */}
            {/* /FormGroup */}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props && props.toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          className="btn btn-brand --bg-primary d-flex align-items-center"
          onClick={handleSubmitData}
        >
          {checkpoints.isLoading && (
            <span className="spinner-border spinner-border-sm mr-2"></span>
          )}
          {actions === "add" ? "Add Condition" : "Save Condition"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCheckPoints;
