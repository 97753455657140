import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";

//redux
import { useDispatch } from "react-redux";

//action
import {
  atcSetAccountPrivilege,
  atcSetAccountPrivilegeStatus,
} from "@actions";

const ModalEditAccount = (props) => {
  const dispatch = useDispatch();
  const { data, action, toggle, isOpen } = props && props;

  //props
  let emailAcc = data && data.Email;
  let firstNameAcc = data && data.FirstName;
  let lastNameAcc = data && data.LastName;
  let roleAcc = data && data.Role;
  let statusAcc = data && data.Status;

  //state
  const [isStatus, setStatus] = useState((data && data.Status) || "");
  const [isUpdate, setUpdate] = useState(false);

  //form
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (action === "edit") {
      setValue("Email", emailAcc);
      setValue("FirstName", firstNameAcc, { shouldValidate: false });
      setValue("LastName", lastNameAcc, { shouldValidate: false });
      setValue("Role", roleAcc, { shouldValidate: false });
      setStatus(statusAcc || "");
    }
  }, [
    action,
    emailAcc,
    firstNameAcc,
    lastNameAcc,
    roleAcc,
    statusAcc,
    setValue,
  ]);

  const onSubmit = (value) => {
    const RoleStatus = value.Role;
    const params = {
      Email: emailAcc,
      FirstName: value.FirstName,
      LastName: value.LastName,
      Status: isStatus
    };
    if (RoleStatus === "Instructor") {
      params["IsInstructor"] = true;
      params["IsAdministrator"] = false;
    } else if (RoleStatus === "Admin") {
      params["IsInstructor"] = true;
      params["IsAdministrator"] = true;
    } else {
      params["IsInstructor"] = false;
      params["IsAdministrator"] = false;
    }
    dispatch(atcSetAccountPrivilege(params));
    reset({ keepIsValid: false, keepValues: false });
    toggle();
  };

  const handleStatus = (type) => {
    setStatus(type);
    setUpdate(true);
    toggle();
  };

  //Update status
  useEffect(() => {
    if(isUpdate) {
      //Send API
      const params = {
        Email: emailAcc,
        Status: isStatus,
      };
      dispatch(atcSetAccountPrivilegeStatus(params));
      setUpdate(false);
    }
  }, [isUpdate, dispatch, emailAcc, isStatus]);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Edit Account</ModalHeader>
        <ModalBody>
          <FormGroup className="mt-2">
            <Label for="FirstName">
              First Name <span className="m--font-danger">*</span>
            </Label>
            <Input
              type="text"
              name="FirstName"
              {...register("FirstName", { required: true })}
              invalid={errors.FirstName?.type === "required"}
              defaultValue={watch("FirstName")}
              onChange={(e) => {
                setValue("FirstName", e.target.value, { shouldValidate: true });
              }}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback invalid={String(errors.Name?.type === "required")}>
              This is required.
            </FormFeedback>
          </FormGroup>
          <FormGroup className="mt-2">
            <Label for="LastName">
              Last Name <span className="m--font-danger">*</span>
            </Label>
            <Input
              type="text"
              name="LastName"
              {...register("LastName", { required: true })}
              invalid={errors.LastName?.type === "required"}
              defaultValue={watch("LastName")}
              onChange={(e) => {
                setValue("LastName", e.target.value, { shouldValidate: true });
              }}
              placeholder=""
              autoComplete="off"
            />
            <FormFeedback
              invalid={String(errors.LastName?.type === "required")}
            >
              This is required.
            </FormFeedback>
          </FormGroup>
          <FormGroup className="mt-2">
            <Label for="Role">Role</Label>
            <Input
              {...register("Role")}
              defaultValue={watch("Role")}
              onChange={(e) => {
                setValue("Role", e.target.value, { shouldValidate: true });
              }}
              type="select"
              name="Role"
              id="Role"
            >
              <option value="Admin">Admin</option>
              <option value="Instructor">Instructor</option>
              <option value="">Learner</option>
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color={`${isStatus === "Inactive" ? "success" : "danger"}`}
            className={`btn btn-sm mr-auto ${
              isStatus === "Inactive" ? "btn-success" : "btn-danger"
            }`}
            onClick={() => handleStatus(isStatus === "Inactive" ? "Active" : "Inactive")}
          >
            {isStatus === "Inactive"
              ? "Activate Account"
              : "Deactivate Account"}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>{" "}
          <Button
            color="primary"
            className="btn btn-brand --bg-primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEditAccount;
