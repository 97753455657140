import React, { useEffect, useState } from 'react';

//ReactTooltip
import ReactTooltip from 'react-tooltip';

//lodash
import _ from "lodash";

function TabList(props) {
    //props 
    const { caseData, data, dataTour, TimePointNum, activeTab, toggle, arrDotResult } = props && props;
    const checkHasdot = arrDotResult?.Studies || arrDotResult?.Labs;
    const TaskRecords = caseData?.data?.Case?.TaskRecords;

    const [priorDocumentsDiff, setPriorDocumentsDiff] = useState([]);
    const [isHiddenDot, setHiddenDot] = useState({
        Prior_Documents: false
    });

    //Rebuild Tooltip
    useEffect(() => {
        ReactTooltip.rebuild()
    });

    //check Data
    useEffect(() => {
        //Get local stotage PriorDocuments (PriorDocumentsStore)
        const PriorDocumentsLocalGet = localStorage.getItem("PriorDocumentsLocal");
        const jsonPriorDocumentsLocalGet = JSON.parse(PriorDocumentsLocalGet);
        jsonPriorDocumentsLocalGet && setPriorDocumentsDiff(jsonPriorDocumentsLocalGet);
    }, [])

    const fileList = dataTour?.filter(function (event) {
        return event?.selector?.indexOf('step-5') > -1
    });

    //So sanh du lieu cu va du lieu moi dua tren timepoint
    const compareDataTimepoint = (data, type) => {
        // Compare LabResults
        const filterDataBytimePointCurrent = _.filter(data, function (o) { return o.TimePointNum === TimePointNum; });
        const filterDataBytimePointOld = _.filter(data, function (o) { return o.TimePointNum === (TimePointNum - 1); });
        const objectCurrent = filterDataBytimePointCurrent && filterDataBytimePointCurrent[0];
        const objectOld = filterDataBytimePointOld && filterDataBytimePointOld[0];
        if ((objectCurrent) && (objectOld)) {
            const resultsDataCurrent = (objectCurrent[type]?.results) || (objectCurrent[type]);
            const resultsDataOld = (objectOld[type]?.results) || (objectOld[type]);
            const compareResults = resultsDataCurrent && resultsDataOld && JSON.stringify(resultsDataCurrent) !== JSON.stringify(resultsDataOld);
            return compareResults;
        }
    }

    //Hidden When click
    const handleHideDot = (type) => {
        //Neu TimePoint 2 tro di thi moi so sanh du lieu co khac nhau hay khong ?
        if (type === 'Prior_Documents' && (TimePointNum > 1 ? compareDataTimepoint(priorDocumentsDiff, "PriorDocumentsStore") : true)) {
            setHiddenDot((prevState) => ({ ...prevState, Prior_Documents: true }));
        }
    }

    const navItem = data?.map((value, index) => {
        return (
            <React.Fragment key={index}>
                {value?.isShow && (
                    <li
                        className={`tabs-med__item position-relative ${activeTab === value?.Type && "active"}`}
                        data-id={index}
                        data-tip={value?.Tooltip}
                        onClick={() => { toggle(value?.Type) }}
                    >
                        {/*  Neu TimePoint 2 tro di thi moi so sanh du lieu co khac nhau hay khong ? */}
                        {value?.Type === 'Prior_Documents' && (TimePointNum > 1 ? compareDataTimepoint(priorDocumentsDiff, "PriorDocumentsStore") : true) && !isHiddenDot?.Prior_Documents && (
                            <span className="notic_dot"></span>
                        )}
                        {/* Kiem trang trang thai local va trang thai api ( khi click vao phan nay call api update la true) */}
                        {checkHasdot && value?.Type === 'Results' && (!TaskRecords?.LabResultViewed || !TaskRecords?.StudyResultViewed) &&
                            <span className="notic_dot"></span>
                        }
                        <div onClick={() => handleHideDot(value?.Type)}></div>
                        <span className={`tabs-med__item--icon ${value?.Icon}`}></span>
                        <span className="tabs-med__item--text" data-type={value?.Type}>{value?.Title}</span>
                    </li>
                )}
            </React.Fragment>
        )
    });

    return (
        <React.Fragment>
            <ReactTooltip place="bottom" />
            <div className="tabs-med-list">
                <ul className="tabs-med" data-tour={`${fileList && fileList[0]?.selector}`}>
                    {navItem}
                </ul>
            </div>
        </React.Fragment>
    )
}

export default TabList;