import React, { useState, useRef } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from "react-bootstrap-table2-paginator";
import { DataEmptyCase } from "@components-common";
import { useDispatch, useSelector } from "react-redux";
import { shareReference } from "@actions";
import styled from 'styled-components';

//apiCaller
import { apiCaller } from "@utils";

//constants
import { teachV2Constants } from "@constants";

//Icon
import { Icon } from '@components/common';
import File from '@images/teachV2/file-upload.svg';
import More from '@images/teachV2/more-vertical.svg';

//elements
import { WrapModalDeleteCommon } from "@components-common/Modal";

//utils
import { LoaderText } from "@utils";

//components
import { UploadFileSuggestion, UploadButton, WrapModalEdit, WrapModalUpload, ModalUploadReferenceDocument } from "../ReferenceLiblary";

//moment
import moment from "moment";

//components
import { StatusField } from '@components-common/elements';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

const ReferenceLibraryWrap = (props) => {
  const { loadDataReferenceBook, listValue, setListValue, inputValue, setInputValue } = props;
  const { SearchBar } = Search;
  const dispatch = useDispatch();
  const { dataFilter: documents, isLoading } = useSelector((state) => state.getReferenceBook) || {};
  const [modal, setModal] = useState({
    uploadDocument: false,
  });
  const isSearchingOrPaging = useRef(false);

  const handleShareReference = async (data) => {
    const dataShare = await dispatch(shareReference({
      "Action": "Share",
      "Target": "Organization",
      "ReferenceId": data?.document_id
    }))
    if (dataShare?.data?.status === 200) {
      toast.success('Successfully shared to Organization References', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loadDataReferenceBook()
    }
  }

  const handleUnshareReference = async (data) => {
    const dataShare = await dispatch(shareReference({
      "Action": "Unshare",
      "Target": "Organization",
      "ReferenceId": data?.document_id
    }))
    if (dataShare?.data?.status === 200) {
      toast.success('Successfully unshared from Organization References', {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      loadDataReferenceBook()
    }
  }

  //toggle
  const toggle = (type, value = null) => {
    setModal((prevState) => ({ ...prevState, [type]: !prevState[type] }));
  };

  // Handle Remove Document
  const handleRemoveDocument = (document_id) => {
    if (document_id) {
      const params = {
        "action_type": "delete_uploaded_document",
        "document_id": document_id
      };
  
      const isLoading = true;
      dispatch({ type: teachV2Constants.DELETE_RAG_DOCUMENT_REQUEST, isLoading });
  
      apiCaller(`/api/medicalcase/functional/`, 'POST', params)
        .then(res => {
          if (res?.data) {
            dispatch({ type: teachV2Constants.DELETE_RAG_DOCUMENT_SUCCESS, payload: res.data });
            loadDataReferenceBook()
          } else {
            const error = "error";
            dispatch({ type: teachV2Constants.DELETE_RAG_DOCUMENT_FAILURE, error });
          }
        })
        .catch(err => {
          const errorMessage = err.message || "API call failed";
          dispatch({ type: teachV2Constants.DELETE_RAG_DOCUMENT_FAILURE, error: errorMessage });
        })
        .finally(() => {
        });
    }
  };

  const componentsCommon = {
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null,
    MultiValueContainer: (props) => {
      return (
        <div className="custom-tag">
          <components.MultiValueContainer {...props} />
        </div>
      );
    }
  };

  const handleAddTag = (value) => {
    setListValue(value)
  }

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const columns = [
    {
      dataField: "title",
      text: "Document",
      headerClasses: "--text-primary text-nowrap",
      headerStyle: { width: "340px" },
      formatter: (cell, row) => {
        return (
          <React.Fragment>
            <div className='text-w-600'>
              {row?.title}
            </div>
            <AuthorList>{row.author_list}</AuthorList>
            <JournalTitle>{row.journal_citation}</JournalTitle>
          </React.Fragment>
        );
      },
    },
    {
      dataField: "file_name",
      text: "File Name",
      headerClasses: "--text-primary",
      headerStyle: { width: "250px" },
      formatter: (cell, row) => {
        return (
          <FileName>
            {cell &&
              <React.Fragment>
                <Icon src={File} fill="#98A2B3" />
                <span className='ml-2' style={{wordBreak: 'break-all'}}>{cell}</span>
              </React.Fragment>
            }
          </FileName>
        );
      },
    },
    {
      Field: "tags",
      text: "Tags",
      headerClasses: "--text-primary align-middle text-center",
      headerStyle: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            {row?.tags?.length > 0 ? (
              <>
                <div className="tag-library">
                  {row?.tags?.[0]}
                </div>
                {row?.tags?.length > 1 && (
                  <div className="tag-library ml-1" data-tip data-for={`tag-${row?.document_id}`}>
                    +{row?.tags?.length - 1}
                    <ReactTooltip id={`tag-${row?.document_id}`} place='top' effect='solid' className="tooltip-tags">
                      <div className='list-tags'>
                        {row?.tags?.map((tag, index) => (
                          index > 0 && tag && <span>{tag}</span>
                        ))}
                      </div>
                    </ReactTooltip>
                  </div>
                )}
              </>
            ) : (<></>)}
          </div>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Date Created",
      headerClasses: "--text-primary text-nowrap",
      headerStyle: { width: "60px" },
      formatter: (cell, row) => {
        return (
          <div className='format-time'>{moment(cell).format('DD/MM/YYYY hh:mm A')}</div>
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      headerClasses: "--text-primary align-middle text-center",
      headerStyle: { width: "100px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center">
            <StatusField status={row?.status} row={row}/>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      headerClasses: "--text-primary text-center",
      headerStyle: { width: "50px" },
      formatter: (cell, row) => {
        return (
          <div className="d-flex align-items-center justify-content-center action-reference">
            <Icon src={More}/>
            <div className="action-reference__list">
              {row?.status?.color !== "warning" && (
                !row?.shared_to_institution ? (
                  <p onClick={() => handleShareReference(row)}>Share to Organization</p>
                ) : (
                  <p onClick={() => handleUnshareReference(row)}>Unshare</p>
                )
              )}
              <WrapModalEdit
                title="Edit Reference Document"
                currentItem={row}
                createOption={createOption}
                {...props}
              >
                {({onOpen}) => (
                  <p onClick={onOpen}>Edit</p>
                )}
              </WrapModalEdit>
              <WrapModalDeleteCommon
                title={`Delete Document`}
                sub={`Are you sure you want to delete this document? This action cannot be undone.`}
                handleRemove={handleRemoveDocument}
                idRemove={row?.document_id}
              >
                {({onOpen}) => (
                  <p onClick={onOpen}>Delete</p>
                )}
              </WrapModalDeleteCommon>
            </div>
          </div>
        );
      },
    }
  ];

  const handleBlur = () => {
    if (inputValue) {
      setListValue((prev) => prev ? [...prev, createOption(inputValue)] : [createOption(inputValue)]);
    }
  }

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <ToolkitProvider keyField="document_id" data={documents?.MyReferences || []} columns={columns} search>
      {(toolkitprops) => (
        <div>
          <div className="d-flex align-items-center justify-content-between">
            <div className='tb-heading-total'>Total Documents: {documents?.MyReferences?.length}</div>
            <div className='d-flex align-items-center'>
              <div className="boxSearch">
                <SearchBar
                  {...toolkitprops.searchProps}
                  onSearch={(e) => {
                    paginationProps.page = 1;
                    toolkitprops.searchProps.onSearch(e);
                    isSearchingOrPaging.current = false;
                  }}
                  onKeyUp={() => { isSearchingOrPaging.current = true; }}
                />
              </div>
              <div className="email-share__form-input ml-2">
                <CreatableSelect
                  components={componentsCommon}
                  inputValue={inputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => {
                    handleAddTag(newValue)}
                  }
                  onInputChange={(newValue) => {
                    setInputValue(newValue)
                  }}
                  onKeyDown={(e) => {
                    paginationProps.onPageChange(1, 10);
                    handleKeyDown(e)
                  }}
                  onBlur={handleBlur}
                  placeholder="Filter by Tags"
                  value={listValue}
                  classNamePrefix={`filter-tag`}
                />
              </div>
              <WrapModalUpload
                title="Upload Reference Document"
                {...props}
              >
                {({onOpen}) => (
                  <UploadButton {...props} toggle={onOpen} />
                )}
              </WrapModalUpload>
            </div>
          </div>
          <BootstrapTable
            condensed
            wrapperClasses="table-custom-common table-responsive mt-4"
            bordered={false}
            data={documents?.MyReferences}
            columns={columns}
            noDataIndication={() => (
              <DataEmptyCase
                title="No Results Found"
                text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
              />
            )}
            {...paginationTableProps}
            {...toolkitprops.baseProps}
          />
          <div className="pagination-custom-case d-flex justify-content-end ml-4">
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </ToolkitProvider>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    totalSize: documents?.MyReferences?.length || [],
  };

  return (
    <React.Fragment>
      <div className={`table-refer bg-white ${documents?.MyReferences?.length === 0 ? 'position-relative' : ''}`} style={{ height: documents?.MyReferences?.length === 0 ? 'calc(100vh - 72px)' : 'auto' }}>
        {isLoading ? (
          <LoaderText />
        ) : (
          (documents?.MyReferences?.length > 0 || listValue?.length > 0) &&
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        )}
        {documents?.MyReferences?.length === 0 && !isLoading && listValue?.length === 0 && (
          <UploadFileSuggestion 
            title="Upload Your Reference Document"
            content="It looks like you haven't uploaded any reference documents yet. Click the ‘Upload Document’ button to add new reference materials."
            org={false}
            toggle={toggle} 
            {...props} 
          />
        )}
      </div>
      <ModalUploadReferenceDocument
        isOpen={modal.uploadDocument}
        toggleModal={() => toggle("uploadDocument")}
        title="Upload Reference Document"
        {...props} 
      />
    </React.Fragment>
  );
};

const AuthorList = styled.p`
  color: #475467;
  font-weight: 400;
`;

const JournalTitle = styled.p`
  color: #475467;
  font-style: italic;
  font-weight: 400;
`;

const FileName = styled.div`
  display: flex;
  align-items: center;
  color: #475467;
  font-weight: 400;
  letter-spacing: 0.15px;
`;

export default ReferenceLibraryWrap;