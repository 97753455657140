import React, { useMemo } from "react";
import { Col } from "reactstrap";
import { CustomProgressBar } from "@components/common";
import { Iconsax } from "@components-common";
import iconList from "./iconList";

const AssignmentItem = ({ type, assignment, fullWidth, borderStyle, handleItemClick }) => {
    const PercentCompleted = assignment?.Series?.ProgressStatus?.PercentCompleted;
    const countCase = assignment?.Series?.Cases?.length || 0;
    const randomIcon = useMemo(() => iconList[Math.floor(Math.random() * iconList.length)], []);

    return (
        <Col
            className={`assignment-list__item custom-padding-x ${borderStyle ? '-border-style' : ''}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleItemClick(assignment)}
            lg={fullWidth ? 12 : 4}
            md={fullWidth ? 12 : 6}
        >
            <div className="assignment-list__item--inner">
                <img className="streakBox__icon" src={randomIcon} alt="icon assignment" />
                <h3 className="heading">{assignment?.LaunchPlanName}</h3>
                <div className="case-info">
                    <div className="case-info__brand case-info__row">
                        {assignment?.Institution?.InstitutionName || "Med2Lab Inc"}
                    </div>
                    <div className="case-info__status case-info__row d-flex align-items-center">
                        <span className="d-flex align-items-center">
                            <Iconsax iconName="notebook" fill="#697586" />
                            <span className="ml-2 txt-gray">{`${countCase} Case${countCase !== 1 ? 's' : ''}`}</span>
                        </span>
                        <span className="ml-4 d-flex align-items-center">
                            <Iconsax iconName="watch" fill="#697586" />
                            <span className="ml-2 txt-gray">30 mins</span>
                        </span>
                    </div>
                    {PercentCompleted !== undefined && (
                        PercentCompleted === 1 ? (
                            <div className="status-progress-bar d-flex align-items-center mt-2">
                                <Iconsax iconName="checked" fill="#099250" />
                                <span className="status-progress-bar__text">Completed</span>
                            </div>
                        ) : (
                            <CustomProgressBar value={PercentCompleted} colorClass="--yellow-bg" />
                        )
                    )}
                </div>
            </div>
        </Col>
    );
};

export default AssignmentItem;