import React from "react";
import { Col, FormGroup, Label } from "reactstrap";

const AddUpdateFoodForm = ({ handleImageChange, imagePreview, foodItem }) => {
    const image_url = foodItem?.image_url;
    return (
        <Col md={6}>
            <FormGroup className="mt-2">
                <Label className="ms-label" for="file">File</Label>
                {(imagePreview || image_url) &&
                    <div className="image-preview mb-3 mx-auto">
                        <img style={{ maxWidth: "200px" }} src={imagePreview || image_url} alt="Preview" />
                    </div>
                }
                <div className="upload-btn-container">
                    <div className="upload-btn-title">
                        Click to upload image
                    </div>
                    <div className="upload-btn-wrapper">
                        <button className="btn">Upload image</button>
                        <input type="file" name="image" onChange={handleImageChange} />
                    </div>
                </div>
                <div className="my-2 --text-primary">
                    <i>Maximum upload file size: <strong>10MB</strong></i>
                </div>
            </FormGroup>
        </Col>
    );
};

export default AddUpdateFoodForm;