import React, { useState, useEffect } from "react";

//router
import { useParams } from "react-router-dom";

//redux
import { useDispatch } from "react-redux";

//Boostrap
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

//Actions
import {
  atcCreateInterventionForPN,
  atcUpdateInterventionForPN,
} from "@actions";

const ModalPNOrder = (props) => {
  let { id } = useParams();
  const dispatch = useDispatch();

  //props
  const data = props && props.data;
  const itemUpdate = props && props.paramsUpdate;
  const type = props && props.type;
  const panel = props && props.panel;
  const headingModal = type === "add" ? "Add PN Order" : "Edit PN Order";
  const timePointId = props && props.timePointId;
  const pnCategoryId = data && data.PNCategoryId; //Name
  
  //state
  const [nameState, setNameState] = useState({});

  useEffect(() => {
    if (type === "edit") {
      setNameState(itemUpdate || {});
    } else {
      setNameState({});
    }
  }, [itemUpdate, type]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNameState((prevState) => ({ ...prevState, [name]: value }));
  };

  const hanldeSubmit = () => {
    const InterventionId =  nameState.Id;
    let params = {
      Target: "Intervention",
      TimePointId: timePointId,
      CategoryId: pnCategoryId,
      PNCategory: panel,
      Name: nameState.Name || "",
      Explanation: nameState.Explanation || ""
    };

    if (type === "add") {
      params.Action = "Create";
      params.Available = true;
      dispatch(atcCreateInterventionForPN(id, params, panel));
    } else {
      params.Action = "Update";
      params.Available = false;
      params.InterventionId = String(InterventionId)
      dispatch(atcUpdateInterventionForPN(id, params, panel, InterventionId));
    }
    props && props.toggle();
  };

  return (
    <Modal
      isOpen={props && props.isOpen}
      toggle={props && props.toggle}
      backdrop={`static`}
    >
      <ModalHeader toggle={props && props.toogle}>{headingModal}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="Name">Name</Label>
            <Input
              type="text"
              name="Name"
              placeholder="Name of the new intervention"
              id="Name"
              defaultValue={nameState.Name}
              onChange={handleChange}
              autoComplete="off"
            />
          </FormGroup>
        </Form>
        <FormGroup>
          <Label for="Explanation">Explanation:</Label>
          <Input
            type="textarea"
            name="Explanation"
            id="Explanation"
            placeholder="Example: Medication has been given"
            defaultValue={nameState.Explanation}
            onChange={handleChange}
          />
        </FormGroup>
        <p className="text-muted small">
          <small>
            [Optional] Notifying the learner that the action has been activated
          </small>
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props && props.toggle}>
          Cancel
        </Button>{" "}
        <Button
          color="danger"
          className="btn btn-brand --bg-primary d-flex align-items-center"
          onClick={hanldeSubmit}
        >
          {type === "add" ? "Add" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalPNOrder;
