import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Iconsax } from "@components-common";
import { BetaComponent } from '@components/common/Beta';
import UserContext from '@context/UserContext';

const CreateNewQuick = ({ isSidebarCollapsed }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const context = useContext(UserContext);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Container ref={dropdownRef}>
            <DropdownToggle onClick={toggleDropdown}>
                <Iconsax iconName="plus-square" fill="#FFF" size={16} />
                {!isSidebarCollapsed && <span className='ml-2'>Create</span>}
            </DropdownToggle>
            {isOpen && (
                <Dropdown isSidebarCollapsed={isSidebarCollapsed}>
                    <DropdownItem to="/teach/mcq" activeClassName="active">
                        <Iconsax iconName="mcq" fill="#697592" size={24} />
                        <DropdownText>Multiple Choice Question</DropdownText>
                    </DropdownItem>
                    {context.privilege === "alpha" &&  (
                        <DropdownItem to="/teach/free-text" activeClassName="active">
                            <Iconsax iconName="free-text" fill="#697592" size={24} />
                            <DropdownText>Free-Text Question</DropdownText>
                        </DropdownItem>
                    )}
                    <DropdownItem to="/teach/script" activeClassName="active">
                        <Iconsax iconName="script" fill="#697592" size={24} />
                        <DropdownText>Case Scenario</DropdownText>
                    </DropdownItem>
                    <DropdownItem to="/teach/module/step1" activeClassName="active">
                        <Iconsax iconName="module" fill="#697592" size={24} />
                        <DropdownText>Interactive Module</DropdownText>
                    </DropdownItem>
                    {context.privilege === "alpha" &&  (
                        <DropdownItem to="/teach/engage" activeClassName="active">
                            <Iconsax iconName="engage" fill="#697592" size={24} />
                            <DropdownText>Engagement Module</DropdownText>
                        </DropdownItem>
                    )}
                    <DropdownItem to="/teach/presentation" activeClassName="active">
                        <Iconsax iconName="slides" fill="#697592" size={24} />
                        <DropdownText>Teaching Slides</DropdownText>
                        <BetaContainer>
                            <BetaComponent fontSize={10} />
                        </BetaContainer>
                    </DropdownItem>
                </Dropdown>
            )}
        </Container>
    );
};

const Container = styled.div`
  position: relative;
  margin: 0 16px;
`;

const DropdownToggle = styled.div`
  display: flex;
  padding: 12px 10px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 6px;
  background: #0089C2;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
    justify-content: ${({ isSidebarCollapsed }) => !isSidebarCollapsed ? 'center' : 'flex-start'};
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
  position: absolute;
  top: ${({ isSidebarCollapsed }) => !isSidebarCollapsed ? '100%' : '110%'};
  left: 0;
  width: ${({ isSidebarCollapsed }) => !isSidebarCollapsed ? '100%' : '300px'};
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  z-index: 1;
`;

const DropdownItem = styled(NavLink)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background-color: ${({ isActive }) => (isActive ? '#F2F4F7' : 'transparent')};

  &.active {
    background-color: #F2F4F7;
    border-radius: 6px;
    span {
        color: #0089C2;
    }
    .iconsax path[stroke],
    .iconsax rect[stroke] {
        stroke: var(--new-primary-color);
    }
    .iconsax path[fill] {
        fill: var(--new-primary-color);
    } 
    rect {
        stroke: var(--new-primary-color);
    }
  }
`;

const DropdownText = styled.span`
  color: ${({ isActive }) => (isActive ? '#0089C2' : '#475467')};
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
`;

const BetaContainer = styled.div`
    margin-left: auto;
`;

export default CreateNewQuick;