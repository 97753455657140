import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import topTaskIcon from '@images/learnerv2/icon-top-task-sp.svg';
import { Iconsax } from "@components-common";

// Components
import { MedicalInfo } from ".";

// Define keyframes for the opening and closing animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100dvh;
  background-color: #fff;
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  animation: ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)} 0.3s ease-out forwards;
`;

const ChatPopupWrapper = styled.div`
  display: flex;
  width: 90%;
  max-width: 500px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  flex-direction: column;
  border-radius: 12px;
  animation: ${({ isClosing }) => (isClosing ? fadeOut : fadeIn)} 0.3s ease-out forwards;
  position: relative;
  height: calc(100vh - 190px);
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  border-radius: 12px;
  position: relative;
  height: 100%;
  &:before {
    content: "";
    background-image: url(${topTaskIcon});
    width: 137px;
    height: 53px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 150px;
  border: none;
  cursor: pointer;
  outline: none;
  z-index: 10000;
  width: 40px;
  height: 40px;
`;

const NarativePopup = (props) => {

  const { onClose } = props && props;

  //state
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
  };

  useEffect(() => {
    if (isClosing) {
      const timeout = setTimeout(() => {
        onClose();
      }, 300); // match the duration of the fadeOut animation
      return () => clearTimeout(timeout);
    }
  }, [isClosing, onClose]);

  return (
    <Overlay isClosing={isClosing}>
      <CloseButton onClick={handleClose}>
        <Iconsax iconName="close" fill="#9AA4B2" iconSize={25} />
      </CloseButton>
      <ChatPopupWrapper isClosing={isClosing}>
        <InnerWrapper>
          <MedicalInfo {...props} />
        </InnerWrapper>
      </ChatPopupWrapper>
    </Overlay>
  );
};

export default NarativePopup;