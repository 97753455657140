import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { apiCaller } from "@utils";

import { TimePointIcon } from "../Advance";

import { useSelector } from "react-redux";

function CongratulationsTimePoint(props) {
    let { id } = useParams();

    const { hanldeToggleAdvance, isOpen } = props || {};
    const [isLoading, setLoading] = useState(false);

    const InterfaceTemplateDetails = useSelector((state) => state?.InterfaceTemplateData?.data?.InterfaceTemplate || {});
    const handleUpdateCaseByAdvance = (e) => {
        const params = { "CaseId": id }
        setLoading(true);
        apiCaller(`/api/learn/case/advance/`, 'POST', params).then(res => {
            if (res?.status === 200) {
                setLoading(false);
                window.location.reload();
            } else {
                setLoading(false);
                console.log("error");
            }
        });
    }

    const ModalTimePointCleared = InterfaceTemplateDetails?.ModalTimePointCleared || {};

    // Chuyển đổi HTML
    const convertToHTML = (text) => ({ __html: text });
    return (
        <Modal
            className="modal-custom"
            zIndex={16000}
            isOpen={isOpen}
            backdrop={`static`}
            centered
            backdropClassName="modal-backdrop-custom"
            modalTransition={{ timeout: 700 }}
        >
            <ModalBody>
                <div className="modal-inner">
                    <div className="modal-inner__pic d-flex justify-content-center">
                        <TimePointIcon />
                    </div>
                    <div className="modal-inner__desc text-center">
                        <h2 className="headline --size-4 mb-4 text-center">
                            <div dangerouslySetInnerHTML={ModalTimePointCleared?.m_tpclear_title?.Text ? convertToHTML(ModalTimePointCleared.m_tpclear_title.Text) : { __html: "Timepoint Cleared!" }} />
                        </h2>
                        <div dangerouslySetInnerHTML={{ __html: ModalTimePointCleared?.m_tpclear_msg?.Text ? convertToHTML(ModalTimePointCleared.m_tpclear_msg.Text).__html : convertToHTML("<p>Boom! You're on fire! 🔥️ <br /> Congrats on the significant progress in the case 💪<br /> <span class='txt-organe'>Ready to unlock the next Timepoint?</span><br /></p>").__html }} />
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="mx-auto" style={{ width: "276px" }}>
                    <Button
                        color='primary'
                        className="btn-brand-sm -btn-lg w-100 -shadown"
                        onClick={handleUpdateCaseByAdvance}
                        disabled={isLoading}
                    >
                        {isLoading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                        <span dangerouslySetInnerHTML={convertToHTML(ModalTimePointCleared?.m_tpclear_button_confirm?.Text || "Next Timepoint, Let's Go!")} />
                    </Button>
                    <Button
                        color='white'
                        className="btn-white-sm mt-3 -btn-lg w-100 -shadown"
                        onClick={() => hanldeToggleAdvance("nextTimepoint")}
                        disabled={isLoading}
                    >
                        <span dangerouslySetInnerHTML={convertToHTML(ModalTimePointCleared?.m_tpclear_button_cancel?.Text || "Wait, I Need To Review Again")} />
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

export default CongratulationsTimePoint;