import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { getAnalysesAnswerBatch } from "@actions";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import { Icon } from '@components/common'
import ExcelIcon from '@images/teachV2/export.svg'
import { DataEmptyCase } from "@components-common";
import { LoaderText } from "@utils";
import * as Config from "@constants/Config";

//moment
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import axios from 'axios';
import { authHeader } from '@helpers';
import { saveAs } from 'file-saver';
import ModalStatistics from './ModalStatistics';

const AnswerAnalyses = () => {
  const dispatch = useDispatch();
  const [loadingDowload, setLoadingDowload] = useState(false);
  let { idAnalyses } = useParams();
  const [openStatistics, setOpenStatistics] = useState(false)

  const handleToggle = () => {
    setOpenStatistics(!openStatistics)
  };
  
  const listCase = useSelector((state) => state?.getAnalysesAnswerBatch || []);

  const handleGetListAnalyses = () => {
    dispatch(getAnalysesAnswerBatch({
      Action: "LoadAnswerBatchAnalysis",
      QuestionAnswerBatchAnalysisId: idAnalyses
    }));
  }

  useEffect(() => {
    handleGetListAnalyses()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      dataField: "Learner",
      text: "Learner",
      headerClasses: "--text-primary font-weight-500",
    },
    {
      dataField: "AnswerText",
      text: "Answer Text",
      headerClasses: "--text-primary font-weight-500",
      headerStyle: { width: "240px" },
    },
    {
      dataField: "HumanScore",
      text: "Human Score",
      headerClasses: "--text-primary font-weight-500",
      classes: "text-nowrap",
      headerStyle: { width: "140px" },
      formatter: (cell, row) => {
        return (
          <div contentEditable>{cell}</div>
        );
      },
    },
    {
      dataField: "AnalysisResult",
      text: "AI Score",
      headerClasses: "--text-primary font-weight-500",
      classes: "text-nowrap",
      headerStyle: { width: "120px" },
      formatter: (cell, row) => {
        return (
          <div contentEditable>{row?.AnalysisResult?.ai_score}</div>
        );
      },
    },
    {
      dataField: "AnalysisResult",
      text: "AI Explanation / Feedback",
      headerClasses: "--text-primary font-weight-500",
      formatter: (cell, row) => {
        return (
          <p className=''>{row?.AnalysisResult?.ai_explanation}</p>
        );
      },
    },
  ];

  const handleExportCsv = (items) => {
    setLoadingDowload(true);

    const params = {
      "Action": "ExportAnswerBatchAnalysis",
      "QuestionAnswerBatchAnalysisId": idAnalyses,
    };
    axios({
      method: "POST",
      url: `${Config.API_URL}/api/feedback/question/analysis/`,
      data: params,
      headers: authHeader(),
      responseType: 'blob',
    })
    .then(function (response) {
      // Tạo Blob từ dữ liệu tệp nhận được
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Tải tệp về máy người dùng
      saveAs(blob, 'data.xlsx');
      setLoadingDowload(false);
    })
    .catch(err => {
      console.error('Error exporting CSV:', err);
      setLoadingDowload(false);
    })
  }

  const rowStyleTable = (row, rowIndex) => {
    const style = {};
    if (row?.HumanScore !== row?.AnalysisResult?.ai_score) {
      style.backgroundColor = '#FFF9EA';
      style.color = "#081B2A"
      style.borderRadius = "10px"
    } else {
      style.color = "#667085"
    }
  
    return style;
  };

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <React.Fragment>
      <ToolkitProvider
        keyField="QuestionAnswerId"
        data={listCase?.data?.RawData || []}
        columns={columns}
        search
      >
        {(toolkitprops) => (
          <div>
            <div className="table-learner__filter d-flex align-items-center justify-content-end">
              <Button
                color="secondary"
                onClick={handleExportCsv}
                disabled={loadingDowload}
                className="d-flex align-items-center"
                >
                <Icon src={ExcelIcon}/>
                <span className="ml-1">
                  Export Data
                </span>
              </Button>
              <Button
                color="primary"
                onClick={handleToggle}
                className="d-flex align-items-center ml-2"
                >
                  Show Statistics
              </Button>
            </div>
            <BootstrapTable
              condensed
              wrapperClasses="table-custom-common table-analyze table-responsive mt-4"
              bordered={false}
              data={(listCase?.data?.RawData && listCase?.data?.RawData) || []}
              columns={columns}
              noDataIndication={() => (
                <DataEmptyCase 
                  title="No Results Found"
                  text="Sorry, we couldn't find any matches for your search. Please try adjusting your search terms or using different keywords."
                />
              )}
              rowStyle={ rowStyleTable }
              {...paginationTableProps}
              {...toolkitprops.baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
      <div className="pagination-custom-case d-flex justify-content-end mr-4 mb-4">
        <PaginationListStandalone {...paginationProps} />
      </div>
    </React.Fragment>
  );

  const options = {
    custom: true,
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    showTotal: true,
    totalSize: listCase?.data?.RawData?.length || [],
  };


  return (
    <div className="table-library position-relative">
      {listCase?.isLoading ? (
        <div className="mt-5">
          <LoaderText />
        </div>
      ) : (
        listCase?.data?.RawData?.length === 0 ? (
          <DataEmptyCase
            title="Your Library is Empty"
            text="It looks like you haven't generated any case scenarios yet. Start creating a new one to see it here."
          />
        ) : (
          <PaginationProvider pagination={paginationFactory(options)}>
            {contentTable}
          </PaginationProvider>
        )
      )}
      {openStatistics && (
        <ModalStatistics
          isOpen={openStatistics}
          toggleModal={handleToggle}
          title=""
          idAnalyses={idAnalyses}
        />
      )}
    </div>
  )
}

export default AnswerAnalyses
