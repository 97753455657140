import React from "react";

//Boostrap
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function ModalViewMedia(props) {
    const { isOpen, toggle, values } = props && props;
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            backdrop={`static`}
            zIndex={16000}
            size="lg"
            centered
        >
            <ModalHeader toggle={toggle}>View Media</ModalHeader>
            <ModalBody className="text-center">
                <div className="py-4">
                    {values?.Type === "image" &&
                        <img className="img-fluid" src={values?.URL} alt="values?.Type" />
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="btn btn-secondary" onClick={toggle}>
                    Close
                </Button>{" "}
            </ModalFooter>
        </Modal>
    );
}

export default ModalViewMedia;
