import React, { useState } from 'react'
import { Icon } from '@components/common'
import { ModalListBook } from '../home/generate'
import FormCommon from './FormCommon'
import ClipLoader from "react-spinners/ClipLoader";
import result from '@images/teachV2/engage.svg';

export default function FormEngage(props) {
  const { bookId, setBookId, loadingResult, watch } = props
  const [showModalBooks, setShowModalBooks] = useState(false);
  
  return (
    <div className="formEngage">
      <div className="formEngage__title">
        <div className="title-generate">
          <div className="title-generate__icon">
            <Icon src={result} color="#fff"/>
          </div>
          <p className="title-generate__text">Engagement Modules</p>
        </div>
      </div>
      <div className="formEngage__form">
        <FormCommon {...props} setShowModalBooks={setShowModalBooks} />
        <button className={`${loadingResult || !watch("name") || !watch("instruction") || !watch("statement") ? "disabled" : ""} formEngage__form-button`} type="submit">
          Create Module
          {loadingResult && <ClipLoader color="#FFF" size={16}/>}
        </button>
      </div>
      <ModalListBook
        isOpen={showModalBooks}
        toggle={() => setShowModalBooks(!showModalBooks)}
        setBookId={setBookId}
        bookId={bookId}
        chooseOne={true}
      />
    </div>
  )
}
