import React, { useState } from "react";

//components
import { FormRegister, AuthLogoHeader } from "@components/auth";

// Modal Compeleted
import { ModalResultRegister } from "@components/auth/ModalCompleted";

function RegisterPageV2(props) {
  const [dataRegister, setDataRegister] = useState(null)

  return (
    <div className="wrapper -loginPageV2">
      <AuthLogoHeader {...props} />
      <div className="login_panel">
        {dataRegister?.buttons ? (
          <ModalResultRegister dataMessage={dataRegister} />
        ) : (
          <FormRegister setDataRegister={setDataRegister} />
        )}
      </div>
    </div>
  );
}

export default RegisterPageV2;