import * as Types from "@constants/ActionTypes";
import {apiCaller} from "@utils";

//fetch Case
export const actFetchOrderRequest = (idParam) => {
    return dispatch => {
        return apiCaller(`/api/learn/case/orders/${idParam}/`, 'GET', null).then(res => {
            const data = res && res.data;
            if(res.status === 200) {
                dispatch(actUpdatSateOrder(data));
                if(data && data.Available) {
                    dispatch(actFetchOrder(data));
                    localStorage.setItem("dataExamAvailable", JSON.stringify(data && data.Available));
                }
            } else {
                console.log("error");
            }
        });
    };
}

export const actFetchOrder = (data) => {
    return {
        type : Types.FETCH_ORDERS,
        payload: data
    }
}

export const actPutItemOrder = (id, params) => {
    return dispatch => {
        return apiCaller(`/api/learn/case/orders/${id}/`, 'PUT', params).then(res => {
            if(res && res.data) {
                console.log("success", params);
            }
        });
    };
}

//Update data
export const updateDataOrders = (data) => {
    return dispatch => {
        dispatch(actUpdatSateOrder(data));
    };
}

export const actUpdatSateOrder= (data) => {
    return {
        type : Types.UPDATE_STATE_ORDERS,
        payload: data
    }
}