import React, { useState, useRef, useEffect, createRef } from 'react'
import { Icon } from '@components/common'
import Result from '@images/teachV2/stick-no-bg.svg'
import Export from '@images/teachV2/export.svg'
import GenerateItem from './GenerateItem'
import axios from "axios";
import * as Config from "@constants/Config";
import { toast } from 'react-toastify';
import { apiCaller } from "@utils";
import { DataSlides } from '../dataSlides';
import pptxgen from "pptxgenjs";
import { Button } from 'reactstrap';

const ResultComponent = (props) => {
  const refDefault = useRef()
  let listSlides = useRef([]);
  const { watch, dataFileImported, dataContentFile, setDataContentFile, dataLoadSlideDesk } = props
  const [dataCaseScript, setDataCaseScript] = useState(dataLoadSlideDesk ? dataLoadSlideDesk : null)
  const [dataSlides, setDataSlides] = useState([])
  const [loadingGenerateScript, setLoadingGenerateScript] = useState(false)
  const [modalOpen, setModalOpen] = useState({
    modalGiveFeedback: false,
  });

  useEffect(() => {
    if (dataCaseScript?.slides) {
      const dataSlidesConvert = dataCaseScript?.slides[0]?.contents?.map((tabOption, index) => ({
        id: index + 1,
        content: tabOption?.Contents
      }))
      setDataSlides(dataSlidesConvert)
      listSlides.current = dataCaseScript?.slides[0]?.contents?.length > 0 && dataCaseScript?.slides[0]?.contents?.map((_, i) => listSlides.current[i+1] ?? createRef());
    }
  }, [dataCaseScript?.slides])

  // Handle Toogle Modal
  const toggleModal = (type) => {
    setModalOpen((prevState) => ({
      ...prevState,
      [type]: !modalOpen[type],
    }));
  };

  const handleGenerateSlides = () => {
    setLoadingGenerateScript(true)
    const formData = new FormData();
    dataFileImported && formData.append("document_file", dataFileImported);
    formData.append("action_type", 'slides_creation_pipeline');
    formData.append("template_id", watch('template_id'));
    formData.append("gpt_model", watch('gpt_model'));
    formData.append("step", "main_slides");
    formData.append("id", dataContentFile?.id);
    dataContentFile?.augmented_document_ids && formData.append("document_id", dataContentFile?.augmented_document_ids);
    formData.append("prompt", watch('prompt'));
    formData.append("learning_objectives", dataContentFile?.learning_objectives);

    axios({
      method: "POST",
      url: `${Config.API_URL}/api/medicalcase/functional/`,
      data: formData,
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json'
      },
    }).then(function (response) {
      if(response.status === 200) {
        setDataCaseScript(response.data)
        const dataSlidesConvert = response.data?.slides[0]?.contents?.map((tabOption, index) => ({
          id: index + 1,
          content: tabOption?.Contents
        }))
        setDataSlides(dataSlidesConvert)
      }
    }).catch(function (error) {
      console.error("Error in API call", error);
    })
    .finally(() => {
      setLoadingGenerateScript(false)
    })
  }

  const handleExport = () => {
    const pptx = new pptxgen();
    dataSlides?.forEach((tab, index) => {
      let slide = pptx.addSlide(tab.id)

      // Convert HTML to PptxGenJS elements
      var parser = new DOMParser();
      var doc = parser.parseFromString(listSlides.current[index].current.innerHTML, 'text/html');
      // Extract elements from parsed HTML
      let headings = Array.from(doc.querySelectorAll('h1, h2, h3, h4, h5, h6'));
      let paragraphs = Array.from(doc.querySelectorAll('p'));
      let lists = Array.from(doc.querySelectorAll('ul, ol'));

      // Add headings
      headings.forEach(function(heading) {
        slide.addText(heading.innerText, { 
          fontSize: 24, 
          bold: true,
          x: 1,
          y: 0.8,
        });
      });

      // Add paragraphs
      paragraphs.forEach(function(paragraph) {
        slide.addText(paragraph.innerText, { 
          x: 0.6,
          y: 1,
        });
      });

      // Add lists
      lists.forEach(function(list) {
        let items = Array.from(list.querySelectorAll('li'));
        let listText = items.map(function(item) { return item.innerText; }).join('\n');
        slide.addText(listText, { 
          bullet: true,
          x: 1.5,
          y: 1.5,
        });
      });

      if (!lists.length && !paragraphs.length && !headings.length) {
        slide.addText(listSlides.current[index].current.innerText, { 
          bullet: true,
          x: 1.5,
          y: 1.5,
        });
      }

    })
    pptx.writeFile({ fileName: dataCaseScript?.title ? dataCaseScript?.title : "slides.pptx" })
  }

  const handleSave = (dataRef, number) => {
    if (dataCaseScript?.slides) {
      const dataCloneFile = {
        ...dataContentFile,
        learning_objectives: refDefault?.current?.innerHTML || dataContentFile?.learning_objectives
      }
      setDataContentFile(dataCloneFile)
      const dataChange = dataCaseScript?.slides[0]?.contents?.map((content, index) => {
        if (index === number) {
          return {
            ...content,
            Contents: dataRef?.current?.innerHTML
          }
        }
        return content
      })
      setDataCaseScript(prev => {
        return {
          ...prev,
          slides: [
            {
              name: "Slides",
              "contents": dataChange
            }
          ],
        }
      })
      const dataClone = {
        ...dataCaseScript,
        slides: [
          {
            name: "Slides",
            "contents": dataChange
          }
        ],
        learning_objectives: refDefault?.current?.innerHTML || dataContentFile?.learning_objectives
      }
      const params = {
        "action_type": "save_slide_deck",
        ...dataClone
      }
      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
        if (res.status === 200) {
          toast.success(`Case contents have been updated!`, {
            position: "top-right"
          });
        }
      });
    } else {
      const dataClone = {
        ...dataContentFile,
        learning_objectives: refDefault?.current?.innerHTML || dataContentFile?.learning_objectives
      }
      setDataContentFile(dataClone)
      const params = {
        "action_type": "save_slide_deck",
        ...dataClone
      }
      apiCaller(`/api/medicalcase/functional/`, 'POST', params).then(res => {
        if (res.status === 200) {
          toast.success(`Case contents have been updated!`, {
            position: "top-right"
          });
        }
      });
    }
  }

  return (
    <div className="resultComponent">
      <div className="resultComponent__header w-100">
        <div className="resultComponent__header-title">
          <div className="result-icon">
            <Icon src={Result}/>
          </div>
          <p>Results</p>
        </div>
      </div>
      <div className="resultComponent__title w-100">
        <p className="resultComponent__title-label">Presentation Title:</p>
        <div className="d-flex align-items-center justify-content-between">
          <p className="resultComponent__title-content w-100">{dataCaseScript?.title || dataContentFile?.title}</p>
          {dataCaseScript?.slides && 
            <Button className='btn btn-export d-flex align-items-center ml-2' onClick={handleExport}>
              <Icon src={Export} stroke="#0089C2"/>
              Export
            </Button>
          }
        </div>
      </div>
      <div className="resultComponent__generate w-100">
        <GenerateItem
          handleGenerateSlides={handleGenerateSlides}
          dataCaseScript={dataCaseScript}
          contentGenerate={dataContentFile?.learning_objectives} 
          loadingGenerateScript={loadingGenerateScript}
          handleSave={handleSave}
          dataRef={refDefault}
          index={0}
          toggleModal={toggleModal}
          {...props} 
        />
      </div>
      {dataCaseScript?.slides && (
        <DataSlides 
          listSlides={listSlides}
          dataSlides={dataSlides}
          handleSave={handleSave}
        />
      )}
    </div>
  )
}

export default ResultComponent
