import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PopperItem } from "@components/common/Popper";
import { Iconsax } from "@components-common";
import { MenuActionFixed } from "../AssistantQuestion";
import { PopperChatFeedback } from "../ChatFeedback";
import _ from "lodash";

// Mobile
import { useMediaQuery } from 'react-responsive';

// Components
import LockView from "./LockView";

//actions
import { setTypeChatActive, LoadVirtualPreceptorStatus } from "@actions";

// Image
import imageInstructor from "@images/learnerv2/pic-assistant-1.png";
import imgUser from '@images/top/user.svg';

// Button ERM Mobile
import { ERMButtonFixed } from "@components/learner/StandardScreen/LearnerMobile";

const AssistantAvatarSmall = (props) => {
  // Destructure props
  const {
    stateType,
    handleItemClick,
    isFullScreenViewWithChat,
    handleClosePopper,
    showPopper
  } = props && props;


  // Extract data from props
  const dispatch = useDispatch();

  // Lấy dữ liệu Case từ Redux store
  const caseStore = useSelector((state) => state.cases || []);
  const caseData = caseStore?.data?.Case;
  const Interface = caseData?.Interface || {};
  const PatientAvatar = Interface?.PatientAvatar;
  const InteractiveItems = Interface?.InteractiveItems || [];
  const InstantChatFeedbackOption = caseData?.ChatSettings?.InstantChatFeedbackOption;
  const PreceptorAvatar = Interface?.PreceptorAvatar;
  const hasChatTask = _.some(InteractiveItems, { 'Task': 'chat' });
  let CurrentFrontSessionId = caseData?.Session?.CurrentFrontSessionId;

  // Get the question data and loading state from the Redux store
  const questionData = useSelector((state) => state.question || {});
  const isQuestionLoading = questionData?.isLoading;

  // Check missing task
  const missingTaskStatus = useSelector((state) => (state?.checkMissingTask?.data) || {});
  const isPendingQuestion = missingTaskStatus?.status === "PendingQuestion";

  //Check Lock Elements
  const isChatPending = useSelector((state) => state.isChatPending || {});
  const isLock = isChatPending?.isLock;

  // Define image paths
  const imagePaths = [
    {
      path: PreceptorAvatar || imageInstructor,
      type: "question",
      isShow: true
    },
    {
      path: PatientAvatar,
      type: "chat",
      isShow: hasChatTask
    }
  ];

  // Define zIndexValue
  const zIndexValue = isFullScreenViewWithChat ? '16000' : '10';

  // Set default active type when the component mounts
  useEffect(() => {
    const activeType = Object.keys(stateType).find(key => stateType[key]);
    if (activeType) {
      dispatch(setTypeChatActive(activeType));
    }
  }, [dispatch, stateType]);

  // Render list item function
  const renderListItem = (item, index) => {
    const isActiveTask = stateType[item.type];
    const visiblePatient = `${item?.isShow ? "d-block" : "d-none"}`
    const listItemClasses = `list-assistant__item position-relative ${visiblePatient} ${isActiveTask ? "active" : ""}`;

    const itemStyles = {
      backgroundImage: `url(${item.path || imgUser})`,
      cursor: 'pointer',
      pointerEvents: isActiveTask || isQuestionLoading ? "none" : "auto",
      width: '40px', // Set width
      height: '40px' // Set height
    };
    const isQuestionPopperVisible = showPopper?.showWhenChat || isPendingQuestion;

    return (
      <div className={listItemClasses} key={index}>
        {isLock && <LockView {...props} />}
        <div
          className={`list-assistant__item--image`}
          lock-type={item.type}
          style={itemStyles}
          onClick={() => {
            // Kiểm tra nếu active thì dừng sự kiện click
            if (isActiveTask) return;

            // Gán type hiện tại cho setTypeChatActive
            handleItemClick(item.type);
            dispatch(setTypeChatActive(item.type));
            if (item?.type === "question") {
              dispatch(LoadVirtualPreceptorStatus(CurrentFrontSessionId)); // Call LoadVirtualPreceptorStatus when question is clicked
            }
          }}
        ></div>
        {item.type === "question" && !stateType.question && (
          <PopperItem
            content={""}
            styleInline={!isMobile ? {
              left: '100%',
              top: '0px',
              position: 'absolute',
              marginLeft: '20px',
              minWidth: 'auto',
              minHeight: '30px',
              padding: '5px'
            } : {
              right: '100%',
              top: '0px',
              position: 'absolute',
              marginLeft: '20px',
              minWidth: 'auto',
              minHeight: '30px',
              padding: '5px'
            }}
            postition={isMobile ? "right" : "left"}
            isOpen={isQuestionPopperVisible}
            icon={<Iconsax iconName="question" size={32} fill="#F79E1C" />}
            handleClosePopper={handleClosePopper}
            buttonClose={false}
          />
        )}
        {!isMobile && item.type === "question" && InstantChatFeedbackOption && (
          <PopperChatFeedback
            {...props}
            styleInline={{
              left: '100%',
              top: '0px',
              position: 'absolute',
              marginLeft: '20px',
              minWidth: '568px',
              minHeight: '30px',
              padding: '5px'
            }}
            postition="left-top"
          />
        )}
      </div>
    );
  };

  // Detect if the screen size is mobile
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  return (
    <div style={{ zIndex: zIndexValue }} id="fixed-list-assistant" className={`${isFullScreenViewWithChat ? "show-fixed" : "hide-fixed"} ${isMobile ? 'is-mobile' : ''}`}>
      {isMobile && <ERMButtonFixed {...props} />}
      <div className="list-assistant">
        {imagePaths?.map((item, index) => renderListItem(item, index))}
      </div>
      <MenuActionFixed {...props} />
    </div>
  );
}

export default AssistantAvatarSmall;