import React, { useState } from "react";
import { FormGroup, Label, Button, FormFeedback } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import { useForm } from "react-hook-form";
import { apiCaller } from "@utils";

function QuestionGeneratePage() {
    const [loading, setLoading] = useState(false);
    const [resData, setResData] = useState({});
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const params = {
                action_type: "create_question",
                medical_topic: data.medical_topic
            };
            const response = await apiCaller("/api/medicalcase/functional/", "POST", params);
            if (response.status === 200) {
                setResData(response.data);
            } else {
                console.error("Error:", response.statusText);
            }
        } catch (error) {
            console.error("Error:", error.message);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="wrapper -appChatPage">
            <div className="main-question-generate bg-white vh-100 py-4">
                <div className="container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup className="mt-2" invalid={errors.medical_topic}>
                            <Label for="medical_topic">Top/Instructions: <span className="m--font-danger">*</span> </Label>
                            <TextareaAutosize
                                {...register("medical_topic", { required: true })}
                                name="medical_topic"
                                placeholder="Enter your medical topic..."
                                className={`w-100 form-control ${errors.medical_topic ? "is-invalid" : ""}`}
                                maxRows={10}
                                minRows={10}
                                defaultValue={watch("medical_topic")}
                                disabled={loading}
                            />
                            {errors.medical_topic && (
                                <FormFeedback className="d-block">
                                    This is required.
                                </FormFeedback>
                            )}

                            <Button
                                type="submit"
                                color="danger"
                                className="btn btn-brand --bg-primary mt-4 px-4"
                                disabled={loading}
                                size="lg"
                            >
                                {loading && <span className="spinner-border spinner-border-sm mr-2"></span>}
                                Generate
                            </Button>
                        </FormGroup>
                    </form>
                    <div className="mt-4">
                        <div className={`whiteSpace-break`} dangerouslySetInnerHTML={{ __html: resData?.question || '' }}></div>
                        <div className="mt-3">
                            {resData?.multiple_choices?.length > 0 && (
                                <ul>
                                    {resData?.multiple_choices?.map(({ id, content }) => (
                                        <li key={id}>
                                            <strong>{id}:</strong> {content}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default QuestionGeneratePage;
