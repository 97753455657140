import React from 'react';
import { useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { PopperItem } from "@components/common/Popper";

function ButtonTaskItem({
    handleClickItem,
    label,
    icon,
    attributeTask,
    attributeTour,
    isDisabled,
    isOpenPopper,
    contentPopper,
    nextTour,
    isErm,
    activeMenuTask,
    dataPopper,
    isViewMessage
}) {
    const missingTaskData = useSelector(state => state?.checkMissingTask?.data) || {};
    const isMissingTask = missingTaskData?.MissingTask;
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const activeMenu = activeMenuTask === attributeTask ? "active" : "";
    const lockState = !isMobile && isDisabled ? "lockItem" : "unlockItem";
    const popperContent = isErm ? contentPopper : dataPopper?.[0]?.Content;
    const popperNextTour = isErm ? nextTour : dataPopper?.[0]?.nextTour;
    const mobileClickStyle = isMobile ? { pointerEvents: 'auto' } : {};
    return (
        <div
            className={`tasks-nav__item position-relative ${activeMenu} ${lockState}`}
            data-task={attributeTask}
            data-tour={attributeTour}
            onClick={handleClickItem}
            style={{
                pointerEvents: activeMenuTask === attributeTask ? 'none' : 'auto',
                ...mobileClickStyle
            }}
        >
            <div className="tasks-nav__item--icon">
                <img src={icon} alt={label} />
            </div>
            <div className="tasks-nav__item--text">
                <div dangerouslySetInnerHTML={{ __html: label }} />
            </div>
            {(isMissingTask === attributeTask || isViewMessage) && (
                <span className='tasks-nav__item--focus'>
                    <span className="pulse"></span>
                    Ready
                </span>
            )}
            <PopperItem
                {...{ attributeTask, attributeTour, isOpenPopper }}
                styleInline={{ right: '180px', bottom: '0', position: 'absolute' }}
                postition="right"
                content={popperContent}
                nextTour={popperNextTour}
            />
        </div>
    );
}

export default ButtonTaskItem;