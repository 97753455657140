import React, { useState, useEffect } from "react";

//components
import ChatRecordsContent from "./ChatRecordsContent";

//utils
import { LoaderText, apiCaller } from "@utils";

//react-router-dom
import { useParams } from "react-router-dom";

function ChatRecordsTab(props) {
    let { id } = useParams();

    //state
    const [activeTab, setActiveTab] = useState(1);
    const [stateData, setData] = useState({});
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            const params = { "Action": "LoadChatRecord" }
            apiCaller(`/api/learn/case/chat/${id}/`, "PUT", params).then((res) => {
                const data = res?.data;
                if (res?.status === 200) {
                    setData(data);
                    setLoading(false);
                } else {
                    console.log('error');
                    setLoading(false);
                }
            });
        }
        //Load Api Model
        fetchData();
    }, [id])

    const handleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <ul className="tabs-corner">
                {stateData?.EMRChatData?.map((value, index) => (
                    <li
                        className={`tabs-corner__item ${activeTab === parseInt(index + 1) ? 'active' : ''}`}
                        key={index}
                        onClick={() => {
                            handleTab(parseInt(index + 1));
                        }}
                    >
                        <span className="tabs-corner__item--text">{value.TimePointName}</span>
                        {value?.Icon && <span className={`tabs-corner__item--icon ${value?.Icon}`}></span>}
                    </li>
                ))}
            </ul>
            <div className="result-tab-content">
                {stateData?.EMRChatData?.map((value, index) => (
                    <div key={index} className={`${activeTab === parseInt(index + 1) ? 'd-block' : 'd-none'}`}>
                        <ChatRecordsContent
                            {...props}
                            ChatRecords={value?.ChatRecords}
                        />
                    </div>
                ))}
            </div>
            {isLoading && <LoaderText />}
        </React.Fragment>
    );
}

export default ChatRecordsTab;