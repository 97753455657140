import React from "react";
import { Icon } from '@components/common'
import EditModal from '@images/teachV2/edit-modal.svg'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalEditRequirement = ({ isOpen, toggleModal }) => {

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      centered
      size='lg'
      className='modal-custom modal-size-lg'
      maxWidth="860px"
    >
      <ModalHeader toggle={toggleModal}>
        <Icon src={EditModal} />
        <p className='mt-3 modal-title__text'>Edit Requirements</p>
      </ModalHeader>
      <ModalBody>
        aaaa
      </ModalBody>
      <ModalFooter>
        <div className='d-flex w-100'>
          <div className={'w-100'}>
            <div className="d-flex align-items-center justify-content-end">
              <Button
                color="secondary"
                className="button-cta button-sm"
                onClick={toggleModal}
                // disabled={isLoading}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
    </ModalFooter>
    </Modal>
  );
};

export default ModalEditRequirement;
