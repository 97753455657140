import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

//JoditEditor
import JoditEditor from "jodit-react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Input,
    Label,
    FormFeedback
} from "reactstrap";

//Config
import * as Config from "@constants/Config";

//redux
import { useDispatch } from "react-redux";

//apiCaller
import { apiCaller } from "@utils";
import { problemsConstant } from "@constants";

const ModalAddProblem = (props) => {
    let { id } = useParams();
    const dispatch = useDispatch();
    const { toggle, isOpen } = props && props;
    const editor = useRef(null);

    //state
    const [isLoading, setLoading] = useState(false);
    const [content, setContent] = useState('');

    //form
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch
    } = useForm();

    const onSubmit = (data) => {
        const params = {
            "Action": "CreateProblem",
            "CaseId": id,
            "Name": data?.Name,
            "Comments": content
        };

        setLoading(true);
        dispatch({ type: problemsConstant.CREATE_PROBLEM_ITEM_REQUEST, isLoading: true });
        apiCaller(`/api/teach/documentation/`, "POST", params).then((res) => {
            const data = res?.data;
            if (res?.status === 200) {
                dispatch({ type: problemsConstant.CREATE_PROBLEM_ITEM_SUCCESS, payload: data });
                toggle();
                setLoading(false);
            } else {
                dispatch({ type: problemsConstant.CREATE_PROBLEM_ITEM_FAILURE, error: "error" });
                setLoading(false);
                toggle();
            }
        });

        //Reset data
        setValue('Name', '', { shouldValidate: false });
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ maxWidth: "800px" }}>
            <ModalHeader toggle={toggle}>Add Problem</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="Name">Name: <span className="m--font-danger">*</span></Label>
                    <Input
                        type="text"
                        name="Name"
                        {...register("Name", { required: true })}
                        invalid={errors.Name?.type === "required"}
                        defaultValue={watch("Name")}
                        onChange={(e) => {
                            setValue("Name", e.target.value, { shouldValidate: true });
                        }}
                        placeholder=""
                        autoComplete="off"
                        className="w-50"
                    />
                    <FormFeedback
                        invalid={String(errors.Name?.type === "required")}
                    >
                        This is required.
                    </FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="Comments">Comments:</Label>
                    <JoditEditor
                        ref={editor}
                        value={content}
                        config={Config.CONFIG_JODIT}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>{" "}
                <Button
                    color="danger"
                    className="btn btn-brand --bg-primary"
                    onClick={handleSubmit(onSubmit)}
                >
                    {isLoading &&
                        <span className="spinner-border spinner-border-sm mr-2"></span>
                    }
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddProblem;
