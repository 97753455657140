import React from "react";

function ListTabsOrders(props) {
    //props
    const { navListGroup, activeTab, handleTab } = props && props;
    return (
        <ul className="tabs-corner">
            {navListGroup?.map((value, index) => (
                <li
                    className={`tabs-corner__item ${activeTab === (index + 1).toString() ? 'active' : ''}`}
                    key={index}
                    onClick={() => {
                        handleTab(String(index + 1));
                    }}
                >
                    <span className="tabs-corner__item--text">{value.Name}</span>
                    <span className={`tabs-corner__item--icon ${value?.Icon}`}></span>
                </li>
            ))}
        </ul>
    );
}

export default ListTabsOrders;
